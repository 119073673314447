import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import {PollLegend} from "./poll-legend";
import {Route} from "react-router-dom";
import $ from "jquery";

export class SolitudeForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=24;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = [1, 2, 3, 4];
        var F = React.Fragment;
        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Вам предлагается ряд утверждений. Пожалуйста, рассмотрите
                    последовательно каждое и оцените, насколько Вы согласны с
                    ним, выбрав один из четырех вариантов ответа: «Не согласен»,
                    «Скорее не согласен», «Скорее согласен», «Согласен».
                </i>
                <div className="poll-header">
                    <h2 className="db cb">
                        Дифференциальный опросник переживания одиночества
                    </h2>
                    <PollLegend
                        className="pt3"
                        opts={[
                            { key: "1", title: (<F>Не<br/>согласен</F>) },
                            { key: "2", title: (<F>Скорее<br/>не согласен</F>) },
                            { key: "3", title: (<F>Скорее<br/>согласен</F>) },
                            { key: "4", title: (<F>Да<br/>согласен</F>) }
                        ]}
                    />
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
						<div className="q1">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_1")}
							>
								Я чувствую себя одиноким.
							</PollRadios>
						</div>
						<div className="q2">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_2")}
							>
								Когда рядом со мной никого нет, я испытываю скуку.
							</PollRadios>
						</div>
						<div className="q3">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_3")}
							>
								Я люблю оставаться наедине с самим собой.
							</PollRadios>
						</div>
						<div className="q4">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_4")}
							>
								Есть люди, с которыми я могу поговорить.
							</PollRadios>
						</div>
						<div className="q5">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_5")}
							>
								Нет никого, к кому бы я мог обратиться.
							</PollRadios>
						</div>
						<div className="q6">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_6")}
							>
								Мне трудно найти людей, с которыми можно было бы поделиться моими мыслями.
							</PollRadios>
						</div>
						<div className="q7">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_7")}
							>
								В одиночестве приходят интересные идеи.
							</PollRadios>
						</div>
						<div className="q8">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_8")}
							>
								Мне трудно быть вдали от людей.
							</PollRadios>
						</div>
						<div className="q9">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_9")}
							>
								Бывают чувства, ощутить которые можно лишь наедине с собой.
							</PollRadios>
						</div>
						<div className="q10">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_10")}
							>
								Есть люди, которые по-настоящему понимают меня.
							</PollRadios>
						</div>
						<div className="q11">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_11")}
							>
								Я не люблю оставаться один.
							</PollRadios>
						</div>
						<div className="q12">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_12")}
							>
								Чтобы понять какие-то важные вещи, человеку необходимо остаться одному.
							</PollRadios>
						</div>
						<div className="q13">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_13")}
							>
								Когда я остаюсь один, я не испытываю неприятных чувств.
							</PollRadios>
						</div>
						<div className="q14">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_14")}
							>
								Я чувствую себя покинутым.
							</PollRadios>
						</div>
						<div className="q15">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_15")}
							>
								В одиночестве голова работает лучше.
							</PollRadios>
						</div>
						<div className="q16">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_16")}
							>
								Люди вокруг меня, но не со мной.
							</PollRadios>
						</div>
						<div className="q17">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_17")}
							>
								В одиночестве человек познает самого себя.
							</PollRadios>
						</div>
						<div className="q18">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_18")}
							>
								Я плохо выношу отсутствие компании.
							</PollRadios>
						</div>
						<div className="q19">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_19")}
							>
								В одиночестве я чувствую себя самим собой.
							</PollRadios>
						</div>
						<div className="q20">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_20")}
							>
								Худшее, что можно сделать с человеком, – это оставить его одного.
							</PollRadios>
						</div>
						<div className="q21">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_21")}
							>
								Мне кажется, что меня никто не понимает.
							</PollRadios>
						</div>
						<div className="q22">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_22")}
							>
								Мне хорошо дома, когда я один.
							</PollRadios>
						</div>
						<div className="q23">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_23")}
							>
								Когда я остаюсь один, я испытываю дискомфорт.
							</PollRadios>
						</div>
						<div className="q24">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("solitude_24")}
							>
								В одиночестве каждый видит в себе то, что он есть на самом деле.
							</PollRadios>
						</div>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SolitudeForm;
