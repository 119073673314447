import React from "react";
import { Link, Route } from "react-router-dom";
import ProfileCard from "./profile-card";
import Menu from "./menu";
import FeedbackSide from './feedback-side'

class EditSide extends React.Component {
    render() {
        var form = this.props.form;
        return (
            <div className="side">
                <div className="nav-panel">
                    <Route
                        render={({ history }) => (
                            <div
                                className="nav-item results red"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/risk/health",
                                        e.target,
                                        true
                                    )
                                }
                            />
                        )}
                    />
                </div>
                <div className="secondary-panel appear-right">
                    <ProfileCard
                        goTo={this.props.goTo}
                        form={form}
                        user={this.props.user}
                    />
                    <Menu
                        form={form.form}
                        checkChanges={this.props.checkChanges}
                        goTo={this.props.goTo}
                    />
                    <div className="bottom-panel">
                        <div className="menu-item">
                            <FeedbackSide/>
                        </div>
                        <div className="menu-item">
                            <Link className="glyph glyph-exit" to="/eng/auth/logout">
                                {form.side.logoutAnchor}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditSide;
