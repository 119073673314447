import React from "react";
import { LabeledInput } from "./LabeledInput";

import "../../css/comp/input-overlay.css";

export class AddressInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { suggests: [], value: "", selected: -1 };

        this.abortRequest = this.abortRequest.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hideSuggests = this.hideSuggests.bind(this);
        this.handleBodyKey = this.handleBodyKey.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.selectSuggest = this.selectSuggest.bind(this);
        this.selectSuggestValue = this.selectSuggestValue.bind(this);
    }

    /**
     * Скрывает список подсказок для инпута.
     * Если задан «e», скрываем только если target вне списка
     * @param {Event} e
     * @param {Boolean} force
     */
    hideSuggests(e, force) {
        if (
            force ||
            e.target.parentNode.className.indexOf("input-overlay") === -1
        ) {
            this.setState({ suggests: [], selected: -1 });
        }
    }
    /**
     * Обработчик нажатия кнопки (на body).
     * Если «Esc» - скрывает список подсказок
     * @param {Event} e
     */
    handleBodyKey(e) {
        if (e.which === 27) {
            //Esc
            this.hideSuggests(null, true);
        }
    }

    /**
     * Обработчик изменения значения поля ввода
     * Показывает список подсказок по значению
     * @param {Object} v Объект вида {name:'',value''}
     */
    handleChange(v) {
        this.abortRequest();
        
        if(this.props.onChange)
            this.props.onChange(v);

        new Promise((resolve, reject) => {
            var x = new XMLHttpRequest();
            x.open(
                "POST",
                "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
            );
            x.setRequestHeader("Content-Type", "application/json");
            x.setRequestHeader("Accept", "application/json");
            x.setRequestHeader(
                "Authorization",
                "Token 69827faa18bdaf673291dc9e10e634996a5fc533"
            );

            x.onload = x.onerror = () => {
                resolve(JSON.parse(x.responseText), x); //Здесь резолв
                this.abortRequest();
            };

            this.requests.push(x);
            x.send(JSON.stringify({ query: v.value, count: 5 }));
        }).then((res, x) => {
            var sugs = res.suggestions;
            if (
                sugs.length === 1 &&
                this.formatValue(sugs[0]) === this.props.value
            )
                sugs = [];
            this.setState({ suggests: sugs, selected: -1 });
        });
    }

    handleKeyDown(e) {
        if (this.state.suggests.length > 0) {
            let sel = -1;
            if (e.which === 40) {
                //Вниз
                sel = this.state.selected + 1;
                if (sel >= this.state.suggests.length) {
                    sel = this.state.suggests.length - 1;
                }
                this.setState({ selected: sel });
                e.preventDefault();
            } else if (e.which === 38) {
                //Вверх
                sel = this.state.selected - 1;
                if (sel < 0) {
                    sel = 0;
                }
                this.setState({ selected: sel });
                e.preventDefault();
            } else if (e.which === 13) {
                //Enter
                if (this.state.selected > -1) {
                    this.selectSuggest(this.state.selected);
                    e.preventDefault();
                }
            }
        }
    }

    /**
     * Выбирает из текущего списка подсказок одну
     * по индексу и устанавливает ее значение в инпут.
     * Вызывает onChange
     * @param {Number} i - Индекс подсказки
     */
    selectSuggest(i) {
        if (this.state.suggests.length > i) {
            var s = this.state.suggests[i];

            this.selectSuggestValue(this.formatValue(s));
        }
    }
    formatValue(s) {
        var vals = ["Россия"];
        if (s.data.region && s.data.region_type !== "г") {
            vals.push(s.data.region + " " + s.data.region_type_full);
        }
        if (s.data.area) {
            vals.push(s.data.area + " " + s.data.area_type_full);
        }
        if (s.data.city) {
            vals.push(s.data.city);
        }
        if (s.data.settlement) {
            vals.push(s.data.settlement_type_full + " " + s.data.settlement);
        }
        if (s.data.street) {
            vals.push(s.data.street + " " + s.data.street_type_full);
        }
        if (s.data.house) {
            vals.push(s.data.house);
            if (s.data.block) {
                vals[vals.length - 1] += s.data.block_type + s.data.block;
            }
        } else if (s.data.block) {
            vals.push(s.data.block_type + " " + s.data.block);
        }
        return vals.join(", ");
    }
    /**
     * Устанавливает значение в инпут. Вызывает onChange
     * @param {string} v - Значение для установки в инпут
     */
    selectSuggestValue(v) {
        this.setState({
            suggests: [],
            selected: -1
        });
        this.handleChange({
            name: this.props.name,
            value: v
        });
    }

    /**
     * Отменяет исполнение всех запущенных XHR
     */
    abortRequest() {
        this.requests.forEach(x => x.abort());
        this.requests = [];
    }
    componentDidMount() {
        this.requests = [];
        document.body.addEventListener("click", this.hideSuggests);
        document.body.addEventListener("keyup", this.handleBodyKey);
    }
    componentWillUnmount() {
        document.body.removeEventListener("click", this.hideSuggests);
        document.body.removeEventListener("keyup", this.handleBodyKey);
    }

    render() {
        var i = 0;
        var sugs = this.state.suggests.map(s => (
            <SuggestListItem
                onClick={this.selectSuggestValue}
                value={this.formatValue(s)}
                selected={i === this.state.selected}
                key={i++}
            />
        ));
        var overlay = <div className="input-overlay">{sugs}</div>;

        if (this.state.suggests.length === 0) {
            overlay = undefined;
        }
        return (
            <React.Fragment>
                <LabeledInput
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    name={this.props.name}
                    type="text"
                    value={this.props.value}
                    label={this.props.label}
                    className="col col-six"
                    icon="icon-search"
                />
                {overlay}
            </React.Fragment>
        );
    }
}

/**
 * Строка списка подсказок
 */
class SuggestListItem extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.onClick) this.props.onClick(this.props.value);
    }
    render() {
        return (
            <div
                onClick={this.handleClick}
                className={"row " + (this.props.selected ? "selected" : "")}
            >
                {this.props.value}
            </div>
        );
    }
}
