import React from "react";
import cx from 'classnames';
import moment from 'moment';

import RiskHeader from "../RiskHeader";
import DateChanger from "../DateChanger";
import PopupBlock from "../PopupBlock";
import EventsListMonth from "./EventsListMonth";
import { save_text_file } from "../utils"

import * as sampleprops from '../sampleprops';
import "../../../css/comp/risk/calendar-year-page.css";

import EventPicker from './EventPicker'
import Modal from 'react-modal';

import LabeledDatePicker from "../../shared/LabeledDatePicker";
import { Api } from "../../../libs/api";

const MONTHS_NAMES = [
    'январь', 'февраль', 'март', 'апрель',
    'май', 'июнь', 'июль', 'август',
    'сентябрь', 'октябрь', 'ноябрь', 'декабрь',
]

export default class CalendarYearPage extends React.Component {
    constructor(props) {
        super(props);

        const currYear = moment().year();

        this.state = {
            currYear: currYear,
            openedMonthIx: null,
            data: sampleprops.calendarYearPageRandom(),
            // TODODOO
            loading_events: true,
            loading_event_types: true,
            events: undefined,
            minDate: new Date(),
            // maxDate: new Date(),
            eventTypes: {},
            modalIsOpen: false,
            selectedEventType: undefined,
            // error dialog
            errorMessageDialogIsOpen: false,
            errorMessage: undefined
        };

        this.eventPickerRef = React.createRef();
    }

    fetchEvents = (year) => {
        Api
        .point('events', {'year': year})
        .then((res) => {

            const minDate = new Date()
            const maxDate = new Date(year, 11, 30, 23, 59)

            this.setState({
                currYear:       year,
                openedMonthIx:  null,
                eventsData:     res.eventsData,
                loading_events: false,
                minDate:        minDate,
                maxDate:        maxDate,
            });

            this.forceFilter();
        })
    }

    forceFilter = () => {
        if(this.eventPickerRef.current)
            this.eventPickerRef.current.filterEventTypes();
    }

    fetchEventTypes = () => {
        Api.point('event_types', {}, 'POST')
        .then((res) => {
            this.setState({
                eventTypes:             res.eventTypes,
                loading_event_types:    false
            })
            this.forceFilter();
        })
        .catch((res) => {
            this.showErrorMessage("Ошибка загрузки списка мероприятий");
        })
    }
    
    onEventCreate = (date) => {

        if(!this.state.selectedEventType){
            this.showErrorMessage("Сначала выберите мероприятие");
            return;
        }

        const response = Api.point('create_event', {
            event: {
                event_type: this.state.selectedEventType,
                date: date
            }
        })

        response.then((res) => {

            this.setState((oldState) => {
                this.state.selectedEventType.event  = res.eventsData.event;
                oldState.selectedEventType          = undefined
                oldState.modalIsOpen                = false
                return oldState
            },
            () => {
                this.fetchEvents(this.state.currYear);
                this.fetchEventTypes();
            })

        })

        response.catch(() => {
            this.showErrorMessage('Ошибка добавления мероприятия')
        })

        return response;
    }

    onEventDelete = (eventType) => {
        Api.point('delete_event', {'event_type': eventType})
        .then(
            () => {
                eventType.event = undefined
                this.fetchEvents(this.state.currYear);
                this.fetchEventTypes();
            }
        )
        .catch(
            () => {
                this.showErrorMessage('Не удалось удалить мероприятие');
            }
        )
    }

    showErrorMessage = (message) => {
        this.setState({
            errorMessageDialogIsOpen: true,
            errorMessage: message
        })
    }

    componentDidMount = () => {
        this.fetchEvents(this.state.currYear);
        this.fetchEventTypes();
    }

    getEventTypeIdsByMonth = (monthIdx) => {

        if(!this.state.eventsData || this.state.eventsData.length === 0) return []

        var filtered = Object.keys(this.state.eventsData).filter(
            (eventTypeId) => 
            this.state.eventsData[eventTypeId].event 
            &&
            new Date(this.state.eventsData[eventTypeId].event.date).getMonth() == monthIdx
        )

        return filtered;
    }


    handleChangerClick = (year) => {
        if(!this.state.loading){
            this.setState({
                loading_events: true
            });
            this.fetchEvents(year);    
        }

    }

    handleItemClick = (eventType) => {
        this.setState({
            modalIsOpen: true,
            selectedEventType: eventType
        })
    }

    downloadCalendar = () => {
        const { currYear, openedMonthIx, data } = this.state // eslint-disable-line no-unused-vars
        

        const strs = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'PRODID:-//Some Company//Some App//RU'
        ]

        data.fullData.forEach((monthData, monthIx) => {
            monthData.byDay.forEach(({ day, events }) => {
                events.forEach((event, ix) => {
                    const date = moment({ year: currYear, month: monthIx, day: day })
                    strs.push(
                        'BEGIN:VEVENT',
                        'UID:' + date.format('YYYYMMDD') + '-' + ix,
                        "DTSTAMP:" + moment().format('YYYYMMDDTHHmmss'),
                        'DTSTART:' + date.format('YYYYMMDD'),
                        'SUMMARY:' + event.title,
                        'END:VEVENT',
                    )
                });
            })
        })
        strs.push('END:VCALENDAR')
        const text = strs.join('\r\n')


        save_text_file('calendar.ics', text)
    }

    renderCalendarCells = () => {
        if(this.state.loading) return <div></div>

        const { currYear, openedMonthIx, data } = this.state

        return MONTHS_NAMES.map((monthName, monthIx) => {
            const nowDate = moment();

            let timePeriod = '';
            if (currYear < nowDate.year()) {
                timePeriod = 'past';
            } else if (currYear > nowDate.year()) {
                timePeriod = 'future';
            } else {
                timePeriod = (monthIx < nowDate.month()) ? 'past'
                    : ((monthIx > nowDate.month()) ? 'future' : 'present');
            }

            const onMoreClick = () => {
                this.setState({
                    openedMonthIx: (openedMonthIx === monthIx) ? null : monthIx,
                });
            }
            return (
                <CalendarYearCell
                    eventsData={this.state.eventsData}
                    monthName={monthName}
                    timePeriod={timePeriod}
                    eventTypeIds={this.getEventTypeIdsByMonth(monthIx)}
                    onMoreClick={onMoreClick}
                    isOpened={openedMonthIx === monthIx}
                    eventsCountLimit={5}
                />
            );
        })
    }

    renderCalendarRows = () => {
        const cells = this.renderCalendarCells();
        return [0, 1, 2].map((rowIx, ix) => (
            <tr key={ix}>
                {[0, 1, 2, 3].map((colIx, ix) => (
                    <td key={ix}>
                        {cells[(rowIx * 4) + colIx]}
                    </td>)
                )}
            </tr>
        ));
    }

    renderPopupBlockCell = () => {
        const { currYear, openedMonthIx, data } = this.state;

        const onCloseClick = () => {
            this.setState({ openedMonthIx: null });
        }

        return (
            <PopupBlock
                className="appear-up"
                title={`Список мероприятий на ${MONTHS_NAMES[openedMonthIx]}`}
                onCloseClick={onCloseClick}
                arrowStyle={{
                    marginLeft: '15px',
                    left: `${25 * (openedMonthIx % 4)}%`,
                }}
            >
                <EventsListMonth
                    data={data.fullData[openedMonthIx]}
                    month={openedMonthIx}
                    eventsData={this.state.eventsData}
                    onEventDelete={this.onEventDelete}
                    onEventRcloseModaleschedule={this.onEventReschedule}
                />
            </PopupBlock>
        )
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        })
    }

    closeErrorMessageDialog = () => {
        this.setState({
            errorMessageDialogIsOpen: false
        })
    }

    render() {
        const { currYear, openedMonthIx } = this.state;

        const rows = this.renderCalendarRows();

        if (openedMonthIx !== null) {
            const list = (
                <tr key={-1} style={{
                    position: "absolute",
                    width: "50%",
                    zIndex: 1
                }}>
                    <td colSpan="4" style={{
                        display: "block",
                        width: "840px"
                    }}>{this.renderPopupBlockCell()}</td>
                </tr>
            )
            const pos = Math.floor((openedMonthIx) / 4) + 1
            rows.splice(pos, 0, list)
        }

        return (
            <div className="calendar-year-page">
                <RiskHeader title={"Календарь мероприятий на год"}>
                    {/* <a className="download-calendar mr" onClick={this.downloadCalendar}>
                        Выгрузить на устройство
                    </a> */}
                    <DateChanger
                        year={currYear}
                        onPrevClick={() => this.handleChangerClick(currYear - 1)}
                        onNextClick={() => this.handleChangerClick(currYear + 1)}
                    />
                </RiskHeader>

                <div className="container main-container">
                    {
                        this.state.loading_events || this.state.loading_event_types ? (
                            <div>Загрузка плана мероприятий</div>
                    ) : (
                    <div className="appear-left" style={{
                        position: "absolute",
                        width: "1260px",
                        // height: "2000px"
                    }}>
                        <table className="calendar-year" style={{
                            width: "840px",
                            display: "inline-block",
                            verticalAlign: "top"
                        }}>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        <div className="col col-three" style={{
                            display: "inline-block",
                            verticalAlign: "top"
                        }}>
                            <EventPicker
                                ref={this.eventPickerRef}
                                onItemClick={this.handleItemClick}
                                eventsData={this.state.eventsData}
                                eventTypes={this.state.eventTypes}
                                year={this.state.currYear}
                                />
                        </div>
                    
                        <DatePickerDialog
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            onSubmit={this.onEventCreate}
                            minDate={this.state.minDate}
                        />

                        <ErrorMessageDialog
                            title="Ошибка"
                            message={this.state.errorMessage}
                            isOpen={this.state.errorMessageDialogIsOpen}
                            onRequestClose={this.closeErrorMessageDialog}
                        />

                    </div>
                    )}
                </div>
            </div>
        );
    }
}

function CalendarYearCell({eventsCountLimit, eventsData, eventTypeIds, monthName, timePeriod, onMoreClick, isOpened}) {
    return (
        <div className="calendar-cell">
            <div className="events-list">
                {eventTypeIds.slice(0, eventsCountLimit).map((eventTypeId, ix) => (
                    <div key={ix} className="events-list-item">
                        {eventsData[eventTypeId].name}
                    </div>
                ))}
            </div>
            <div className="total">
                {eventTypeIds.length}
            </div>
            <div className={cx("month-name", timePeriod)}>
                {monthName}
            </div>
            {eventTypeIds.length !== 0 && (
                <a className="more" onClick={onMoreClick}>
                    Подробнее
                    <span className={cx("icon", isOpened ? "down" : "right")} />
                </a>
            )}
        </div>
    )
}

const customStyles = {
    overlay :{
        //fix for popups
        zIndex: 9999
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      background            : '#f0f0f0',
      paddingTop            : '20px',
      //
      display               : 'flex',
      flexDirection         : 'column',
      overflow              : 'inherit'
    }
};

class DatePickerDialog extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            selectedDate: undefined,
        }
    }

    handleSubmit = (event) => {
        if(this.state.selectedDate){
            this.props.onSubmit(this.state.selectedDate)
        }
    }

    handleDatePickerChange = ({name, value}) => {
        this.setState({
            selectedDate: value
        })
    }

    render(){
        return <div>
            <Modal
            isOpen          ={this.props.isOpen}
            onRequestClose  ={this.props.onRequestClose}
            style           ={customStyles} 
            contentLabel    ="Выбор даты проведения мероприятия"
            >            
                
                <div 
                style={{
                    display     : 'absolute',
                    width       : '100%',
                    height      : '100%',
                    background  : '#f0f0f0'
                }}
                >
                
                <h2
                className="db cb"
                style={{marginBottom  : '20px'}}
                >
                    Новое мероприятие
                </h2>

                <div style={{
                    display         : 'flex',
                    flexDirection   : 'row'
                    }}>
                </div>

                <LabeledDatePicker
                    ref={this.datePickerRef}
                    label="Дата мероприятия"
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    value={this.state.selectedDate}
                    startMode="month"
                    onChange={this.handleDatePickerChange}
                    readOnly
                />

                <input
                type="button"
                value="X"
                style={{
                    top     : '20px',
                    right   : '20px',
                    position: 'absolute',
                    zIndex  : '9000'
                }}
                onClick={this.props.onRequestClose}
                />

                <div className="db cb"></div>

                <button 
                    style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '20px'
                    }}
                    onClick={this.handleSubmit}
                >Назначить</button>
                </div>

            </Modal>
        </div>
    }
}


class ErrorMessageDialog extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }
    }

    render(){
        return             <Modal
            isOpen          ={this.props.isOpen}
            onRequestClose  ={this.props.onRequestClose}
            style           ={customStyles} 
        >            
            
            <div 
            style={{
                display     : 'absolute',
                width       : '100%',
                height      : '100%',
                background  : '#f0f0f0'
            }}
            >
            
            <h2
            className="db cb"
            style={{marginBottom  : '20px'}}
            >
                {this.props.title}
            </h2>

            <div style={{
                display         : 'flex',
                flexDirection   : 'row'
                }}>
            </div>

                <div className="db cb">
                    {this.props.message}
                </div>

                <button 
                    style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '20px'
                    }}
                    onClick={this.props.onRequestClose}
                >Ок</button>
            </div>

        </Modal>
    }
}