import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import {PollLegend} from "./poll-legend";
import {Route} from "react-router-dom";

export class SolitudeForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
        if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = [1, 2, 3, 4];
        var F = React.Fragment;
        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Вам предлагается ряд утверждений. Пожалуйста, рассмотрите
                    последовательно каждое и оцените, насколько Вы согласны с
                    ним, выбрав один из четырех вариантов ответа: «Не согласен»,
                    «Скорее не согласен», «Скорее согласен», «Согласен».
                </i>
                <div className="poll-header">
                    <h2 className="db cb">
                        Дифференциальный опросник переживания одиночества
                    </h2>
                    <PollLegend
                        // className="pb3 pt3"
                        opts={[
                            { key: "1", title: (<F>Не<br/>согласен</F>) },
                            { key: "2", title: (<F>Скорее<br/>не согласен</F>) },
                            { key: "3", title: (<F>Скорее<br/>согласен</F>) },
                            { key: "4", title: (<F>Да<br/>согласен</F>) }
                        ]}
                    />
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_1")}
                        >
                            Я чувствую себя одиноким.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_2")}
                        >
                            Когда рядом со мной никого нет, я испытываю скуку.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_3")}
                        >
                            Я люблю оставаться наедине с самим собой.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_4")}
                        >
                            Есть люди, с которыми я могу поговорить.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_5")}
                        >
                            Нет никого, к кому бы я мог обратиться.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_6")}
                        >
                            Мне трудно найти людей, с которыми можно было бы поделиться моими мыслями.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_7")}
                        >
                            В одиночестве приходят интересные идеи.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_8")}
                        >
                            Мне трудно быть вдали от людей.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_9")}
                        >
                            Бывают чувства, ощутить которые можно лишь наедине с собой.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_10")}
                        >
                            Есть люди, которые по-настоящему понимают меня.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_11")}
                        >
                            Я не люблю оставаться один.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_12")}
                        >
                            Чтобы понять какие-то важные вещи, человеку необходимо остаться одному.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_13")}
                        >
                            Когда я остаюсь один, я не испытываю неприятных чувств.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_14")}
                        >
                            Я чувствую себя покинутым.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_15")}
                        >
                            В одиночестве голова работает лучше.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_16")}
                        >
                            Люди вокруг меня, но не со мной.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_17")}
                        >
                            В одиночестве человек познает самого себя.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_18")}
                        >
                            Я плохо выношу отсутствие компании.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_19")}
                        >
                            В одиночестве я чувствую себя самим собой.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_20")}
                        >
                            Худшее, что можно сделать с человеком, – это оставить его одного.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_21")}
                        >
                            Мне кажется, что меня никто не понимает.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_22")}
                        >
                            Мне хорошо дома, когда я один.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_23")}
                        >
                            Когда я остаюсь один, я испытываю дискомфорт.
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("solitude_24")}
                        >
                            В одиночестве каждый видит в себе то, что он есть на самом деле.
                        </PollRadios>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Save answers
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SolitudeForm;
