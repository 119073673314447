import React, { Component } from "react";
import { RadioGroup, Radio } from "../../shared/CustomRadio";

export class PollRadios extends Component {
    render() {
        var opts = (this.props.opts || []).slice();

        let add = 0;
        if (opts[0] === 0) add++;
        
        if (!this.props.centered) opts = opts.reverse(); //Потому что float:right;
        
        var radios = opts.map((x, i) => {
            let tx = this.props.titles ? this.props.titles[i] : null;
            if(tx && typeof tx === "number")
                tx = tx+".";
            let t = tx ? tx : typeof x === "number" ? `${x + add}.` : x;
            let v =
                typeof x === "number"
                    ? x
                    : this.props.centered   
                    ? i //Прямой порядок значений
                    : opts.length - i - 1; //Обратный порядок
            return (
                <Radio
                    value={v}
                    key={i}
                    className={
                        "poll-radio " + (this.props.centered ? "" : "fr")
                    }
                >
                    {t}
                </Radio>
            );
        });
        let title = (
            <div className={"poll-title title-" + opts.length}>
                {" "}
                {this.props.children}
            </div>
        );
        return (
            <li
                className={
                    "poll-radios" + (this.props.centered ? " centered" : "")
                }
            >
                {this.props.centered && title}
                <RadioGroup
                    className={!this.props.centered ? "fr" : ""}
                    {...this.props}
                >
                    {radios}
                </RadioGroup>
                {!this.props.centered && title}
                <div className="cb" />
            </li>
        );
    }
}

export default PollRadios;
