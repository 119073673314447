import React from "react";


export default class RiskHeader extends React.Component {
    setTitle = () => {
        document.title = this.props.title + ": Риски - Med.Proto";
    }
    componentDidMount() {
        this.setTitle();
    }
    componentDidUpdate() {
        this.setTitle();
    }
    render = () => {
        return (
            <div className="header">
                <div className="container">
                    <div className="form" style={{
                        marginLeft: "240px"
                    }}>
                        <h1 className="one-line appear-down">
                            {this.props.title}
                        </h1>
                        <div className="header-additional-children">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


/*
function _RiskHeader(props) {
    return (
        <div className="header">
            <div className="nav-panel fl appear-right">
                <div className="nav-item red results current two-lines">
                    Состояние здоровья, оценка рисков и рекомендации
                </div>
            </div>
            <div className="container">
                <div className="form">
                    <h1 className="one-line appear-down">
                        {props.title}
                    </h1>
                    {props.children}
                </div>
            </div>
            <div className="cb"></div>
        </div>
    );
}
*/
