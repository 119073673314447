import React from "react";
import { Api } from "../../libs/api";
import FeedbackModal from "../shared/FeedbackModal";

class FeedbackSide extends React.Component {
    
    componentDidMount() {}

    render() {
        return <div>
            <FeedbackModal/>
        </div>
    }
}

export default FeedbackSide;