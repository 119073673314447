import React from "react";
import Button from "../../shared/button";

import { BaseEditForm } from "../base-edit-form";
import { Api } from "../../../libs/api";


const POLLS_SUMMARY = [

    {
        id: 'neoffi',
        alias: "neo_ffi_neuroticism",
        title: 'Опросник личностных черт (методика NEO-FFI)',
        description: `
Личностный опросник (тест «Большая пятерка») — позволяет оценить, насколько у человека выражены следующие черты личности:
нейротизм, экстраверсия, готовность к согласию, сознательность и открытость опыту.
        `,
    },


    {
        id: 'stability',
        alias: "resilience",
        title: 'Уровень устойчивости',
        description: `
Тест жизнестойкости является надежным и валидным инструментом психологической диагностики,
результаты которого не зависят от пола, образования и региона проживания человека.
Результаты теста жизнестойкости позволяют оценить способность и готовность человека активно
и гибко действовать в ситуации стресса и трудностей или его уязвимость к переживаниям стресса и депрессии.
        `,
    },
    {
        id: 'solitude',
        alias: "solitude_common_experience",
        title: 'Чувство одиночества',
        description: `
Дифференциальный опросник переживания одиночества -
оригинальный психодиагностический инструмент, направленный
на изучение одиночества как многомерного феномена.
Включает как негативные так и позитивные стороны
исследуемого являения.
        `,
    },
    {
        id: 'economic',
        alias: "sub_economic_well-being_result",
        title: 'Финансовое благополучие',
        description: `
Опросник для измерения субъективного экономического
благополучия, которое рассматривается как отношение личности
к материальным аспектам жизни и выражает
ее жизненную позицию в сфере материальных
устремлений и потребления.
        `,
    },
    {
        id: 'disfunctional',
        alias: "Dysfunctional_thinking_result",
        title: 'Дисфункциональное мышление',
        description: `
Опросник «Шкала дисфункциональных отношений» А. Бека и А.
Вейсмана, предназначенный для исследования когнитивных
искажений, лежащих в основе неадекватного
эмоционального реагирования и психогенных расстройств.
        `,
    },
    {
        id: 'social',
        alias: "social_problem_result",
        title: 'Социальные проблемы',
        description: `
Представленный опросник является первым на русском
языке инструментом, позволяющим выявлять стили решения
социальных (личностных) проблем. Данный опросник может быть
полезным как при проведении исследований в области
психологии личности, так и в психологии
экспертности и экспертизы.
        `,
    },
    {
        id: 'hads',
        alias: "HADS_anxiety_result",
        title: 'Шкала Тревоги и Депрессии',
        description: `
Шкала, предназначена для скринингового выявления тревоги и
депрессии у пациентов. Отличается простотой применения
и обработки. Заполнение шкалы не требует
продолжительного времени и не вызывает затруднений
у пациента.
        `,
    },
    {
        id: 'selfrelation',
        alias: "selfrelation_score",
        title: 'Опросник самоотношения',
        description: `
Опросник позволяет выявить три уровня самоотношения,
отличающихся по степени обобщенности: лобальное
самоотношение; самоотношение, дифференцированное
по самоуважению, аутсимпатии, самоинтересу
и ожиданиям отношения к себе; уровень конкретных
действий (готовностей к ним) в отношении к своему
«Я».
        `,
    },
    {
        id: 'selfefficacy',        
        alias: "Selfefficacy_result_subject_activity",
        title: 'Диагностика самоэффективности',
        description: `
Здесь приводится одна из конкретных диагностических методик,
разработанных Маддуксом и Шеером, направленная на
количественное определение уровня самоэффективности. Речь
идет об оценке человеком своего потенциала в сфере
предметной деятельности и в сфере общения, которым он
реально может воспользоваться.
        `,
    },
    {
        id: 'motivation',        
        alias: "Motivation_result",
        title: 'Ценность здорового образа жизни',
        description: `
Опросник поможет узнать, почему вам важно заботиться о своем здоровье и к какой из 6 мотиваций к здоровому образу жизни можно отнести вашу.
        `,
    },

]

export default class PollsAllForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "", loading: true, 
            results: {
                selfrelation: new Date()
            }
        };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    handleSaveClick() {
        this.setState({ success: "Информация успешно сохранена! " });
    }

    componentDidMount() {

        const polls = POLLS_SUMMARY.reduce(
            (acc, poll) => {
                acc[poll.id] = poll.alias

                return acc
            }
            , {})

        Api.getPollStatus({ polls: polls })
            .then(r => {
                this.setState({ results: r.results, loading: false })
            })
            .catch(r => {
                this.setState({ loading: false, error: true })
            });
    }

    render() {

        const polls_summary = POLLS_SUMMARY
        .sort((a, b) => {
            let finished_a = this.state.results && this.state.results[a.id] ? this.state.results[a.id] : null
            let finished_b = this.state.results && this.state.results[b.id] ? this.state.results[b.id] : null
            let date_a = null
            let date_b = null

            if(finished_a)
                date_a = new Date(finished_a)

            if(finished_b)
                date_b = new Date(finished_b)

            return date_a - date_b
        })
        .map(({ id, title, description }) => (
            <PollSummary
                id={id}
                title={title}
                description={description}
                finished={this.state.results && this.state.results[id] ? this.state.results[id] : undefined}
            />
        ));

        return (
            <div className="appear-left">
                {polls_summary}
            </div>
        );
    }
}

function formatDate(string) {
    const date  = new Date(string);

    var day     = date.getDate();
    var month   = date.getMonth() + 1;
    var year    = date.getFullYear();
  
    return day + '.' + month + '.' + year;
}

class PollSummary extends React.Component {
    render() {

        return  <div>
        {this.props.finished ? (
            <div>
                <div>

                    <h2 className="cb db">{this.props.title}</h2>
					
                    <div className="col col-four fl mb-1-5">
                        {this.props.description}
                    </div>

                </div>
            
                <div className="col col-two fl col-last" style={{
                    textAlign: "center"
                }}>
                    <div className="info-baloon arrow-left ta-center mb">
                        Опросник пройден: {formatDate(this.props.finished)}
                    </div>
                    <div className="cb" />
                    <Button to={`/edit/polls/${this.props.id}`} className="mb2">
                        Перепройти
                    </Button>
                </div>
            </div>
        ) : (
            <div>
                <h2 className="cb db">{this.props.title}</h2>
                <div className="col col-four fl mb-1-5">
                    {this.props.description}
                </div>            
                <div className="col col-two fl col-last">
                    <div className="info-baloon arrow-left ta-center mb">
                        Пройдите опросник, чтобы получить результат
                    </div>
                </div>
                <div className="cb" />
                <Button to={`/edit/polls/${this.props.id}`} className="mb2">
                    Приступить к выполнению
                </Button>
            </div>
        )}
        </div>
    }
}