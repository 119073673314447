import React from "react";

const FillArc = (props) => {

    let a = (3.6 * props.fill - 90) * Math.PI / 180; //градус в радианах -90
    //Точки внешнего круга (концы арок)
    let oX = 10 + 9 * Math.cos(a);
    let oY = 10 + 9 * Math.sin(a);
    //Точки внутреннего круга
    let iX = 10 + 6 * Math.cos(a);
    let iY = 10 + 6 * Math.sin(a);
    //SVG описание правой арки и линии, соединяющей внешнюю и внутреннюю арки
    let oArc = `A 9 9 0 0 1 ${oX} ${oY}`;
    let o2iL = `L ${iX} ${iY}`;

    let svg = "";
    if (props.fill <= 50) {
        svg = `M 10 1 ${oArc} ${o2iL} A 6 6 0 0 0 10 4 L 10 1`;
    }
    else {
        svg = `M 10 1 A 9 9 0 0 1 10 19 ${oArc} ${o2iL} A 6 6 0 0 0 10 16 A 6 6 0 0 0 10 4 L 10 1`;
    }

    //Цвета арок в зависимости от заполнения
    let color = props.fill <= 25 ? "#C53838" : props.fill <= 50 ? "#E6C800" : "#38C559";

    return (
        <svg className="fill-arc" width="20" height="20">
            <defs>
                <mask id="hole">
                    <rect width="100%" height="100%" fill="white" />
                    <circle r="5" cx="10" cy="10" fill="black" />
                </mask>
            </defs>
            <circle r="10" cx="10" cy="10" mask="url(#hole)" fill="#FFFFFF" />
            <path d={svg} fill={color} />
        </svg>
    );

}

export default FillArc;
