import React from "react";
import AvatarEditor from "./avatar-editor";
import UserSample from "./user-sample";
import LabeledInput from "../shared/LabeledInput";
import LabeledDatePicker from "../shared/LabeledDatePicker";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import { AddressInput } from "../shared/AddressInput";
import Button from "../shared/button";
import { Route } from "react-router-dom";

import { BaseEditForm, BtnMessage } from "./base-edit-form";
import { Api } from "../../libs/api";

export class ProfileForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = { _error: "", _success: "", _loading: true };
        this.baseState = {};
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "gender") {
            //Сбрасываем значение Менопаузы
            fields.men40 = v.value === "F" ? "" : fields.men40;
        }

        if (this.props.onChange)
            this.props.onChange({
                name: this.props.form,
                digits: this.props.digits,
                fields
            });
    }
    handleContactsSaveClick = history => {
        var fields = this.props.fields || {};
        this.setState({ _passwordError: "", _passwordSuccess: "" });

        if (!fields["current-password"]) {
            this.setState({ _passwordError: "Укажите текущий пароль" });
            return;
        }
        if (fields["new-password"] !== fields["confirm-new-password"]) {
            this.setState({
                _passwordError:
                    "Поля «Новый пароль» и «Повторите новый пароль» должны совпадать"
            });
            return;
        }
        this.setState({ passLoading: true });
        Api.setNewPass(fields["current-password"], fields["new-password"])
            .then(x => {
                this.setState({
                    _passwordError: "",
                    _passwordSuccess: "Новый пароль успешно установлен!",
                    passLoading: false
                });
                history.push("/auth/logout");
            })
            .catch(x => {
                this.setState({
                    _passwordError: x.message,
                    _passwordSuccess: "",
                    passLoading: false
                });
            });
    };

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <label>Фотография профиля:</label>
                <AvatarEditor
                    user={UserSample}
                    {...this.baseEditProps("avatar")}
                />
                <div className="cb" />
                <div style={{"display":"none"}}>
                    <div className="col col-two fl">
                        <LabeledInput
                            {...this.baseEditProps("familyName", "", "фамилия")}
                            label="Фамилия"
                        />
                    </div>
                    
                    <div className="col col-two fl">
                        <LabeledInput
                            {...this.baseEditProps("givenName", "", "имя")}
                            label="Имя"
                        />
                    </div>
                    <div className="col col-two col-last fl">
                        <LabeledInput
                            {...this.baseEditProps("middleName", "", "отчество")}
                            label="Отчество"
                        />
                    </div>
                    <div className="cb" />
                </div>
                <div className="col col-two fl">
                    <LabeledDatePicker
                        {...this.baseEditProps("birthdate")}
                        label="Дата рождения"
                    />
                </div>
                <div className="col col-two fl mb-1-5">
                    <label className="cb db">Ваш пол:</label>
                    <RadioGroup {...this.baseEditProps("gender")}>
                        <Radio value="M" className="col fl">
                            Мужской
                        </Radio>
                        <Radio value="F" className="col col-last fl">
                            Женский
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                {fields.gender === "F" && (
                    <div className="col col-four col-last appear-up mb-1-5">
                        <label>Менопауза:</label>
                        <RadioGroup {...this.baseEditProps("men40")}>
                            <Radio value={0} className="col col-four">
                                Менопауза не наступила
                            </Radio>
                            <Radio value={1} className="col col-four">
                                Менопауза наступила до 40 лет
                            </Radio>
                            <Radio value={2} className="col col-four">
                                Менопауза наступила после 40 лет
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("height")}
                        label="Ваш рост"
                        units="см"
                        btns={this.baseOneSaveBtns("height")}
                    />
                </div>
                <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("weight")}
                        label="Ваш вес"
                        units="кг"
						min="0"
						type="number"
                        btns={this.baseOneSaveBtns("weight")}
                    />
                </div>
                <div className="db" />
                <div className="col col-two mb-1-5">
                    <label>Семейное положение:</label>
                    <Check {...this.baseEditProps("familyStatus")}>
                        Есть постоянный партнёр
                    </Check>
                </div>
                <div className="cb" />

                <div className="col col-six mb-1-5">
                    <AddressInput
                        {...this.baseEditProps("locations")}
                        label="Место проживания"
                        subLabel="(до улицы)"
                    />
                </div>
                <div className="col col-two col-last mb-1-5">
                    <LabeledInput
                        label="Телефон"
                        {...this.baseEditProps("numberPhone")}
                    />
                </div>
                <div className="cb" />
                <div className="btn-container mt mb-1-5">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/illnesses",
                                        e.target
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Болезни и семейный анамнез»
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                    <div className="cb" />
                </div>
                <h2 className="db cb">Контактная информация и безопасность</h2>
                <div className={this.state.passLoading ? "loading" : ""}>
                    <div className="col col-two fl">
                        <label>Электронная почта:</label>
                        <div className="input mb-1-5">
                            {this.emptyOrDef(this.props.fields["username"], "")}
                        </div>
                        <i className="comment db">
                            Чтобы установить новый пароль, обязательно укажите
                            свой текущий пароль в соответствующем поле.
                        </i>
                    </div>
                    <div className="col col-two fl">
                        <LabeledInput
                            label="Текущий пароль"
                            {...this.baseEditProps("current-password")}
                            type="password"
                            autoComplete={false}
                        />
                        <a href={"javascript:showPsw('current-password')"}>Показать/скрыть пароль</a><br /><br />
                        <LabeledInput
                            label="Новый пароль"
                            {...this.baseEditProps("new-password")}
                            type="password"
                            autoComplete={false}
                        />
                        <a href={"javascript:showPsw('new-password')"}>Показать/скрыть пароль</a><br /><br />
                        <LabeledInput
                            label="Повторите новый пароль"
                            {...this.baseEditProps("confirm-new-password")}
                            type="password"
                            autoComplete={false}
                        />
                        <a href={"javascript:showPsw('confirm-new-password')"}>Показать/скрыть пароль</a><br /><br />
                    </div>
                    <div className="col col-two fl col-last">
                        <label className="mb">Рекомендации:</label>
                        <i className="db mb comment">
                            Старайтесь придумать уникальный пароль, который Вы
                            не использовали нигде прежде.
                        </i>
                        <i className="db mb comment">
                            Устойчивый к взлому пароль должен содержать строчные
                            и прописные буквы и цифры.
                        </i>
                        <i className="db mb comment">
                            Длина пароля должна быть не менее восьми символов.
                        </i>
                    </div>
                </div>
                <div className="cb" />
                <div className="btn-container col col-four">
                    <Route
                        render={({ history }) => (
                            <Button
                                loading={
                                    this.props.loading || this.state.passLoading
                                }
                                className="fr"
                                onClick={() =>
                                    this.handleContactsSaveClick(history)
                                }
                            >
                                Сохранить
                            </Button>
                        )}
                    />

                    <BtnMessage
                        error={this.state._passwordError}
                        success={this.state._passwordSuccess}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default ProfileForm;
