import React from "react";
import cx from "classnames";
import moment from "moment";

import "../../../../css/comp/risk/history-date-picker.css";

export default class HistoryDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hide);
    }

    select = item => {
        if (this.props.onChange) this.props.onChange(item);
        this.setState({ selected: item });
    };
    show = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hide);
    };
    hide = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hide);
    };

    render() {
        const { className, selected, onSelect } = this.props;
        const { isOpen } = this.state;

        const parsedSelectedDate = moment(selected);

        
        let temp  = []

        let items = this.props.items.reduce(
            (acc, item) => {
                let date = moment(item).format("DD.MM.YYYY")

                if(!temp.includes(date)){
                    temp.push(date)
                    acc.push(item)
                }

                return acc
            },
            []
        )

        return (
            <div
                className={cx(
                    "history-date-picker",
                    { open: isOpen },
                    className
                )}
            >
                {selected && (
                    <React.Fragment>
                        <div className="display" onClick={this.show}>
                            <span className="prefix">Дата оценки:</span>{" "}
                            <span className="month">
                                {parsedSelectedDate.format("DD.MM")}
                            </span>
                            <span className="year">
                                {parsedSelectedDate.format(".YYYY")}
                            </span>
                            <span className="icon history" />
                        </div>
                        {isOpen ? (
                            <div className="items appear-up">
                                {items.slice(-10).map(item => (
                                    <div
                                        key={item}
                                        className={cx("item", {
                                            selected: item === selected
                                        })}
                                        onClick={() => onSelect(item)}
                                    >
                                        {moment(item).format("DD.MM.YYYY")}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
