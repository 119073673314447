import React from "react";
import classnames from "classnames";
import { Route } from "react-router-dom";


import { Radio, RadioGroup } from "../shared/CustomRadio";
import Button from "../shared/button";
import { BaseEditForm, BtnMessage } from "./base-edit-form";
import Slider from "../shared/Slider";
import LabeledMonthPicker from "../shared/LabeledMonthPicker";


export class SocialForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };

        this.containerRef = React.createRef();
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }
    handleSliderDragStart = name => {
        this.containerRef.current.className = classnames(
            this.containerRef.current.className,
            {
                "no-select": true
            }
        );
    };
    handleSliderDragStop = name => {
        this.containerRef.current.className = this.containerRef.current.className.replace(
            "no-select",
            ""
        );
    };

    componentDidUpdate() {}
    componentDidMount() {}

    render() {
        // let fields = this.props.fields || {};
        return (
            <div ref={this.containerRef} className="appear-left">
                <div className="col col-three col-last mb-1-5">
                    <label>
                    How do you assess the general state of your health?
                    </label>
                    <RadioGroup {...this.baseEditProps("health_status")}>
                        <Radio value={0} className="col col-three col-last">
                        Very good
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                        Good enough                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                        Average
                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                        Bad enough                        </Radio>
                        <Radio value={4} className="col col-three col-last">
                        Bad
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>
                    How would you rate your overall health compared to other people of your age?
                    </label>
                    <RadioGroup {...this.baseEditProps("health_status_equal")}>
                        <Radio value={0} className="col col-three col-last">
                        Much better
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                        A little better                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                        Neither better nor worse                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                        A little bit worse                        </Radio>
                        <Radio value={4} className="col col-three col-last">
                        Much worse                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <div className="col col-four fl mb-1-5">
                    <label>Your education:</label>
                    <RadioGroup {...this.baseEditProps("education")}>
                        <Radio value={0} className="col col-four col-last">
                        Unfinished 9 classes
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                        Graduated 9 classes
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                        Secondary{" "}
                            <i className="comment">(Grade 11 completed)</i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                        Incomplete secondary vocational{" "}
                            <i className="comment db">
                            (not graduated from vocational school / college / technical school)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                        Graduated vocational{" "}
                            <i className="comment db">
                            (graduated from vocational school / college / technical school)
                            </i>
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                        I have or get higher education                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-two fl mb-1-5 col-last">
                    <LabeledMonthPicker
                        label="Date of education"
                        {...this.baseEditProps("education_date")}
                    />
                    <i className="comment db mt-half">
                    (the date of receipt of the last education up to a month)
                    </i>
                </div>
                <div className="cb" />
                <div className="col col-four fl mb-1-5">
                    <label>Level of material well-being:</label>
                    <RadioGroup {...this.baseEditProps("moneylvl")}>
                        <Radio value={0} className="col col-four col-last">
                        Not enough money even for food                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                        Enough for food, but not enough to buy clothes and shoes
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                        Enough for clothes and shoes, but not enough for the purchase of small household appliances
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                        Enough money for various purchases, but buying expensive things requires a loan
                            <i className="comment db">
                            (computer, washing machine, refrigerator)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                        Enough money for everything, and for the purchase of an apartment, a car, a summer house it is necessary to accumulate money
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                        I can afford everything                        </Radio>
                    </RadioGroup>
                </div>
                {/* <div className="col col-two fl col-last mb-1-5 appear-left">
                    <div className="info-baloon mt-label mb">
                        Более точные данные об уровне субъективного
                        материального благополучия вы можете внести, пройдя
                        специальный тест
                    </div>
                    <Button beforeGo={this.handleSaveClick} to="/edit/polls/economic  " className="col col-two col-last">
                        Приступить к выполнению
                    </Button>
                </div> */}
                <div className="col col-six col-last mt-1-5 mb-1-5">
                    <label>
                    Please rate the level of stress in your life over the past month:
                    </label>
                    <Slider
                        className="col-six mt"
                        noInput={true}
                        {...this.baseEditProps("stress")}
                        ticks={4}
                        from={0}
                        to={4}
                        fromLabel="very low"
                        toLabel="very high"
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                    />
                </div>
                <div className="col col-three col-last mb-1-5">
                    <label>Your living conditions:</label>
                    <RadioGroup {...this.baseEditProps("alone_fils")}>
                        <Radio value={0} className="col col-three col-last">
                        I live with my family
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                        Living without a family
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four mb-1-5">
                    <label>The degree of religiosity:</label>
                    <RadioGroup {...this.baseEditProps("relgion", "")}>
                        <Radio value={0} className="col col-four col-last">
                        Not religious
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Low{" "}
                            <i className="comment db">
                            (I consider myself a religious person, but I do not observe all traditions)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Average{" "}
                            <i className="comment db">
                            (I observe the main traditions of religion, but not all and not always)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            High{" "}
                            <i className="comment db">
                            (I try to observe all traditions regularly and / or belong to a religious community)
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/events",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to section "Life events"
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SocialForm;
