import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../shared/button";
import RiskHeader from "../RiskHeader";
import IndicatorBlock from "./IndicatorBlock";
import HistoryDatePicker from "./HistoryDatePicker";
import { Api } from "../../../libs/api";

export default class ProblemsOverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoaded: false,
            error: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
            this.setState({ isLoaded: false });
            this.fetchData();
        }
    }

    fetchData = () => {
        //Если каким-то образом нет ни одной оценки риска, не посылаем никаких запросов
        if (!this.props.selectedDate) {
            this.setState({ isLoaded: true, data: null });
            return;
        }
        //Получаем список рисков
        return Api.getRisksList(this.props.selectedDate)
            .then(res => {
                this.setState({
                    data: res.result,
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({ error: err });
            });
    };

    handleRisksRecalc = e => {
        this.setState({ isLoaded: false });
        //Вызываем пересчет 
        Api.recalcRisks().then(() => {
            if (this.props.onRecalc) {
                this.props.onRecalc().then(x => {
                    this.setState({ isLoaded: true });
                });
            }
        });
    };

    render() {
        const { match, historyDates, selectedDate, onSelectDate } = this.props;
        const { data, isLoaded, error } = this.state;

        // Массив для указания сортировки
        const arr = [];
        arr['depression']   = 0;
        arr['hypertension'] = 1;
        arr['infarct']      = 2;
        arr['insult']       = 3;
        
        let content = (
            <div className="form appear-left">
                {data &&
                    Object.values(data).sort((a, b) => arr[a.id] - arr[b.id]).map(problem => (
                        <IndicatorBlock
                            key={problem.id}
                            className="mb-1-5"
                            fill={problem.rang * 20}
                        >
                            <div>
                                <h2 className="pb-half">{problem.name}</h2>
                                {" ("}
                                <Link to={`${match.path}/${problem.id}`}>
                                    факторы риска и рекомендации
                                </Link>
                                {")"}
                            </div>
                            <i className="db">
                                {problem.description} Перейдя в данный раздел Вы
                                сможете подробно ознакомиться с выявленными у
                                Вас факторами риска и рекомендациями по их
                                снижению.
                            </i>
                        </IndicatorBlock>
                    ))}
                {!data && (
                    <i className="comment db mb">
                        Расчет рисков для Вас еще ни разу не проводился.
                        Заполните поля раздела «Информация о пользователе» и
                        нажмите на кнопку «Пересчитать риски».
                    </i>
                )}
                <h2>Пересчёт рисков</h2>
                {/*<i className="comment db mb-1-5 cb">
                    Кнопка «Пересчитать риски» находится на этой странице
                    временно, пока не реализовано какое-то более системной
                    решение. Например, пересчет рисков по расписанию.
                </i>*/}
                <Button
                    className={"fr" + (isLoaded ? "" : " btn-loading")}
                    onClick={this.handleRisksRecalc}
                >
                    Пересчитать риски
                </Button>
                <div className="cb" />
            </div>
        );

        if (error) {
            content = <FormError>{error}</FormError>;
        } else if (!isLoaded) {
            content = (
                <LoadingMessage>происходит загрузка данных</LoadingMessage>
            );
        }

        return (
            <React.Fragment>
                <RiskHeader title={"Проблемные зоны"}>
                    <HistoryDatePicker
                        selected={selectedDate}
                        items={historyDates}
                        onSelect={onSelectDate}
                    />
                </RiskHeader>
                <div className="container main-container">
					<div className="form appear-left">
						<MotivationResult title={"В результате опроса по мотивации"}>
							загрузка ...
						</MotivationResult>
					</div>
					{content}
				</div>
            </React.Fragment>
        );
    }
}

class LoadingMessage extends React.PureComponent {
    render() {
        return (
            <div className="form appear-left">
                <h2>Подождите&hellip;</h2>{" "}
                <i className="comment">({this.props.children})</i>
            </div>
        );
    }
}

class FormError extends React.PureComponent {
    render() {
        return (
            <div className="form appear-left">
                <h2>Произошла ошибка</h2>
                <i className="comment db">{this.props.children}</i>
            </div>
        );
    }
}

/*
1 #'environment'
2 #'acceptance'
3 #'youth'
4 #'try'
5 #'negative'
6 #'appearance'
*/
/*
class IntroText extends React.PureComponent {
    render() {
        return (
			{this.props.info}
			<div className="hd">{this.props.hd}</div>
        );
    }
}
*/

class MotivationResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            motiveRes:  0,
			motiveFlag: false,
			error: null
        };
    }
    componentDidMount() {
        Api.getPollResult({polls: {motivation: "Motivation_result"}}).then(res => {
			if(res.results.motivation != undefined){
				this.setState({ motiveRes: res.results.motivation });
				this.setState({ motiveFlag: true });
			}
		}).catch(err => {
			this.setState({ error: err });
		});
    }
	render() {
		let kn_active    = ['', 'kn-inactive','kn-inactive','kn-inactive','kn-inactive','kn-inactive','kn-inactive'];
		let slide_active = ['', 's-none','s-none','s-none','s-none','s-none','s-none'];
		let hd           = ['', 'Быть в гармония с природой и окружающими', 'Восприятие вас в обществе', 'Сохранение молодости', 'Преодоление себя', 'Отрешиться от всех проблем', 'Как вы выглядите']
		
		kn_active[this.state.motiveRes]    = ('kn-active');
		slide_active[this.state.motiveRes] = ('s-flex');

        // если опросник Мотивации не пройден, контейнер со слайдером должен иметь нулевую высоту
        let sliderHeight = ('mb-1-5 slHeight_0');
        let priglashenie = ('priglashenie');
        if(parseInt(this.state.motiveRes) != 0){
            sliderHeight = ('mb-1-5');
            priglashenie = ('priglashenie_none');
        }

		return (
            <div className="form appear-left">
            <div id="recSlider" className={sliderHeight}>
                <div className={priglashenie}>
                    <img src="/res/img/slider/1.png" border="0" alt="Пройти опросник по Мотивации" className="img-priglashenie" hspace="15" vspace="1" />
                    Приглашаем Вас пройти анкетирование для выяснения Ваших индивидуальных мотивационных особенностей к ведению здорового образа жизни.<br />
                    <a href="/edit/polls/motivation?risk=3">Пройти анкету ...</a>
                </div>
                <div className="slider-item" id="s1" className={slide_active[1]}>
                    <div className="slider-image"> <img src="/res/img/slider/5.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Быть в гармонии с природой</div>
                        <ul>
                            <li>Быть в ладу со своей природой, ощущать гармонию с собой и миром важно для вас.</li>
                            <li>Вести осознанный и экологичный образ жизни вам помогут персональные рекомендации.</li>
                            <li>Вы сможете соблюдать тонкий баланс между потребностями своего организма и условиями вашей жизнедеятельности.</li>
                            <li>Здоровый образ жизни - это вклад не только в собственное благополучие, но и в благополучие окружающего мира.</li>
                        </ul>
                    </div>
                </div>
                <div className="slider-item" id="s2" className={slide_active[2]}>
                    <div className="slider-image"><img src="/res/img/slider/2.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Социальное одобрение</div>
                        <ul>
                            <li>Заботиться о себе, внимательно относиться к своему здоровью и образу жизни стало признаком современного и прогрессивного человека.</li>
                            <li>Станьте частью активного ЗОЖ-сообщества, следуя нашим персональным рекомендациям.</li>
                            <li>Пусть вашей молодости и энергичности позавидуют окружающие.</li>
                            <li>Покажите пример своим близким и помогайте вместе с вами изменить образ жизни.</li>
                        </ul>
                    </div>
                </div>
                <div className="slider-item" id="s3" className={slide_active[3]}>
                    <div className="slider-image"><img src="/res/img/slider/1.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Сохранение молодости и активности</div>
                        <div></div>
                        <ul>
                            <li>Сохранять бодрость и активность очень важно.</li>
                            <li>Стресс, плохой сон, нездоровое питание, вредные привычки плохо сказываются на тонусе и приводят к раннему старению.</li>
                            <li>Наши рекомендации, помогут предотвратить развитие конкретных заболеваний, дольше сохранять молодость и энергию, ни в чем себя не ограничивать - путешествовать, танцевать, заниматься спортом, чувствовать себя отлично.</li>
                        </ul>
                    </div>
                </div>
                <div className="slider-item" id="s4" className={slide_active[4]}>
                    <div className="slider-image"> <img src="/res/img/slider/4.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Вызов собственной лени</div>
                        <ul>
                            <li>Станьте лучшей версией себя, меняя свой образ жизни.</li>
                            <li>Бросьте вызов лени! Здоровое питание, физическая активность, хороший сон и другие полезные привычки раздвинут горизонты ваших возможностей.</li>
                            <li>Рекомендации, которые вы получите, помогут вам лучше узнать возможности вашего организма и прокачать силу воли.</li>
                            <li>Только непрерывное саморазвитие позволяет двигаться вперед к новым вершинам.</li>
                        </ul>
                    </div>
                </div>
                <div className="slider-item" id="s5" className={slide_active[5]}>
                    <div className="slider-image"> <img src="/res/img/slider/6.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Отключение от проблем, выход из стресса</div>
                        <ul>
                            <li>Жизнь полна стрессов и проблем, которые негативно влияют на наше состояние. К счастью, мы можем от этого защититься!</li>
                            <li>Плохой сон, вредные привычки, нездоровое питание снижают нашу сопротивляемость стрессу и могут ухудшать настроение.</li>
                            <li>Рекомендации, которые вы получите, помогут лучше и увереннее себя чувствовать каждый день.</li>
                            <li>Отвлекитесь от негативных эмоций, уделяя внимание себе и своему здоровью.</li>
                        </ul>
                    </div>
                </div>
                <div className="slider-item" id="s6" className={slide_active[6]}>
                    <div className="slider-image"> <img src="/res/img/slider/3.png" className="shadow-bottom" /></div>
                    <div className="slider-text">
                        <div className="slider-header">Сохранение внешнего вида и красоты</div>
                        <ul>
                            <li>Сохранять внешнюю привлекательность и быть в хорошей форме для вас важно.</li>
                            <li>Стресс, плохой сон, вредные привычки плохо сказываются на внешности - состоянии кожи, физическом тонусе, весе.</li>
                            <li>Наши рекомендации, помогут вам не только поддержать здоровье, но и как можно дольше оставаться красивыми.</li>
                            <li>Вы сможете улучшить цвет лица, сбросить лишний вес, подтянуть мышцы и чувствовать уверенность в себе.</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className="knopki">
                <div><a href="javascript:changeSlide(1);" id="kn1" className={kn_active[1]}>&bull;</a></div>
                <div><a href="javascript:changeSlide(2);" id="kn2" className={kn_active[2]}>&bull;</a></div>
                <div><a href="javascript:changeSlide(3);" id="kn3" className={kn_active[3]}>&bull;</a></div>
                <div><a href="javascript:changeSlide(4);" id="kn4" className={kn_active[4]}>&bull;</a></div>
                <div><a href="javascript:changeSlide(5);" id="kn5" className={kn_active[5]}>&bull;</a></div>
                <div><a href="javascript:changeSlide(6);" id="kn6" className={kn_active[6]}>&bull;</a></div>
            </div>
    
        </div>
		); // return
	}
} // MotivationResult
