import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import EditLayout from "./comp/edit/layout";
import PollsLayout from "./comp/edit/polls/layout";
import RiskLayout from "./comp/risk/RiskLayout";
import { AuthRouting } from "./comp/auth/routing";
import { Api } from "./libs/api";
import GFitLogin from "./comp/auth/gfit/GFitLogin";

// 
import EditLayoutENG           from "./eng/comp/edit/layout";
import PollsLayoutENG          from "./eng/comp/edit/polls/layout";
import RiskLayoutENG           from "./eng/comp/risk/RiskLayout";
import AuthRoutingENG          from "./eng/comp/auth/routing";
import GFitLoginENG            from "./eng/comp/auth/gfit/GFitLogin";

class App extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Med.Proto";
        this.state = { loading: true };
    }
    componentDidMount = () => {
        Api.getConfig().then(x => {
            console.log("Config loaded: ", x);
            try {
                window["VK"].init({
                    apiId: Api.config.vkApiId
                });
            } catch(e) { /* */ }
            this.setState({ loading: false });
        });
    };

    render() {
        if (this.state.loading) return <React.Fragment />;
        return (
            <Switch>
                {/* RUS */}
                <Route path="/gfit/login" component={GFitLogin}></Route>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/auth/start" />}
                />
                <Route path="/auth" component={AuthRouting} />
                <Route
                    exact
                    path="/edit"
                    render={() => <Redirect to="/edit/profile" />}
                />

                <Route
                    exact
                    path="/edit/polls"
                    render={() => <Redirect to="/edit/polls/all" />}
                />
                <Route path="/edit/polls/:form" component={PollsLayout} />
                <Route path="/edit/:form" component={EditLayout} />
                <Route path="/risk" component={RiskLayout} />

                {/* ENG */}
                {/* <Route path="/gfit/login" component={GFitLoginENG}></Route> */}
                <Route
                    exact
                    path="/eng"
                    render={() => <Redirect to="/eng/auth/start" />}
                />
                <Route path="/eng/auth" component={AuthRoutingENG} />
                <Route
                    exact
                    path="/eng/edit"
                    render={() => <Redirect to="/eng/edit/profile" />}
                />

                <Route
                    exact
                    path="/eng/edit/polls"
                    render={() => <Redirect to="/eng/edit/polls/all" />}
                />
                <Route path="/eng/edit/polls/:form" component={PollsLayoutENG} />
                <Route path="/eng/edit/:form" component={EditLayoutENG} />
                <Route path="/eng/risk" component={RiskLayoutENG} />
            </Switch>
        );
    }
}

export default App;
