import React from "react";

import "../../../css/comp/input-overlay.css";

export class LabeledInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: "", focused: false };

        this.input = React.createRef();
    }

    oneSymbol = (str, s) => {
        var ps = str.split(s);
        if (ps.length > 1) {
            return ps[0] + s + ps[1];
        }
        return str;
    };

    handleChange = event => {
        if (this.props.onChange) {
            let _val = event.target.value;

            if (this.props.digits) {
                //Только числа
                _val = _val.replace(/[^0-9.-]/gi, "");
                //Одна точка
                _val = this.oneSymbol(_val, ".");
                //Один минус
                _val = this.oneSymbol(_val, "-");
                //Только первый минус
                if (_val.indexOf("-") !== 0) _val = _val.replace("-", "");
            } else if (this.props.emptyValue && _val === "") {
                _val = this.props.emptyValue;
            }

            this.props.onChange({
                name: this.props.name,
                value: _val
            });
        }
    };
    handleFocus = () => {
        this.setState({ focused: true });
    };
    handleBlur = () => {
        this.setState({ focused: false });
    };
    handleKeyUp = e => {
        if (this.props.onKeyUp) this.props.onKeyUp(e);
    };
    handleKeyDown = e => {
        if (this.props.onKeyDown) this.props.onKeyDown(e);
    };
    handleOptionsChange = option => {
        if (this.props.onOptionChange)
            this.props.onOptionChange({ name: this.props.name, ...option });

        this.hideOptions(null, true);
    };
    focusInput = () => {
        this.setState({ focused: true });
        this.input.current.focus();
    };
    classInParent = (target, css, times) => {
        if (times < 0) return false;
        if (!target) return false;
        if (!target.className) return false;
        if (target.className.indexOf(css) > -1) return true;
        return this.classInParent(target.parentNode, css, times - 1);
    };
    hideOptions = (e, force) => {
        if (this.state.optionsVisible) {
            if (force || !this.classInParent(e.target, "input-overlay", 2)) {
                this.setState({ optionsVisible: false });
            }
        }
    };
    /**
     * Обработчик нажатия кнопки (на body).
     * Если «Esc» - скрывает список подсказок
     * @param {Event} e
     */
    handleBodyKey = e => {
        if (e.which === 27) {
            //Esc
            this.hideOptions(null, true);
        }
    };
    componentDidMount() {
        this.input.current.autoсomplete = "some-value";
        document.body.addEventListener("click", this.hideOptions);
        document.body.addEventListener("keyup", this.handleBodyKey);
    }
    componentWillUnmount() {
        document.body.removeEventListener("click", this.hideOptions);
        document.body.removeEventListener("keyup", this.handleBodyKey);
    }
    /**
     * Возвращает или поданное значение, или значение по умолчанию
     * @param {string} v Значение поля
     * @param {string} def Значение поля по умолчанию (если пустое)
     */
    emptyValue(v, def, emptyString) {
        // console.log(v, def, emptyString);
        let res = v;

        if (v === undefined || v === null || (emptyString && v === ""))
            res = def || "";

        return res;
    }
    handleConfirmNo = () => {
        if (this.props.onConfirmNo) {
            this.props.onConfirmNo(this.props.name);
        }
    };
    handleConfirmYes = () => {
        if (this.props.onConfirmYes) this.props.onConfirmYes(this.props.name);
    };
    handleErrorClick = () => {
        console.log("error clicked", this.props.name);
        if (this.props.onErrorClick) this.props.onErrorClick(this.props.name);
    };
    handleFormSubmit = e => {
        e.preventDefault();
        return;
    };
    render() {
        // let rnd = Math.round(Math.random() * 99999);
        var className =
            "input mb-1-5" +
            (this.props.error ? " input-error" : "") +
            (this.props.className ? " " + this.props.className : "");
        if (this.props.icon) {
            className += " with-icon";
        }
        if (this.props.btns) {
            className += " with-icon-" + this.props.btns.length;
        }
        if (this.props.units) {
            className += " with-units";
        }
        if (this.state.focused) {
            className += " input-focus";
        }

        var units = this.props.units;
        if (units && typeof units === "function")
            units = units(this.props.name);

        let props = this.props;



        let input = (
            <input
                ref={this.input}
                type={props.type || "text"}
                min={props.min || ""}
                name={props.name}
                value={this.emptyValue(props.value, "", true)}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyUp={this.handleKeyUp}
                onKeyDown={this.handleKeyDown}
                autoComplete={props.autoComplete === false ? props.name : ""}
            />
        );
        if (props.autoComplete === false) {
            input = (
                <form
                    onSubmit={this.handleFormSubmit}
                    autoComplete={props.name}
                >
                    <input
                        type={props.type}
                        defaultValue="some-value"
                        name={props.name}
                        style={{ display: "none" }}
                    />
                    {input}
                </form>
            );
        }

        return (
            <React.Fragment>
                {this.props.label && <label>{this.props.label}:</label>}
                <div className={className}>
                    {input}
                    {this.props.units && <span className="units">{units}</span>}
                    {this.props.icon && (
                        <span className={this.props.icon}> </span>
                    )}
                    {!!this.props.btns &&
                        this.props.btns.map((b, index) => {
                            let p = { ...b };
                            if (p.onClick === "options")
                                p.onClick = () =>
                                    this.setState({
                                        optionsVisible: !this.optionsVisible
                                    });
                            return <InputButton key={index} {...p} />;
                        })}
                </div>
                {this.props.options &&
                    this.props.options.length > 0 &&
                    this.state.optionsVisible && (
                        <div className="input-overlay appear-up">
                            {this.props.options.map((a, i) => {
                                return (
                                    <InputOverlayItem
                                        key={i}
                                        value={a.value(
                                            this.props.name,
                                            a.units
                                        )}
                                        units={a.units}
                                        onClick={this.handleOptionsChange}
                                    />
                                );
                            })}
                        </div>
                    )}
                {!!this.props.error && (
                    <div
                        className="field-error appear-up mb"
                        onClick={this.handleErrorClick}
                    >
                        {this.props.error}
                    </div>
                )}
                {!!this.props.confirm && (
                    <div className="field-confirm appear-up mb">
                        {this.props.confirm} Save?
                        <div className="btns mt-half">
                            <button
                                onClick={this.handleConfirmYes}
                                className="mr-half"
                            >
                                Yes
                            </button>{" "}
                            <button onClick={this.handleConfirmNo}>No</button>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

class InputButton extends React.Component {
    handleClick = () => {
        if (this.props.onClick) this.props.onClick(this.props.name);
    };
    render() {
        return (
            <span
                title={this.props.title || ""}
                className={"glyph fr " + this.props.className}
                onClick={this.handleClick}
            />
        );
    }
}
class InputOverlayItem extends React.Component {
    handleClick = () => {
        if (this.props.onClick) this.props.onClick(this.props);
    };
    render() {
        return (
            <div
                onClick={this.handleClick}
                className={"row " + (this.props.selected ? "selected" : "")}
            >
                <i className="comment fr">[{this.props.units}]</i>
                {this.props.value}
            </div>
        );
    }
}

export default LabeledInput;
