import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import { PollLegend } from "./poll-legend";
import { Route } from "react-router-dom";
import $ from "jquery";

const POOL_ITEMS = [
    {
        id: "motivation_questionnaire_1",
        description:  "Чтобы жить в соответствии с природой",
		num: 1,
    },
    {
        id: "motivation_questionnaire_2",
        description:  "Чтобы жить дольше",
		num: 2,
    },
    {
        id: "motivation_questionnaire_3",
        description:  "Чтобы получить одобрение от окружающих",
		num: 3,
    },
    {
        id: "motivation_questionnaire_4",
        description:  "Чтобы жить экологично",
		num: 4,
    },
    {
        id: "motivation_questionnaire_5",
        description:  "Чтобы в старости быть активным",
		num: 5,
    },
    {
        id: "motivation_questionnaire_6",
        description:  "Чтобы познать себя",
		num: 6,
    },
    {
        id: "motivation_questionnaire_7",
        description:  "Чтобы снимать стресс",
		num: 7,
    },
    {
        id: "motivation_questionnaire_8",
        description:  "Чтобы нравиться людям",
		num: 8,
    },
    {
        id: "motivation_questionnaire_9",
        description:  "Чтобы сохранить здоровье",
		num: 9,
    },
    {
        id: "motivation_questionnaire_10",
        description:  "Чтобы испытать себя",
		num: 10,
    },
    {
        id: "motivation_questionnaire_11",
        description:  "Чтобы хорошо выглядеть",
		num: 11,
    },
    {
        id: "motivation_questionnaire_12",
        description:  "Чтобы отдыхать от проблем",
		num: 12,
    },
    {
        id: "motivation_questionnaire_13",
        description:  "Чтобы иметь красивую фигуру",
		num: 13,
    },
    {
        id: "motivation_questionnaire_14",
        description:  "Чтобы убежать от жизненных проблем",
		num: 14,
    },
    {
        id: "motivation_questionnaire_15",
        description:  "Чтобы быть в гармонии с собой",
		num: 15,
    },
    {
        id: "motivation_questionnaire_16",
        description:  "Чтобы быть сексуально привлекательным",
		num: 16,
    },
    {
        id: "motivation_questionnaire_17",
        description:  "Чтобы развить силу воли",
		num: 17,
    },
    {
        id: "motivation_questionnaire_18",
        description:  "Чтобы быть популярным",
		num: 18,
    },

]
    .map((obj) => ({
        ...obj,
        opts: [1, 2, 3, 4, 5],
        titles: ['5.', '4.', '3.', '2.', '1.'],

    }))



export default class MotivationForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=18;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
        
        var params = window
        .location
        .search
        .replace('?','')
        .split('&')
        .reduce(
            function(p,e){
                var a = e.split('=');
                p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
            },
            {}
        );
        console.log( params['risk']);
        const risk = params['risk'];

		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history, risk);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };


    render () {

        const renderedPoolItems = (
            <ol className="poll-reset">
                {POOL_ITEMS.map(({ id, description, num, opts, titles }) => (
					<div className={'q'+num}>
						<PollRadios
							key={id}
							opts={opts}
							titles={titles}
							{...this.baseEditProps(id)}
						>
							{description}
						</PollRadios>
					</div>
                ))}
            </ol>
        )

        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Выбирайте тот ответ, который наилучшим образом отражает Ваше мнение.
					Отвечайте в темпе, не задумываясь. Не пропускайте вопросов.<br />
					Опросник поможет узнать, почему вам важно заботиться о своем здоровье и к какой из шести мотиваций к здоровому образу жизни можно отнести вашу.
                </i>
                
                <div className="poll-header">
                    <h2 className="db cb">
                        Ценность здорового образа жизни
                    </h2>

                    <PollLegend
                        className="pt3"
                        opts={[
                            { key: "1", title: "Это совсем не про меня" },
                            { key: "2", title: "Скорее не про меня" },
                            { key: "3", title: "Не знаю" },
                            { key: "4", title: "Скорее соответствует мне" },
                            { key: "5", title: "Полностью соответствует мне" },
                        ]}
                    />
                </div>

                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    {renderedPoolItems}
                </div>

                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}
