import React from "react";
import "../../../css/comp/fast-graph.css";
import * as d3 from "d3";

import moment from "moment";
import "moment/locale/ru";

export class FastGraphRisks extends React.Component {
    constructor(props) {
        super(props);

        this.svg = React.createRef();
        this.colors = this.props.colors || [
            "#38C559",
            "#3883C5",
            "#C53838",
            "#E6C800",
            "#e67a00"
        ];
        this.tipColors = {
            "#38C559": "cold-green",
            "#3883C5": "blue",
            "#C53838": "red",
            "#E6C800": "yellow",
            "#E67A00": "orange",
            "#A0C538": "green"
        };

        this.state = {};

        this.data = this.props.data;
        ///Раскомментируйте, если нужна заглушка
        // if (!this.data) {
        //     //Пример данных
        //     var data = [];
        //     for (var j = 0; j < 2; j++) {
        //         var a = [];

        //         for (var i = 0; i < 10; i++) {
        //             var d = new Date();
        //             d.setDate((30 / 10) * i);
        //             a.push({
        //                 x: d,
        //                 y: Math.round(Math.random() * 5 + 8) * 1000
        //             });
        //         }
        //         data.push(a);
        //     }
        //     this.data = data;
        // }
    }
    formatByVal = (v, format) => {
        if (Date.prototype.isPrototypeOf(v)) return moment(v).format(format);
        else return v;
    };
    prepareData = d => {
        if (!d) return { data: [], trends: [], colors: {} };

        var ids = [];
        var data = [];
        var colors = {};
        //Список серий
        for (let i in d.actual_risks) {
            let r = d.actual_risks[i];
            for (let j = 0; j < r.length; j++) {
                let v = r[j];
                if (ids.indexOf(v.id) > -1) continue;
                ids.push(v.id);
                colors[v.id] = this.getBarColor(v.rang * 20);
                data.push([]);
            }
        }
        var sel = (arr, k) => {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id === k) return arr[i];
            }
            return { id: k, name: "", rang: 0 };
        };
        for (let i in d.actual_risks) {
            let r = d.actual_risks[i];
            var x = moment(i);

            for (var k = 0; k < ids.length; k++) {
                let v = sel(r, ids[k]);
                data[k].push({
                    x: x,
                    ...v
                });
            }
        }

        // for (let i = 0; i < data.length; i++) {
        //     console.log(data[i]);
        //     data[i] = data[i].sort((a, b) => a.x.valueOf() - b.x.valueOf());
        //     console.log("sorted", data[i]);
        // }
        

        return { data: data, trends: [], colors: colors };
    };
    getBarColor = v => {
        let color =
            v <= 20
                ? "#38C559"
                : v <= 40
                ? "#A0C538"
                : v <= 60
                ? "#E6C800"
                : v <= 80
                ? "#E67A00"
                : "#C53838";
        return color;
    };
    getUniqueRisks = () => {
        var risks = [];
        if (!this.props.data) return risks;
        var ids = [];
        for (let i in this.props.data.actual_risks) {
            let r = this.props.data.actual_risks[i];
            for (let j = 0; j < r.length; j++) {
                let v = r[j];
                if (ids.indexOf(v.id) > -1) continue;
                ids.push(v.id);
                risks.push(v);
            }
        }
        return risks;
    };
    draw = () => {
        let { data, trends, colors } = this.prepareData(this.props.data); // eslint-disable-line no-unused-vars
        this.data = data;

        // this.colors = this.props.colors || this.colors;
        var xFormat = this.props.xFormat || (v => this.formatByVal(v, "DD/MM"));
        var yFormat = this.props.yFormat || (v => this.formatByVal(v, "DD/MM"));

        //svg
        var svg = d3.select(this.svg.current);

        //Удаляем лишнее, если есть
        svg.selectAll("*").remove();
        //Габариты
        var w = this.svg.current.clientWidth;
        var h = this.svg.current.clientHeight;
        let ff = false;
        if (!w || !h) {
            let box = this.svg.current.getBoundingClientRect();
            w = box.right - box.left;
            h = box.bottom - box.top;
            ff = true;
        }

        //Отступы
        var margins = { top: 20, right: 0, bottom: 20, left: 30 };

        //Полный домен значений
        var fullData = [];
        for (let i = 0; i < data.length; i++) {
            fullData = fullData.concat(data[i]);
        }

        //Минимальная и максимальная дата (ось X)
        var minX = d3.min(fullData, d => d.x);
        var maxX = d3.max(fullData, d => d.x);
        //Минимальная и максимальная дата (ось Y)
        var minY = 0;
        var maxY = 5;

        var xr = d3
            .scaleTime()
            .range([margins.left, w - margins.right - 4])
            .domain([minX, maxX]);
        var yr = d3
            .scaleLinear()
            .range([h - margins.top, margins.bottom])
            .domain([minY, maxY]);

        //Рабочая область графика
        svg.append("rect")
            .attr("x", margins.left)
            .attr("y", margins.top)
            .attr("width", w - margins.left - margins.right)
            .attr("height", h - margins.top - margins.bottom)
            .attr("stroke", "none")
            .attr("fill", "#FFF");

        //Оси
        var axis = svg.append("g").attr("class", "axis");
        axis.append("path")
            .attr("d", `M${margins.left},0 L${margins.left},${h}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        axis.append("path")
            .attr("d", `M0,${margins.top} L${w},${margins.top}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        axis.append("path")
            .attr("d", `M0,${h - margins.bottom} L${w},${h - margins.bottom}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        //Подписи
        var maxYt = yFormat(maxY);
        var minYt = yFormat(minY);
        var ls = "0";
        if (maxYt.length > 4 || minYt.length > 4) ls = "-.7px";
        else if (maxYt.length > 3 || minYt.length > 3) ls = "-.4px";

        var getColor = serie => {
            if (serie.length > 0) return colors[serie[0].id];
            return "";
        };

        //OY
        axis.append("text")
            .attr("x", margins.left - 3)
            .attr("y", margins.top - 3)
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .text(maxYt);

        axis.append("text")
            .attr("x", margins.left - 3)
            .attr("y", h - margins.bottom - 3)
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .text(minYt);

        //OX
        axis.append("text")
            .attr("x", margins.left + 3)
            .attr("y", h - margins.bottom + 3 + (ff ? 10 : 0))
            .attr("text-anchor", "start")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .attr("alignment-baseline", "hanging")
            .text(xFormat(minX));

        axis.append("text")
            .attr("x", w)
            .attr("y", h - margins.bottom + 3 + (ff ? 10 : 0))
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .attr("alignment-baseline", "hanging")
            .text(xFormat(maxX));

        for (let i = 0; i < data.length; i++) {
            //График
            var line = d3
                .line()
                .x(d => xr(d.x))
                .y(d => yr(d.rang))
                .curve(d3.curveMonotoneX);

            svg.append("svg:path")
                .attr("d", line(data[i]))
                .attr("stroke", getColor(data[i]))
                .attr("stroke-width", "2")
                .attr("fill", "none")
                .attr(
                    "transform",
                    "translate(0,-" + (margins.bottom - margins.top) + ")"
                )
                .attr("class", "fast-graph-line");
            //Точки
            svg.selectAll("dot")
                .data(data[i])
                .enter()
                .append("circle")
                .attr("stroke", getColor(data[i]))
                .attr("stroke-width", "2")
                .attr("fill", "#FFF")
                .attr("r", 3)
                .attr("cx", d => {
                    return xr(d.x);
                })
                .attr("cy", d => {
                    return yr(d.rang);
                })
                // .on("mouseover", d => {
                //     this.setState({
                //         tipX: xr(d.x),
                //         tipY: yr(d.rang),
                //         tipText: xFormat(d.x) + ": " + yFormat(d.rang),
                //         tipColor: this.tipColors[this.colors[i]] || ""
                //     });
                // })
                .on("mouseout", d => {
                    this.setState({ tipText: "" });
                });
        }
    };
    componentDidMount() {
        this.draw();
    }
    componentDidUpdate() {
        if (this.props.data && this.props.data !== this.data) {
            this.draw();
        }
    }
    render() {
        var tipPos = {
            left: (this.state.tipX || 0) + "px",
            top: (this.state.tipY || 0) + "px"
        };
        return (
            <div className={"fast-graph " + this.props.className}>
                <div
                    className={`tip ${
                        this.state.tipText ? "visible appear-down" : ""
                    } ${this.state.tipColor || ""}`}
                    style={tipPos}
                >
                    {this.state.tipText || ""}
                </div>
                <svg ref={this.svg} />
                <div className="legend mt mb">
                    {this.getUniqueRisks().sort( (a, b) => b.rang - a.rang).map((x, i) => {

                        return (
                            <GraphLegendItem
                                key={x.id}
                                className="mb-half"
                                color={
                                    this.tipColors[
                                        this.getBarColor(x.rang * 20)
                                    ]
                                }
                                name={x.name}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

class GraphLegendItem extends React.PureComponent {
    render() {
        return (
            <div className={"legend-item " + (this.props.className || "")}>
                <div className={"color " + this.props.color} />
                <div className="title">{this.props.name}</div>
            </div>
        );
    }
}

export default FastGraphRisks;
