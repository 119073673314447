import React from "react";
import cx from 'classnames';
// import moment from "moment";
import "moment/locale/ru";

import "../../../css/comp/risk/date-changer.css";




// const MONTHS_NAMES = [
//     'январь', 'февраль', 'март', 'апрель',
//     'май', 'июнь', 'июль', 'август',
//     'сентябрь', 'октябрь', 'ноябрь', 'декабрь',
// ]

export default class DateChanger extends React.Component {
    render() {
        const {
            className,
            year, monthIx,
            prevDisabled, nextDisabled,
            onPrevClick, onNextClick,
            isLoading,
        } = this.props

        return (
            <div className={cx("date-changer", className)}>
                {!prevDisabled && (
                    <div className="change-button" onClick={!isLoading && onPrevClick}>
                        <span className="icon left" />
                    </div>
                )}

                <div className="date">
                    {monthIx && (
                        <span className="month">
                            {/* {MONTHS_NAMES[monthIx]}
                            {', '} */}
                        </span>
                    )}
                    <span className="year">{year}</span>
                    
                </div>

                {!nextDisabled && (
                    <div className="change-button" onClick={!isLoading && onNextClick}>
                        <span className="icon right"/>
                    </div>
                )}
            </div>
        );
    }
}
