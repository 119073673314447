import React from "react";
import cx from 'classnames';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);


const getDaysByWeeks = (date) => {
    const now = date.clone();
    const start = now.clone().startOf('month').startOf('isoWeek');
    const end = now.clone().endOf('month').endOf('isoWeek');

    const fullRange = moment.range(start, end);
    const weeksStarts = Array.from(fullRange.by('weeks'));

    const daysByWeeks = weeksStarts.map(((weekStart) => {
        const weekRange = moment.rangeFromInterval('week', 1, weekStart)
        return Array.from(weekRange.by('days', { excludeEnd: true }))
    }));
    return daysByWeeks;
}

export default class CalendarMonth extends React.Component {
    renderCell = (day) => {
        const data = (this.props.date.month() === day.month())
            ? (this.props.data[day.date()] || null)
            : null
        const isDifferentMonth = this.props.date.month() !== day.month()

        return (
            <div className="cell">
                <div className={cx("top-corner", {'different-month': isDifferentMonth})}>
                    {day.format('DD')}
                </div>
                <i className="content">
                    {data && data.events.join(',\n')}
                </i>
                <i className="bottom-corner">
                    {data && (data.more ? `ещё ${data.more}` : '')}
                </i>
            </div>
        );
    }

    render() {
        const daysByWeeks = getDaysByWeeks(this.props.date)

        return (
            <table className="calendar month">
                <tbody>
                    {daysByWeeks.map((weekDays, ix) => (
                        <tr key={ix}>
                            {weekDays.map((day, ix) => (
                                <td key={ix}>
                                    {this.renderCell(day)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
