import React from "react";
import { Route } from "react-router-dom";
// import Socials from "./socials";
import Button from "../shared/button";
import LabeledInput from "../shared/LabeledInput";
import { Api } from "../../libs/api";
import $ from "jquery";

class AuthForm extends React.Component {
    constructor(props) {
        super(props);

        this.toRemember = this.toRemember.bind(this);
        this.fieldChanged = this.fieldChanged.bind(this);
        this.validate = this.validate.bind(this);
        this.getError = this.getError.bind(this);

        this.state = {
            errors: {}
        };

        this.emailInput = React.createRef();
    }
    getError(key) {
        return this.state.errors[key] || "";
    }
    toRemember() {
        //Передаем выше
        if (this.props.toRemember) this.props.toRemember();
    }
    fieldChanged(val) {
        //Сохраняемм значение в локальном состоянии (по имени поля)
        var toSet = {};
        toSet[val.name] = val.value;
        this.setState(toSet);

        //Если E-mail - передаем выше
        if (val.name === "auth-username" && this.props.onEmailChange) {
            this.props.onEmailChange(val.value.toLowerCase());
        }
    }
    componentDidMount() {
        //Фокус на инпуте «E-mail» после открытия формы востановления пароля
        this.emailInput.current.focusInput();
        this.setState({ "auth-username": this.props.email }); //Востанавливаем локальное состояние из того что выше
    }
    handleErrorClick = name => {
        var errs = this.state.errors;
        console.log(errs, name);
        errs[name] = ""; //Скрываем ошибку
        this.setState({ errors: errs });
    };
    validate(history) {
        //var his = history || this.context.router.history;
        var errors = {};
        var hasError = false;

        if (!this.state["auth-username"]) {
            errors["auth-username"] = "Заполните поле «E-mail»";
            hasError = true;
        }

        if (!this.state["auth-password"]) {
            errors["auth-password"] = "Заполните поле «Пароль»";
            hasError = true;
        }

        //Обновляем ошибки предварительной обработки на полях
        this.setState({ errors: errors });

        if (!hasError) {
            this.setState({ loading: true });
            Api.login({
                username: this.state["auth-username"].toLowerCase(),
                password: this.state["auth-password"]
            })
                .then((res, x) => {
                    //Успешно! переадресовываем на профиль
                    this.setState({ loading: false });
                    history.push("/edit");
                })
                .catch(e => {
                    //Что-то страшное случилось
                    this.setState({
                        loading: false,
                        errors: { "auth-password": e.message }
                    });
                });
        }
    }
    render() {
        return (
            <div className="form-left pr-1-5 appear-right">
                <h2>Уже зарегистрированы?</h2>
                <i className="comment mb-1-5 db">
                    Введите E-mail и пароль, указанные при регистрации, чтобы
                    авторизоваться в системе
                </i>

                <LabeledInput
                    ref={this.emailInput}
                    type="text"
                    name="auth-username"
                    label="E-mail"
                    error={this.getError("auth-username")}
                    value={this.props.email}
                    onChange={this.fieldChanged}
                    onErrorClick={this.handleErrorClick}
                />
                <Route
                    render={({ history }) => (
                        <LabeledInput
                            type="password"
                            name="auth-password"
                            label="Пароль"
                            error={this.getError("auth-password")}
                            value={this.state["auth-password"]}
                            onChange={this.fieldChanged}
                            onErrorClick={this.handleErrorClick}
                            onKeyUp={e => {
                                if (e.which === 13)
                                    return this.validate(history);
                            }}
                            id="auth-password"
                        />
                    )}
                />
                <a href={"javascript:showPsw('auth-password')"}>Показать/скрыть пароль</a><br /><br />
                <div className="btn-container">
                    <div className="fl">
                        <a onClick={this.toRemember}>Забыли пароль?</a>
                    </div>
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                loading={this.state.loading}
                                onClick={() => this.validate(history)}
                            >
                                Войти в систему
                            </Button>
                        )}
                    />

                </div>
                {/* <Socials /> */}
            </div>
        );
    }
}

export default AuthForm;
