import React from "react";
import { Link, Route } from "react-router-dom";

class ProfileCard extends React.Component {
    getFullName = user => {
        var names = [];
        if (user.familyName && user.familyName !== "фамилия")
            names.push(user.familyName);
        if (user.givenName && user.givenName !== "имя")
            names.push(user.givenName);
        if (user.middleName && user.middleName !== "отчество")
            names.push(user.middleName);
        return names.join(" ") || "Анонимный Пользователь";
    };

    componentDidMount() {}

    render() {
        var form = this.props.form;
        var user = this.props.user || {};

        var avatar = user.avatar || "/res/img/man.svg";
        if (avatar.indexOf("http://") !== 0 && avatar.indexOf("/") !== 0)
            avatar = "//" + avatar;

        var loading = !this.props.user;

        return (
            <div className={"profile-card " + (loading ? "loading" : "")}>
                <div
                    className="avatar fl"
                    style={
                        loading
                            ? {}
                            : {
                                  backgroundColor: "#FFF",
                                  backgroundImage: "url(" + avatar + ")"
                              }
                    }
                />
                <div className="info">
                    <div className="title">{this.getFullName(user)}</div>
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                className="glyph glyph-edit"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/profile",
                                        e.target
                                    )
                                }
                            >
                                {form.side.profileAnchor}
                            </a>
                        )}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default ProfileCard;
