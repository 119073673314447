import React from "react";
import "../../css/comp/button.css";
import { Route } from "react-router-dom";

export class Button extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }
    onClick(history, e) {
        //Ничего не делаем, если кнопка в состоянии загрузки
        if (this.props.loading) {
            return;
        }

        //Пустое обещание
        let p = new Promise((res, rej) => {
            if (res) res();
        });
        if (this.props.beforeGo) p = this.props.beforeGo();

        //Есле передан переход на другую страницу, меняем состояние истории
        if (this.props.to) {
            p.then(x => {
                history.push(this.props.to);
            });
            return;
        }

        //Если есть onClick, вызываем onClick
        if (this.props.onClick) {
            p.then(x => {
                this.props.onClick(e);
            });
        }
    }
    render() {
        var css =
            (this.props.loading ? " btn-loading" : "") +
            (this.props.className ? " " + this.props.className : "");
        return (
            <Route
                render={({ history }) => (
                    <button
                        className={css}
                        onClick={e => {
                            if(this.props.onClick){
                                this.props.onClick(e)
                            }else{
                                this.onClick(history, e)}
                            }
                        }
                    >
                        {this.props.children}
                    </button>
                )}
            />
        );
    }
}

export default Button;
