import React from "react";
import {Link, Route} from "react-router-dom";

class EditHeader extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }
    render() {
        var form = this.props.form;

        return (
            <div className="header">
                <div className="nav-panel fl appear-right">
                    <div className="nav-item yellow profile current">
                        Информация о пользователе
                    </div>
                </div>
                <div className="container">
                    <div className="form">
                        {this.props.back && (
                            <Route
                            render={({ history }) => (
                                <Link
                                    onClick={e =>{
                                        e.preventDefault();
                                        this.props.goTo(
                                            history,
                                            this.props.back.to,
                                            e.target,
                                            true
                                            )
                                        }
                                    }
                                    to={this.props.back.to}
                                    className="glyph glyph-before glyph-left-round back-btn"
                                >
                                    {this.props.back.title}
                                </Link>
                            )}
                            />
                        )}
                        <h1 className="one-line appear-down">
                            {form.title}{" "}
                            <i className="comment">{form.comment}</i>
                        </h1>
                    </div>
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default EditHeader;
