import React from "react";
import { RememberForm, RememberSecondForm } from "./remember-form";
import AuthForm from "./auth-form";

class LeftAuthForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { form: "auth", email: '' };
        this.toRemember = this.toRemember.bind(this);
        this.toAuth = this.toAuth.bind(this);
        this.toSecond = this.toSecond.bind(this);

        this.emailChanged = this.emailChanged.bind(this);

        this.rememberInput = React.createRef();
    }
    toRemember() {
        this.setState({ form: "remember" });
    }
    toAuth() {
        this.setState({ form: "auth"});
    }
    toSecond(){
        this.setState({form: "remember-second"});
    }
    emailChanged(val) {
        this.setState({ email: val });
    }
    render() {
        switch (this.state.form) {
            case "remember":
                return <RememberForm toAuth={this.toAuth} toSecond={this.toSecond} email={this.state.email} onEmailChange={this.emailChanged} />
            case "remember-second":
                return <RememberSecondForm toAuth={this.toAuth} />
            default:
                return <AuthForm toRemember={this.toRemember} email={this.state.email} onEmailChange={this.emailChanged} />
        }
    }
}

export default LeftAuthForm;
