import React from "react";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { FastGraph } from "../shared/FastGraph";
import { LabeledInput } from "../shared/LabeledInput";
import { Check } from "../shared/CustomCheck";
import moment from "moment";
import "moment/locale/ru";
import { BaseEditForm, BtnMessage } from "./base-edit-form";
import $ from "jquery";

export class PhysiologyForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "", show_ad: false };
    }
    componentDidMount(){
        console.log({"test_1": this.props.fields });
        console.log({"test_2": this.baseEditProps("ads_upper_usual").value});
        var adUpUssual = (this.emptyOrDef(this.baseEditProps("ads_upper_usual").value, 0) == '') ? this.emptyOrDef(this.baseEditProps("ads_upper_usual").value, 0) : 0;
        
        var show_ad_param = (parseInt(adUpUssual) != 0) ? true : false;
        this.setState({show_ad: show_ad_param});
        if(show_ad_param){
            //$(".ad_differ_class").removeClass("check-unchecked");
            //$(".ad_differ_class").addClass("check-checked");
            // присвоить 1 флажку
        }
        
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "ad_differ") {
            console.log({ "vvv": v.value });
            if(parseInt(v.value) == 0){
                this.props.fields["ads_upper_usual"] = 0;
                this.props.fields["ads_down_usual"]  = 0;
                this.setState({ show_ad: false });
            }else{
                this.setState({ show_ad: true });
            }
        }

        /*
        if (v.name === "ads_upper_usual") {
            console.log({ "upper": v.value });
        }
        if (v.name === "ads_down_usual") {
            console.log({ "down": v.value });
        }
        */

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    render() {
        const F = React.Fragment;
        let fields = this.props.fields;

        return (
            <div className="appear-left">
                <div
                    className={
                        "col col-two col-last mb-1-5" +
                        (fields["_loading-pulse_person"] ? " loading" : "")
                    }
                >
                <h2 className="cb db">Пульс</h2>
                
                <div className="col col-four fl">
                    <div className="col col-four col-last fl mb cb">
                    <FastGraph
                        className="mb"
                        {...this.baseGraphProps("pulse_person")}
                        xFormat={v => moment(v).format("DD/MM")}
                        yFormat={v => Math.round(v)}
                    />
                    </div>
                </div>

                    <LabeledInput
                        {...this.baseEditProps("pulse_person")}
                        label="Пульс"
                        className="col col-two col-last"
                        units="уд/м"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("pulse_person")}
                    />
                </div>
                
                <h2 className="cb db">Рабочее артериальное давление</h2>
                <div className="col col-four col-last mb-1-5">
                    Физиологические показатели человека, такие как пульс и артериальное давление индивидуальны и зависят от многих факторов. По умолчанию нормой для артериального давления считается среднепопуляционная величина в зависимости от возраста. Однако, на давление влияют анатомические особенности (рост, вес), физическая тренированность, уровень стресса, место проживания, сфера деятельности, заболевания и другие факторы, под влиянием которых индивидуальное нормальное давление может быть выше или ниже популяционного. Артериальное давление, измеренное в состоянии покоя, при котором человек чувствует себя хорошо, называется «нормальным». А если человек принимает препараты от давления (при наличии соответствующего диагноза), то «рабочим», «целевым» или «адаптированным».<br />
                    В зависимости от возраста нормальными считаются следующие показатели артериального давления:<br />
                    <b className="bb">18-44 лет:</b> 120/80 мм рт. ст.<br />
                    <b className="bb">45-59 лет:</b> 130/85 мм рт. ст.<br />
                    <b className="bb">60 лет и старше:</b> 140/85 мм рт. ст.<br />
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Если комфортное значение Вашего артериального давления, которое часто называют рабочим, отличается от данной величины (в большую или в меньшую сторону) более чем на 10 единиц, то поставьте галочку около слова «Отличается», а затем введите значение Вашего рабочего давления:
                    </label>
                    <Check
                        className="col col-four col-last ad_differ_class"
                        {...this.baseEditProps("ad_differ")}
                    >
                        Отличается
                    </Check>
                </div>
                {fields["ad_differ"] === 1 && (
                    <div>
                        <div className="col col-four fl appear-up">
                            <div className="col col-two fl">
                                <LabeledInput
                                    {...this.baseEditProps("ads_upper_usual")}
                                    label={
                                        <F>
                                            Верхнее рабочее давление{" "}
                                            <i className="comment red">
                                                (систолическое)
                                            </i>
                                        </F>
                                    }
                                    className="col col-two"
                                    units="мм рт с"
                                    type="number"
                                    btns={this.baseOneSaveBtns("ads_upper_usual")}
                                />
                            </div>
                            <div className="col col-two fl col-last">
                                <LabeledInput
                                    {...this.baseEditProps("ads_down_usual")}
                                    label={
                                        <F>
                                            Нижнее рабочее давление{" "}
                                            <i className="comment">
                                                (диастолическое)
                                            </i>
                                        </F>
                                    }
                                    className="col col-two col-last"
                                    units="мм рт с"
                                    type="number"
                                    btns={this.baseOneSaveBtns("ads_down_usual")}
                                />
                            </div>
                        </div>
                        <div className="col col-two fl col-last appear-left">
                            <i className="comment">
                                Рабочее давление — давление, при&nbsp;котором вы
                                чувствуете себя нормально.
                            </i>
                        </div>
                    </div>
                )}

                <h2 className="cb db">Текущее артериальное давление</h2>
                <div className="col col-four fl">
                    <div className="col col-four col-last fl mb cb">
                        <FastGraph
                            {...this.baseGraphProps([
                                "ads_down_now",
                                "ads_upper_now"
                            ])}
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v)}
                            colors={["#3883C5", "#C53838"]}
							className="mb"
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl" +
                            (fields["_loading-ads_upper_now"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("ads_upper_now")}
                            label={
                                <F>
                                    Верхнее{" "}
                                    <i className="comment red">
                                        (систолическое)
                                    </i>
                                </F>
                            }
                            type="number"
                            min="0"
                            className="col col-two col-last"
                            units="мм рт с"
                            btns={this.baseOneSaveBtns("ads_upper_now")}
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl col-last" +
                            (fields["_loading-ads_down_now"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("ads_down_now")}
                            label={
                                <F>
                                    Нижнее{" "}
                                    <i className="comment">(диастолическое)</i>
                                </F>
                            }
                            type="number"
                            min="0"
                            className="col col-two col-last"
                            units="мм рт с"
                            btns={this.baseOneSaveBtns("ads_down_now")}
                        />
                    </div>
                </div>
                <div className="col col-two col-last fl">
                    <i className="comment db" style={{ paddingTop: "280px" }}>
                        Измерение давления автоматическим измерителем
                        (тонометром) нужно производить трижды и выводить среднее
                        или использовать последнее значение.
                    </i>
                </div>
                <h2 className="cb">Антропометрические параметры тела</h2>
                <div className="col col-two col-last cb">
                    <LabeledInput
                        {...this.baseEditProps("height")}
                        label="Ваш рост"
                        className="col col-two"
                        units="см"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("height")}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-weight"] ? " loading" : "")
                    }
                >
                    <FastGraph
                        {...this.baseGraphProps("weight")}
                        xFormat={v => moment(v).format("DD/MM")}
                        //yFormat={v => Math.round(v)}
                        yFormat={v => v}
                        className="mb"
                    />
                    <LabeledInput
                        {...this.baseEditProps("weight")}
                        label="Текущая масса тела"
                        className="col col-two col-last"
                        units="кг"
                        btns={this.baseOneSaveBtns("weight")}
                        type="number"
                        min="0"
                    />
                </div>
                <div className="col col-four col-last fl mb cb">
                        <FastGraph
                            {...this.ratioGraphProps([
                                "OTcm",
                                "OBcm"
                            ])}
                            label="Изменение отношения окружности талии к окружности бёдер"
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v*100)/100}
                            colors={["#3883C5", "#C53838"]}
                            minY={0.0}
                            maxY={3.0}
                            yOffset={0}
                        />
                </div>

                <div className="cb" />

                <div className="col col-two fl">

                {/* <div className="cb" /> */}
                    <div
                        className={
                            "col col-two fl col-last" +
                            (fields["_loading-OTcm"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("OTcm")}
                            label="Текущая окружность талии"
                            className="col col-two col-last"
                            units="см"
                            btns={this.baseOneSaveBtns("OTcm")}
                            type="number"
                            min="0"
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl" +
                            (fields["_loading-OBcm"] ? " loading" : "")
                        }
                    >
                        {/* <FastGraph
                            {...this.baseGraphProps("OBcm")}
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v)}
                            className="mb"
                        /> */}
                        <LabeledInput
                            {...this.baseEditProps("OBcm")}
                            label="Текущий обхват бедер"
                            className="col col-two col-last"
                            units="см"
                            btns={this.baseOneSaveBtns("OBcm")}
                            type="number"
                            min="0"
                        />
                    </div>
                </div>
                        <div className="col col-four fl col-last">
                            <i className="comment db mb">
                                Измеряют обхват бедер с помощью сантиметровой ленты по
                                самой выступающей точке ягодиц. Встаньте перед зеркалом
                                в профиль, оберните ленту вокруг бедер, заметьте самую
                                выступающую точку ягодиц, переместите ленту так, чтобы
                                она пересекалась с этой точкой, произведите замер.
                            </i>
                            <i className="comment db mb">
                                Важно, чтобы лента была обернута плотно, без провисаний,
                                но при этом не была внатяжку.
                            </i>
                            <i className="comment db mb">
                                Для правильного замера обхвата бедер должны быть
                                выполнены все вышеописанные условия.
                            </i>
                        </div>
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/lab",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Лабораторные и
                                инструментальные исследования»
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default PhysiologyForm;
