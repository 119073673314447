import React from "react";
import Button from "../../shared/button";

import { BaseEditForm } from "../base-edit-form";
import { Api } from "../../../../libs/api";


const POOLS_SUMMARY = [

    {
        id: 'neoffi',
        title: 'Опросник личностных черт (методика NEO-FFI)',
        description: `
Личностный опросник (тест «Большая пятерка») — позволяет оценить, насколько у человека выражены следующие черты личности:
нейротизм, экстраверсия, готовность к согласию, сознательность и открытость опыту.
        `,
    },


    {
        id: 'stability',
        title: 'Sustainability level',
        description: `
        Here follows the text, which is short, on three lines, describes the essence of the test for which it is intended. The counting method is not disclosed, so as not to affect the user's responses. The length of the description may be four lines.
        `,
    },
    {
        id: 'solitude',
        title: 'Feeling alone',
        description: `
        The differential questionnaire of experiencing loneliness is an original psychodiagnostic tool aimed at studying loneliness as a multidimensional phenomenon. It includes both negative and positive sides of the studied phenomenon.
        `,
    },
    {
        id: 'economic',
        title: 'Financial well-being',
        description: `
        The questionnaire is to measure the subjective economic well-being, which is considered as the attitude of the individual to the material aspects of life and expresses its life position in the sphere of material aspirations and consumption.
        `,
    },
    {
        id: 'disfunctional',
        title: 'Dysfunctional thinking',
        description: `
        The A. Beck and A. Weisman scale of dysfunctional relationships questionnaire, designed to study the cognitive distortions underlying the inadequate emotional response and psychogenic disorders.
        `,
    },
    {
        id: 'social',
        title: 'Social problems',
        description: `
        The presented questionnaire is the first russian tool to identify styles of solving social (personal) problems. This questionnaire can be useful both when conducting research in the field of personality psychology, and in the psychology of expertise and expertise.
        `,
    },
    {
        id: 'hads',
        title: 'Anxiety and Depression Scale',
        description: `
        The scale is intended for screening the detection of anxiety and depression in patients. Differs in simplicity of application and processing. Filling the scale does not require a long time and does not cause difficulties for the patient.
        `,
    },
    {
        id: 'selfrelation',
        title: 'Self-Questionnaire',
        description: `
        The questionnaire allows you to identify three levels of self-attitude, differing in the degree of generalization: the lobal self-attitude; self-attitude, differentiated by self-esteem, autympathy, self-interest and expectations of the relationship to yourself; the level of concrete actions (readiness for them) in relation to his “I”.
        `,
    },
    {
        id: 'selfefficacy',
        title: 'Self-efficacy diagnostics',
        description: `
        Here is one of the specific diagnostic methods developed by Maddux and Scheer, aimed at quantifying the level of self-efficacy. It is about assessing a person’s potential in the field of subject activity and in the field of communication, which he can really use.
        `,
    },

]

export default class PollsAllForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    handleSaveClick() {
        this.setState({ success: "Информация успешно сохранена! " });
    }

    componentDidMount() {
        Api.getPollStatus({ q_list: [] })
            .then(x => {
                console.log(x);
            })
            .catch(x => {
                console.error(x);
            });
    }

    render() {

        const pools_summary = POOLS_SUMMARY.map(({ id, title, description }) => (
            <React.Fragment key={id}>
                <h2 className="cb db">{title}</h2>
                <div className="col col-four fl mb-1-5">
                    {description}
                </div>
                <div className="col col-two fl col-last">
                    <div className="info-baloon arrow-left ta-center mb">
                        Pass the questionnaire to get the result
                    </div>
                </div>
                <div className="cb" />
                <Button to={`/eng/edit/polls/${id}`} className="mb2">
                    Proceed to accomplish
                </Button>
            </React.Fragment>
        ));

        return (
            <div className="appear-left">
                {pools_summary}
            </div>
        );
    }
}
