import React from "react";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import { LabeledInput } from "../shared/LabeledInput";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class IllnessesForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        //При сбросе типа диабета сбрасываем значения формы диабета
        if (v.name === "diabetes" && v.value !== 2)
            fields["diabetes_form"] = -1;

        //При отсутствии головной боли сбрасываем связанные значения полей
        if (v.name === "headache_frequency" && v.value === 0) {
            fields["headache_force"] = -1;
            fields["headache-meds"] = -1;
            fields["head_pain"] = -1;
        }

        if (v.name === "BPG" && v.value === 0)
            fields["dizziness_duration"] = -1;

        if (v.name === "loss_sight" && v.value === 0)
            fields["loss_sight_d"] = -1;

        if (v.name === "awkward_mov" && v.value === 0)
            fields["awkward_mov_d"] = -1;

        if (v.name === "head-vesseles-research" && v.value !== 2) {
            fields["head-vesseles-cartoid-lumen"] = 0;
            fields["head-vesseles-vertebral-lumen"] = 0;
            fields["head-vesseles-aneurysm"] = 0;
        }
        if (v.name === "steno" && v.value !== 3) {
            fields["noooooo"] = 0;
            fields["pach"] = 0;
            fields["OTJ"] = 0;
            fields["BISPN"] = 0;
        }
        if (v.name === "homocist" && v.value !== 1) {
            fields["homocist_cause"] = -1;
        }
        if (v.name === "ren_sick" && v.value !== 1) {
            fields["ren_stad"] = -1;
        }
        if (v.name === "schit_jel" && v.value !== 1) {
            fields["schit_jel_type"] = -1;
        }
        if (v.name === "fam_hyp_hol" && v.value !== 1) {
            fields["fam_hyp_hol_type"] = -1;
        }
        if (v.name === "hypertonic" && [0, 6].indexOf(v.value) > -1) {
            fields["Isolated_systolic_hypertension"] = -1;
        }
        let arr = [
            "other-states-chest-pain",
            "other-states-fast-heart-pain",
            "other-states-dyspnea"
        ];
        //Если "Нет" - снимаем все галочки с остальных
        if (v.name === "other-states-no" && v.value === 1) {
            for (let i = 0; i < arr.length; i++) {
                fields[arr[i]] = 0;
            }
        } else if (arr.includes(v.name)) {
            //...и наоборот
            if (v.value === 1) {
                fields["other-states-no"] = 0;
            }
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidMount() {
    }
    render() {
        let fields = this.props.fields || {};
        let F = React.Fragment;
        return (
            <div className="appear-left">
                <h2 className="cb db">Medical conditions</h2>
                <div className="col col-four fl mb-1-5">
                    <label>Hypertension:</label>
                    <RadioGroup {...this.baseEditProps("hypertonic", 6)}>
                        <Radio value={0} className="col col-four">
                            No diseases
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Stage 1 latent hypertension
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Stage 1 transient hypertension
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Stage 2 labile hypertension
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Stage 2 stable hypertension
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Stage 3 hypertension
                        </Radio>
                        <Radio value={6} className="col col-four">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                {[0, 6].indexOf(this.emptyOrDef(fields["hypertonic"], 6)) ===
                    -1 && (
                    <div className="col col-two fl col-last mb-1-5 appear-down">
                        <label>
                            Isolated systolic hypertension:
                        </label>
                        <RadioGroup
                            {...this.baseEditProps(
                                "Isolated_systolic_hypertension"
                            )}
                        >
                            <Radio value={0} className="col fl">
                                No
                            </Radio>
                            <Radio value={1} className="col fl col-last">
                                Yes
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="db" />
                {[0, 6].indexOf(this.emptyOrDef(fields["hypertonic"], 6)) ===
                    -1 && (
                    <F>
                        <div className="col col-four fl appear-up">
                            <div className="col col-two fl">
                                <LabeledInput
                                    {...this.baseEditProps("ads_upper_usual")}
                                    label={
                                        <F>
                                            Systolic{" "}
                                            <i className="comment red">
                                                (upper number)
                                            </i>{" "}
                                            blood pressure range
                                        </F>
                                    }
                                    className="col col-two"
                                    units="mm Hg"
                                />
                            </div>
                            <div className="col col-two fl col-last">
                                <LabeledInput
                                    {...this.baseEditProps("ads_down_usual")}
                                    label={
                                        <F>
                                            Diastolic{" "}
                                            <i className="comment">
                                                (lower number)
                                            </i>{" "}
                                            blood pressure range
                                        </F>
                                    }
                                    className="col col-two col-last"
                                    units="mm Hg"
                                />
                            </div>
                        </div>
                        <div className="col col-two fl col-last appear-left">
                            <i className="comment">
                                Normal blood pressure – when you feel normal.
                            </i>
                        </div>
                    </F>
                )}
                <div className="col col-four col-last mb-1-5">
                    <label>Hypotension:</label>
                    <RadioGroup {...this.baseEditProps("hypoton", 3)}>
                        <Radio value={0} className="col col-two">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Yes, orthostatic
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Yes, chronic
                        </Radio>
                        <Radio value={3} className="col col-two">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Diabetes (presence and type):</label>
                    <RadioGroup {...this.baseEditProps("diabetes", 4)}>
                        <Radio value={0} className="col col-three">
                            No diabetes
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Type 1
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Type 2
                        </Radio>
                        <Radio value={3} className="col col-three">
                            Gestational diabetes
                        </Radio>
                        <Radio value={4} className="col col-three">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["diabetes"], -1) === 2 && (
                    <div className="col col-three col-last fl appear-left">
                        <label>Форма диабета:</label>
                        <RadioGroup {...this.baseEditProps("diabetes_form")}>
                            <Radio value={0} className="col col-three">
                                Лёгкая форма{" "}
                                <i className="comment">(регулируется диетой)</i>
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Средней степени тяжести{" "}
                                {" "}<i className="comment db">
                                    (регулируется сахароснижающими препаратами)
                                </i>
                            </Radio>
                            <Radio value={2} className="col col-three">
                                Тяжёлое течение{" "}
                                <i className="comment">
                                    (регулируется инсулином)
                                </i>
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Atrial fibrillation:</label>
                    <RadioGroup {...this.baseEditProps("merc_aritm", 3)}>
                        <Radio value={0} className="col col-three">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Yes, periodic{" "}
                            {" "}<i className="comment db">
                                (attacks several times per month or less)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Yes, permanent{" "}
                            {" "}<i className="comment db">
                                (weekly attacks, several times per week)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-three">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <h2 className="cb db">Pain syndrome</h2>
                <div className="col col-two fl mb-1-5">
                    <label>Headache (presence and frequency):</label>
                    <RadioGroup
                        {...this.baseEditProps("headache_frequency", 0)}
                    >
                        <Radio value={0} className="col col-two">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Seldom
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Occasionally
                        </Radio>
                        <Radio value={3} className="col col-two">
                            Often
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-two fl appear-up">
                        <label>Headache pain:</label>
                        <RadioGroup {...this.baseEditProps("headache_force")}>
                            <Radio value={0} className="col col-two">
                            Minor
                            </Radio>
                            <Radio value={1} className="col col-two">
                            Moderate
                            </Radio>
                            <Radio value={2} className="col col-two">
                            Strong
                            </Radio>
                            <Radio value={3} className="col col-two">
                            Very strong
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-two col-last fl appear-left">
                        <label>Duration of pains:</label>
                        <RadioGroup {...this.baseEditProps("head_pain")}>
                            <Radio value={0} className="col col-two">
                                Several hours
                            </Radio>
                            <Radio value={1} className="col col-two">
                                24 hours and more
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-four col-last appear-up mb-1-5">
                        <label>
                        Do medications {" "}<i className="comment">(painkillers)</i>{" "} help with your headaches?
                        </label>
                        <RadioGroup {...this.baseEditProps("headache_meds")}>
                            <Radio value={1} className="col">
                                Yes
                            </Radio>
                            <Radio value={0} className="col col-last">
                                No
                            </Radio>
                        </RadioGroup>
                        <div className="cb" />
                    </div>
                )}
                <div className="col col-three mb-1-5 cb">
                    <label>Do you suffer from migraines?</label>
                    <RadioGroup {...this.baseEditProps("migren", 0)}>
                        <Radio value={0} className="col col-two">
                            No, doesn’t bother
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Yes, with aura
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-three mb-1-5 cb">
                    <label>
                        Chronic pain{" "}
                        <i className="comment">(of any localization)</i>:
                    </label>
                    <RadioGroup {...this.baseEditProps("HronPain", 0)}>
                        <Radio value={0} className="col col-two">
                            No, doesn’t bother
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Yes
                        </Radio>
                    </RadioGroup>
                </div>
                <h2 className="cb db">Other</h2>
                <div className="col col-three fl mb-1-5">
                    <label>
                        Did the causeless dizziness bother you {" "}
                        <i className="comment">
                        (without any obvious reason)
                        </i>
                        ?
                    </label>
                    <RadioGroup {...this.baseEditProps("BPG", 0)}>
                        <Radio value={0} className="col col-three">
                        No, never experienced
                        </Radio>
                        <Radio value={1} className="col col-three">
                        Experienced once
                        </Radio>
                        <Radio value={2} className="col col-three">
                        Experience occasionally
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields.BPG, 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left">
                        <label>
                        Duration of dizziness:
                        </label>
                        <RadioGroup
                            {...this.baseEditProps("dizziness_duration")}
                        >
                            <Radio value={0} className="col col-three">
                            Several minutes
                            </Radio>
                            <Radio value={1} className="col col-three">
                            Several hours
                            </Radio>
                            <Radio value={2} className="col col-three">
                            24 hours or more
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                        Have you experienced a temporary vision loss in one eye or visual field loss
                    </label>
                    <RadioGroup {...this.baseEditProps("loss_sight", 0)}>
                        <Radio value={0} className="col col-three">
                        No, did not occur
                        </Radio>
                        <Radio value={1} className="col col-three">
                        Occurred once
                        </Radio>
                        <Radio value={2} className="col col-three">
                        Occurs periodically
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["loss_sight"], 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left mt2">
                        <label>
                        Duration of vision loss:
                        </label>
                        <RadioGroup {...this.baseEditProps("loss_sight_d")}>
                            <Radio value={0} className="col col-three">
                            Several minutes
                            </Radio>
                            <Radio value={1} className="col col-three">
                            Several hours
                            </Radio>
                            <Radio value={2} className="col col-three">
                            24 hours or more
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Have you experienced memory loss during recent years?
                    </label>
                    <RadioGroup {...this.baseEditProps("memory_loss")}>
                        <Radio value={1} className="col col fl">
                            Yes
                        </Radio>
                        <Radio value={0} className="col col col-last fl">
                            No
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                    Have you ever had a sudden short-term weakness or awkwardness when moving in one arm or leg, or arm and leg at the same time?
                    </label>
                    <RadioGroup {...this.baseEditProps("awkward_mov", 0)}>
                        <Radio value={0} className="col col-three">
                            No, never experienced
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Experienced once
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Experience occasionally
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["awkward_mov"], 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left mt2">
                        <label>Duration:</label>
                        <RadioGroup {...this.baseEditProps("awkward_mov_d")}>
                            <Radio value={0} className="col col-three">
                                Several minutes
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Several hours
                            </Radio>
                            <Radio value={2} className="col col-three">
                                24 hours and more
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Have you noted in recent years a significant increase fatigue,{" "}
                        <br />
                        decreased performance?
                    </label>
                    <RadioGroup {...this.baseEditProps("increase_fatigue")}>
                        <Radio value={1} className="col col fl">
                            Yes
                        </Radio>
                        <Radio value={0} className="col col col-last fl">
                            No
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                <div className="cb" />

                <div className="col col-three fl mb-1-5">
                    <label>
                        Homocysteinemia as a result of hypovitinosis 
                        {" "}
                        <i className="comment">
                        (vitamin deficiency)
                        </i>
                        :
                    </label>
                    <RadioGroup {...this.baseEditProps("homocist")}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            I don't know
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                {this.emptyOrDef(fields["homocist"], 2) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label className="mt">Причина Гомоцистеинемии:</label>
                        <RadioGroup {...this.baseEditProps("homocist_cause")}>
                            <Radio value={0} className="col col-three col-last">
                                Вследствие дефицита витамина B6
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Вследствие дефицита витамина B12
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                Вследствие дефицита фолиевой кислоты
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Select conditions you’ve been diagnosed with:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("vaskul")}
                    >
                        Vasculitis / Vasculopathy
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("antifospho")}
                    >
                        Antiphospholipid syndrome
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("GipaC")}
                    >
                        Hepatitis C
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("System_volk")}
                    >
                        Systemic lupus erythematosus
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("sys_sclero")}
                    >
                        Systemic scleroderma
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("uzel_peritanit")}
                    >
                        Nodular periarteritis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("f_cerebro_artereet")}
                    >
                        Primary cerebral arteritis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("fybro_displazia_sosud")}
                    >
                        Fibrous muscular dysplasia of vessels
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("ritino")}
                    >
                        Retinopathy
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("insult_hs")}
                    >
                        Stroke
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("infarkt_hs")}
                    >
                        Myocardial infarction
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("aterscker")}
                    >
                        Atherosclerosis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("crirt_coronal_sind_anam")}
                    >
                        Acute coronary syndrome
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("spondelt")}
                    >
                        Spondylitis <i className="comment">(ankylosing spondylitis)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("revm_art")}
                    >
                        Rheumatoid arthritis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("artrit")}
                    >
                        Arthritis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("apnoe")}
                    >
                        Obstructive sleep apnea syndrome
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("importent")}
                    >
                        Severe erectile dysfunction
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("hantington")}
                    >
                        Huntington's disease
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("parkinson")}
                    >
                        Parkinson's disease
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("icenkushinga")}
                    >
                        Itsenko-Cushing disease
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("addisona")}
                    >
                        Addison's disease
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("ras_sclerosis")}
                    >
                        Multiple sclerosis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("akne")}
                    >
                        Acne
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("psoriaz")}
                    >
                        Psoriasis
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("periodontit")}
                    >
                        Periodontitis
                    </Check>
                </div>
                <div className="col col-four mb-1-5">
                    <label>
                        Did you undergo coronary revascularization?
                    </label>
                    <RadioGroup {...this.baseEditProps("coronal_rev")}>
                        <Radio value={0} className="col col-two col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Yes, angioplasty
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Yes, stenting
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Yes, shunting
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-three col-last mb-1-5">
                    <label>Neoplasms:</label>
                    <RadioGroup {...this.baseEditProps("bad_cancer", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            I don't know
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Chronic kidney disease:</label>
                    <RadioGroup {...this.baseEditProps("ren_sick", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            I don't know
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["ren_sick"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Укажите стадию болезни:</label>
                        <RadioGroup {...this.baseEditProps("ren_stad", -1)}>
                            <Radio value={0} className="col col-three col-last">
                                1 стадия
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                2 стадия
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                3а стадия
                            </Radio>
                            <Radio value={3} className="col col-three col-last">
                                3б стадия
                            </Radio>
                            <Radio value={4} className="col col-three col-last">
                                4 стадия
                            </Radio>
                            <Radio value={5} className="col col-three col-last">
                                5 стадия
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Thyroid gland diseases:</label>
                    <RadioGroup {...this.baseEditProps("schit_jel", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["schit_jel"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Вид заболевания:</label>
                        <RadioGroup
                            {...this.baseEditProps("schit_jel_type", -1)}
                        >
                            <Radio value={0} className="col col-three col-last">
                                Гипотиреоз
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Гипертиреоз
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                Другое
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Familial hypercholesterolemia:</label>
                    <RadioGroup {...this.baseEditProps("fam_hyp_hol", -1)}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            I don`t know
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["fam_hyp_hol"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Вид заболевания:</label>
                        <RadioGroup
                            {...this.baseEditProps("fam_hyp_hol_type", -1)}
                        >
                            <Radio value={0} className="col col-three col-last">
                                Гетерозиготная
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Гомозиготная
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                {/* <div className="col col-four mb-1-5">
                    <label>Псориаз:</label>
                    <RadioGroup {...this.baseEditProps("psoriaz", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, лёгкая форма
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, тяжёлая форма
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four mb-1-5">
                    <label>Периодонтит:</label>
                    <RadioGroup {...this.baseEditProps("periodontit", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, острый
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, хронический
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div> */}

                <div className="col col-two col-last mb-1-5">
                    <label>Angina:</label>
                    <RadioGroup {...this.baseEditProps("steno", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Yes, atypical
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Yes, typical
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Yes, without specifying
                        </Radio>
                        <Radio value={4} className="col col-two col-last">
                            I don't know
                        </Radio>
                    </RadioGroup>
                </div>
                {[3].indexOf(this.emptyOrDef(fields["steno"], 4)) > -1 && (
                    <div className="col col-four col-last mb-1-5 appear-up">
                        <label>
                            Испытывали ли вы когда-нибудь одно из перечисленных
                            состояний:
                        </label>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("noooooo")}
                        >
                            Нет
                        </Check>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("pach")}
                        >
                            Боль в области грудины длительностью 2-5 минут без
                            или с иррадиацией в левую руку, спину, нижнюю
                            челюсть или эпигастральную область в отсутствие или
                            во время физической нагрузки, либо эмоционального
                            стресса
                        </Check>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("OTJ")}
                        >
                            Одышку, ощущение «тяжести», «жжения» в области
                            сердца
                        </Check>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("BISPN")}
                        >
                            Быстроисчезающую боль в сердце после прекращения
                            нагрузки либо после приема нитратов
                        </Check>
                        <div className="cb" />
                    </div>
                )}
                <div className="col col-six col-last mb-1-5">
                    <label>Mental disorders:</label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("BDSP")}
                    >
                        Borderline personality disorder {" "}
                        <i className="comment">(diagnosis)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PoSR")}
                    >
                        Post traumatic stress disorder
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("TrevR")}
                    >
                        Anxiety disorder
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EDIP")}
                    >
                        Past depressive episodes
                    </Check>
                    <div className="cb" />
                </div>
                <h2 className="db cb">
                Long term drug use
                {" "}<i className="comment">(from one year and above)</i>
                </h2>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("snot_year")}
                >
                    Sleeping pills
                    {/* <i className="db comment">
                        (Фенобарбитал, Бромизовал, бензодиазепины – Нитразепам,
                        Клоназепам, Мидазолам, Гидазепам, Ниметазепам,
                        Флунитразепам, Алпразолам, Диазепам, Клобазам,
                        Мидазолам, Лоразепам, Клоразапат, Лопразолам,
                        Хлордиазепоксид, Феназепам, Триазолам, Гидазепам,
                        Бромазепам, Темазепам, Флуразепам и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("painkiller_yer")}
                >
                    Painkillers
                    {/* <i className="db comment">
                        (Морфин, Метилморфин, Метадон, Фентанил, Промедол,
                        Бупренорфин, Пентазоцин, Буторфанол, Налбуфин, Налоксон,
                        Трамадол и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("sedat_year")}
                >
                    Sedatives
                    {/* <i className="db comment">
                        (Корвалол, Валокордин, Валосердин и др.)
                    </i> */}
                </Check> 
                {/* <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("gipotenz_year")}
                >
                    Antihypertensives
                    <i className="db comment">
                        (Раунатин, Резерпин, Апрессин, Клофелин, Допегит,
                        Анаприлин, Обзидан, Верапамил, Нифедипин и др.)
                    </i>
                </Check> */}
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("ster_year")}
                >
                    Steroid
                    {/* <i className="db comment">
                        (Метандростенолон, Неробол, Дианабол, Ретаболил и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("interferon_year")}
                >
                    Interferon alpha
                </Check>
                {/* <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("Bblock_year")}
                >
                    Бета-блокаторы
                    <i className="db comment">
                        (Тенормин, Лопрессор, Корег и др.)
                    </i>
                </Check> */}
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("izoto_year")}
                >
                    Isotrethionine
                    {/* <i className="db comment">
                        (или аналоги: Акнекутан, Верокутан, Дерморетин, Ретасол,
                        Ретиноевая мазь, Роаккутан, Сотрет, 13-цис-Ретиноевая
                        кислота и др.)
                    </i> */}
                </Check>
                {this.emptyOrDef(fields["gender"], -1) === "F" && (
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UOGY")}
                >
                    Oral contraceptives containing progesterone
                    {/* <i className="db comment">
                        (Мини-пили – Чарозетта, Микролют, Экслютон и др.)
                    </i> */}
                </Check>
                )}

                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UPSPY")}
                >
                    Anticonvulsant
                    {/* <i className="comment db">
                        (Фенобарбитал и другие барбитураты, Целонтин, Заронтин и
                        др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UAPPY")}
                >
                    Antipsychotic
                    {/* <i className="db comment">
                        (Галоперидол, Аминазин, Трифтазин, Триседил, производные
                        Фенотиазина – Мажептил, Модитен, Пролинат, Тразин,
                        Эсказин и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UGPY")}
                >
                    Hormonal
                    {/* <i className="db comment">
                        (Преднизолон, Гидрокортизон, Премарин, Норплант и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UHPY")}
                >
                    Heart drugs
                    <i className="comment db">(Novokainamid, Digoxin, etc.)</i>
                </Check>
                <Check
                    className="col col-six col-last mb-1-5"
                    {...this.baseEditProps("antimigren_year")}
                >
                    Antimigraine
                    {/* <i className="comment db">
                        (Naproxen, Rapten Rapid, etc.)
                    </i> */}
                </Check>
                <h2 className="cb db">Chemotherapy and Radiation Therapy</h2>
                <div className="col col-six col-last mb-1-5">
                    <label>
                        Have you ever undergone chemotherapy to treat cancer?
                    </label>
                    <RadioGroup {...this.baseEditProps("himka")}>
                        <Radio value={0} className="col col-two col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Yes, earlier
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Yes, currently
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mb-1-5">
                    <label>
                        Have you ever undergone radiation therapy to the chest, head or neck?
                    </label>
                    <RadioGroup {...this.baseEditProps("LuchTer")}>
                        <Radio value={0} className="col col-two col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Yes, earlier
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Yes, currently
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <h2 className="cb db">Family history</h2>
                <Check
                    className="col col-four mb-1-5"
                    {...this.baseEditProps("depression_family")}
                >
                    Depression among close relatives
                    {" "}<i className="comment">(parents, brothers, sisters)</i>
                </Check>
                <div className="col col-three fl mb-1-5">
                    <label>Early strokes:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas1")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas2")}
                    >
                        Extended family
                        {" "}<i className="comment db">(grandmothers / grandfathers, grandchildren, five / aunts, nephews)</i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>Strokes in elderly:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas3")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas4")}
                    >
                        Extended family
                        {" "}<i className="comment db">(grandmothers / grandfathers, grandchildren, five / aunts, nephews)</i>
                    </Check>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Early myocardial infarction:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas1")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas2")}
                    >
                       Extended family
                        {" "}<i className="comment db">
                        (grandmothers / grandfathers, grandchildren, five / aunts, nephews)
                        </i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>Myocardial infarction in old age:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas3")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas4")}
                    >
                        Extended family
                        {" "}<i className="comment db">
                        (grandmothers / grandfathers, grandchildren, five / aunts, nephews)
                        </i>
                    </Check>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Early deaths from heart disease:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas1")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas2")}
                    >
                        Extended family
                        {" "}<i className="comment db">
                        (grandmothers / grandfathers, grandchildren, five / aunts, nephews)
                        </i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>
                    Deaths of heart disease in old age:
                    </label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas3")}
                    >
                        Immediate family
                        {" "}<i className="comment db">(parents, brothers, sisters)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas4")}
                    >
                        Extended family
                        {" "}<i className="comment db">
                        (grandmothers / grandfathers, grandchildren, five / aunts, nephews)
                        </i>
                    </Check>
                </div>
                <div className="cb" />
                <div className="btn-container mt">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/lifestyle",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to the section "Lifestyle"
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default IllnessesForm;
