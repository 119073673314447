import React from "react";
import classnames from "classnames";
import { Route } from "react-router-dom";


import { Radio, RadioGroup } from "../shared/CustomRadio";
import Button from "../shared/button";
import { BaseEditForm, BtnMessage } from "./base-edit-form";
import Slider from "../shared/Slider";
import LabeledMonthPicker from "../shared/LabeledMonthPicker";


export class SocialForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };

        this.containerRef = React.createRef();
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }
    handleSliderDragStart = name => {
        this.containerRef.current.className = classnames(
            this.containerRef.current.className,
            {
                "no-select": true
            }
        );
    };
    handleSliderDragStop = name => {
        this.containerRef.current.className = this.containerRef.current.className.replace(
            "no-select",
            ""
        );
    };

    componentDidUpdate() {}
    componentDidMount() {}

    render() {
        // let fields = this.props.fields || {};
        return (
            <div ref={this.containerRef} className="appear-left">
                <div className="col col-three col-last mb-1-5">
                    <label>
                        Как вы оцениваете общее состояние своего здоровья?
                    </label>
                    <RadioGroup {...this.baseEditProps("health_status")}>
                        <Radio value={0} className="col col-three col-last">
                            Очень хорошее
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Достаточно хорошее
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Среднее
                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                            Достаточно плохое
                        </Radio>
                        <Radio value={4} className="col col-three col-last">
                            Очень плохое
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Как бы вы оценили общее состояние своего здоровья по
                        сравнению с&nbsp;другими людьми своего возраста?
                    </label>
                    <RadioGroup {...this.baseEditProps("health_status_equal")}>
                        <Radio value={0} className="col col-three col-last">
                            Гораздо лучше
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Немного лучше
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Ни лучше, ни хуже
                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                            Немного хуже
                        </Radio>
                        <Radio value={4} className="col col-three col-last">
                            Гораздо хуже
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <div className="col col-four fl mb-1-5">
                    <label>Ваше образование:</label>
                    <RadioGroup {...this.baseEditProps("education")}>
                        <Radio value={0} className="col col-four col-last">
                            Неоконченные 9 классов
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Оконченные 9 классов
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Среднее{" "}
                            <i className="comment">(оконченные 11 классов)</i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Не оконченное среднее профессиональное{" "}
                            <i className="comment db">
                                (не окончил ПТУ/колледж/техникум)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                            Оконченное среднее профессиональное{" "}
                            <i className="comment db">
                                (окончил ПТУ/колледж/техникум)
                            </i>
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                            Имею или получаю высшее образование
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-two fl mb-1-5 col-last">
                    <LabeledMonthPicker
                        label="Дата получения образования"
                        {...this.baseEditProps("education_date")}
                    />
                    <i className="comment db mt-half">
                        (дата получения последнего образования с точностью до
                        месяца)
                    </i>
                </div>
                <div className="cb" />
                <div className="col col-four fl mb-1-5">
                    <label>Уровень материального благополучия:</label>
                    <RadioGroup {...this.baseEditProps("moneylvl")}>
                        <Radio value={0} className="col col-four col-last">
                            Не хватает денег даже на еду
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Хватает на еду, но не хватает на покупку одежды и
                            обуви
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Хватает на одежду и обувь, но не хватает на покупку
                            мелкой бытовой техники
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Хватает денег на различные покупки, но покупка
                            дорогих вещей требует кредита
                            <i className="comment db">
                                (компьютера, стиральной машины, холодильника)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                            Хватает денег на все, а на покупку квартиры, машины,
                            дачи необходимо накапливать денежные средства
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                            Могу позволить себе все
                        </Radio>
                    </RadioGroup>
                </div>
                {/* <div className="col col-two fl col-last mb-1-5 appear-left">
                    <div className="info-baloon mt-label mb">
                        Более точные данные об уровне субъективного
                        материального благополучия вы можете внести, пройдя
                        специальный тест
                    </div>
                    <Button beforeGo={this.handleSaveClick} to="/edit/polls/economic  " className="col col-two col-last">
                        Приступить к выполнению
                    </Button>
                </div> */}
                <div className="col col-six col-last mt-1-5 mb-1-5">
                    <label>
                        Оцените, пожалуйста, уровень стресса в вашей жизни на
                        протяжении последнего месяца:
                    </label>
                    <Slider
                        className="col-six mt"
                        noInput={true}
                        {...this.baseEditProps("stress")}
                        ticks={4}
                        from={0}
                        to={4}
                        fromLabel="очень низкий"
                        toLabel="очень высокий"
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                    />
                </div>
                <div className="col col-three col-last mb-1-5">
                    <label>Ваши условия проживания:</label>
                    <RadioGroup {...this.baseEditProps("alone_fils")}>
                        <Radio value={0} className="col col-three col-last">
                            Проживаю с семьёй
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Проживание без семьи
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four mb-1-5">
                    <label>Степень религиозности:</label>
                    <RadioGroup {...this.baseEditProps("relgion", "")}>
                        <Radio value={0} className="col col-four col-last">
                            Не религиозен
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Низкая{" "}
                            <i className="comment db">
                                (считаю себя религиозным человеком, но не
                                соблюдаю все традиции)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Средняя{" "}
                            <i className="comment db">
                                (соблюдаю основные традиции религии, но не все и
                                не всегда)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Высокая{" "}
                            <i className="comment db">
                                (стараюсь соблюдать все традиции регулярно и/или
                                состою в религиозной общине)
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/events",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «События жизни»
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SocialForm;
