import React from "react";
import "../../../css/comp/avatar-editor.css";

class AvatarEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/svg"
        ];
    }

    handleDrop = e => {
        e.preventDefault();

        if (e.dataTransfer) {
            var file = e.dataTransfer.files[0];
            if (!this.allowedTypes.indexOf(file.type) > -1) {
                this.setState({ error: "Unsupported file format" });
            }

            if (this.props.onChange) {
                this.props.onChange({ name: this.props.name, value: file });
            }
        }
        this.setState({ dragOver: false });
    };

    handleDragOver = e => {
        e.preventDefault();

        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = "move";
            e.dataTransfer.effectAllowed = "move";
        }

        this.handleDragEnter();
    };

    handleDragEnter = e => {
        if (!this.state.dragOver) {
            this.setState({ dragOver: true });
        }
    };

    handleDragLeave = e => {
        this.setState({ dragOver: false });
    };

    render() {
        var avatar = this.props.value || "/res/img/man.svg";
        var sampleAvatar = !this.props.value;

        if (avatar.indexOf("http") !== 0 && avatar.indexOf("/") !== 0)
            avatar = "//" + avatar;

        return (
            <div
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                className={
                    "avatar-editor mb-1-5" +
                    (this.state.dragOver ? " drag-over" : "")
                }
            >
                <div
                    className="avatar fl"
                    style={{
                        backgroundColor: "#FFF",
                        backgroundImage: "url(" + avatar + ")",
                        backgroundSize: sampleAvatar ? "90px" : "cover"
                    }}
                />
                <p>
                    To attach a photo, drag it here with your mouse or click the area (icon) and select the file from the list on your device
                </p>
                <div className="cb"></div>
            </div>

        );
    }
}

export default AvatarEditor;
