import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RiskSide from "./RiskSide";
import RiskMenu from "./RiskMenu";

import EventsLayout from "./events/EventsLayout";
import HealthLayout from "./health/HealthLayout";

import "../../../css/comp/risk/risk-layout.css";
import "../../../css/comp/risk/common.css";


export default class RiskLayout extends React.Component {
    render() {
        return (
            <div className="risk">
                <RiskSide>
                    <RiskMenu />
                </RiskSide>

                <Switch>
                    <Route path={`/eng/risk/health`} component={HealthLayout} />
                    <Route path={`/eng/risk/events`} component={EventsLayout} />

                    <Redirect to={`/eng/risk/health`} />
			    </Switch>
            </div>
        );
    }
}
