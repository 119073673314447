import React from "react";
import cx from 'classnames';

import "../../css/comp/risk/underlined-heading.css";


export default function UnderlinedHeading(props) {
    return (
        <div className={cx('underlined-heading', props.className)}>
            <div className='inner'>
                {props.children}
            </div>
        </div>
    );
}
