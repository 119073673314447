
function range(start, end) {
    var ans = [];
    for (let i = start; i < end; i++) {
        ans.push(i);
    }
    return ans;
}



var PollsStates = (() => {
    var _ns = {
        def: {
            side: {
                profileAnchor: "О себе",
                logoutAnchor: "Выйти"
            }
        },
        states: {
            all: {
                title: "Другие опросники",
                noWindowTitle: true
            },
            stability: {
                title: "Уровень устойчивости",
                comment: "",
                fields: [
                    "stability_0",
                    "stability_1",
                    "stability_2",
                    "stability_3",
                    "stability_4",
                    "stability_5",
                    "stability_6",
                    "stability_7",
                    "stability_8",
                    "stability_9",
                    "stability_10",
                    "stability_11"
                ],
                noSave: []
            },
            solitude: {
                title: "Переживание одиночества",
                comment: "",
                noSave: [],
                fields: [
                    "solitude_1",
                    "solitude_2",
                    "solitude_3",
                    "solitude_4",
                    "solitude_5",
                    "solitude_6",
                    "solitude_7",
                    "solitude_8",
                    "solitude_9",
                    "solitude_10",
                    "solitude_11",
                    "solitude_12",
                    "solitude_13",
                    "solitude_14",
                    "solitude_15",
                    "solitude_16",
                    "solitude_17",
                    "solitude_18",
                    "solitude_19",
                    "solitude_20",
                    "solitude_21",
                    "solitude_22",
                    "solitude_23",
                    "solitude_24"
                ]
            },
            economic: {
                title: "Финансовое благополучие",
                fields: [
                    "sub_economic_well-being_1",
                    "sub_economic_well-being_2",
                    "sub_economic_well-being_3",
                    "sub_economic_well-being_4",
                    "sub_economic_well-being_5",
                    "sub_economic_well-being_6",
                    "sub_economic_well-being_7",
                    "sub_economic_well-being_8",
                    "sub_economic_well-being_9",
                    "sub_economic_well-being_10",
                    "sub_economic_well-being_11",
                    "sub_economic_well-being_12",
                    "sub_economic_well-being_13",
                    "sub_economic_well-being_14",
                    "sub_economic_well-being_15",
                    "sub_economic_well-being_16",
                    "sub_economic_well-being_17",
                    "sub_economic_well-being_18",
                    "sub_economic_well-being_19",
                    "sub_economic_well-being_20",
                    "sub_economic_well-being_21",
                    "sub_economic_well-being_22",
                    "sub_economic_well-being_23",
                    "sub_economic_well-being_24",
                    "sub_economic_well-being_25",
                    "sub_economic_well-being_26"
                ],
                converters: {
                    "sub_economic_well-being_10": x => Math.round(x)
                }
            },
            disfunctional: {
                title: "Дисфункциональное мышление",
                noSave: [],
                fields: [
                    "Dysfunctional_thinking_0",
                    "Dysfunctional_thinking_1",
                    "Dysfunctional_thinking_2",
                    "Dysfunctional_thinking_3",
                    "Dysfunctional_thinking_4",
                    "Dysfunctional_thinking_5",
                    "Dysfunctional_thinking_6",
                    "Dysfunctional_thinking_7",
                    "Dysfunctional_thinking_8",
                    "Dysfunctional_thinking_9",
                    "Dysfunctional_thinking_10",
                    "Dysfunctional_thinking_11",
                    "Dysfunctional_thinking_12",
                    "Dysfunctional_thinking_13",
                    "Dysfunctional_thinking_14",
                    "Dysfunctional_thinking_15",
                    "Dysfunctional_thinking_16",
                    "Dysfunctional_thinking_17",
                    "Dysfunctional_thinking_18",
                    "Dysfunctional_thinking_19",
                    "Dysfunctional_thinking_20",
                    "Dysfunctional_thinking_21",
                    "Dysfunctional_thinking_22",
                    "Dysfunctional_thinking_23",
                    "Dysfunctional_thinking_24",
                    "Dysfunctional_thinking_25",
                    "Dysfunctional_thinking_26",
                    "Dysfunctional_thinking_27",
                    "Dysfunctional_thinking_28",
                    "Dysfunctional_thinking_29",
                    "Dysfunctional_thinking_30",
                    "Dysfunctional_thinking_31",
                    "Dysfunctional_thinking_32",
                    "Dysfunctional_thinking_33",
                    "Dysfunctional_thinking_34",
                    "Dysfunctional_thinking_35",
                    "Dysfunctional_thinking_36",
                    "Dysfunctional_thinking_37",
                    "Dysfunctional_thinking_38",
                    "Dysfunctional_thinking_39"
                ]
            },
            social: {
                title: "Социальные проблемы",
                noSave: [],
                fields: [
                    "social_problem_1",
                    "social_problem_2",
                    "social_problem_3",
                    "social_problem_4",
                    "social_problem_5",
                    "social_problem_6",
                    "social_problem_7",
                    "social_problem_8",
                    "social_problem_9",
                    "social_problem_10",
                    "social_problem_11",
                    "social_problem_12",
                    "social_problem_13",
                    "social_problem_14",
                    "social_problem_15",
                    "social_problem_16",
                    "social_problem_17",
                    "social_problem_18",
                    "social_problem_19",
                    "social_problem_20",
                    "social_problem_21",
                    "social_problem_22",
                    "social_problem_23",
                    "social_problem_24",
                    "social_problem_25",
                    "social_problem_26",
                    "social_problem_27",
                    "social_problem_28",
                    "social_problem_29",
                    "social_problem_30",
                    "social_problem_31",
                    "social_problem_32",
                    "social_problem_33",
                    "social_problem_34",
                    "social_problem_35",
                    "social_problem_36",
                    "social_problem_37",
                    "social_problem_38"
                ]
            },
            // alcohol: {
            //     title: "Употребление алкоголя"
            // },
            hads: {
                title: "Шкала Тревоги и Депрессии",
                noSave: [],
                fields: [
                    "HADS_anxiety_1",
                    "HADS_anxiety_2",
                    "HADS_anxiety_3",
                    "HADS_anxiety_4",
                    "HADS_anxiety_5",
                    "HADS_anxiety_6",
                    "HADS_anxiety_7",
                    "HADS_depression_1",
                    "HADS_depression_2",
                    "HADS_depression_3",
                    "HADS_depression_4",
                    "HADS_depression_5",
                    "HADS_depression_6",
                    "HADS_depression_7"
                ]
            },
            selfrelation: {
                title: "Самоотношение",
                noSave: [],
                fields: [
                    "selfrelation_0",
                    "selfrelation_1",
                    "selfrelation_2",
                    "selfrelation_3",
                    "selfrelation_4",
                    "selfrelation_5",
                    "selfrelation_6",
                    "selfrelation_7",
                    "selfrelation_8",
                    "selfrelation_9",
                    "selfrelation_10",
                    "selfrelation_11",
                    "selfrelation_12",
                    "selfrelation_13",
                    "selfrelation_14",
                    "selfrelation_15",
                    "selfrelation_16",
                    "selfrelation_17",
                    "selfrelation_18",
                    "selfrelation_19",
                    "selfrelation_20",
                    "selfrelation_21",
                    "selfrelation_22",
                    "selfrelation_23",
                    "selfrelation_24",
                    "selfrelation_25",
                    "selfrelation_26",
                    "selfrelation_27",
                    "selfrelation_28",
                    "selfrelation_29",
                    "selfrelation_30",
                    "selfrelation_31",
                    "selfrelation_32",
                    "selfrelation_33",
                    "selfrelation_34",
                    "selfrelation_35",
                    "selfrelation_36",
                    "selfrelation_37",
                    "selfrelation_38",
                    "selfrelation_39",
                    "selfrelation_40",
                    "selfrelation_41",
                    "selfrelation_42",
                    "selfrelation_43",
                    "selfrelation_44",
                    "selfrelation_45",
                    "selfrelation_46",
                    "selfrelation_47",
                    "selfrelation_48",
                    "selfrelation_49",
                    "selfrelation_50",
                    "selfrelation_51",
                    "selfrelation_52",
                    "selfrelation_53",
                    "selfrelation_54",
                    "selfrelation_55",
                    "selfrelation_56"
                ]//,
                //allConverter: x => (x === 1 ? 0 : 1) //Инвертируем значения
            },
            selfefficacy: {
                title: "Диагностика самоэффективности",
                fields: [
                    "Selfefficacy_0",
                    "Selfefficacy_1",
                    "Selfefficacy_2",
                    "Selfefficacy_3",
                    "Selfefficacy_4",
                    "Selfefficacy_5",
                    "Selfefficacy_6",
                    "Selfefficacy_7",
                    "Selfefficacy_8",
                    "Selfefficacy_9",
                    "Selfefficacy_10",
                    "Selfefficacy_11",
                    "Selfefficacy_12",
                    "Selfefficacy_13",
                    "Selfefficacy_14",
                    "Selfefficacy_15",
                    "Selfefficacy_16",
                    "Selfefficacy_17",
                    "Selfefficacy_18",
                    "Selfefficacy_19",
                    "Selfefficacy_20",
                    "Selfefficacy_21",
                    "Selfefficacy_22"
                ],
                noSave: []
            },

            neoffi: {
                title: "Личностные черты",
                noSave: [],
                fields: range(1, 61).map((i) => `neo_ffi_${i}`)
            },
			
            motivation: {
                title: "Ценность здорового образа жизни",
                fields: [
                    "motivation_questionnaire_1",
                    "motivation_questionnaire_2",
                    "motivation_questionnaire_3",
                    "motivation_questionnaire_4",
                    "motivation_questionnaire_5",
                    "motivation_questionnaire_6",
                    "motivation_questionnaire_7",
                    "motivation_questionnaire_8",
                    "motivation_questionnaire_9",
                    "motivation_questionnaire_10",
                    "motivation_questionnaire_11",
                    "motivation_questionnaire_12",
                    "motivation_questionnaire_13",
                    "motivation_questionnaire_14",
                    "motivation_questionnaire_15",
                    "motivation_questionnaire_16",
                    "motivation_questionnaire_17",
                    "motivation_questionnaire_18"
                ],
                noSave: []
            },
			
        },
        /**
         * Получает состояние по умолчанию для Layout в разделе /edit/polls/:form
         * @param {string} form - Имя формы, например "profile"
         * @return {object} Состояние Layout
         */
        get: form => {
            var def = _ns.def;
            def.form = form;

            return {
                ...def,
                ...(_ns.states[form] || {})
            };
        }
    };
    return _ns;
})();

export default PollsStates;
