import React from "react";
import { Api } from "../../../libs/api";

export default class GFitLogin extends React.Component {

    constructor(props) {
        super(props);

        this.parseCodeParam     = this.parseCodeParam.bind(this);
    }

    parseCodeParam(){
        let result = /code=(.+?)[&$]/.exec(document.location.search);

        if(result != null)
            return result[1];
        
        return result;
    }

    componentDidMount() {
        let code = this.parseCodeParam();

        if(code != null){
            window.opener.postMessage({code: code}, '*');
            window.close();
        }
    }

    render() {
        return <div>Вы будете перенаправлены для авторизации</div>
    }
}
