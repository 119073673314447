import React from "react";

const Header = (props) => {
    return <div className="header">
        
        <div className="form">
            <h1 className="one-line" style={{
                display: "inline-block"
            }}
            
            >{props.title || "Sign In"}</h1>

            <div className="fr" style={{
                display: "inline-block",
                verticalAlign: 'baseline'
            }}>
                        <a href="/">RU</a>
                        |
                        <a href="/eng">ENG</a>
            </div>

        </div>

    </div>;
};

export default Header;