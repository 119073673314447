

export function save_text_file(filename, text) {
    var el = document.createElement('a');
    el.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    el.setAttribute('download', filename);
    // el.setAttribute('target', '_blank');

    // el.style.display = 'none';
    // document.body.appendChild(el);
    el.click();
    // document.body.removeChild(el);
}
