import React from "react";
import Header from "./header";

import "../../css/pages/auth.css";
import { Qs } from "../../libs/Qs";
import { Api } from "../../libs/api";

export class VkLoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { redirect: null, title: "Подождите пару секунд..." };
    }
    componentDidMount() {
        var data = Qs.parse(window["location"].hash);
        this.setState({
            redirect: (
                <i className="comment">
                    (Авторизация VK успешна. Производится сохранение данных
                    авторизации)
                </i>
            )
        });
        Api.vkLogin(data)
            .then(x => {
                window.opener.handleVkLoginResponse(data, x);
                window.close();
            })
            .catch(x => {
                // console.log(x);
                window.opener.handleVkLoginResponse(data, x);
                this.setState({
                    redirect: (
                        <React.Fragment>
                            <i className="comment red db">{x.message}</i>
                            <p>(Inner: {x.innerMessage} )</p>
                        </React.Fragment>
                    ), title: "Произошла ошибка!"
                });
            });
    }
    render() {
        return (
            <div className="auth">
                <Header />
                <div className="form form-auth form-full">
                    <h2>{this.state.title}</h2>{" "}
                    {this.state.redirect || (
                        <i className="comment">
                            (Производится обработка параметров авторизации VK)
                        </i>
                    )}
                    <div className="cb" />
                </div>
            </div>
        );
    }
}
