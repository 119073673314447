import React from "react";

import LabeledInput from "../shared/LabeledInput";
import Button from "../shared/button";
import { Api } from "../../../libs/api";


export class RememberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRemember: false,
            loading: false,
            errors: {
                'remember-email': null,
            },
        };

        this.emailInput = React.createRef();
    }

    toAuth = () => {
        //Передаем выше
        if (this.props.toAuth) this.props.toAuth();
    }
    toSecond = () => {
        if (this.state.loading) return;

        this.setState({ loading: true });

        Api.sendRememeberMail(this.props.email).then(x => {
            console.log(x);
            if (this.props.toSecond) this.props.toSecond();
        }).catch(x=>{
            console.warn(x);
            this.setState({
                loading: false,
                errors: {
                    'remember-email': x.message,
                },
            });
        });
    }
    emailChanged = (val) => {
        if (this.props.onEmailChange) this.props.onEmailChange(val.value);
    }

    componentDidMount() {
        //Фокус на инпуте «E-mail» после открытия формы востановления пароля
        this.emailInput.current.focusInput();
    }

    render() {
        return (
            <div className="form-left pr-1-5 appear-right">
                <h2>Password recovery</h2>
                <i className="comment mb-1-5 db">
                    Enter your email address and click the button -- an email will be sent to you with a instruction to reset your password
                </i>
                <LabeledInput
                    ref={this.emailInput}
                    value={this.props.email}
                    onChange={this.emailChanged}
                    type="text"
                    name="remember-email"
                    label="E-mail"
                    error={this.state.errors["remember-email"]}
                />
                <div className="btn-container">
                    <div className="fl">
                        <a onClick={this.toAuth}>Back to login</a>
                    </div>
                    <Button
                        className={
                            "fr" + (this.state.loading ? " loading" : "")
                        }
                        onClick={this.toSecond}
                    >
                        Recover
                    </Button>
                </div>
            </div>
        );
    }
}



export class RememberSecondForm extends React.Component {
    constructor(props) {
        super(props);

        this.toAuth = this.toAuth.bind(this);
    }
    toAuth() {
        //Передаем выше
        if (this.props.toAuth)
            this.props.toAuth();
    }
    render() {
        return (
            <div className="form-left pr-1-5 appear-right">
                <h2>Success!</h2>
                <i className="comment mb-1-5 db">
                Instructions for recovering the password from your account have been sent to your email.
                </i>
                <div className="btn-container">
                        <Button className="fr" onClick={this.toAuth}>Sign in</Button>
                </div>
            </div>
        );
    }
};
