import React from "react";
import { Link } from "react-router-dom";
import "../../css/comp/risk-indicator.css";

export class RiskIndicator extends React.Component {
    calcOuterPhi(fi) {
        return (
            fi +
            Number(
                (
                    Math.sin((2 * (fi - 180) * Math.PI) / 180) * 13.0540728933
                ).toFixed(10)
            )
        );
    }
    calcInnerPhi(fi) {
        //Без слёз не взглянешь, конечно. Из-за погрешности округления
        return (
            fi +
            Number(
                (
                    Math.sin((2 * (fi - 180) * Math.PI) / 180) * 10.64177772475
                ).toFixed(10)
            )
        ); // при 35 дает примерно 10
    }
    calcPoints(cfi) {
        let _cfi = cfi + 25;

        let _fi = this.calcOuterPhi(_cfi);

        let fiO1 = ((35 - 180) * Math.PI) / 180; //Начальный угол внешнего круга (рад)
        let fiO2 = ((_fi - 180) * Math.PI) / 180; //Конечный угол внешнего круга (рад)

        var fi2 = this.calcInnerPhi(_fi);

        let fiI1 = ((45 - 180) * Math.PI) / 180; //Начальный угол внутреннего круга (рад)
        let fiI2 = ((fi2 - 180) * Math.PI) / 180; //Конечный угол внутреннего круга (рад)

        //Точки внешнего круга (концы арок)
        let oX1 = 70 + 70 * Math.cos(fiO1);
        let oY1 = 70 + 70 * Math.sin(fiO1);
        let oX2 = 70 + 70 * Math.cos(fiO2);
        let oY2 = 70 + 70 * Math.sin(fiO2);

        //Точки внутреннего круга (концы арок)
        let iX1 = 70 + 35 * Math.cos(fiI1);
        let iY1 = 70 + 35 * Math.sin(fiI1);
        let iX2 = 70 + 35 * Math.cos(fiI2);
        let iY2 = 70 + 35 * Math.sin(fiI2);
        return [
            { x: oX1, y: oY1 },
            { x: oX2, y: oY2 },
            { x: iX2, y: iY2 },
            { x: iX1, y: iY1 }
        ];
    }
    render() {
        var fill = Number(this.props.fill || 20);

        let [p1, p2, p3, p4] = this.calcPoints(130);

        let d = `M ${p1.x} ${p1.y} A 70 70 0 0 1 ${p2.x} ${p2.y} L ${p3.x} ${
            p3.y
        } A 35 35 0 0 0 ${p4.x} ${p4.y} L ${p1.x} ${p1.y}`;

        //var cfi = fill * 1.3;
		var cfi = (fill * 1.3 + 25 > 155) ? 130 : fill * 1.3 ;

        let fiO2 = ((cfi + 25 - 180) * Math.PI) / 180; //Конечный угол внешнего круга (рад)

        //Точки внешнего круга (концы арок)
        let oX2 = 70 + 70 * Math.cos(fiO2);
        let oY2 = 70 + 70 * Math.sin(fiO2) - 12;

        let d3 = `M 0 58 A 70 70 0 0 1 ${oX2} ${oY2} L 70 58 L 0 58`;

        // var color =
        //     fill <= 20
        //         ? "#38C559"
        //         : fill <= 40
        //             ? "#a0c538"
        //             : fill <= 60
        //                 ? "#E6C800"
        //                 : fill <= 80
        //                     ? "#e67a00"
        //                     : "#C53838";
        var color = "#3883c5";
        var risk =
            fill <= 20
                ? "Очень низкий"
                : fill <= 40
                    ? "Низкий риск"
                    : fill <= 60
                        ? "Средний риск"
                        : fill <= 80
                            ? "Высокий риск"
                            : "Очень высокий";

        return (
            <div className="risk-indicator">
                <div className="baloon">{risk}</div>
                <svg width="120px" height="64px">
                    <defs>
                        <mask id={`risk-mask-${this.props.fill}`}>
                            <rect width="100%" height="100%" fill="black" />
                            <path fill="white" d={d3} />
                        </mask>
                        <filter
                            id="risk-shadow"
                            x="0"
                            y="0"
                            width="200%"
                            height="200%"
                        >
                            <feDropShadow
                                dx="0"
                                dy="2"
                                stdDeviation="2"
                                floodColor="#0B1F35"
                                floodOpacity=".15"
                            />
                        </filter>
                    </defs>
                    <g className="arcs">
                        <path fill="#DDD" d={d} filter="url(#risk-shadow)" />
                        <path
                            fill={color}
                            d={d}
                            mask={`url(#risk-mask-${this.props.fill})`}
                        />
                    </g>
                </svg>
                <div
                    className="cursor"
                    style={{ transform: "rotate(" + (cfi + 25) + "deg)" }}
                />
                {this.props.to && <Link to={this.props.to}>{this.props.title}</Link>}
            </div>
        );
    }
}
