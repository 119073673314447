import React from "react";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { Check } from "../shared/CustomCheck";
import { LabeledInput } from "../shared/LabeledInput";
import { Radio, RadioGroup } from "../shared/CustomRadio";

import classnames from "classnames";

import "moment/locale/ru";
import { Slider } from "../shared/Slider";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class LabForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
        this.containerRef = React.createRef();
    }
    // handleSliderDragStart = name => {
    //     this.containerRef.current.className = classnames(
    //         this.containerRef.current.className,
    //         {
    //             "no-select": true
    //         }
    //     );
    // };
    // handleSliderDragStop = name => {
    //     this.containerRef.current.className = this.containerRef.current.className.replace(
    //         "no-select",
    //         ""
    //     );
    // };
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "Head_neck_reserach" && v.value !== 2) {
            fields["slart70"]       = -1;
            fields["hordart70"]     = -1;
            fields["anevrheads"]    = -1;
        }

        if (this.props.onChange)
            var that = this.props.onChange({ name: this.props.form, fields });
            console.log(that.state)
    }

    handleUnitsChange = option => {
        var s = {};
        s[option.name] = option.value;
        s[option.name + "-units"] = option.units;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields: s });
    };
    getUnitsValue = (name, units) => {
        let fields = this.props.fields || {};
        var cUnits = fields[name + "-units"] || "";
        var cVal = Number(fields[name]) || 0;

        if (cUnits === units) return cVal;
        if (cUnits === "ммоль./л.")
            return Math.round(cVal * 38.7 * 10000) / 10000; // -> мг./дл.
        if (cUnits === "мг./дл.")
            return Math.round((10000 * cVal) / 38.7) / 10000; // -> ммоль./л.
    };
    getUnits = name => {
        return (this.props.fields || {})[name + "-units"] || "";
    };

    componentDidMount() {}

    render() {
        var F = React.Fragment;
        let fields = this.props.fields || {};
        return (
            <div className="appear-left" ref={this.containerRef}>
                <h2>Immunological blood test</h2>
                <Check
                    className="col col-three col-last mb-1-5"
                    {...this.baseEditProps("citokeens")}
                >
                    Increased cytokine levels
                </Check>
                <h2 className="db cb">Blood chemistry</h2>
                <div className="col col-six fl col-last">
                    <i className="comment db mb">
                    Please note that data from biochemical blood tests can be entered using different dimensions ([mmol. / L.] Or [mg. / DL.]). To select a dimension, click the down arrow next to the dimension text in the input field. After selection, the system automatically recalculates the entered data. You can manually translate the parameter values ​​using the formula: value [mmol. / L.] × 38.7 = value [mg./dl.]
                    </i>
                </div>
                <div className="cb" />
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-TotalHol"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("TotalHol")}
                        label="Total cholesterol"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units={this.getUnits}
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("TotalHol")}
                        options={[
                            { value: this.getUnitsValue, units: "mmol. / l." },
                            { value: this.getUnitsValue, units: "mg. / DL." }
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-hol_lpnp"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("hol_lpnp")}
                        label="LDL cholesterol"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units={this.getUnits}
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("hol_lpnp")}

                        options={[
                            { value: this.getUnitsValue, units: "mmol. / l." },
                            { value: this.getUnitsValue, units: "mg. / DL." }                            
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-hol_lpvp"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("hol_lpvp")}
                        label="Cholesterol HDL"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units={this.getUnits}
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("hol_lpvp")}

                        options={[
                            { value: this.getUnitsValue, units: "mmol. / l." },
                            { value: this.getUnitsValue, units: "mg. / DL." }  
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-triglycerides"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("triglycerides")}
                        label="Triglycerides"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units={this.getUnits}
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("triglycerides")}

                        options={[
                            { value: this.getUnitsValue, units: "mmol. / l." },
                            { value: this.getUnitsValue, units: "mg. / DL." }
                        ]}
                    />
                </div>
                <div className="cb" />
                {/* blood_clqotting */}
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-blood_clqotting"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("blood_clqotting")}
                        label="Blood clotting (INR)"
                        className="col col-two col-last"
                        type="number"
                        min="0"
                        onOptionChange={this.handleUnitsChange}
                        units="ед."
                        btns={this.baseOneSaveBtns("blood_clqotting")}

                    />
                </div>

                <div className="cb" />

                <Check
                    {...this.baseEditProps("blood_drug")}
                    className="col col-four col-last mb-1-5"
                >
                    I take drugs that affect blood clotting
                </Check>
                <div className="col col-four col-last mb-1-5">
                    <label>
                    Select all the genetic polymorphisms which you have been diagnosed:
                    </label>
                    <Check
                        {...this.baseEditProps("mgas1", 0)}
                        className="col col-four col-last"
                    >
                        Elevated lipoprotein-a and protein Apo-E
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas2", 0)}
                        className="col col-four col-last"
                    >
                        Reducing the level of active antithrombotic proteins
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas3", 0)}
                        className="col col-four col-last"
                    >
                        Homocysteinemia
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas4", 0)}
                        className="col col-four col-last"
                    >
                        CADASIL syndrome
                    </Check>
                </div>
                <div className="cb" />
                <h2 className="db cb">Urine analysis for albumin and creatinine</h2>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-creatinin20"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("creatinin20")}
                        label="Creatinine, daily excretion"
                        className="col col-two col-last"
                        units="mg / day"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("creatinin20")}

                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-albumin"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("albumin")}
                        label="Albumin in urine, concentration"
                        className="col col-two col-last"
                        units="mg./l."
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("albumin")}

                    />
                </div>
                <div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-albumin_day"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("albumin_day")}
                        label="Albumin, daily excretion"
                        className="col col-two col-last"
                        units="mg / day"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("albumin_day")}

                    />
                </div>
                <div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-speed_club_filtrac"]
                            ? " loading"
                            : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("speed_club_filtrac")}
                        label="Glomerular filtration rate"
                        className="col col-two col-last"
                        units={
                            <F>
                                ml / min /
                                <span className="green">1,73</span>
                                m²
                            </F>
                        }
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("speed_club_filtrac")}

                    />
                </div>
                <h2 className="db cb">Functional studies</h2>
                <div className="col col-four fl mb-1-5">
                    <label>
                    Electrocardiography data{" "}
                        <i className="comment">(ECG)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PHTLJ")}
                    >
                        Left ventricular hypertrophy
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PQQSEKG")}
                    >
                        Abnormal Q wave or QS interval
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("bllpg")}
                    >
                        Blockade of the left bundle branch block
                    </Check>
                </div>
                <div className="col col-two fl col-last">
                    <i className="comment">
                    Data for this section must be completed from the conclusion.
                    </i>
                </div>
                <div className="cb" />
                {/* <div className="col col-four col-last cb mb-1-5">
                    <label>
                        Данные Эхо-кардиографии{" "}
                        <i className="comment">(Эхо-КГ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-eho"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-eho")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-four fl mb-1-5">
                    <label>
                        Данные компьютерной томографии{" "}
                        <i className="comment">(КТ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-kt"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-kt")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("calcium-index-kt")}
                        label="Кальциевый индекс по КТ"
                        // className="col col-two col-last"
                    />
                </div> */}
                {/* <div className="col col-four cb col-last mb-1-5">
                    <label>
                        Данные магниторезонансной томографии{" "}
                        <i className="comment">(МРТ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-mrt"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-mrt")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-four col-last mb-1-5">
                    <label>
                        Данные коронароангиографии{" "}
                        <i className="comment">
                            (стеноз просвета коронарных сосудов)
                        </i>
                        :
                    </label>
                    <Slider
                        {...this.baseEditProps("coronary-angiography")}
                        ticks={10}
                        from={0}
                        to={100}
                        digits={0}
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                        units="%"
                    />
                </div> */}
                {/* <div className="col col-four col-last mb-1-5">
                    <label>
                        Данные ультразвукового <i className="comment">(УЗДГ)</i>{" "}
                        исследования периферических артерий:
                        <i className="comment db">
                            (стеноз сосудов атеросклеротическими бляшками)
                        </i>
                    </label>
                    <Slider
                        {...this.baseEditProps("uzdg-peripheral-arteries")}
                        ticks={10}
                        from={0}
                        to={100}
                        digits={0}
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                        units="%"
                    />
                </div> */}
                <div className="cb" />
                {/* <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("pulse-vawe-speed")}
                        label="Скорость пульсовой волны"
                        units="м./с."
                        // className="col col-two col-last"
                    />
                </div> */}
                <div
                    className={
                        "col col-two col-last fl" +
                        (fields["_loading-lpi"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("lpi")}
                        label="Ankle-brachial index"
                        className="col col-two col-last"
                        min="0"
                        type="number"
                        onOptionChange={this.handleUnitsChange}
                        units="ед."
                        btns={this.baseOneSaveBtns("lpi")}
                    />
                </div>

                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                    Have you conducted a study of <br />
                    vessels of the head and neck?
                    </label>
                    <RadioGroup {...this.baseEditProps("Head_neck_reserach")}>
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Yes, no deviations detected
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Yes, deviations identified
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["Head_neck_reserach"], 0) === 2 && (
                    <div className="col col-three col-last fl mb-1-5 mt appear-left">
                        <label>Отклонения, выявленные при исследовании:</label>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("slart70")}
                        >
                            Просвет сонных артерий сужен ≥70%
                        </Check>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("hordart70")}
                        >
                            Просвет позвоночных артерий сужен ≥70%
                        </Check>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("anevrheads")}
                        >
                            Аневризмы сосудов головного мозга
                        </Check>
                        <div className="cb" />
                    </div>
                )}
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/social",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to section "Environment and social status"
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default LabForm;
