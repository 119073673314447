import { Stubs } from "./stubs";
import moment from "moment";
import "moment/locale/ru";
import { Qs } from "./Qs";

/**
 * Определенная ошибка. Определена через прототипы, потому что у меня
 * есть сомнения, что Babel правильно интерпретирует расширение ошибки
 * @param {string} message Сообщение об ошибке
 */
const DefinedError = function (message, inner) {
    this.name = "DefinedError";
    this.message = message;
    this.stack = new Error().stack;
    this.innerMessage = inner || "";
};
DefinedError.prototype = new Error();

export const Api = (function () {
    var ns = {
        config: { url: "dimhealth.ru:8007" },
        configLoaded: false,
        /**
         * В зависимости от того, запущен ли проект на локальной машине разработчика
         * возвращаем или хочт API из конфигурации или адрес заппущенной рядом cors-proxy.
         * Нужно для корректного проксирования cookie
         * @returns {string} Адрес API
         */
        getApiHost: () => {
            return (window.location.host === 'localhost:3000' || window.location.host === '127.0.0.1:3000')
                ? '127.0.0.1:3001'
                : ns.config.url;
        },
        /**
         * Получает конфигурацию приложения из файла /static/config.json, записывает ее в Api.config
         */
        getConfig: async () => {
            try {
                const plain = await fetch("/config.json", { method: "GET" });
                ns.config = await plain.json();
                ns.configLoaded = true;
                return ns.config;
            } catch (x) {
                console.warn(
                    "no config file loaded... It's OK, we'll use default config."
                );
            }
        },
        /**
         * Делает запрос на заданный адрес с зааданными данными.
         * В случае успеха ожидает корректный JSON, если такой есть, проверяет значение поля «status».
         * Если статус не в [200,299], вызывает исключение с текстом из поля «message».
         * Если JSON не корректен или запрос не завершен должным образом, вызывает исключение.
         * @param {Object} options Параметры запроса
         * @param {string} options.url Адрес метода API
         * @param {string} options.method Метод (GET|POST)
         * @param {(Object|FormData)} options.data Данные, для передачи в метод API
         * @returns {Promise}
         */
        call: async options => {
            var data = {
                credentials: "include",
                method: options.method
            };
            if (["GET", "HEAD"].indexOf(options.method) === -1)
                data.body = options.data;

            try {
                const x = await fetch(options.url, data);
                if (!x.ok)
                    throw new DefinedError("Ошибка соединения с сервером");

                const json = await x.json();

                if ((json.status || "200").toString().indexOf("2") !== 0)
                    throw new DefinedError(
                        json.message || json.result || "Неизвестная ошибка"
                    );

                return json;
            } catch (err) {
                if (DefinedError.prototype.isPrototypeOf(err)) {
                    throw err;
                }
                throw new DefinedError(
                    "Произошла ошибка соединения или распознавания ответа от сервера.",
                    err.message
                );
            }
        },
        /**
         * Вызывает метод API с заданными параметрами.
         * Если вызов происходит с локального адреса (только 127.0.0.1),
         * использует в качестве адреса API (127.0.0.1:3001) - middleware.
         * В других случаях адрес API из конфига (или 78.110.62.174:8007)
         * @param {string} point Имя метода API. Не должно начинаться с «/»
         * @param {(object|FormData)} data Данные для передачи в метод API
         * @param {string} [method=POST] Метод вызова API
         * @return {Promise}
         */
        point: function (point, data, method) {
            let apiHost = ns.getApiHost();
            let qs = "";
            //Если метод GET, формируем строку параметров
            if (method === "GET") {
                qs = Qs.form(data);
            }
            return ns.call({
                url: `https://${apiHost}/${point}${qs}`,
                method: method || "POST",
                data: FormData.prototype.isPrototypeOf(data)
                    ? data
                    : JSON.stringify(data)
            });
        },
        /**
         * Авторизует пользователя, либо возвращает ошибку о том, что пользователь не авторизован.
         * В случае успеха задает авторизационную cookie, которая потом проверяется при каждом запросе
         * @param {Object} data Данные о пользователе
         * @param {string} data.username Имя пользователя (email)
         * @param {string} data.password Пароль пользователя (не менее 8 символов, 1 буква верхнего регистра, 1 цифра)
         * @returns {Promise}
         */
        login: function (data) {
            return ns.point("login_rest", data);
        },
        /**
         * Стирает авторизационные куки
         * @return {Promise}
         */
        logout: function () {
            return ns.point("logout", null, "GET");
        },
        /**
         * Регистрирует нового пользователя
         * @param {Object} data Данные о пользователе
         * @param {string} data.username Имя пользователя (email)
         * @param {string} data.password Пароль пользователя (не менее 8 символов, 1 буква верхнего регистра, 1 цифра)
         * @returns {Promise}
         */
        register: function (data) {
            return ns.point("registration", {
                ...data,
                given_name: "имя", //Жесть конечно
                family_name: "фамилия",
                middle_name: "отчество",
                gender: "M"
            });
        },
        /**
         * Загружает на сервер файл с аватаром пользователя
         * @param {FormData} data Данные для загрузки
         * @param {Object} data.avatar Данные с загружаемым файлом из DataTransfer.files
         * @returns {Promise}
         */
        setAvatar: function (data) {
            return ns.point("av_img", data);
        },
        /**
         * Получает поля для карточки профиля, так же используется для проверки авторизованности
         * @returns {Promise}
         */
        getProfileCard: function () {
            return ns.getData([
                "familyName",
                "givenName",
                "middleName",
                "avatar"
            ]);
        },
        /**
         * Получает список ограничений полей
         * @param {string[]} fields Список полей
         * @returns {Promise}
         */
        getFields: function (fields) {
            return ns.point("get_fields", {
                fields: fields || []
            });
        },
        /**
         * Получает список значений полей
         * @param {string[]} fields Список полей
         * @returns {Promise}
         */
        getData: function (fields) {
            return ns.point("get_data", {
                fields: fields || []
            });
        },
        recalcRisks: function () {
            return ns.point("assess_risks", {});
        },
        /**
         * Получает данные для графиков на форме по идентификатору поля и периоду
         * @param {string} field Поле, по которому выбирать данные для графиков
         * @param {string} startDate Начало периода, дата в формате YYYY-MM-DD
         * @param {string} endDate Конец периода, дата в формате YYYY-MM-DD
         */
        getGraphicData: function (field, startDate, endDate) {
            return ns.point("get_graphic_data", {
                field: field,
                startDate: startDate,
                endDate: endDate
            });
        },
        /**
         * Сохраняет список значений полей
         * @param {Object} fields Значения полей {поле:значение}
         * @param {string} [enc_type=risk2] Что за поле такое и для чего нужно лучше спросить у разработчиков серверной части.
         * @param {string} [form] Наименование формы, необходимо только для опросников.
         * @returns {Promise}
         */
        setData: function (fields, enc_type, form) {
            let data = {
                conceptClass: "base_knowledge",
                priority: "0",
                enc_type: enc_type || "risk2",
                note: {},
                params: fields || []
            };
            if (form) data.form = form;
            return ns.point("s_api", data);
        },
        /**
         * Меняет текущий пароль пользователя
         * @param {string} currentPass Текущий пароль пользователя
         * @param {string} newPass Новый пароль пользователя
         * @returns {Promise}
         */
        setNewPass: function (currentPass, newPass) {
            return ns.point("new_pass", {
                old_pass: currentPass,
                new_pass: newPass
            });
        },
        /**
         * Возвращает список рисков
         * @param {string} date Дата в формате "YYYY-MM-DD"
         * @returns {Promise}
         */
        getRisksList: function (date) {
            return ns.point("prob_all", {
                system: "all", //Всегда all, пока не договоримся об обратном
                date: date || moment().format("YYYY-MM-DD") //Самую последнюю до
            });
        },
        /**
         * Возвращает полную информацию о риске за дату.
         * Вместе с факторами и рекоментациями, сгруппированными по разным оценкам
         * @param {string} date Дата в формате "YYYY-MM-DD"
         * @param {string} risk Идентификатор риска (.name из getRisksList)
         * @returns {Promise}
         */
        getRiskInfo: function (date, risk) {
            let _r = risk || "";
            let _d = date || moment().format("YYYY-MM-DD");
            return ns.point("prob_risk", {
                risk: _r,
                date: _d
            });
        },
        /**
         * Возвращает список дат всех оценок (для элемента управления сверху-справа)
         * @returns {Promise}
         */
        getRisksHistory: function () {
            return ns.point("prob_history", { system: "all" }).then(x => {
                let p = new Promise((res, rej) => {
                    if (!Array.prototype.isPrototypeOf(x.result)) {
                        let a = [];
                        for (let i in x.result) {
                            a.push(x.result[i]);
                        }
                        x.result = a;
                        res(x);
                    } else res(x);
                });
                return p;
            });
        },
        /**
         * Возвращает данные для графика на странице «Карточка риска»
         * @param {Object} data Параметры запроса
         * @param {string} data.startDate Дата начала периода
         * @param {string} data.endDate Дата конца периода
         * @param {string} data.risk Идентификатор риска
         */
        getRiskDynamic: function (data) {
             return ns.point("risk_dynamic", data || {});
            // return new Promise((resolve, reject) => {
            //     var stub = Stubs.risk_dynamic();
            //     resolve(stub);
            // });
        },
        /**
         * Получает статусы опросников (завершён, дату поледнего прохождения)
         * @param {Object} data Параметры запроса
         * @param {string[]} data.q_list Список опросников. Можно подать пустой — вернет все
         */
        getPollStatus: function (data) {
            return ns.point("get_merker", data);
        },
        /**
         * Получает результаты для списка опросников
         * @param {Object} data Параметры запроса
         * @param {string[]} data.q_list Список идентификаторов результатов опросника в виде <id опросника>+"_result"
         */
        getPollResult: function (data) {
            return ns.point("get_qst", data);
        },
        /**
         * Посылает пользователю письмо с инструкцией, объясняющей порядок действий при востановлении пароля
         * @param {string} mail Email пользователя
         * @returns {Promise}
         */
        sendRememeberMail: function (email) {
            return ns.point("forgotten_pass", { email: email });
        },
        /**
         * Устанавливает новый пароль пользователю по E-Mail и сгенерированному ключу безопасности (guid)
         * @param {string} mail E-mail пользователя
         * @param {string} guid Строка-ключ для авторизации действия изменения пароля
         * @param {string} pass Новый пароль (подчиняется все правилам, как при регистрации)
         * @returns {Promise}
         */
        setPassByEmail: function (mail, guid, pass) {
            return ns.point("der_pass", {
                mail: mail,
                password: pass,
                guid: guid
            });
        },
        /**
         * Отменяет последнее сохраненное значение поля
         * @param {string} field Имя поля
         * @param {string} [conceptClass='base_knowledge'] Класс концептов
         */
        cancelFieldValue: function (field, conceptClass) {
            return ns.point("u_api", { conceptClass: conceptClass || "base_knowledge", field: field });
        },
        /**
         * Сохраняет парамметры авторизации пользователя в VK
         * @param {Object} data Данные авторизации, пришедшие от формы авторизации VK
         * @param {string} data.access_code Код авторизации
         * @param {string} data.user_id Идентификатор пользователя VK
         * @returns {Promise}
         */
        vkLogin: function (data) {
            return ns.point("vklogin", data);
        },
        /**
         * Теоретически должен сохранять данные об авторизации с Google.OAuth. По факту, не понятно, есть ли вообще.
         * @param {Object} data Данные об авторизации пользователя
         * @param {string} data.token_api Access Token
         * @param {string} data.client_id Идентификатор приложения Google.API
         * @returns {Promise}
         */
        gFitLogin: function (data) {
            return ns.point("google_fit_login", data);
        },
        /**
         * Получает какие-то данные из Google.Fit. Не работает пока
         * @param {Object} data Данные об авторизации пользователя
         * @param {string} data.token_api Access Token
         * @param {string} data.client_id Идентификатор приложения Google.API
         * @param {Array} data.concepts Список строк-концептов для выбора
         * @returns {Promise}
         */
        gFitRetrive: function (data) {
            return ns.point("google_fit_retrieve", data);
        }
    };
    return ns;
})();
