import React from "react";

export const LabeledBlock = function(props) {
    return (
        <React.Fragment>
            <div className="block-label">{props.title}</div>
            <div className="col col-six white-block col-last pt pb pl pr">
                {props.children}
            </div>
        </React.Fragment>
    );
};
export default LabeledBlock;
