import React from "react";
import AvatarEditor from "./avatar-editor";
import UserSample from "./user-sample";
import LabeledInput from "../shared/LabeledInput";
import LabeledDatePicker from "../shared/LabeledDatePicker";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import { AddressInput } from "../shared/AddressInput";
import Button from "../shared/button";
import { Route } from "react-router-dom";

import { BaseEditForm, BtnMessage } from "./base-edit-form";
import { Api } from "../../../libs/api";

export class ProfileForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = { _error: "", _success: "", _loading: true };
        this.baseState = {};
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "gender") {
            //Сбрасываем значение Менопаузы
            fields.men40 = v.value === "F" ? "" : fields.men40;
        }

        if (this.props.onChange)
            this.props.onChange({
                name: this.props.form,
                digits: this.props.digits,
                fields
            });
    }
    handleContactsSaveClick = history => {
        var fields = this.props.fields || {};
        this.setState({ _passwordError: "", _passwordSuccess: "" });

        if (!fields["current-password"]) {
            this.setState({ _passwordError: "Please, enter current password" });
            return;
        }
        if (fields["new-password"] !== fields["confirm-new-password"]) {
            this.setState({
                _passwordError:
                    "Fields «New password» and «Repeat new password» should be identical"
            });
            return;
        }
        this.setState({ passLoading: true });
        Api.setNewPass(fields["current-password"], fields["new-password"])
            .then(x => {
                this.setState({
                    _passwordError: "",
                    _passwordSuccess: "New password set successfully!",
                    passLoading: false
                });
                history.push("/eng/auth/logout");
            })
            .catch(x => {
                this.setState({
                    _passwordError: x.message,
                    _passwordSuccess: "",
                    passLoading: false
                });
            });
    };

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <label>Your photo:</label>
                <AvatarEditor
                    user={UserSample}
                    {...this.baseEditProps("avatar")}
                />
                <div className="cb" />
                <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("familyName", "", "Surname")}
                        label="Surname"
                    />
                </div>
                <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("givenName", "", "Name")}
                        label="Name"
                    />
                </div>
                <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("middleName", "", "Patronymic name")}
                        label="Patronymic name"
                    />
                </div>
                <div className="cb" />
                <div className="col col-two fl">
                    <LabeledDatePicker
                        {...this.baseEditProps("birthdate")}
                        label="Date of Birth"
                    />
                </div>
                <div className="col col-two fl mb-1-5">
                    <label className="cb db">Your gender:</label>
                    <RadioGroup {...this.baseEditProps("gender")}>
                        <Radio value="M" className="col fl">
                            Male
                        </Radio>
                        <Radio value="F" className="col col-last fl">
                            Female
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                {fields.gender === "F" && (
                    <div className="col col-four col-last appear-up mb-1-5">
                        <label>Менопауза:</label>
                        <RadioGroup {...this.baseEditProps("men40")}>
                            <Radio value={0} className="col col-four">
                                Менопауза не наступила
                            </Radio>
                            <Radio value={1} className="col col-four">
                                Менопауза наступила до 40 лет
                            </Radio>
                            <Radio value={2} className="col col-four">
                                Менопауза наступила после 40 лет
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("height")}
                        label="Your height"
                        units="cm."
                    />
                </div>
                <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("weight")}
                        label="Your weight"
                        units="kg."
                    />
                </div>
                <div className="db" />
                <div className="col col-two mb-1-5">
                    <label>Family status:</label>
                    <Check {...this.baseEditProps("familyStatus")}>
                        Have a life partner/Married
                    </Check>
                </div>
                <div className="cb" />

                <div className="col col-six mb-1-5">
                    <AddressInput
                        {...this.baseEditProps("locations")}
                        label="Address"
                        // subLabel="(до улицы)"
                    />
                </div>
                <div className="col col-two col-last mb-1-5">
                    <LabeledInput
                        label="Phone"
                        {...this.baseEditProps("numberPhone")}
                    />
                </div>
                <div className="cb" />
                <div className="btn-container mt mb-1-5">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/illnesses",
                                        e.target
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to the section "Health condition and family history”
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                    <div className="cb" />
                </div>
                <h2 className="db cb">Info</h2>
                <div className={this.state.passLoading ? "loading" : ""}>
                    <div className="col col-two fl">
                        <label>E-mail:</label>
                        <div className="input mb-1-5">
                            {this.emptyOrDef(this.props.fields["username"], "")}
                        </div>
                        <i className="comment db">
                            To create a new password, be sure to enter your current password in the appropriate field.
                        </i>
                    </div>
                    <div className="col col-two fl">
                        <LabeledInput
                            label="Current password"
                            {...this.baseEditProps("current-password")}
                            type="password"
                            autoComplete={false}
                        />
                        <LabeledInput
                            label="New password"
                            {...this.baseEditProps("new-password")}
                            type="password"
                            autoComplete={false}
                        />
                        <LabeledInput
                            label="Repeat new password"
                            {...this.baseEditProps("confirm-new-password")}
                            type="password"
                            autoComplete={false}
                        />
                    </div>
                    <div className="col col-two fl col-last">
                        <label className="mb">Tips:</label>
                        <i className="db mb comment">
                            Try to use unique password that you have not used anywhere else.
                        </i>
                        <i className="db mb comment">
                            Password should contain lowercase and uppercase letters and numbers.
                        </i>
                        <i className="db mb comment">
                            Password length should be not less than 8 characters.
                        </i>
                    </div>
                </div>
                <div className="cb" />
                <div className="btn-container col col-four">
                    <Route
                        render={({ history }) => (
                            <Button
                                loading={
                                    this.props.loading || this.state.passLoading
                                }
                                className="fr"
                                onClick={() =>
                                    this.handleContactsSaveClick(history)
                                }
                            >
                                Save
                            </Button>
                        )}
                    />

                    <BtnMessage
                        error={this.state._passwordError}
                        success={this.state._passwordSuccess}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default ProfileForm;
