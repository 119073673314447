import React from "react";
import { Route } from "react-router-dom";
import Button from "../shared/button";
import LabeledInput from "../shared/LabeledInput";
import { Api } from "../../../libs/api";

class RegForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: {},
            pwdType: "password",
            "reg-password": "",
            "reg-password-confirm": "" //Начальные значения
        };
    }
    getError = key => {
        return this.state.errors[key] || "";
    };
    fieldChanged = val => {
        //Сохраняемм значение в локальном состоянии (по имени поля)
        var toSet = {};
        toSet[val.name] = val.value;
        this.setState(toSet);
    };
    handleErrorClick = name => {
        var errs = this.state.errors;
        errs[name] = ""; //Скрываем ошибку
        this.setState({ errors: errs });
    };
    generatePassword = () => {
        var pwd = Math.random()
            .toString(33)
            .substring(2, 12);
        var res = "";
        //Первая буква — заглавная
        for (var i = 0, up = false; i < pwd.length; i++) {
            if (!up && /[a-z]{1}/.test(pwd[i])) {
                up = true;
                res += pwd[i].toUpperCase();
            } else {
                res += pwd[i];
            }
        }
        //Если нет ни одной цифры, добавляем что-нибудь в конец строки
        if (!/[0-9]+/.test(res)) {
            res += Math.round(Math.random() * 10);
        }
        this.setState({
            "reg-password": res,
            "reg-password-confirm": res,
            pwdType: "text"
        });
    };
    validate = history => {
        var errors = {};
        var hasError = false;

        if (!this.state["reg-username"]) {
            hasError = true;
            errors["reg-username"] = "Please, enter «E-mail»";
        } else {
            //По стандарту RFC 5322... ну, почти
            var ereg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!ereg.test(this.state["reg-username"])) {
                hasError = true;
                errors["reg-username"] =
                    "Please, enter correct «E-mail»";
            }
        }
        if (!this.state["reg-password"]) {
            hasError = true;
            errors["reg-password"] = "Please, enter «Password»";
        }

        if (this.state["reg-password"] !== this.state["reg-password-confirm"]) {
            hasError = true;
            errors["reg-password-confirm"] =
                "Fields «Password» and «Repeat new password» should be identical";
        }

        if (!hasError) {
            var pwd = this.state["reg-password"];
            if (
                pwd.length < 10 ||
                !/[A-Z]+/.test(pwd) ||
                !/[0-9]+/.test(pwd) ||
                !/[a-zA-Z0-9]{8,}/.test(pwd)
            ) {
                hasError = true;
                errors["reg-password"] =
                    "Passowrd should contain 10 latin letters with at least 1 number and 1 capital letter";
            }
        }

        this.setState({ errors: errors });

        if (!hasError) {
            this.setState({ loading: true });
            let data = {
                username: this.state["reg-username"],
                password: this.state["reg-password"]
            };
            Api.register(data)
                .then((res, x) => {
                    //Успешно! переадресовываем на вторую страницу и сбрасываем поля
                    this.setState({
                        loading: false,
                        "reg-username": "",
                        "reg-password": "",
                        "reg-password-confirm": ""
                    });
                    // this.toSecond();
                    //Переходим сразу на профиль
                    // history.push("/edit");
                    return true;
                })
                .then(x => Api.login(data))
                .then((x, y) => {
                    // console.log(x, y);
                    history.push("/eng/edit");
                })
                .catch(e => {
                    //Что-то страшное случилось
                    this.setState({
                        loading: false,
                        errors: { "reg-password": e.message }
                    });
                });
        }
    };
    toSecond = () => {
        if (this.props.toSecond) this.props.toSecond();
    };
    render() {

        return (
            <div className="form-right pl-1-5 appear-left">
                <h2>New account?</h2>
                <i className="comment mb-1-5 db">
                    Register new account by filling out the form below
                </i>
                    <LabeledInput
                        type="email"
                        name="reg-username"
                        label="E-mail"
                        error={this.getError("reg-username")}
                        value={this.state["reg-username"]}
                        onChange={this.fieldChanged}
                        onErrorClick={this.handleErrorClick}
                        autoComplete={false}
                    />
                    <LabeledInput
                        value={this.state["reg-password"]}
                        type={this.state.pwdType}
                        name="reg-password"
                        label="Password"
                        error={this.getError("reg-password")}
                        onChange={this.fieldChanged}
                        onErrorClick={this.handleErrorClick}
                        autoComplete={"reg-password"}
                    />
                    <LabeledInput
                        value={this.state["reg-password-confirm"]}
                        type={this.state.pwdType}
                        name="reg-password-confirm"
                        label="Repeat password"
                        error={this.getError("reg-password-confirm")}
                        onChange={this.fieldChanged}
                        onErrorClick={this.handleErrorClick}
                        autoComplete={"reg-password-confirm"}
                    />
                {this.state.pwdType === "text" && (
                    <i className="comment db mb-1-5">
                        Don't forget to save generated password before you proceed with registration.
                    </i>
                )}
                <div className="btn-container">
                    <div className="fl">
                        <a onClick={this.generatePassword}>
                            Give me a password
                        </a>
                    </div>
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                loading={this.state.loading}
                                onClick={() => this.validate(history)}
                            >
                                Sign Up
                            </Button>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default RegForm;
