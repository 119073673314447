import React from "react";
import { Route } from "react-router-dom";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import { Radio, RadioGroup } from "../../shared/CustomRadio";
import PollRadios from "./poll-radios";
import Slider from "../../shared/Slider";
import classnames from "classnames";

export class FinancialForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };

        this.containerRef = React.createRef();
    }
    handleSliderDragStart = name => {
        this.containerRef.current.className = classnames(
            this.containerRef.current.className,
            {
                "no-select": true
            }
        );
    };
    handleSliderDragStop = name => {
        this.containerRef.current.className = this.containerRef.current.className.replace(
            "no-select",
            ""
        );
    };
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
        if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = [1, 2, 3, 4, 5];
        var basicNeedsTitles = [
            "Очень высокая",
            "Высокая",
            "Средняя",
            "Низкая",
            "Очень низкая"
        ];
        var discussFrequencyTitles = [
            "Очень часто",
            "Часто",
            "Иногда",
            "Редко",
            "Очень редко"
        ].reverse();
        return (
            <div className="appear-left" ref={this.containerRef}>
                <h2 className="db cb">
                    Субъективное экономическое благоплучие
                </h2>
                <i className="comment">
                    Опросник для измерения субъективного экономического
                    благополучия, которое рассматривается как отношение личности
                    к материальным аспектам жизни и выражает ее жизненную
                    позицию в сфере материальных устремлений и потребления. Вам
                    будет предложен ряд вопросов и варианты ответов на них. Ваша
                    задача – выбрать один из них, который, по Вашему мнению,
                    больше соответствует действительности
                </i>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Как изменился материальный уровень Вашей жизни за
                        последний год?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_1", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Значительно снизился
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Скорее снизился, чем вырос
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Остался без изменения
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Скорее вырос, чем снизился
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Значительно вырос
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        В ближайшем будущем материальный уровень Вашей жизни
                        снизится или возрастет?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_2", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Значительно снизитя
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Скорее снизится, чем вырос
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Останется без изменения
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Скорее вырастет, чем снизится
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Значительно вырастет
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Как Вы оцениваете имеющиеся объективные финансовые
                        возможности для повышения благосостояния семьи?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_3", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Очень низкие
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Низкие
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В чем-то высокие, а в чем-то нет
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Высоки
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Очень высокие
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Как изменится экономическая ситуация в стране в
                        ближайшем будущем?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_4", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Значительно ухудшится
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Скорее ухудшится, чем нет
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Останется без изменения
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Скорее улучшится, чем нет
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Значительно улучшится
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        В целом благоприятны или неблагоприятны текущие
                        экономические условия в стране для роста личного
                        благосостояния и доходов?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_5", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Очень неблагоприятны
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Неблагоприятны
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В чем-то благоприятны, а в чем-то нет
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Благоприятны
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Очень благоприятны
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Какой уровень материального благополучия Вы смогли бы
                        достичь, приложив максимум усилий и реализовав свои
                        способности?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_6", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Обеспечить семье минимальный прожиточный уровень
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Обеспечить прожиточный уровень, как у большинства
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Обеспечить крепкий достаток в семье
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Стать состоятельным человеком{" "}
                            <i className="comment db">
                                (обеспечить будущее своих детей)
                            </i>
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Стать богатым человеком, ни в чем себе не отказывать
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Испытываете ли Вы чувство безнадежности{" "}
                        <i className="comment">(безысходности)</i>
                        из-за невозможности самому улучшить свое финансовое
                        положение?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_7", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Это чувство не покидает меня
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Такое чувство возникает довольно часто
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Возникает иногда
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Такое чувство возникает довольно редко
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Не возникает
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Финансовые неудачи чаще всего вызывают у Вас апатию или
                        желание активной деятельности?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_8", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Чувство собственной беспомощности, апатию
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Сильное огорчение
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Равнодушие
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Желание быстрее забыть о неудаче
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Желание активной деятельности
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Как Вы оцениваете уровень собственного (семьи)
                        материального благосостояния в настоящее время?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_9", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Очень низкий
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Низкий
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Средний
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Высокий
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Очень высокий
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mt-1-5">
                    <label>
                        Если расположить все российское население на шкале в
                        соответствии с уровнем материального благосостояния,
                        так, чтобы на одном полюсе оказались самые бедные, а на
                        другом – самые богатые, то как Вы отметите собственное
                        положение на шкале благосостояния, в котором Вы
                        находитесь в настоящий момент?
                    </label>
                    <Slider
                        className="col-six mt"
                        noInput={true}
                        {...this.baseEditProps("sub_economic_well-being_10")}
                        ticks={4}
                        from={1}
                        to={5}
                        fromLabel="самые бедные"
                        toLabel="самые богатые"
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                    />
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Испытываете ли Вы недостаток в денежных средствах в
                        настоящее время?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_11", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Не испытываю
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой степени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой степени{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <label className="col col-four col-last">
                        В какой степени Ваши доходы (Вашей семьи) удовлетворяют
                        Ваши основные потребности?{" "}
                        <i className="comment">(степень удовлетворенности)</i>:
                    </label>
                    <div className="radio-legend">
                        {basicNeedsTitles.map((x, i) => (
                            <div className="info-baloon fr ml" key={i}>
                                {x}
                            </div>
                        ))}
                        <div className="cb" />
                    </div>
                    <div className="cb" />
                    <ol className="poll-reset poll-wide mt">
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("sub_economic_well-being_12")}
                        >
                            В независимости и свободе
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("sub_economic_well-being_13")}
                        >
                            В материальном благополучии
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("sub_economic_well-being_14")}
                        >
                            В самореализации, самовыражении
                        </PollRadios>
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("sub_economic_well-being_15")}
                        >
                            В безопасности
                        </PollRadios>
                    </ol>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Испытываете ли Вы и в какой степени тревогу,
                        беспокойство за свое материальное положение, а также
                        Вашей семьи в связи с экономическим развитием страны?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_16", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Не испытываю
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой степени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой степени{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Испытываете ли Вы потребность и в какой степени в
                        повышении своих доходов?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_17", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Не испытываю
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой степени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой степени{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <label className="col col-four col-last">
                        Как часто Вы обсуждаете вопросы собственного
                        материального положения с&nbsp;друзьями и коллегами по
                        работе?
                    </label>
                    <div className="radio-legend">
                        {discussFrequencyTitles.map((x, i) => (
                            <div className="info-baloon fr ml" key={i}>
                                {x}
                            </div>
                        ))}
                        <div className="cb" />
                    </div>
                    <div className="cb" />
                    <ol className="poll-reset poll-wide mt">
                        <PollRadios
                            opts={[1, 2, 3, 4, 5]}
                            {...this.baseEditProps("sub_economic_well-being_18")}
                        >
                            С друзьями
                        </PollRadios>
                        <PollRadios
                            opts={[1, 2, 3, 4, 5]}
                            {...this.baseEditProps("sub_economic_well-being_19")}
                        >
                            С коллегами по работе или&nbsp;учебе
                        </PollRadios>
                    </ol>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Укажите степень Вашего беспокойства за собственное
                        материальное положение в будущем:
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_20", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Не испытываю
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой степени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой степени{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Хотели ли бы Вы повысить уровень своих доходов и в какой
                        мере?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_21", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Нет
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой мере
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой мере
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней мере
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой мере
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой мере{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Как Вы оцениваете силу{" "}
                        <i className="comment">(интенсивность)</i> Вашего
                        желания иметь деньги?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_22", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Не испытываю
                        </Radio>
                        <Radio value={4} className="col col-four">
                            В очень малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В малой степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            В средней степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            В большой степени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            В очень большой степени{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mt-1-5">
                    <label>
                        Насколько, по Вашему мнению, деньги значимы для Вас в
                        настоящий момент?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_23", -1)}
                    >
                        <Radio value={5} className="col col-four">
                            Совсем незначимы
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Значимы в малой степени
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Значимы в средней степени
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Значимы в большой степени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Значимы в очень большой степени
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Насколько Вы удовлетворены финансовым положением Вашей
                        семьи?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_24", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Совсем не удовлетворен
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Скорее не удовлетворен, чем удовлетворен
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В чем-то удовлетворен, а в чем-то нет
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Скорее удовлетворен, чем не удовлетворен
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Полностью удовлетворен
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5">
                    <label>
                        Насколько Вы удовлетворены материальным положением Вашей
                        семьи?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_25", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Совсем не удовлетворен
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Скорее не удовлетворен, чем удовлетворен
                        </Radio>
                        <Radio value={3} className="col col-four">
                            В чем-то удовлетворен, а в чем-то нет
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Скорее удовлетворен, чем не удовлетворен
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Полностью удовлетворен
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mt-1-5">
                    <label>
                        Как Вы оцениваете финансовое положение свое и Вашей
                        семьи в настоящее время?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("sub_economic_well-being_26", -1)}
                    >
                        <Radio value={1} className="col col-four">
                            Денег не&nbsp;хватает даже на&nbsp;продукты питания,
                            <br />
                            постоянно испытываем нужду
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Денег хватает только на продукты питания,
                            но&nbsp;на&nbsp;одежду уже не&nbsp;хватает,
                            прибегаем к посторонней помощи.
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Денег хватает только на самое необходимое —
                            на&nbsp;продукты питания и&nbsp;одежду,
                            но&nbsp;на&nbsp;товары длительного пользования{" "}
                            <i className="comment">
                                (телевизор, холодильник и т. п.)
                            </i>{" "}
                            уже не хватает.
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Денег хватает на продукты питания, одежду
                            и&nbsp;товары длительного пользования,
                            но&nbsp;на&nbsp;очень дорогие предметы{" "}
                            <i className="comment">
                                (квартира, машина, дача и&nbsp;т.п.)
                            </i>{" "}
                            уже не хватает, регулируем свои расходы.
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Денег хватает на&nbsp;все и&nbsp;даже на&nbsp;очень
                            дорогие предметы, делаем накопления.{" "}
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Save answers
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default FinancialForm;
