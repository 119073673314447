import React from "react";
import Draggable from "react-draggable";
import { LabeledInput } from "./LabeledInput";
import "../../../css/comp/slider.css";

export class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value };
        this.scaleRef = React.createRef();
    }

    handleStart = () => {
        if (this.props.onStartDrag) this.props.onStartDrag(this.props.name);
    };
    handleStop = () => {
        if (this.props.onStopDrag) this.props.onStopDrag(this.props.name);
    };
    handleDrag = (e, data) => {
        let val =
            Math.round((data.x * this.k + this.props.from) * this.digits) /
            this.digits;
        if (this.props.onDrag) this.props.onDrag(this.props.name);
        //Изменение
        if (this.props.onChange)
            this.props.onChange({ name: this.props.name, value: val });
    };
    ///Прозрачно транслируем изменение инпута, чтобы управлять состоянием слайдера сверху
    handleInputChange = v => {
        let _val = v.value;
        //Границы
        var nv = Number(_val);
        if (!isNaN(nv)) {
            if (nv < this.props.from) {
                _val = this.props.from;
            }
            if (nv > this.props.to) {
                _val = this.props.to;
            }
        }
        let _v = { ...v };
        _v.value = _val;
        //Отправляем наверх
        if (this.props.onChange) this.props.onChange(_v);
    };
    componentDidMount() {
        this.w =
            this.scaleRef.current.clientWidth - (this.props.offset || 20) || 1; //1 чтобы не было деления на 0
        this.k = (this.props.to - this.props.from) / this.w;
        this.digits = Math.pow(
            10,
            this.props.digits === 0 ? 0 : this.props.digits || 2
        );

        if (this.props.onChange) this.props.onChange(this.props);
    }
    emptyOrDef = (v, def) => {
        return v !== null && v !== undefined ? v : def;
    };
    legend = side => {
        return (
            this.props[side + "Label"] || this.emptyOrDef(this.props[side], "")
        );
    };
    render() {
        // var F = React.Fragment;
        var ticks = this.props.ticks || 5;
        var tickSize = Math.floor(10000 / ticks) / 100;
        var tickBlocks = [];
        for (let i = 0; i < ticks; i++) {
            tickBlocks.push(
                <div
                    className={
                        "tick fl " +
                        (i === 0
                            ? "tick-first"
                            : i === ticks - 1
                            ? "tick-last"
                            : "")
                    }
                    style={{ width: tickSize + "%" }}
                    key={i}
                >
                    &nbsp;
                </div>
            );
        }
        let val = 0;
        if (this.w && this.k) {
            var fVal = (this.props.value || this.props.from) - (this.props.from || 0);
            val = (fVal) / this.k;
        }
        var cntCols =
            (this.props.className || "col-four").indexOf("col-six") > -1
                ? "col-five"
                : "col-three";
        var css =
            "slider " +
            (this.props.className || "") +
            (this.props.noInput ? " no-input" : "");
        var cssCnt =
            "slider-cnt col " +
            (this.props.noInput
                ? cntCols === "col-five"
                    ? "col-four"
                    : "col-two"
                : cntCols + " fl");
        return (
            <div className={css}>
                <div className={cssCnt}>
                    <div className="legend left fl">{this.legend("from")}</div>
                    <div className="legend right fr">{this.legend("to")}</div>
                    <div className="tick-container ml mr">{tickBlocks}</div>
                    <div className="scale" ref={this.scaleRef}>
                        <Draggable
                            axis="x"
                            position={{ x: val, y: 0 }}
                            grid={[1, 1]}
                            bounds="parent"
                            onStart={this.handleStart}
                            onDrag={this.handleDrag}
                            onStop={this.handleStop}
                        >
                            <div className="slider-handle" />
                        </Draggable>
                    </div>
                    <div className="cb" />
                </div>
                {!this.props.noInput && (
                    <LabeledInput
                        className="col fl col-last"
                        value={this.props.value}
                        name={this.props.name}
                        onChange={this.handleInputChange}
                        units={this.props.units}
                        digits={true}
                    />
                )}
                <div className="cb" />
            </div>
        );
    }
}

export default Slider;
