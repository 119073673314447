import React from "react";
import cx from 'classnames';

import UnderlinedHeading from './UnderlinedHeading';

import "../../css/comp/risk/ordered-list.css";


export default function OrderedList(props) {
    return (
        <div className={cx("ordered-list", props.className)}>
            {props.heading && (
                <UnderlinedHeading className="mb">
                    {props.heading}
                </UnderlinedHeading>
            )}

            <ol className="items">
                {props.items.map((item, ix) => (
                    (React.isValidElement(item) || typeof item === 'string') ? (
                        <React.Fragment key={ix}>
                            <li className="item-content">
                                {item}
                            </li>
                            <li className="item-after empty"></li>
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={ix}>
                            <li className="item-content">
                                {item.content}
                            </li>
                            <li className={cx("item-after", { empty: !item.after })}>
                                {item.after}
                            </li>
                        </React.Fragment>
                    )
                ))}
            </ol>
        </div>
    );
}
