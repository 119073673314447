import React from "react";
import cx from 'classnames';

import "../../../css/comp/risk/popup-block.css";


export default function PopupBlock(props) {
    return (
        <div className={cx("popup-block", props.className)}>
            {/* <div
                className="arrow"
                style={props.arrowStyle}
            /> */}

            <div className="mb-1-5">
                <div className="title fl">
                    {props.title}
                </div>
                <div className="close-button fr" onClick={props.onCloseClick}>
                    <span className="icon close" />
                </div>
                <div className="cb" />
            </div>

            {props.children}
        </div>
    );
}
