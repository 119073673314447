import React from "react";
import Header from "./header";
import { Redirect } from "react-router-dom";

import "../../../css/pages/auth.css";
import { Api } from "../../../libs/api";

export class AuthLogoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { redirect: "" };
    }
    componentDidMount() {
        Api.logout()
            .then(x => {
                this.setState({ redirect: <Redirect to="/eng/auth" /> });
            })
            .catch(x => {
                this.setState({ redirect: <Redirect to="/eng/auth" /> });
            });
    }
    render() {
        return (
            <div className="auth">
                <Header />
                <div className="form form-auth form-full">
                    <h2>Please stand by...</h2> <div className="cb" />
                    {this.state.redirect}
                </div>
            </div>
        );
    }
}
