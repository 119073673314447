import React from "react";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import { LabeledInput } from "../shared/LabeledInput";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class IllnessesForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }

    handleChange(v) {
        console.log(
            this.baseEditProps("gender", "M")
        )
        var fields = {};
        fields[v.name] = v.value;

        //При сбросе типа диабета сбрасываем значения формы диабета
        if (v.name === "diabetes" && v.value !== 2)
            fields["diabetes_form"] = -1;

        //При отсутствии головной боли сбрасываем связанные значения полей
        if (v.name === "headache_frequency" && v.value === 0) {
            fields["headache_force"] = -1;
            fields["headache-meds"] = -1;
            fields["head_pain"] = -1;
        }

        if (v.name === "BPG" && v.value === 0)
            fields["dizziness_duration"] = -1;

        if (v.name === "loss_sight" && v.value === 0)
            fields["loss_sight_d"] = -1;

        if (v.name === "awkward_mov" && v.value === 0)
            fields["awkward_mov_d"] = -1;

        if (v.name === "head-vesseles-research" && v.value !== 2) {
            fields["head-vesseles-cartoid-lumen"] = 0;
            fields["head-vesseles-vertebral-lumen"] = 0;
            fields["head-vesseles-aneurysm"] = 0;
        }
        if (v.name === "steno" && v.value !== 3) {
            fields["noooooo"] = 0;
            fields["pach"] = 0;
            fields["OTJ"] = 0;
            fields["BISPN"] = 0;
        }
        if (v.name === "homocist" && v.value !== 1) {
            fields["homocist_cause"] = -1;
        }
        if (v.name === "ren_sick" && v.value !== 1) {
            fields["ren_stad"] = -1;
        }
        if (v.name === "schit_jel" && v.value !== 1) {
            fields["schit_jel_type"] = -1;
        }
        if (v.name === "fam_hyp_hol" && v.value !== 1) {
            fields["fam_hyp_hol_type"] = -1;
        }
        if (v.name === "hypertonic" && [0, 6].indexOf(v.value) > -1) {
            fields["Isolated_systolic_hypertension"] = -1;
        }
        let arr = [
            "other-states-chest-pain",
            "other-states-fast-heart-pain",
            "other-states-dyspnea"
        ];
        //Если "Нет" - снимаем все галочки с остальных
        if (v.name === "other-states-no" && v.value === 1) {
            for (let i = 0; i < arr.length; i++) {
                fields[arr[i]] = 0;
            }
        } else if (arr.includes(v.name)) {
            //...и наоборот
            if (v.value === 1) {
                fields["other-states-no"] = 0;
            }
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidMount() {
    }
    render() {
        let fields = this.props.fields || {};
        let F = React.Fragment;
        return (
            <div className="appear-left">
                <h2 className="cb db">Мои заболевания</h2>
                <div className="col col-four col-last fl mb-1-5">
                    <label>Гипертоническая болезнь:</label>
                    <RadioGroup {...this.baseEditProps("hypertonic", 6)}>
                        <Radio value={0} className="col col-four">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Гипертония 1 стадии латентная
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Гипертония 1 стадии транзиторная
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Гипертония 2 стадии лабильная
                        </Radio>
                        <Radio value={4} className="col col-four">
                            Гипертония 2 стадии стабильная
                        </Radio>
                        <Radio value={5} className="col col-four">
                            Гипертония 3 стадии
                        </Radio>
                        <Radio value={6} className="col col-four">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="db" />
                <div className="col col-four fl mb-1-5">
                    <label>Гипотония:</label>
                    <RadioGroup {...this.baseEditProps("hypoton", 3)}>
                        <Radio value={0} className="col col-three">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Да, ортостатическая
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Да, постоянная <i className="comment">(хроническая/идиопатическая)</i>
                        </Radio>
                        <Radio value={3} className="col col-three">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {([0, 6].indexOf(this.emptyOrDef(fields["hypertonic"], 6)) ===
                    -1 && [0, 3].indexOf(this.emptyOrDef(fields["hypoton"], 3)) ===
                    -1) && (
                    <div className="col col-two fl col-last mb-1-5 appear-down">
                        <label>
                            Изолированная систолическая артериальная
                            гипертензия:
                        </label>
                        <RadioGroup
                            {...this.baseEditProps(
                                "Isolated_systolic_hypertension"
                            )}
                        >
                            <Radio value={0} className="col fl">
                                Нет
                            </Radio>
                            <Radio value={1} className="col fl col-last">
                                Есть
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="db" />
                {([0, 6].indexOf(this.emptyOrDef(fields["hypertonic"], 6)) ===
                    -1 && [0, 3].indexOf(this.emptyOrDef(fields["hypoton"], 3)) === -1) && (
                    <F>
                        <div className="col col-four fl appear-up">
                            <div className="col col-two fl">
                                <LabeledInput
                                    {...this.baseEditProps("ads_upper_usual")}
                                    label={
                                        <F>
                                            Верхнее рабочее давление{" "}
                                            <i className="comment red">
                                                (систолическое)
                                            </i>
                                        </F>
                                    }
                                    className="col col-two"
                                    units="мм. рт. с."
                                />
                            </div>
                            <div className="col col-two fl col-last">
                                <LabeledInput
                                    {...this.baseEditProps("ads_down_usual")}
                                    label={
                                        <F>
                                            Нижнее рабочее давление{" "}
                                            <i className="comment">
                                                (диастолическое)
                                            </i>
                                        </F>
                                    }
                                    className="col col-two col-last"
                                    units="мм. рт. с."
                                />
                            </div>
                        </div>
                        <div className="col col-two fl col-last appear-left">
                            <i className="comment">
                            Артериальное давление, измеренное в состоянии покоя, при котором человек чувствует себя хорошо, называется «нормальным». А если человек принимает препараты от давления (при наличии гипертонии) или страдает гипотонией, то «рабочим», «целевым» или «адаптированным».
                            </i>
                        </div>
                    </F>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Диабет (наличие и тип):</label>
                    <RadioGroup {...this.baseEditProps("diabetes", 4)}>
                        <Radio value={0} className="col col-three">
                            Нет диабета
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Сахарный диабет первого типа
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Сахарный диабет второго типа
                        </Radio>
                        <Radio value={3} className="col col-three">
                            Гестационный диабет
                        </Radio>
                        <Radio value={4} className="col col-three">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["diabetes"], -1) === 2 && (
                    <div className="col col-three col-last fl appear-left">
                        <label>Форма диабета:</label>
                        <RadioGroup {...this.baseEditProps("diabetes_form")}>
                            <Radio value={0} className="col col-three">
                                Лёгкая форма{" "}
                                <i className="comment">(регулируется диетой)</i>
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Средней степени тяжести{" "}
                                <i className="comment db">
                                    (регулируется сахароснижающими препаратами)
                                </i>
                            </Radio>
                            <Radio value={2} className="col col-three">
                                Тяжёлое течение{" "}
                                <i className="comment">
                                    (регулируется инсулином)
                                </i>
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Мерцательная аритмия:</label>
                    <RadioGroup {...this.baseEditProps("merc_aritm", 3)}>
                        <Radio value={0} className="col col-three">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Да, периодическая форма{" "}
                            <i className="comment db">
                                (приступы несколько раз в месяц и реже)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Да, постоянная форма{" "}
                            <i className="comment db">
                                (приступы еженедельно, несколько раз в неделю)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-three">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <h2 className="cb db">Болевой синдром</h2>
                <div className="col col-two fl mb-1-5">
                    <label>Головная боль (наличие и частота):</label>
                    <RadioGroup
                        {...this.baseEditProps("headache_frequency", 0)}
                    >
                        <Radio value={0} className="col col-two">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Редко
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Периодически
                        </Radio>
                        <Radio value={3} className="col col-two">
                            Часто
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-two fl appear-up">
                        <label>Сила головной боли:</label>
                        <RadioGroup {...this.baseEditProps("headache_force")}>
                            <Radio value={0} className="col col-two">
                                Слабая
                            </Radio>
                            <Radio value={1} className="col col-two">
                                Умеренная
                            </Radio>
                            <Radio value={2} className="col col-two">
                                Сильная
                            </Radio>
                            <Radio value={3} className="col col-two">
                                Очень сильная
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-two col-last fl appear-left">
                        <label>Продолжительность приступов:</label>
                        <RadioGroup {...this.baseEditProps("head_pain")}>
                            <Radio value={0} className="col col-two">
                                Несколько часов
                            </Radio>
                            <Radio value={1} className="col col-two">
                                Сутки и более
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                {this.emptyOrDef(fields["headache_frequency"], 0) !== 0 && (
                    <div className="col col-four col-last appear-up mb-1-5">
                        <label>
                            Помогают ли вам от головной боли лекарственные
                            препараты <i className="comment">(анальгетики)</i>:
                        </label>
                        <RadioGroup {...this.baseEditProps("headache_meds")}>
                            <Radio value={1} className="col fl">
                                Да
                            </Radio>
                            <Radio value={0} className="col col-last fl">
                                Нет
                            </Radio>
                        </RadioGroup>
                        <div className="cb" />
                    </div>
                )}
                <div className="col col-three mb-1-5 cb">
                    <label>Беспокоит ли вас мигрень?</label>
                    <RadioGroup {...this.baseEditProps("migren", 0)}>
                        <Radio value={0} className="col col-two">
                            Нет, не беспокоит
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Да, с аурой
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-three mb-1-5 cb">
                    <label>
                        Хроническая боль{" "}
                        <i className="comment">(любой локализации)</i>:
                    </label>
                    <RadioGroup {...this.baseEditProps("HronPain", 0)}>
                        <Radio value={0} className="col col-two">
                            Нет, не беспокоит
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Да
                        </Radio>
                    </RadioGroup>
                </div>
                <h2 className="cb db">Прочее</h2>
                <div className="col col-three fl mb-1-5">
                    <label>
                        Беспокоило ли вас беспричинное головокружение{" "}
                        <i className="comment">
                            (необъяснимое предшествующей ситуацией)
                        </i>?
                    </label>
                    <RadioGroup {...this.baseEditProps("BPG", 0)}>
                        <Radio value={0} className="col col-three">
                            Нет, не беспокоило
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Беспокоило однократно
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Беспокоит периодически
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields.BPG, 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left">
                        <label>
                            Продолжительность приступов головокружения:
                        </label>
                        <RadioGroup
                            {...this.baseEditProps("dizziness_duration")}
                        >
                            <Radio value={0} className="col col-three">
                                Несколько минут
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Несколько часов
                            </Radio>
                            <Radio value={2} className="col col-three">
                                Сутки и более
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                        Возникала ли у Вас когда-нибудь внезапно кратковременная
                        потеря зрения на один глаз или выпадение полей зрения?
                    </label>
                    <RadioGroup {...this.baseEditProps("loss_sight", 0)}>
                        <Radio value={0} className="col col-three">
                            Нет, не возникала
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Возникала однократно
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Возникает периодически
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["loss_sight"], 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left mt2">
                        <label>
                            Продолжительность приступов нарушения зрения:
                        </label>
                        <RadioGroup {...this.baseEditProps("loss_sight_d")}>
                            <Radio value={0} className="col col-three">
                                Несколько минут
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Несколько часов
                            </Radio>
                            <Radio value={2} className="col col-three">
                                Сутки и более
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Вы отмечаете за последние годы заметное снижение
                        памяти?
                    </label>
                    <RadioGroup {...this.baseEditProps("memory_loss")}>
                        <Radio value={1} className="col col fl">
                            Да
                        </Radio>
                        <Radio value={0} className="col col col-last fl">
                            Нет
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                        Возникала у Вас когда-нибудь внезапно кратковременная
                        слабость или неловкость при движении в одной руке или
                        ноге либо руке и ноге одновременно?
                    </label>
                    <RadioGroup {...this.baseEditProps("awkward_mov", 0)}>
                        <Radio value={0} className="col col-three">
                            Нет, не возникала
                        </Radio>
                        <Radio value={1} className="col col-three">
                            Возникала однократно
                        </Radio>
                        <Radio value={2} className="col col-three">
                            Возникает периодически
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["awkward_mov"], 0) !== 0 && (
                    <div className="col col-three col-last fl appear-left mt2">
                        <label>Продолжительность приступов:</label>
                        <RadioGroup {...this.baseEditProps("awkward_mov_d")}>
                            <Radio value={0} className="col col-three">
                                Несколько минут
                            </Radio>
                            <Radio value={1} className="col col-three">
                                Несколько часов
                            </Radio>
                            <Radio value={2} className="col col-three">
                                Сутки и более
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Вы отмечаете в последние годы значительное повышение{" "}
                        <br />
                        утомляемости, снижение работоспособности?
                    </label>
                    <RadioGroup {...this.baseEditProps("increase_fatigue")}>
                        <Radio value={1} className="col col fl">
                            Да
                        </Radio>
                        <Radio value={0} className="col col col-last fl">
                            Нет
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                <div className="cb" />

                <div className="col col-three fl mb-1-5">
                    <label>
                        Гомоцистеинемия как следствие гиповитаминоза{" "}
                        <i className="comment">
                            (пониженная концентрация витаминов в крови)
                        </i>
                        :
                    </label>
                    <RadioGroup {...this.baseEditProps("homocist")}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                    <div className="cb" />
                </div>
                {this.emptyOrDef(fields["homocist"], 2) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label className="mt">Причина Гомоцистеинемии:</label>
                        <RadioGroup {...this.baseEditProps("homocist_cause")}>
                            <Radio value={0} className="col col-three col-last">
                                Вследствие дефицита витамина B6
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Вследствие дефицита витамина B12
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                Вследствие дефицита фолиевой кислоты
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Отметьте, какие из перечисленных заболеваний выявлены у
                        Вас:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("vaskul")}
                    >
                        Васкулиты/васкулопатии
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("antifospho")}
                    >
                        Антифосфолипидный синдром
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("GipaC")}
                    >
                        Гепатит С
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("System_volk")}
                    >
                        Системная красная волчанка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("sys_sclero")}
                    >
                        Системная склеродермия
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("uzel_peritanit")}
                    >
                        Узелковый периартериит
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("f_cerebro_artereet")}
                    >
                        Первичный церебральный артериит
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("fybro_displazia_sosud")}
                    >
                        Фиброзномышечная дисплазия сосудов
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("ritino")}
                    >
                        Ретинопатия
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("insult_hs")}
                    >
                        Инсульт
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("infarkt_hs")}
                    >
                        Инфаркт миокарда
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("aterscker")}
                    >
                        Атеросклероз
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("crirt_coronal_sind_anam")}
                    >
                        Острый коронарный синдром
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("spondelt")}
                    >
                        Спондилит <i className="comment">(болезнь Бехтерева)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("revm_art")}
                    >
                        Ревматоидный артрит
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("artrit")}
                    >
                        Артрит
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("apnoe")}
                    >
                        Синдром обструктивного апноэ во сне
                    </Check>
                    {this.emptyOrDef(fields["gender"], -1) === "M" && (
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("importent")}
                    >
                        Выраженная эректильная дисфункция
                    </Check>
                    )}

                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("hantington")}
                    >
                        Болезнь Хантингтона
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("parkinson")}
                    >
                        Болезнь Паркинсона
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("icenkushinga")}
                    >
                        Болезнь Иценко-Кушинга
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("addisona")}
                    >
                        Болезнь Аддисона
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("ras_sclerosis")}
                    >
                        Рассеянный склероз
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("akne")}
                    >
                        Акне
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("psoriaz")}
                    >
                        Тяжелая форма псориаза
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("periodontit")}
                    >
                        Хронический периодонтит
                    </Check>
                </div>
                <div className="col col-four mb-1-5">
                    <label>
                        Проводилась ли Вам коронарная реваскуляризация?
                    </label>
                    <RadioGroup {...this.baseEditProps("coronal_rev")}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, ангиопластика
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, стентирование
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Да, шунтирование
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-three col-last mb-1-5">
                    <label>Новообразования:</label>
                    <RadioGroup {...this.baseEditProps("bad_cancer", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Хроническая болезнь почек:</label>
                    <RadioGroup {...this.baseEditProps("ren_sick", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["ren_sick"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Укажите стадию болезни:</label>
                        <RadioGroup {...this.baseEditProps("ren_stad", -1)}>
                            <Radio value={0} className="col col-three col-last">
                                1 стадия
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                2 стадия
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                3а стадия
                            </Radio>
                            <Radio value={3} className="col col-three col-last">
                                3б стадия
                            </Radio>
                            <Radio value={4} className="col col-three col-last">
                                4 стадия
                            </Radio>
                            <Radio value={5} className="col col-three col-last">
                                5 стадия
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Заболевания щитовидной железы:</label>
                    <RadioGroup {...this.baseEditProps("schit_jel", 2)}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["schit_jel"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Вид заболевания:</label>
                        <RadioGroup
                            {...this.baseEditProps("schit_jel_type", -1)}
                        >
                            <Radio value={0} className="col col-three col-last">
                                Гипотиреоз
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Гипертиреоз
                            </Radio>
                            <Radio value={2} className="col col-three col-last">
                                Другое
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Семейная гиперхолестеринемия:</label>
                    <RadioGroup {...this.baseEditProps("fam_hyp_hol", -1)}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["fam_hyp_hol"], -1) === 1 && (
                    <div className="col col-three col-last fl mb-1-5 appear-left">
                        <label>Вид заболевания:</label>
                        <RadioGroup
                            {...this.baseEditProps("fam_hyp_hol_type", -1)}
                        >
                            <Radio value={0} className="col col-three col-last">
                                Гетерозиготная
                            </Radio>
                            <Radio value={1} className="col col-three col-last">
                                Гомозиготная
                            </Radio>
                        </RadioGroup>
                    </div>
                )}
                <div className="cb" />
                {/* <div className="col col-four mb-1-5">
                    <label>Псориаз:</label>
                    <RadioGroup {...this.baseEditProps("psoriaz", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, лёгкая форма
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, тяжёлая форма
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four mb-1-5">
                    <label>Периодонтит:</label>
                    <RadioGroup {...this.baseEditProps("periodontit", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, острый
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, хронический
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div> */}

                <div className="col col-two col-last mb-1-5">
                    <label>Стенокардия:</label>
                    <RadioGroup {...this.baseEditProps("steno", -1)}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, атипичная
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, типичная
                        </Radio>
                        <Radio value={3} className="col col-two col-last">
                            Да, без уточнения формы
                        </Radio>
                        <Radio value={4} className="col col-two col-last">
                            Не знаю
                        </Radio>
                    </RadioGroup>
                </div>
                {[3].indexOf(this.emptyOrDef(fields["steno"], 4)) > -1 && (
                    <div className="col col-four col-last mb-1-5 appear-up">
                        <label>
                            Испытывали ли вы когда-нибудь одно из перечисленных
                            состояний:
                        </label>
                        {/*<Check
                            className="col col-four col-last"
                            {...this.baseEditProps("noooooo")}
                        >
                            Нет
                        </Check>*/}
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("pach")}
                        >
                            Боль в области грудины длительностью 2-5 минут без
                            или с иррадиацией в левую руку, спину, нижнюю
                            челюсть или эпигастральную область в отсутствие или
                            во время физической нагрузки, либо эмоционального
                            стресса
                        </Check>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("OTJ")}
                        >
                            Одышку, ощущение «тяжести», «жжения» в области
                            сердца
                        </Check>
                        <Check
                            className="col col-four col-last"
                            {...this.baseEditProps("BISPN")}
                        >
                            Быстроисчезающую боль в сердце после прекращения
                            нагрузки либо после приема нитратов
                        </Check>
                        <div className="cb" />
                    </div>
                )}
                <div className="col col-six col-last mb-1-5">
                    <label>Психические расстройства:</label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("BDSP")}
                    >
                        Пограничное расстройство личности{" "}
                        <i className="comment">(диагноз)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PoSR")}
                    >
                        Посттравматическое стрессовое расстройство
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("TrevR")}
                    >
                        Тревожное расстройство
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EDIP")}
                    >
                        Эпизоды депрессии в прошлом
                    </Check>
                    <div className="cb" />
                </div>
                <h2 className="db cb">
                    Прием препаратов в течение долгого времени &nbsp;
                    <i className="comment">(от года и более)</i>
                </h2>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("snot_year")}
                >
                    Снотворные
                    {/* <i className="db comment">
                        (Фенобарбитал, Бромизовал, бензодиазепины – Нитразепам,
                        Клоназепам, Мидазолам, Гидазепам, Ниметазепам,
                        Флунитразепам, Алпразолам, Диазепам, Клобазам,
                        Мидазолам, Лоразепам, Клоразапат, Лопразолам,
                        Хлордиазепоксид, Феназепам, Триазолам, Гидазепам,
                        Бромазепам, Темазепам, Флуразепам и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("painkiller_yer")}
                >
                    Обезболивающие
                    {/* <i className="db comment">
                        (Морфин, Метилморфин, Метадон, Фентанил, Промедол,
                        Бупренорфин, Пентазоцин, Буторфанол, Налбуфин, Налоксон,
                        Трамадол и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("sedat_year")}
                >
                    Седативные
                    {/* <i className="db comment">
                        (Корвалол, Валокордин, Валосердин и др.)
                    </i> */}
                </Check> 
                {/* <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("gipotenz_year")}
                >
                    Гипотензивные
                    <i className="db comment">
                        (Раунатин, Резерпин, Апрессин, Клофелин, Допегит,
                        Анаприлин, Обзидан, Верапамил, Нифедипин и др.)
                    </i>
                </Check> */}
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("ster_year")}
                >
                    Стероидные
                    {/* <i className="db comment">
                        (Метандростенолон, Неробол, Дианабол, Ретаболил и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("interferon_year")}
                >
                    Интерферон-альфа
                </Check>
                {/* <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("Bblock_year")}
                >
                    Бета-блокаторы
                    <i className="db comment">
                        (Тенормин, Лопрессор, Корег и др.)
                    </i>
                </Check> */}
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("izoto_year")}
                >
                    Изотретионин
                    {/* <i className="db comment">
                        (или аналоги: Акнекутан, Верокутан, Дерморетин, Ретасол,
                        Ретиноевая мазь, Роаккутан, Сотрет, 13-цис-Ретиноевая
                        кислота и др.)
                    </i> */}
                </Check>
                {this.emptyOrDef(fields["gender"], -1) === "F" && (
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UOGY")}
                >
                    Оральные контрацептивы, содержащие прогестерон
                    {/* <i className="db comment">
                        (Мини-пили – Чарозетта, Микролют, Экслютон и др.)
                    </i> */}
                </Check>
                )}

                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UPSPY")}
                >
                    Противосудорожные
                    {/* <i className="comment db">
                        (Фенобарбитал и другие барбитураты, Целонтин, Заронтин и
                        др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UAPPY")}
                >
                    Антипсихотические
                    {/* <i className="db comment">
                        (Галоперидол, Аминазин, Трифтазин, Триседил, производные
                        Фенотиазина – Мажептил, Модитен, Пролинат, Тразин,
                        Эсказин и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UGPY")}
                >
                    Гормональные
                    {/* <i className="db comment">
                        (Преднизолон, Гидрокортизон, Премарин, Норплант и др.)
                    </i> */}
                </Check>
                <Check
                    className="col col-six col-last"
                    {...this.baseEditProps("UHPY")}
                >
                    Сердечные
                    <i className="comment db">(Бета-блокаторы, альфа-блокаторы, гипотензивные, антиаритмические, вазодилататоры и др.)</i>
                </Check>
                <Check
                    className="col col-six col-last mb-1-5"
                    {...this.baseEditProps("antimigren_year")}
                >
                    Антимигренозные
                    {/* <i className="comment db">
                        (Напроксен, Раптен рапид и др.)
                    </i> */}
                </Check>
                <h2 className="cb db">Химиотерапия и лучевая терапия</h2>
                <div className="col col-six col-last mb-1-5">
                    <label>
                        Проводилась ли Вам когда-либо химиотерапия в целях
                        лечения онкологических заболеваний?
                    </label>
                    <RadioGroup {...this.baseEditProps("himka")}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, ранее
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, в настоящее время
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-six col-last mb-1-5">
                    <label>
                        Проводилась ли Вам когда-либо лучевая терапия на грудную
                        клетку, голову или шею?
                    </label>
                    <RadioGroup {...this.baseEditProps("LuchTer")}>
                        <Radio value={0} className="col col-two col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Да, ранее
                        </Radio>
                        <Radio value={2} className="col col-two col-last">
                            Да, в настоящее время
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />
                <h2 className="cb db">Семейный анамнез</h2>
                <Check
                    className="col col-four mb-1-5"
                    {...this.baseEditProps("depression_family")}
                >
                    Депрессия у близких родственников{" "}
                    <i className="comment">(родители, братья, сёстры)</i>
                </Check>
                <div className="col col-three fl mb-1-5">
                    <label>Ранние инсульты:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas1")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas2")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>Инсульты в пожилом возрасте:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas3")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("sas4")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Ранние инфаркты миокарда:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas1")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas2")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>Инфаркты миокарда в пожилом возрасте:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas3")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("ishas4")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>Ранние смерти от сердечных заболеваний:</label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas1")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas2")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
                <div className="col col-three col-last fl mb-1-5">
                    <label>
                        Смерти от сердечных заболеваний в пожилом возрасте:
                    </label>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas3")}
                    >
                        У родственников 1-й степени родства
                        <i className="comment db">(родители, братья, сёстры)</i>
                    </Check>
                    <Check
                        className="col col-three"
                        {...this.baseEditProps("infas4")}
                    >
                        У родственников 2-й степени родства
                        <i className="comment db">
                            (бабушки/дедушки, внуки, дяти/тети, племянники)
                        </i>
                    </Check>
                </div>
				
                <div className="cb" />
					<div className="col col-three fl mb-1-5">
					<label>
						Ранняя артериальная гипертензия:
					</label>
					<Check
						className="col col-four mb-1-5"
						{...this.baseEditProps("fhag1")}
					>
						У родственников 1-й степени родства
						<i className="comment">(родители, братья, сёстры)</i>
					</Check>
				</div>
				<div className="col col-three col-last fl mb-1-5">
				</div>
				<div className="cb" />
				
				
				
				
                <div className="btn-container mt">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/lifestyle",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу Образ жизни
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default IllnessesForm;
