import React, { PureComponent } from "react";

export class BaseEditForm extends React.Component {
    emptyOrDef = (v, def) => {
        return v !== null && v !== undefined ? v : def;
    };
    baseEditProps = (name, def, noValue) => {
        let props = {
            onChange: this.handleChange,
            onConfirmYes: this.handleOnConfirmYes,
            onConfirmNo: this.handleOnConfirmNo,
            name: name,
            value: this.emptyOrDef((this.props.fields || {})[name], def),
            emptyValue: noValue,
            confirm: this.emptyOrDef(
                (this.props.fields || {})[name + "-fieldConfirm"],
                ""
            ),
            error: this.emptyOrDef(
                (this.props.fields || {})[name + "-fieldError"],
                ""
            )
        };

        var f = this.fieldsOnForm || [];
        if (f.indexOf(name) === -1) {
            f.push(name);
        }
        this.fieldsOnForm = f;

        if (props.value === noValue) props.value = "";
        return props;
    };
    baseGraphProps = (name, def) => {
        var names = Array.prototype.isPrototypeOf(name) ? name : [name];
        var data = [];
        for (let i = 0; i < names.length; i++) {
            data = data.concat(
                this.props.fields[names[i] + "-graph"] || def || [[]]
            );
        }
        let props = {
            data: data
        };
        return props;
    };

    ratioGraphProps = (name, def) => {
        // кейс: 
        // пользователь вводит ошибочное значение, тут же вводит новое "верное"
        // 
        // требование: 
        //      на каждый день:
        //         - каждому параметру должно ставиться в соответствие самое позднее его значение
        // 
        //         - если хотя бы один из параметров не имеет значений за текущий день, то
        //              исключить день из результатов
        // 
        var names = Array.prototype.isPrototypeOf(name) ? name : [name];

        if (names.length != 2){
            return {
                data: []
            }; 
        }

        var temp = {};
        var data = [];

        for (let i = 0; i < 2; i++) {
            var prop_name = names[i];

            var records = (this.props.fields[prop_name + "-graph"] || def || [[]])[0];

            records.map(function(record){
                var day  = new Date(record.x);
                day.setHours(0, 0, 0, 0);

                if(!temp.hasOwnProperty(day)){
                    temp[day] = []
                }else{
                    if(!temp[day].hasOwnProperty(prop_name)){
                        temp[day][prop_name] = record;
                    }else{
                        if(record.x > temp[day][prop_name].x){
                            temp[day][prop_name] = record;
                        }
                    }
                }
            });
        }

        var dates = Object.keys(temp);

        dates.forEach(function(day){
            var props       = temp[day];

            var props_keys  = Object.keys(temp[day]);

            if(props_keys.length == 2){
                var val1 = props[names[0]].y;
                var val2 = props[names[1]].y;
                var ratio = val1 / val2;

                data.push(
                    {
                        x: props[names[0]].x,
                        y: ratio
                    }
                )
            }
        });

        let props = {
            data: [data]
        };  
        
        return props;
    };

    baseOneSaveBtns = name => {
        let _name = name;
        let btns = [
            {
                name: "add",
                className: "glyph-plus",
                title: "Add",
                onClick: () => this.handleOneFieldSave(_name)
            }
        ];
        if (this.props.fields["_undo-" + _name]) {
            btns.push({
                name: "undo",
                className: "glyph-undo",
                title: "Undo",
                onClick: () => this.handleOneFieldUndo(_name)
            });
        }
        return btns;
    };
    handleOneFieldSave = (f, confirmed) => {
        if (this.props.onOneSave) {
            let v = this.props.fields[f];
            return this.props.onOneSave(this.props.form, f, v, confirmed);
        }
    };
    handleOneFieldUndo = f => {
        if (this.props.onOneUndo) {
            return this.props.onOneUndo(this.props.form, f);
        }
    };
    handleOnConfirmYes = f => {
        this.handleOneFieldSave(f, true);
    };
    handleOnConfirmNo = f => {
        if (this.props.onConfirmNo) {
            this.props.onConfirmNo(f);
        }
    };
    handleSaveClick = () => {
        if (this.props.onSave) return this.props.onSave(this.props.form);
    };
    /**
     * Генерирует случайные значения со шкалой X - месяцами
     * Размерность соответствует количеству генераторов.
     * @param {function[]} yGens Массив генераторов для значения Y
     * @param {function} xGen Функция генератор значений X (принимает предыдущее значение)
     * @param {Number} times Количество значений серий
     * @returns {Array[]} Массив серий вида [[{x:<Date>,y:Number},...],...]
     */
    genSampleData = (yGens, xGen, times) => {
        var res = [];
        var i;
        for (i = 0; i < yGens.length; i++) {
            res.push([]);
        }
        if (!xGen) {
            for (i = 0; i < new Date().getMonth(); i++) {
                let d = new Date();
                d.setDate(1);
                d.setMonth(i);
                for (let s = 0; s < res.length; s++) {
                    res[s].push({ x: d, y: yGens[s](res, s) });
                }
            }
        } else {
            let d = new Date();
            for (i = 0; i < (times || 10); i++) {
                for (let s = 0; s < res.length; s++) {
                    res[s].push({ x: d, y: yGens[s](res, s) });
                }
                d = xGen(d);
            }
        }
        return res;
    };
}

export class BtnMessage extends PureComponent {
    constructor(props){
        super(props)
        
        this.state = {
            visible: false,
            delay: this.props.delay || 5000,
            timer: undefined
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if(this.props.success !== prevProps.success || this.props.error !== prevProps.error){
            setTimeout(() => {
                this.setState({ 
                    visible: true,
                    timer: this.setTimer()
                })
            }, 100)
        }
    }

    setTimer = () => {
        var timer = setTimeout(() => {
            this.setState({ visible: false })
        }, this.state.delay)

        return timer
    }

    componentWillUnmount = () => {
        if(this.state.timer)
            clearTimeout(this.state.timer);
    }

    render() {
        var className = this.props.error
            ? "error"
            : this.props.success
            ? "success"
            : "";

        if (className && this.state.visible) {
            return (

                <div className="abs-info fr" style={this.state.style}>
                    <div
                        className={`right-info ${className} appear-right fr mr`}
                    >
                        {this.props.error || this.props.success || ""}
                    </div>
                </div>
            );
        }
        return <React.Fragment />;
    }
}
export default BaseEditForm;
