import React from "react";

import RiskHeader from "../RiskHeader";
import HistoryDatePicker from "./HistoryDatePicker";
import "../../../css/comp/risk/problem-page.css";
import "moment/locale/ru";

export default class RiskErrorPage extends React.PureComponent {
    render() {
        return (
            <div className="problem-page">
                <RiskHeader title="Что-то пошло не так">
                    <HistoryDatePicker
                        selected={new Date()}
                        items={[new Date()]}
                    />
                </RiskHeader>

                <div className="container main-container">
                    <div className="form appear-left">
                        <i className="comment db">{this.props.error.message}</i>
                    </div>
                </div>
            </div>
        );
    }
}

