import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthLayout from "./layout";
import { AuthStartForm } from "./auth-start";
import {AuthLogoutForm} from "./logout";
import { VkLoginForm } from "./vklogin";

export const AuthRouting = props => {
    return (
        <Switch>
            <Route exact path="/auth/logout" component={AuthLogoutForm} />
            <Route exact path="/auth/start" component={AuthStartForm} />
            <Route exact path="/auth/vklogin" component={VkLoginForm} />
            <Route path="/auth" component={AuthLayout} />
        </Switch>
    );
};

export default AuthRouting;
