import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import {PollLegend} from "./poll-legend";
import {Route} from "react-router-dom";
import $ from "jquery";

export class DisfunctionalForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=40;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        let F = React.Fragment;
        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Опросник содержит ряд мнений, позиций и принципов, которых
                    иногда придерживаются люди. Прочтите, пожалуйста, каждый
                    пункт внимательно и для каждого утверждения укажите ответ,
                    который наиболее соответствует Вашему мнению. Поскольку все
                    люди разные, то здесь не может быть правильных или
                    неправильных ответов. При принятии решения о том, верно ли,
                    с Вашей точки зрения, то или иное утверждение, просто
                    подумайте о том, какой Вы есть или как Вы думаете обычно или
                    чаще всего.
                </i>
                <div className="poll-header">
                    <h2 className="db cb">
                        Тест на исследование когнитивных искажений
                    </h2>
                    
                    <PollLegend
                        className="pt3"
                        opts={[
                            { key: "1", title: (<F>Полностью<br/>согласен</F>) },
                            { key: "2", title: (<F>В основном<br/>согласен</F>) },
                            { key: "3", title: (<F>Скорее<br/>согласен</F>) },
                            { key: "4", title: (<F>Трудно<br/>определить</F>) },
                            { key: "5", title: (<F>Скорее<br/>не согласен</F>) },
                            { key: "6", title: (<F>В основном<br/>не согласен</F>) },
                            { key: "7", title: (<F>Полностью<br/>не согласен</F>) },
                        ]}
                    />
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
						<div className="q1">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_0")}
							>
								Трудно быть счастливым, если ты не&nbsp;красив,
								не&nbsp;интеллигентен, не&nbsp;богат
								и&nbsp;не&nbsp;имеешь творческих способностей.
							</PollRadios>
						</div>
						<div className="q2">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_1")}
							>
								Счастье — это нечто, связанное с&nbsp;отношением
								к&nbsp;самому себе, нежели с&nbsp;теми чувствами,
								которые другие люди испытывают к&nbsp;тебе.
							</PollRadios>
						</div>
						<div className="q3">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_2")}
							>
								Люди, вероятно, будут думать обо&nbsp;мне плохо,
								если я сделаю ошибку.
							</PollRadios>
						</div>
						<div className="q4">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_3")}
							>
								Если я не&nbsp;буду все&nbsp;время хорошим,
								то&nbsp;другие не&nbsp;будут меня уважать.
							</PollRadios>
						</div>
						<div className="q5">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_4")}
							>
								Подвергаться даже незначительному риску глупо,
								потому что в&nbsp;случае неудачи произойдет
								катастрофа.
							</PollRadios>
						</div>
						<div className="q6">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_5")}
							>
								Можно приобрести уважение другого человека,
								не&nbsp;будучи одаренным в&nbsp;чем-либо.
							</PollRadios>
						</div>
						<div className="q7">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_6")}
							>
								Я счастлив только тогда, когда другие люди мною
								восхищаются.
							</PollRadios>
						</div>
						<div className="q8">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_7")}
							>
								Когда человек просит о&nbsp;помощи, то&nbsp;это
								признак слабости.
							</PollRadios>
						</div>
						<div className="q9">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_8")}
							>
								Если я не&nbsp;такой хороший, как&nbsp;другие люди,
								то&nbsp;это значит, что&nbsp;я менее ценный человек.
							</PollRadios>
						</div>
						<div className="q10">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_9")}
							>
								Если я не&nbsp;справляюсь со&nbsp;своей работой,
								то&nbsp;я вообще как&nbsp;человек несостоятелен{" "}
								<i className="comment">(неудачник)</i>.
							</PollRadios>
						</div>
						<div className="q11">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_10")}
							>
								Если человек не&nbsp;может что-то сделать правильно
								и&nbsp;в&nbsp;совершенстве, то&nbsp;вообще нет
								смысла начинать это дело.
							</PollRadios>
						</div>
						<div className="q12">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_11")}
							>
								Делать ошибки полезно, тогда я могу на&nbsp;них
								учиться.
							</PollRadios>
						</div>
						<div className="q13">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_12")}
							>
								Если кто-то не&nbsp;разделяет моего мнения, то,
								по-видимому, это значит, что он не&nbsp;любит меня.
							</PollRadios>
						</div>
						<div className="q14">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_13")}
							>
								Если я частично не&nbsp;справляюсь с&nbsp;чем-то,
								то&nbsp;это также плохо, как потерпеть полную
								неудачу.
							</PollRadios>
						</div>
						<div className="q15">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_14")}
							>
								Если&nbsp;бы люди знали, каков данный человек
								в&nbsp;действительности, то они думали&nbsp;бы
								о&nbsp;нем плохо.
							</PollRadios>
						</div>
						<div className="q16">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_15")}
							>
								Я&nbsp;—&nbsp;ничтожество, если человек, которого я
								люблю, меня не&nbsp;любит.
							</PollRadios>
						</div>
						<div className="q17">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_16")}
							>
								Можно получить удовольствие от&nbsp;работы,
								не&nbsp;принимая во&nbsp;внимание ее конечный
								результат.
							</PollRadios>
						</div>
						<div className="q18">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_17")}
							>
								Прежде, чем что-то предпринимать, надо иметь
								разумную перспективу на&nbsp;успех.
							</PollRadios>
						</div>
						<div className="q19">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_18")}
							>
								Моя ценность как личности в&nbsp;большей степени
								зависит от&nbsp;того, какого мнения обо&nbsp;мне
								другие люди.
							</PollRadios>
						</div>
						<div className="q20">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_19")}
							>
								Если я не&nbsp;буду предъявлять к&nbsp;себе самые
								высокие требования, то&nbsp;я, вероятно,
								в&nbsp;конце концов превращусь во&nbsp;второсортного
								человека.
							</PollRadios>
						</div>
						<div className="q21">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_20")}
							>
								Если я хочу быть ценной личностью, то&nbsp;я должен,
								по&nbsp;крайней мере в&nbsp;какой-то области стать
								выдающимся.
							</PollRadios>
						</div>
						<div className="q22">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_21")}
							>
								Люди, у&nbsp;которых есть хорошие идеи, более ценны,
								чем те, у&nbsp;кого&nbsp;их&nbsp;нет.
							</PollRadios>
						</div>
						<div className="q23">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_22")}
							>
								Я волнуюсь, когда делаю ошибку.
							</PollRadios>
						</div>
						<div className="q24">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_23")}
							>
								Мое собственное мнение о&nbsp;себе более важно, чем
								мнение других обо&nbsp;мне.
							</PollRadios>
						</div>
						<div className="q25">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_24")}
							>
								Чтобы быть добрым, нравственным человеком, надо
								помогать каждому, кто нуждается в&nbsp;помощи.
							</PollRadios>
						</div>
						<div className="q26">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_25")}
							>
								Если я задаю вопрос, то&nbsp;это признак моей
								несостоятельности.
							</PollRadios>
						</div>
						<div className="q27">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_26")}
							>
								Ужасно, когда тебя порицают люди, важные
								для&nbsp;тебя.
							</PollRadios>
						</div>
						<div className="q28">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_27")}
							>
								Если у&nbsp;Вас нет&nbsp;человека, который может
								быть для&nbsp;Вас опорой, то&nbsp;Вы неизбежно
								становитесь несчастным.
							</PollRadios>
						</div>
						<div className="q29">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_28")}
							>
								Я могу достичь важных для&nbsp;меня целей
								и&nbsp;не&nbsp;надрываясь.
							</PollRadios>
						</div>
						<div className="q30">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_29")}
							>
								Может быть так, что человек, которого бранят,
								при&nbsp;этом не&nbsp;раздражается.
							</PollRadios>
						</div>
						<div className="q31">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_30")}
							>
								Я не&nbsp;могу доверять другим людям, потому что они
								могут оказаться жестокими по&nbsp;отношению
								ко&nbsp;мне.
							</PollRadios>
						</div>
						<div className="q32">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_31")}
							>
								Нельзя быть счастливым, если другие не&nbsp;любят
								тебя.
							</PollRadios>
						</div>
						<div className="q33">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_32")}
							>
								Это хорошо — отказываться от&nbsp;собственных
								интересов, чтобы нравиться другим людям.
							</PollRadios>
						</div>
						<div className="q34">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_33")}
							>
								Мое счастье зависит больше от&nbsp;других людей, чем
								от&nbsp;меня самого.
							</PollRadios>
						</div>
						<div className="q35">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_34")}
							>
								Чтобы быть счастливым, мне не&nbsp;требуется
								признания со&nbsp;стороны других.
							</PollRadios>
						</div>
						<div className="q36">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_35")}
							>
								Если человек будет избегать проблем, то&nbsp;они,
								вероятно, исчезнут.
							</PollRadios>
						</div>
						<div className="q37">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_36")}
							>
								Я могу быть счастливым и&nbsp;довольным, даже
								если&nbsp;немало хорошего в&nbsp;жизни проходит
								мимо&nbsp;меня.
							</PollRadios>
						</div>
						<div className="q38">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_37")}
							>
								Очень важно, что обо&nbsp;мне думают другие люди.
							</PollRadios>
						</div>
						<div className="q39">
							<PollRadios
								opts={[7,6,5,4,3,2,1]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_38")}
							>
								Изолированность от&nbsp;других неизменно ведет
								к&nbsp;ощущению несчастливости.
							</PollRadios>
						</div>
						<div className="q40">
							<PollRadios
								opts={[1,2,3,4,5,6,7]}
								titles={[7,6,5,4,3,2,1]}
								{...this.baseEditProps("Dysfunctional_thinking_39")}
							>
								Я могу быть счастливым, не&nbsp;будучи любимым
								другими.
							</PollRadios>
						</div>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default DisfunctionalForm;
