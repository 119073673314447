/* eslint-disable no-multi-str */

import moment from 'moment';


const getRandom = (min, max) => (
    Math.floor(min + Math.random() * (max + 1 - min))
)

const getDummyText = (lenMin, lenMax) => {
    const chunk = '- Dummy Text -' // 14
    const minRepeat = Math.ceil(lenMin / chunk.length)
    const maxRepeat = Math.ceil(lenMax / chunk.length)
    const repeatTimes = getRandom(minRepeat, maxRepeat)
    return chunk.repeat(repeatTimes)
}

// =============================================================================

export const all = {
    systems: [
        {
            name: 'Сердечно-сосудистая система',
            amount: 11,
            description: 'Сердечно-сосудистые заболевания (ССЗ) — общее название заболеваний системы кровообращения. \
По статистике Всемирной Организации Здравоохранения, ССЗ являются основной причиной смерти во всем мире. \
По оценкам, в 2015 году от ССЗ умерло 17,7 миллиона человек, что составило 31% всех случаев смерти в мире.',
            risks: [
                { fill: 100, name: 'Аритмия' },
                { fill: 80, name: 'Варикоз' },
                { fill: 60, name: 'Артериальная гипертензия' },
                { fill: 40, name: 'Ишемическая болезнь сердца' },
                { fill: 40, name: 'Ревматическая лихорадка' },
                { fill: 20, name: 'Атеросклероз' },
            ],
        },
        {
            name: 'Психология',
            amount: 9,
            description: 'Под психическим здоровьем подразумевается наличие нормы в интеллектуальном развитии человека, \
реалистичность восприятия и способность к саморегуляции, коммуникативную культуру и нравственное поведение. \
Активное участие в трудовой, общественной, семейно-бытовой, досуговой формах жиздеятельности человека.',
            risks: [
                { fill: getRandom(1, 100), name: 'Навязчивые состояния' },
                { fill: getRandom(1, 100), name: 'Социальная фобия' },
                { fill: getRandom(1, 100), name: 'Депрессия' },
                { fill: getRandom(1, 100), name: 'Неврастения' },
                { fill: getRandom(1, 100), name: 'Панические атаки' },
                { fill: getRandom(1, 100), name: 'Стрессовое расстройство' },
            ],
        },
    ],
}


export const system = {
  name: 'Сердечно-сосудистая система',
  risks: [
    {
      name: 'Аритмия',
      fill: 100,
      description: 'Патологическое состояние, приводящее к нарушению частоты, \
ритмичности и последовательности возбуждения и сокращения сердца. \
При таком патологическом состоянии может существенно нарушаться нормальная сократительная активность сердца, \
что, в свою очередь, может привести к целому ряду серьёзных осложнений',
    },
    {
      name: 'Варикоз',
      fill: 80,
      description: 'Варикозное расширение вен (упрощённо варико́з) — часто встречающийся патологический процесс, поражение вен \
(сосудов, несущих кровь к сердцу), для которого характерно увеличение диаметра просвета, истончение венозной стенки и \
образование «узлов» — аневризмоподобных локальных расширений.',
    },
    {
      name: 'Артериальная гипертензия',
      fill: 60,
      description: 'Артериальная гипертония (гипертензия, гипертоническая болезнь) - \
самое частое хроническое заболевание у взрослых, связанное с повышением артериального давления. \
Полностью вылечить это заболевание невозможно, однако артериальное давление можно держать под контролем.',
    },
    {
      name: 'Ишемическая болезнь сердца',
      fill: 40,
      description: 'Ишемическая болезнь сердца представляет собой поражение миокарда, обусловленное расстройством коронарного \
кровообращения, возникающее в результате нарушения равновесия между коронарным кровотоком и метаболическими потребностями \
сердечной мышцы. Иными словами, миокард нуждается в бо́льшем количестве кислорода, чем его поступает с кровью. ',
    },
    {
      name: 'Ревматическая лихорадка',
      fill: 40,
      description: 'Системное заболевание соединительной ткани с преимущественной локализацией поражений \
в сердечно-сосудистой системе, развитием суставного, кожного и неврологического синдромов, \
возникающее у детей 7—15 лет на фоне иммунного ответа организма на антигены бета-гемолитического \
стрептококка группы А и перекрёстную реактивность со схожими тканями организма человека.',
    },
    {
      name: 'Атеросклероз',
      fill: 20,
      description: 'Хроническое заболевание артерий эластического и мышечно-эластического типа, \
возникающее вследствие нарушения липидного и белкового обмена и сопровождающееся отложением холестерина \
и некоторых фракций липопротеидов в просвете сосудов. Отложения формируются в виде атероматозных бляшек. \
Последующее разрастание в них соединительной ткани (склероз), и кальциноз стенки сосуда приводят к деформации \
и сужению просвета вплоть до обтурации (закупорки сосуда).',
    },
  ]
}


export const card = {
  systemName: 'Сердечно-сосудистая система',
  overview: system.risks[2],

  factors: [
    'Повышеное потребление алкоголя',
    'Низкая физическая активность',
    'Повышеное потребление соли с пищей',
    'Повышеное уровень холестирина',
    'Стрессы',
  ],
  recommendations: [
    {
      title: 'Снизьте уровень потребление алкоголя до небольших доз (до 50 миллилитров).',
      description: 'Небольшие дозы помогают гипертонику расширить спазмированные сосуды, избавиться от головной боли, \
ощутить прилив сил. Алкоголь при гипертонии в излишнем количестве приводит к обратному эффекту – после расширения сосудов \
следует их резкий спазм, что провоцирует негативные последствия – возникает состояние похмелья.',
    },
    {
      title: 'Увеличьте физическую нагрузку.',
      description: 'Полезны ходьба (30 - 40 минут ежедневно быстрым шагом), плавание (2 раза в неделю), езда на велосипеде. \
Противопоказаны соревновательные виды спорта (волейбол, футбол, теннис), изометрические нагрузки (подъем тяжести), \
упражнения с наклонами туловища.',
    },
    {
      title: 'Снизьте количество потребляемой соли в день до нормы (не более 5 грамм)',
      description: 'Существует несомненная связь между количеством потребляемой соли и уровнем артериального давления. \
Натрий и вода накапливаются в стенке сосуда, утолщая ее, что вызывает рост периферического \
сопротивления сосудов и повышение артериального давления.',
    },
  ],
}

// =============================================================================

const problemsIds = ['infarct', 'insult', 'depression']

const problemsSummary = {
    infarct: {
        id: 'infarct',
        name: 'Инфаркт миокарда',
    },
    insult: {
        id: 'insult',
        name: 'Инсульт',
    },
    depression: {
        id: 'depression',
        name: 'Депрессия',
    },
}

const problemsDescriptionsOverviewPage = {
    infarct: 'Поражение сердечной мышцы, вызванное острым нарушением \
ее кровоснабжения из-за тромбоза (закупорки) одной из артерий ­сердца атеросклеротической бляшкой. \
При этом пораженная часть мышцы отмирает, то есть развивается ее некроз. \
Гибель клеток начинается через 20–40 минут с момента прекращения кровотока.',
    insult: 'Инсульт – острое нарушение мозгового кровообращение, \
которое приводит к повреждению и отмиранию нейронов головного мозга. \
При закупорке кровеносного сосуда тромбом возникает ишемический инсульт, а при разрыве сосуда – геморрагический. \
Ишемический инсульт происходит гораздо чаще, чем геморрагический.',
    depression: 'Психическое расстройство, основными признаками которого являются сниженное настроение \
и снижение или утрата способности получать удовольствие (ангедония). \
К дополнительным симптомам депрессии могут относиться сниженная самооценка, \
неадекватное чувство вины, пессимизм, нарушение концентрации, расстройства сна и аппетита и суицидальные тенденции. ',
}

const problemsPercent = { infarct: 100, insult: 80, depression: 60 }
export const problemsOverviewSample = problemsIds.map((id) => (
    {
        ...problemsSummary[id],
        percent: problemsPercent[id],
        description: problemsDescriptionsOverviewPage[id],
    }
))


const groupsSummary = [
    {
        title: 'Образ жизни',
        description: 'К этой группе относятся риски, связанные физической активностью, регулярностью, составом \
и качеством питания, потреблением никотина и алкоголя, наличием или отсутствием стрессовых ситуаций в жизни, \
уровнем материального благополучия.',
    },
    {
        title: 'Физиологические показатели',
        description: 'К этой группе относятся риски, связанные с физиологическими параметрами, такими как: \
излишний вес, повышенный уровень холестерина, паталогические параметры состава мочи и так далее.',
    },
]

const wayOfLivingGroupDetails = {
    factors: [
        'Повышеное потребление алкоголя',
        'Низкая физическая активность',
        'Повышеное потребление соли с пищей',
        'Повышеное уровень холестирина',
        'Стрессы',
    ],
    recommendations: [
        {
            title: 'Снизьте уровень потребление алкоголя до небольших доз (до 50 миллилитров).',
            description: '\
Небольшие дозы помогают гипертонику расширить спазмированные сосуды, избавиться от головной боли, ощутить прилив сил. \
Алкоголь при гипертонии в излишнем количестве приводит к обратному эффекту – после расширения сосудов следует \
их резкий спазм, что провоцирует негативные последствия – возникает состояние похмелья.',
        },
        {
            title: 'Увеличьте физическую нагрузку.',
            description: '\
Полезны ходьба (30 - 40 минут ежедневно быстрым шагом), плавание (2 раза в неделю), езда на велосипеде. \
Противопоказаны соревновательные виды спорта (волейбол, футбол, теннис), изометрические нагрузки (подъем тяжести), \
упражнения с наклонами туловища.',
        },
        {
            title: 'Снизьте количество потребляемой соли в день до нормы (не более 5 грамм)',
            description: '\
Существует несомненная связь между количеством потребляемой соли и уровнем артериального давления. \
Натрий и вода накапливаются в стенке сосуда, утолщая ее, что вызывает рост периферического \
сопротивления сосудов и повышение артериального давления.',
        },
    ],
}

const dummyGroupDetails = () => {
    const res = { factors: [], recommendations: [] }
    for (let i = 0; i < getRandom(3, 11); i++) {
        res.factors.push(
            getDummyText(5, 50)
        )
    }
    for (let i = 0; i < getRandom(2, 7); i++) {
        res.recommendations.push(
            { title: getDummyText(20, 100), description: getDummyText(200, 400) }
        )
    }
    return res
}

const infarctData = {
    ...problemsSummary['infarct'],
    description: 'Поражение сердечной мышцы, вызванное острым нарушением ее кровоснабжения из-за тромбоза (закупорки) \
одной из артерий ­сердца атеросклеротической бляшкой. Одна из клинических форм ишемической болезни сердца, \
протекающая с развитием ишемического некроза участка миокарда, обусловленного абсолютной или относительной \
недостаточностью его кровоснабжения.',
    groups: [
        {
            ...groupsSummary[0],
            percent: 60,
            details: wayOfLivingGroupDetails,
        },
        {
            ...groupsSummary[1],
            percent: 40,
            details: dummyGroupDetails(),
        },
    ],
}

const dummyGroups = () => (
    groupsSummary.map((obj) => ({
        ...obj,
        percent: getRandom(1, 100),
        details: dummyGroupDetails(),
    }))
)

export const problemsDataSample = {
    infarct: infarctData,
    insult: {
        ...problemsSummary['insult'],
        description: getDummyText(200, 500),
        groups: dummyGroups(),
    },
    depression: {
        ...problemsSummary['depression'],
        description: getDummyText(200, 500),
        groups: dummyGroups()
    },
}

const _historyDates = [moment().format('YYYY-MM-DD')]
for (let i = 0; i < getRandom(9, 14); i++) {
    const lastDate = moment(_historyDates[0])
    const newDate = lastDate.clone().subtract(getRandom(10, 100), 'days')
    _historyDates.unshift(newDate.format('YYYY-MM-DD'))
}
export const historyDatesSample = _historyDates

// =============================================================================

export const dropdown = [
    'Все проблемные зоны',
    '[[Длинный длинный длинный длинный пункт]',
    ...all.systems.map(system => (system.name)),
    'Желудочно-кишечный тракт',
]


export const calendarYear = [
    {
        events: ['антропометрия', 'артериального давление', 'клинический анализ крови', 'общий анализ мочи', 'флюрография легких'],
        total: 6,
    },
    {
        events: ['уровень холестирина', 'уровень глюкозы в крови', 'спирометрия'],
        total: 3,
    },
    {
        events: ['осмотр врачем терапевтом', 'индивидуальное профилактическое консультирование'],
        total: 2,
    },
    {
        events: ['анализ острой заболеваемости'],
        total: 1,
    },


    {
        events: ['электрокардиография', 'электрокардиография в покое'],
        total: 2,
    },
    {
        events: ['определение абсолютного сумарного сердечно-сосудистого риска'],
        total: 1,
    },
    {
        events: ['осмотр врачем терапевтом', 'индивидуальное профилактическое консультирование'],
        total: 2,
    },
    {
        events: [],
        total: 0,
    },


    {
        events: ['уровень холестирина', 'уровень глюкозы в крови, спирометрия'],
        total: 3,
    },
    {
        events: ['антропометрия', 'артериального давление', 'клинический анализ крови', 'общий анализ мочи', 'флюрография легких'],
        total: 6,
    },
    {
        events: ['осмотр врачем терапевтом', 'индивидуальное профилактическое консультирование'],
        total: 2,
    },
    {
        events: ['анализ острой заболеваемости'],
        total: 1,
    },
]


const calendarMonthSamples = [
    { events: ['Прием лекарств'], more: 3 },
    { events: ['Пробежка'] },
    { events: ['Анализ крови', 'пробжека'], more: 1 },
    { events: ['Спортзал'] },
    { events: ['Прием лекарств'], more: 1 },
    { events: ['Спортзал'] },
    { events: ['Пробежка', 'Пробежка', 'Пробежка'] },
]
let _calendarMonth = {}
for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 7; j++) {
        const k = (i * 7) + j + 2
        if (k > 31) break
        _calendarMonth[k] = calendarMonthSamples[j]
    }
}
export const calendarMonth = _calendarMonth


const eventsSamples = [
    {
        title: 'Определение относительного суммарного сердечно-сосудистого риска',
        comment: '(при отсутствии заболеваний, связанных с атеросклерозом)',
    },

    {
        title: 'Антропометрия',
        comment: '(измерение роста стоя, массы тела, окружности талии, расчет индекса массы тела)',
    },
    { title: 'Измерение артериального давления' },
    { title: 'Клинический анализ крови' },

    {
        title: 'Прием (осмотр) врачом-терапевтом, по завершении исследований первого\
этапа диспансеризации, проводимых с периодичностью 1 раз в 3 года'
    },

    { title: 'Проведение индивидуального или группового (школы для пациентов) углубленного профилактического консультирования' },
    { title: 'Флюорография легких' },
    { title: 'Определение уровня общего холестерина в крови' },
    { title: 'Определение уровня глюкозы в крови натощак' },
    { title: 'Индивидуальное профилактическое консультирование' },
    { title: 'Прием (осмотр) врачом-терапевтом в рамках второго этапа диспансеризации' },
]
export const eventsList = [
    {
        year: 2018,
        monthIx: 7-1, // июль
        byDay: [
            { day: 10, events: eventsSamples.slice(0, 1) },
            { day: 13, events: eventsSamples.slice(1, 4) },
            { day: 15, events: eventsSamples.slice(4, 5) },
        ],
        other: eventsSamples.slice(5),
    },
    {
        year: 2018,
        monthIx: 8-1, // август
        byDay: [
            { day: 4, events: eventsSamples.slice(0, 1) },
            { day: 13, events: eventsSamples.slice(1, 4) },
            { day: 21, events: eventsSamples.slice(4, 5) },
        ],
        other: eventsSamples.slice(5),
    },
]
export const eventsListRandom = (n=20) => {
    const arrs = [[], [], [], []];
    eventsSamples.slice(0, n).forEach((event) => {
        arrs[getRandom(0, 3)].push(event);
    });
    return {
        byDay: [
            ...(arrs[0].length ? [{ day: getRandom( 1, 10), events: arrs[0] }] : []),
            ...(arrs[1].length ? [{ day: getRandom(11, 20), events: arrs[1] }] : []),
            ...(arrs[2].length ? [{ day: getRandom(21, 30), events: arrs[2] }] : []),
        ],
        other: arrs[3].length ? arrs[3] : null,
    }
}


export const calendarYearPageRandom = () => {
    const fullData = []
    for (let i = 0; i < 12; i++) {
        fullData.push(eventsListRandom(getRandom(0, 11)))
    }
    const calendarData = fullData
        .map((monthData) => (
            [].concat(
                ...monthData.byDay.map(d => d.events),
                (monthData.other || []),
            )
                .map(e => e.title)
        ))
        .map((monthEvents) => ({
            events: monthEvents.slice(0, 5),
            total: monthEvents.length,
        }))
    return { fullData, calendarData }
}
