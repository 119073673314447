import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import { PollLegend } from "./poll-legend";
import { Route } from "react-router-dom";
import $ from "jquery";

export class StabilityForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=12;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log("'" + this.fieldsOnForm.join("','") + "'");
    }

    render() {
        var vals = [0, 1, 2, 3];
        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Ответьте, пожалуйста, на&nbsp;несколько вопросов
                    о&nbsp;себе. Выбирайте тот ответ, который наилучшим образом
                    отражает Ваше мнение. Здесь нет правильных или неправильных
                    ответов, так как важно только Ваше мнение. Просьба работать
                    в&nbsp;темпе, подолгу не&nbsp;задумываясь над ответами.
                    Работайте последовательно, не&nbsp;пропуская вопросов.
                </i>
                <div className="poll-header">

                    <h2 className="db cb">
                        Тест на выявление степени жизнестойкости
                    </h2>
                    <PollLegend
                        className="pt3"
                        opts={[
                            { key: "1", title: "Да" },
                            { key: "2", title: "Скорее да" },
                            { key: "3", title: "Скорее нет" },
                            { key: "4", title: "Нет" }
                        ]}
                        />
                </div>
                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
						<div className="q1">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_0")}
							>
								Иногда мне кажется, что никому нет до меня дела.
							</PollRadios>
						</div>
						<div className="q2">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_1")}
							>
								Мои мечты редко сбываются.
							</PollRadios>
						</div>
						<div className="q3">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_2")}
							>
								Порой мне кажется, что все мои усилия тщетны.
							</PollRadios>
						</div>
						<div className="q4">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_3")}
							>
								Иногда я чувствую себя лишним даже в кругу друзей.
							</PollRadios>
						</div>
						<div className="q5">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_4")}
							>
								Я часто сожалею о том, что уже сделано.
							</PollRadios>
						</div>
						<div className="q6">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_5")}
							>
								Возникающие проблемы часто кажутся мне
								неразрешимыми.
							</PollRadios>
						</div>
						<div className="q7">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_6")}
							>
								Мне кажется, я не живу полной жизнью, а только играю
								роль.
							</PollRadios>
						</div>
						<div className="q8">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_7")}
							>
								Иногда меня пугают мысли о будущем.
							</PollRadios>
						</div>
						<div className="q9">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_8")}
							>
								Бывает, на меня наваливается столько проблем, что
								просто руки опускаются.
							</PollRadios>
						</div>
						<div className="q10">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_9")}
							>
								Мне кажется, жизнь проходит мимо меня.
							</PollRadios>
						</div>
						<div className="q11">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("stability_10")}
							>
								Мне кажется, если бы в прошлом у меня было меньше
								разочарований и&nbsp;невзгод, мне было бы сейчас
								легче жить на свете.
							</PollRadios>
						</div>
						<div className="q12">
							<PollRadios
								opts={[3, 2, 1, 0]}
								titles={[4, 3, 2, 1]}
								{...this.baseEditProps("stability_11")}
							>
								Я всегда уверен, что смогу воплотить в жизнь то, что
								задумал.
							</PollRadios>
						</div>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default StabilityForm;
