import React from "react";
import cx from 'classnames';

import { RiskIndicator } from "../../shared/RiskIndicator";


export default function IndicatorBlock(props) {
    return (
        <div className={cx(props.className)}>
            <div className="col fl">
                <RiskIndicator fill={props.fill} />
            </div>
            <div className="col col-five col-last fl">
                {props.children}
            </div>
            <div className="cb" />
        </div>
    );
}
