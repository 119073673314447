import React from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import cx from 'classnames';

import "../../../css/comp/risk/risk-menu.css";
// import DevMenu from "./DevMenu";


export default function RiskMenu(props) {
    return (
        <div className="risk-menu">
            <Scrollbars
                renderThumbVertical={props => (<div {...props} className="scroll-thumb" />)}
            >

                <NavLink
                    to="/eng/risk/health"
                    activeClassName="open"
                    className={cx("risk-menu-item", "icon-cardiogram")}
                >
                    Риски заболеваний
                </NavLink>
                <NavLink
                    to="/eng/risk/events"
                    activeClassName="open"
                    className={cx("risk-menu-item", "icon-calendar")}
                >
                    Календарь мероприятий
                </NavLink>

                {/* {window.location.hostname === '127.0.0.1' && <DevMenu />} */}

            </Scrollbars>
        </div>
    );
}
