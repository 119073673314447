import React from "react";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class EventsForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "heavy_chronical_disease_family" && v.value < 2) {
            fields["heavy_chronical_disease_family_hz"] = "";
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidUpdate() {
        console.log(this.fieldsOnForm.join("','"));
    }
    componentDidMount() {}

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <div className="col col-four col-last mb-1-5">
                    <label>
                    With me recently (during the last year) the following happened:
                    </label>
                    {/* <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("FPILY")}
                    >
                        Обнаружена беременность
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("NPILY")}
                    >
                        Рождение ребенка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("WOILY")}
                    >
                        Потеря работы
                    </Check> */}
                    <Check
                        className="col col-three col-last"
                        {...this.baseEditProps("lost_men")}
                    >
                        Loss of a loved one
                    </Check>
                    {/* <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("rbchitly")}
                    >
                        Расставание с близким человеком
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pidgtpg")}
                    >
                        Переезд в другой город/страну
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pjitpg")}
                    >
                        Приобретение жилья
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("uurijvtpg")}
                    >
                        Ухудшение условий жизни и/или работы
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("viptpg")}
                    >
                        Выход на пенсию
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("nbvtpg")}
                    >
                        Незапланированная беременность{" "}
                        <i className="comment">(ваша или партнерши)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pkvtpg")}
                    >
                        Пережитая катастрофа
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("fnditpg")}
                    >
                        Физическое нападение{" "}
                        <i className="comment">(драка, ограбление и прочее)</i>
                    </Check> */}
                </div>
                <div className="col col-three fl mb-1-5">
                    <label>
                    Are you involved in caring for a sick person?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps(
                            "heavy_chronical_disease_family"
                        )}
                    >
                        <Radio value={0} className="col col-three col-last">
                            No
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                        I live in the same room                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                        I help in the treatment
                            <i className="comment db">
                            (I perform medical procedures, accompany visits to doctors and provide other assistance)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                        Helping to meet your needs
                            <i className="comment db">
                            (hygiene, feeding, walking)                            </i>
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["heavy_chronical_disease_family"], 0) >
                    1 && (
                    <div className="col col-three fl col-last mb-1-5 appear-left">
                        <label>
                            How often does a person need help?
                        </label>
                        <RadioGroup
                            {...this.baseEditProps(
                                "heavy_chronical_disease_family_hz"
                            )}
                        >
                            <Radio value={0} className="col col-two fl">
                                Everyday
                            </Radio>
                            <Radio value={1} className="col col-two fl">
                                Some times a week
                            </Radio>
                            <Radio
                                value={2}
                                className="col col-two fl col-last"
                            >
                                Some times a month 
                            </Radio>
                            <div className="cb" />
                        </RadioGroup>
                    </div>
                )}
                <div className="col col-four col-last mb-1-5">
                    <label>
                    Check out the events that happened to you in childhood and have a strong influence on your current life:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PEICYO")}
                    >
                        Physical abuse in childhood
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EEICYO")}
                    >
                        Emotional abuse in childhood
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("SEICYO")}
                    >
                        Child sexual abuse
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("TPICYO")}
                    >
                        Another traumatic event
                    </Check>
                </div>
                <div className="col col-six col-last mb-1-5">
                    <label>
                    Mark the events that happened to you in adulthood over a period of more than 1 year ago that have a strong influence on your current life:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("m_dead")}
                    >
                        Loss of a loved person / animal
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("RBPIOY")}
                    >
                        Parting with a loved one
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("Ctastroph_old")}
                    >
                        Experienced disaster or life threatening situation
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PEIOYY")}
                    >
                        Physical violence
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EEIOYY")}
                    >
                        Emotional abuse
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("SEIOYY")}
                    >
                        Sexual abuse
                    </Check>
                </div>
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/polls",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to section "Other questionnaires"
                            </a>
                        )}
                    />

                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default EventsForm;
