import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";

import { Route } from "react-router-dom";

export class SelfEfficiencyForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
        if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = [
            "−5",
            "−4",
            "−3",
            "−2",
            "−1",
            "0",
            "+1",
            "+2",
            "+3",
            "+4",
            "+5"
        ];
        return (
            <div className="appear-left">
                <h2 className="db cb">
                    Методика определения уровня самоэффективности
                </h2>
                <i className="comment db mb">
                    Здесь приводится одна из&nbsp;конкретных диагностических
                    методик, разработанных Маддуксом и&nbsp;Шеером, направленная
                    на&nbsp;количественное определение уровня самоэффективности.
                    Речь идет об&nbsp;оценке человеком своего потенциала
                    в&nbsp;сфере предметной деятельности и&nbsp;в&nbsp;сфере
                    общения, которым он&nbsp;реально может воспользоваться.
                </i>
                <i className="comment db mb-1-5">
                    Согласны ли вы с&nbsp;предлагаемыми утверждениями?
                    Если&nbsp;абсолютно согласны, отметьте значение «+5», если
                    абсолютно не&nbsp;согласны — значение «–5».
                    В&nbsp;зависимости от&nbsp;степени своего согласия или
                    несогласия с&nbsp;утверждениями используйте для&nbsp;ответа
                    промежуточные оценки шкалы в&nbsp;области положительных
                    или&nbsp;отрицательных значений, соответственно.
                </i>
                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_0")}
                        >
                            Когда я что-либо планирую, я всегда уверен(а), что
                            могу выполнить данную работу
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_1")}
                        >
                            Одна из моих проблем состоит в том, что я не могу
                            сразу взяться за работу, которую мне необходимо
                            выполнить, оттягивая этот момент до последнего
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_2")}
                        >
                            Если я не могу выполнить работу с первого раза, я
                            продолжаю попытки до тех пор, пока не справлюсь с
                            ней
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_3")}
                        >
                            Когда я ставлю важные для себя цели, мне редко
                            удается достичь их
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_4")}
                        >
                            Я часто бросаю дела, не закончив их
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_5")}
                        >
                            Я стараюсь избегать трудностей
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_6")}
                        >
                            Если что-то кажется мне слишком трудным, я не стану
                            даже пытаться выполнить это хоть как-нибудь
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_7")}
                        >
                            Если я делаю что-то крайне необходимое, но не
                            слишком приятное для меня, я все равно буду
                            упорствовать до тех пор, пока не доведу дело до
                            конца
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_8")}
                        >
                            Если я решил(а) что-то сделать, буду идти напролом,
                            до конца
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_9")}
                        >
                            Если мне не удается быстро выучить что-то новое,
                            сразу бросаю это дело
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_10")}
                        >
                            Когда проблемы возникают неожиданно, мне не удается
                            справиться с ними
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_11")}
                        >
                            Я не пытаюсь научиться чему-то новому, если оно
                            выглядит слишком сложным для меня
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_12")}
                        >
                            Неудачи не смущают меня, а только заставляют
                            предпринимать еще более настойчивые попытки
                            справиться с ситуацией
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_13")}
                        >
                            Я испытываю уверенность в своих силах при решении
                            сложных проблем
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_14")}
                        >
                            Я вполне уверен(а) в себе
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_15")}
                        >
                            Я легко бросаю дела
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_16")}
                        >
                            Я не похож(а) на человека, который легко справляется
                            с любыми проблемами в жизни
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_17")}
                        >
                            Мне трудно приобретать новых друзей
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_18")}
                        >
                            Если я встречаю человека, с которым мне было бы
                            приятно поговорить, иду к нему сам, не дожидаясь,
                            пока он подойдет ко мне
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_19")}
                        >
                            Если мне не удастся стать близким другом интересного
                            мне человека, я, скорее всего, прекращу попытки
                            общения с ним
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_20")}
                        >
                            Если я познакомился(-ась) с человеком, который на
                            первый взгляд кажется мне не слишком интересным, все
                            равно не прекращаю сразу общения с ним
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_21")}
                        >
                            Я не слишком уютно чувствую себя на собраниях, в
                            компаниях, в больших группах людей
                        </PollRadios>
                        <PollRadios
                            opts={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]}
                            titles={vals}
                            centered={true}
                            {...this.baseEditProps("Selfefficacy_22")}
                        >
                            Я приобрел(а) всех друзей благодаря своей
                            способности устанавливать контакты
                        </PollRadios>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Save answers
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SelfEfficiencyForm;
