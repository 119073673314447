import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../shared/button";
import RiskHeader from "../RiskHeader";
import IndicatorBlock from "./IndicatorBlock";
import HistoryDatePicker from "./HistoryDatePicker";

import { Api } from "../../../../libs/api";

export default class ProblemsOverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoaded: false,
            error: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
            this.setState({ isLoaded: false });
            this.fetchData();
        }
    }

    fetchData = () => {
        //Если каким-то образом нет ни одной оценки риска, не посылаем никаких запросов
        if (!this.props.selectedDate) {
            this.setState({ isLoaded: true, data: null });
            return;
        }
        //Получаем список рисков
        return Api.getRisksList(this.props.selectedDate)
            .then(res => {
                this.setState({
                    data: res.result,
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({ error: err });
            });
    };

    handleRisksRecalc = e => {
        this.setState({ isLoaded: false });
        //Вызываем пересчет 
        Api.recalcRisks().then(() => {
            if (this.props.onRecalc) {
                this.props.onRecalc().then(x => {
                    this.setState({ isLoaded: true });
                });
            }
        });
    };

    render() {
        const { match, historyDates, selectedDate, onSelectDate } = this.props;
        const { data, isLoaded, error } = this.state;
        
        let content = (
            <div className="form appear-left">
                {data &&
                    Object.values(data).sort((a, b) => b.rang - a.rang).map(problem => (
                        <IndicatorBlock
                            key={problem.id}
                            className="mb-1-5"
                            fill={problem.rang * 20}
                        >
                            <div>
                                <h2 className="pb-half">{problem.name}</h2>
                                {" ("}
                                <Link to={`${match.path}/${problem.id}`}>
                                    факторы риска и рекомендации
                                </Link>
                                {")"}
                            </div>
                            <i className="db">
                                {problem.description} Перейдя в данный раздел Вы
                                сможете подробно ознакомиться с выявленными у
                                Вас факторами риска и рекомендациями по их
                                снижению.
                            </i>
                        </IndicatorBlock>
                    ))}
                {!data && (
                    <i className="comment db mb">
                        Расчет рисков для Вас еще ни разу не проводился.
                        Заполните поля раздела «Информация о пользователе» и
                        нажмите на кнопку «Пересчитать риски».
                    </i>
                )}
                <h2>Пересчёт рисков</h2>
                <i className="comment db mb-1-5 cb">
                    Кнопка «Пересчитать риски» находится на этой странице
                    временно, пока не реализовано какое-то более системной
                    решение. Например, пересчет рисков по расписанию.
                </i>
                <Button
                    className={"fr" + (isLoaded ? "" : " btn-loading")}
                    onClick={this.handleRisksRecalc}
                >
                    Пересчитать риски
                </Button>
                <div className="cb" />
            </div>
        );

        if (error) {
            content = <FormError>{error}</FormError>;
        } else if (!isLoaded) {
            content = (
                <LoadingMessage>происходит загрузка данных</LoadingMessage>
            );
        }

        return (
            <React.Fragment>
                <RiskHeader title={"Проблемные зоны"}>
                    <HistoryDatePicker
                        selected={selectedDate}
                        items={historyDates}
                        onSelect={onSelectDate}
                    />
                </RiskHeader>
                <div className="container main-container">{content}</div>
            </React.Fragment>
        );
    }
}
class LoadingMessage extends React.PureComponent {
    render() {
        return (
            <div className="form appear-left">
                <h2>Подождите&hellip;</h2>{" "}
                <i className="comment">({this.props.children})</i>
            </div>
        );
    }
}

class FormError extends React.PureComponent {
    render() {
        return (
            <div className="form appear-left">
                <h2>Произошла ошибка</h2>
                <i className="comment db">{this.props.children}</i>
            </div>
        );
    }
}
