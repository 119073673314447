import React from "react";
import { Redirect, Route } from "react-router-dom";

import EditHeader from "../header";
import EditSide from "../side";
import "../../../css/pages/edit.css";
import { Api } from "../../../libs/api";

import * as Scroll from "react-scroll";
import { StabilityForm } from "./stability";
import PollsStates from "./polls-states";
import PollsAllForm from "./polls";
import SolitudeForm from "./solitude";
import DisfunctionalForm from "./disfunctional";
// import SleepingForm from "./sleeping";
import SocialProblemsForm from "./social";
import FinancialForm from "./financial";
// import AlcoholForm from "./alcohol";
import HADSForm from "./hads";
import SelfRelationsForm from "./self-relations";
import SelfEfficiencyForm from "./self-efficiency";
import NeoFfiForm from "./neoffi";
import MotivationForm from "./motivation";
import Modal from 'react-modal';

/*
TODO: Сохранение изменений
TODO: Обязательная очистка полей после getData
*/

class PollsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _loading: true
        };
    }
    defaultState = () => {
        return {
            _loading: false,
            _redirect: false,
            _error: null,
            _success: null
        };
    };
    setTitle = () => {
        var state = PollsStates.get(this.props.match.params.form);
        document.title = "Опросники : Изменение данных - Med.Proto";
        if (!state.noWindowTitle)
            document.title = state.title + ": " + document.title;
    };
    emptyPromise = () => {
        return new Promise((resolve, rejects) => {
            resolve({ status: 200, result: {} });
        });
    };
    /** Возвращает пустое состояние с незаполненными полями */
    emptyFields = () => {
        var state = PollsStates.get(this.props.match.params.form);
        var res = {};
        var fields = state.fields || [];
        var noSave = state.noSave || [];
        for (var i = 0; i < fields.length; i++) {
            if (noSave.indexOf(fields[i]) > -1) continue;
            res[state.fields[i]] = null;
        }
        return res;
    };
    /**
     * Проверяет, есть ли не сохраненные данные и осуществляет переход на др. страницу
     * @param {History} history История браузера
     * @param {string} to Адрес страницы перехода (относительный)
     * @param {Element} target Элемент, который вызвал переход, чтобы показть предупреждение в случае не сохраненных данных
     * @param {Boolean} isTop Если true, показывать предупреждение НАД элементом
     */
    // goTo = (history, to) => {
        // history.push(to);
    // };
    
    firstQuestionWithoutAnswer = () => {
        let poll_radios_groups = document.getElementsByClassName("poll-radios");
        let hasClass = (r, c) => ( (" " + r.className + " ").replace(/[\n\t]/g, " ").indexOf(c) > -1 );

        let questions_with_no_answer = Array.from(poll_radios_groups).filter(
            (g) => {
                let poll_radios = g.getElementsByClassName("poll-radio");
                return Array.from(poll_radios).some((r) => hasClass(r, "radio-unchecked")) === true; //PF (исп. checked === fale)
            }
        )

        if(questions_with_no_answer.length > 0){ // > 0 PF
            let index = Array.from(poll_radios_groups).indexOf(
                questions_with_no_answer[0]
            )
            return index;
        }

        return undefined;

    }

    pollIsEmpty = () => {
        let poll_radios_groups = document.getElementsByClassName("poll-radios");
        let hasClass = (r, c) => ( (" " + r.className + " ").replace(/[\n\t]/g, " ").indexOf(c) > -1 );

        let questions_with_no_answer = Array.from(poll_radios_groups).filter(
            (g) => {
                let poll_radios = g.getElementsByClassName("poll-radio");
                return Array.from(poll_radios).some((r) => hasClass(r, "radio-checked")) === false;
            }
        )
        
        return questions_with_no_answer.length === poll_radios_groups.length;
    }

    handleGoToConfirmYes = history => {
        this.clearGoTo();
        history.push(this.state.confirmTo);
    };
    handleGoToConfirmNo = () => {
        this.clearGoTo();
    };

    clearGoTo = () => {
        this.setState({ confirmGoTo: false, confirmTo: "" });
    };


    goTo = (history, to, target, isTop) => {
        let first_question_without_answer = this.firstQuestionWithoutAnswer();
        console.log(
            "goTo",
            first_question_without_answer
        )
        if (first_question_without_answer + 1 && !this.pollIsEmpty()) {
            let rect = target.getBoundingClientRect();
            this.setState({
                confirmTo: to,
                confirmGoTo: true,
                goToPos: { x: rect.x, y: rect.y + rect.height, isTop: false }
            });
            return;
        }
        this.clearGoTo();
        history.push(to);
    };
    /**
     * Получает данные с сервера и заполняет состояние
     * @param {string[]} fields Список идентификаторов полей
     * @param {Boolean} rewriteFields Если задан, очищает поля формы перед заполнением. В противном случае — обновляет только поданные поля
     * @param {Boolean} noConstraints Если true, не загружает ограничения к полям
     * @returns {Promise} Обещание выполнения /get_data, /get_fields и набора запросов для графиков или пустой, если для формы не заданы поля или "fields" не заполнен
     */
    getData = (fields, rewriteFields, noConstraints, graphs) => {
        let form = this.props.match.params.form;
        var state = PollsStates.get(form);

        var _fields = fields || state.fields;

        if (!_fields || !Api.config.showPollsAnswers)
            return this.emptyPromise();

        var promises = [Api.getData(_fields)];

        return Promise.all(promises).then(x => {
            let s = {};
            if (rewriteFields) s = this.emptyFields();

            s = { ...s, ...x[0].result };

            this.setState(s);
        });
    };

    getUserCard = () => {
        return Api.getProfileCard().then(x => {
            this.setState({ user: x.result });
        });
    };

    handleChange = v => {
        var toSet = {};
        for (var i in v.fields) {
            var field = v.fields[i];

            toSet[i] = field;
        }

        this.setState({
            ...toSet,
            changedFields: { ...this.state.changedFields, ...toSet }
        });
        return this;
    };

    getDataFromState = name => {

        var state = PollsStates.get(name);
        var fields = state.fields || [];
        var converters = state.converters || {};

        let data = {};
        var exclude = state.noSave || [];

        let notAll = false; //Флаг, проверяющий, заполнены ли все поля
        let emptyFields = [];

        //Для всех полей из списка полей на форме выбираем значения из состояния страницы
        for (let i = 0; i < fields.length; i++) {
            let f = fields[i];
            let v = this.state[f];
            if (v === null || v === undefined || v === "" || v === "_clear") {
                notAll = true;

                emptyFields.push(f);
                
                continue; //Исключаем пустые значения
            }
            if (exclude.indexOf(f) > -1) {
                notAll = true;
                continue; //Исключаем все значения в массиве noSave
            }

            let conv = converters[f] || state.allConverter; //Если задан конвертер, используем на данных
            v = data[f] = conv ? conv(v) : v;
        }
        
        let noAnswers = this.pollIsEmpty();

        return { notAll: notAll, data: data, emptyFields: emptyFields};
    };
    saveFields = (form, history, flag_risk) => {
        if (this.state._loading) return;

        let { notAll, noAnswers, data } = this.getDataFromState(form.form);

        console.log("toSave: ", notAll, data);

        if (notAll && !noAnswers) {
            this.setState({
                ...this.defaultState(),
                _loading: false,
                _error: "Обязательно заполните все поля!",
                modalIsOpen: true
            });
            return;
        }

        this.setState({ ...this.defaultState(), _loading: true });

        //Сохраняем изменившиеся свойства через API
        Api.setData(data, "questionnaire", form.form)
            .then(x => {
                this.setState({
                    ...this.defaultState(),
                    _success: "Информация успешно сохранена!"
                });
				
				if(parseInt(flag_risk) == 3){
					history.push("/risk/health");
				}else{
					history.push("/edit/polls");
				}
            })
            .catch(x => {
                this.setState({
                    ...this.defaultState(),
                    _error: x.message
                });
            });
    };
    componentDidUpdate(prev) {
        this.setTitle();

        if (this.props.match.params.form !== prev.match.params.form) {
            this.setState({ _loading: true });
            Promise.all([this.getData(null, true), this.getUserCard()]).then(
                () => {
                    this.setState(this.defaultState());
                }
            );
        }

        //Загружаем новые данные, только если изменилась форма
        if (this.props.match.params.form !== prev.match.params.form) {
            Scroll.animateScroll.scrollTo(0, { duration: 300 });
        }
    }
    toAuth = () => {
        this.setState({ _redirect: <Redirect to="/auth" /> });
    };
    componentDidMount() {
        this.setTitle();
        this.setState({ _loading: true });
        Promise.all([this.getData(null, true), this.getUserCard()]).then(() => {
            this.setState(this.defaultState());
        });
        //.catch(this.toAuth);
        Scroll.animateScroll.scrollTo(0, { duration: 300 });
    }
    getFormComponent = (form, props) => {
        switch (form) {
            case "all":
                return <PollsAllForm {...props} />;
            case "stability":
                return <StabilityForm {...props} />;
            // case "lonliness":
            //     return <LonlinessForm {...props} />;
            case "solitude":
                return <SolitudeForm {...props} />;
            case "economic":
                return <FinancialForm {...props} />;
            case "disfunctional":
                return <DisfunctionalForm {...props} />;
            // case "sleeping":
            //     return <SleepingForm {...props} />;
            case "social":
                return <SocialProblemsForm {...props} />;
            // case "alcohol":
            //     return <AlcoholForm {...props} />;
            case "hads":
                return <HADSForm {...props} />;
            case "selfrelation":
                return <SelfRelationsForm {...props} />;
            case "selfefficacy":
                return <SelfEfficiencyForm {...props} />;

            case "neoffi":
                return <NeoFfiForm {...props} />;
				
            case "motivation":
				//alert(props);
                return <MotivationForm {...props} />;

            default:
                return null;
        }
    };

    closeErrorMessageDialog = () => {
        this.setState({
            modalIsOpen: false
        })
    }

    scrollTop = () => {
        Scroll.animateScroll.scrollTo(0, { duration: 300 });
    };
    render() {

        if (this.state._redirect)
            return <React.Fragment>{this.state._redirect}</React.Fragment>;

        let form = this.props.match.params.form;
        var state = PollsStates.get(form);

        var props = {
            onChange: this.handleChange,
            form: state,
            fields: this.state,
            loading: this.state._loading,
            onSave: this.saveFields
        };

        var back =
            form === "all"
                ? null
                : {
                      to: "/edit/polls/all",
                      title: "Вернуться в раздел «Опросники»"
                  };

        let content = this.getFormComponent(form, props);

        return (
            <div className={"edit" + (this.state._loading ? " loading" : "")}>
                <div onClick={this.scrollTop} className="fixed-btn scroll-up" />
                <EditHeader 
                    back={back}
                    form={state}
                    goTo={this.goTo}
                />
                <EditSide
                    form={{ ...state, ...{ form: "polls" } }}
                    user={this.state.user}
                    goTo={this.goTo}
                />
                <div className="container main-container">
                    <div className="form">{content}</div>
                </div>
                {this.state.confirmGoTo && (
                    <Route
                        render={({ history }) => (
                            <div
                                className={
                                    "go-to-confirm" +
                                    (this.state.goToPos.isTop ? " is-top" : "")
                                }
                                style={{
                                    top: this.state.goToPos.y + "px",
                                    left: this.state.goToPos.x + "px"
                                }}
                            >
                                На форме остались не сохраненные данные, вы
                                уверены, что хотите перейти в другой раздел?
                                <div className="btns mt-half">
                                    <button
                                        onClick={() =>
                                            this.handleGoToConfirmYes(history)
                                        }
                                        className="mr-half"
                                    >
                                        Да
                                    </button>{" "}
                                    <button
                                        onClick={() =>
                                            this.handleGoToConfirmNo(history)
                                        }
                                    >
                                        Нет
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                )}
				
				<ErrorMessageDialog
					title="Ошибка"
					getNumber={this.firstQuestionWithoutAnswer}
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeErrorMessageDialog}
				/>
				
            </div>
        );
    }
}

export default PollsLayout;

class ErrorMessageDialog extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }
    }

    render(){

        let number = this.props.getNumber()

        return             <Modal
            isOpen          ={this.props.isOpen}
            onRequestClose  ={this.props.onRequestClose}
            style           ={customStyles} 
        >            
            
            <div 
            style={{
                display     : 'absolute',
                width       : '100%',
                height      : '100%',
                background  : '#f0f0f0'
            }}
            >
            
            <h2
            className="db cb"
            style={{marginBottom  : '20px'}}
            >
                {this.props.title}
            </h2>

            <div style={{
                display         : 'flex',
                flexDirection   : 'row'
                }}>
            </div>

                <div className="db cb">
                    Пожалуйста, заполните вопрос под номером {number + 1}
                </div>

                <button 
                    style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '20px'
                    }}
                    onClick={this.props.onRequestClose}
                >Ок</button>
            </div>

        </Modal>
    }
}

const customStyles = {
    overlay :{
        //fix for popups
        zIndex: 9999
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      background            : '#f0f0f0',
      paddingTop            : '20px',
      //
      display               : 'flex',
      flexDirection         : 'column',
      overflow              : 'inherit'
    }
};