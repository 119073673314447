import React from "react";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { Check } from "../shared/CustomCheck";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class EventsForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "heavy_chronical_disease_family" && v.value < 2) {
            fields["heavy_chronical_disease_family_hz"] = "";
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidUpdate() {
        console.log(this.fieldsOnForm.join("','"));
    }
    componentDidMount() {}

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Со мною недавно{" "}
                        <i className="comment">(в течение последнего года)</i>{" "}
                        произошло следующее:
                    </label>
                    {/* <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("FPILY")}
                    >
                        Обнаружена беременность
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("NPILY")}
                    >
                        Рождение ребенка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("WOILY")}
                    >
                        Потеря работы
                    </Check> */}
                    <Check
                        className="col col-three col-last"
                        {...this.baseEditProps("lost_men")}
                    >
                        Потеря близкого человека
                    </Check>
                    {/* <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("rbchitly")}
                    >
                        Расставание с близким человеком
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pidgtpg")}
                    >
                        Переезд в другой город/страну
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pjitpg")}
                    >
                        Приобретение жилья
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("uurijvtpg")}
                    >
                        Ухудшение условий жизни и/или работы
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("viptpg")}
                    >
                        Выход на пенсию
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("nbvtpg")}
                    >
                        Незапланированная беременность{" "}
                        <i className="comment">(ваша или партнерши)</i>
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("pkvtpg")}
                    >
                        Пережитая катастрофа
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("fnditpg")}
                    >
                        Физическое нападение{" "}
                        <i className="comment">(драка, ограбление и прочее)</i>
                    </Check> */}
                </div>
                {/* PF - убрать фигурные скобки при раскомментировании
                <div className="col col-three fl mb-1-5">
                    <label>
                        Участвуете ли Вы в уходе за больным человеком?
                    </label>
                    <RadioGroup
                        {...this.baseEditProps(
                            "heavy_chronical_disease_family"
                        )}
                    >
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Проживаю в одном помещении
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Помогаю в лечении
                            <i className="comment db">
                                (выполняю медицинские процедуры, сопровождаю в
                                визитах к врачам и оказываю другую помощь)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-three col-last">
                            Помогаю в удовлетворении потребностей
                            <i className="comment db">
                                (гигиена, кормление, прогулки)
                            </i>
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["heavy_chronical_disease_family"], 0) >
                    1 && (
                    <div className="col col-three fl col-last mb-1-5 appear-left">
                        <label>
                            Как часто больному человеку необходима помощь:
                        </label>
                        <RadioGroup
                            {...this.baseEditProps(
                                "heavy_chronical_disease_family_hz"
                            )}
                        >
                            <Radio value={0} className="col col-two fl">
                                Ежедневно
                            </Radio>
                            <Radio value={1} className="col col-two fl">
                                Несколько раз в неделю
                            </Radio>
                            <Radio
                                value={2}
                                className="col col-two fl col-last"
                            >
                                Несколько раз в месяц
                            </Radio>
                            <div className="cb" />
                        </RadioGroup>
                    </div>
                )}*/
                }
                <div className="col col-four col-last mb-1-5">
                    <label>
                        Отметьте события, которые случились с вами в детском
                        возрасте и оказывают сильное влияние на вашу сегодняшнюю
                        жизнь:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PEICYO")}
                    >
                        Физическое насилие в детском возрасте
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EEICYO")}
                    >
                        Эмоциональное насилие в детском возрасте
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("SEICYO")}
                    >
                        Сексуальное насилие в детском возрасте
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("TPICYO")}
                    >
                        Другое травматическое событие
                    </Check>
                </div>
                <div className="col col-six col-last mb-1-5">
                    <label>
                        Отметьте события, которые случились с вами во взрослом
                        возрасте в период более 1 года назад, оказывающие
                        сильное влияние на вашу сегодняшнюю жизнь:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("m_dead")}
                    >
                        Потеря близкого человека/животного
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("RBPIOY")}
                    >
                        Расставание с близким человеком
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("Ctastroph_old")}
                    >
                        Пережитая катастрофа или ситуация угрозы для жизни
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PEIOYY")}
                    >
                        Физическое насилие
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("EEIOYY")}
                    >
                        Эмоциальное насилие
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("SEIOYY")}
                    >
                        Сексуальное насилие
                    </Check>
                </div>
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/polls",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Опросники»
                            </a>
                        )}
                    />

                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default EventsForm;
