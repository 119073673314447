import React from "react";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';

import Button from "../../shared/button";
import RiskHeader from "../RiskHeader";
import CalendarMonth from "./CalendarMonth";
import Dropdown from "../Dropdown";
import DateChanger from "../DateChanger";
import * as sampleprops from '../sampleprops';

import "../../../../css/comp/risk/calendar-month-page.css";


export default class CalendarMonthPage extends React.Component {
    render() {
        const { match } = this.props
        const date = moment(match.params.date, 'YYYY-MM')

        if (!date.isValid()) {
            return (<Redirect to={`/eng/risk/events/calendar`} />)
        }

        return (
            <React.Fragment>
                <RiskHeader title={"Календарь мероприятий"}>
                    <DateChanger
                        date={date}
                        toPrev={`/eng/risk/events/calendar/${date.clone().add(-1, 'months').format('YYYY-MM')}`}
                        toNext={`/eng/risk/events/calendar/${date.clone().add(1, 'months').format('YYYY-MM')}`}
                    />
                </RiskHeader>

                <div className="container main-container">
                    <div className="form appear-left">

                        <div className="mb-1-5">
                            <Link
                                to={`/eng/risk/events/list`}
                                className="btn-square btn fl mr-1-5"
                            >
                                <span className="icon menu" />
                            </Link>

                            <Dropdown
                                className="fl"
                                items={sampleprops.dropdown}
                                default={sampleprops.dropdown[0]}
                            />

                            {/* <Button className="fr">Выгрузить на устройство</Button> */}
                            <div className="cb" />
                        </div>

                        <CalendarMonth
                            data={sampleprops.calendarMonth}
                            date={date}
                        />

                    </div>
                </div>
            </React.Fragment>
        );

    }
}
