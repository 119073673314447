import React from "react";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { Check } from "../shared/CustomCheck";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class FoodForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidMount() {}

    render() {
        var F = React.Fragment;
        return (
            <div className="appear-left">
                <h2 className="db cb">
                    Среднее потребление овощей и фруктов в день{" "}
                    <i className="comment">(всех, кроме консервированных)</i>
                </h2>
                <div className="col col-three fl mb-1-5">
                    <label>Фрукты:</label>
                    <RadioGroup {...this.baseEditProps("fruiit_less")}>
                        <Radio value={0} className="col col-three col-last">
                            Менее 200 грамм
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Более 200 грамм
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-three fl col-last mb-1-5">
                    <label>Овощи:</label>
                    <RadioGroup {...this.baseEditProps("vegan_less")}>
                        <Radio value={0} className="col col-three col-last">
                            Менее 200 грамм
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Более 200 грамм
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="db" />
                <div className="col col-three col-last mb-1-5">
                    <label>Оцените Ваше питание:</label>
                    <RadioGroup {...this.baseEditProps("food_style")}>
                        <Radio value={0} className="col col-three col-last">
                            Придерживаюсь сбалансированного питания
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Предпочитаю жирную пищу
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Предпочитаю высокоуглеводную пищу
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four fl mb-1-5">
                    <label>Как регулярно Вы принимаете пищу?</label>
                    <RadioGroup {...this.baseEditProps("freq_eat")}>
                        <Radio value={1} className="col col-four col-last">
                            1-2 раза в день
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            3 раза в день и чаще
                        </Radio>
                    </RadioGroup>
                </div>
				<div className="col col-three col-last mb-1-5 appear-up">
					<label>
						Как много соли Вы потребляете в день?
					</label>
					<RadioGroup {...this.baseEditProps("food_salt")}>
						<Radio
							value={0}
							className="col col-three col-last"
						>
							Менее 5 грамм
						</Radio>
						<Radio
							value={1}
							className="col col-three col-last"
						>
							5 грамм и более
						</Radio>
					</RadioGroup>
				</div>
				
				
                <div className="col col-two fl col-last mb-1-5">
                    <div
                        className="info-baloon mt-label mb"
                        style={{ marginTop: "-16px" }}
                    >
                        Более подробные данные о характере потребляемой пищи вы
                        можете внести, ответив на&nbsp;дополнительные вопросы
                    </div>
                    <Button
                        onClick={() => {
                            this.setState({ otherShown: true });
                        }}
                        className="col col-two col-last"
                    >
                        Показать все вопросы
                    </Button>
                </div>
                <div className="cb" />
                {this.state.otherShown && (
                    <F>
                        <div className="col col-four col-last mb-1-5 appear-up">
                            <label>
                                Выберите утверждения, которые характеризуют
                                принимаемую Вами пищу:
                            </label>
                            <Check
                                {...this.baseEditProps("D_less", "0")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                витамина D
                            </Check>
                            <Check
                                {...this.baseEditProps("B6_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                витамина B6
                            </Check>
                            <Check
                                {...this.baseEditProps("B12_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                витамина B12
                            </Check>
                            <Check
                                {...this.baseEditProps("fa_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                фолиевой кислоты
                            </Check>
                            <Check
                                {...this.baseEditProps("se_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                селена
                            </Check>
                            <Check
                                {...this.baseEditProps("fr_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                железа
                            </Check>
                            <Check
                                {...this.baseEditProps("ca_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                кальция
                            </Check>
                            <Check
                                {...this.baseEditProps("ma_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                магния
                            </Check>
                            <Check
                                {...this.baseEditProps("zn_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                цинка
                            </Check>
                            <Check
                                {...this.baseEditProps("tr_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                триптофана
                            </Check>
                            <Check
                                {...this.baseEditProps("complex_carbs_less")}
                                className="col col-four col-last"
                            >
                                Пища с низким содержанием (или отсутствием)
                                сложных углеводов
                            </Check>
                            <Check
                                {...this.baseEditProps("simple_carbs_high")}
                                className="col col-four col-last"
                            >
                                Пища с высоким содержанием (избытком) простых
                                углеводов
                            </Check>
                            <Check
                                {...this.baseEditProps("fatty_acids_high")}
                                className="col col-four col-last"
                            >
                                Пища с высоким содержанием (избытком) жирных
                                кислот Омега-6
                            </Check>
                        </div>
                    </F>
                )}
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/physiology",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Физиология»
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default FoodForm;
