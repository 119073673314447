import moment from "moment";

var EditStates = (() => {
    var _ns = {
        def: {
            side: {
                profileAnchor: "О себе",
                logoutAnchor: "Выйти"
            }
        },
        confirms: {
            upper: (n, v) => {
                if (n >= v) {
                    return "Введенное значение выше максимально допуcтимого.";
                }
                return false;
            },
            lower: (n, v) => {
                if (n <= v) {
                    return "Введенное значение ниже минимально допуcтимого.";
                }
                return false;
            }
        },
        warnings: {
            upper: (n, v) => {
                if (n >= v) {
                    return "Значение существенно выше нормы. Советуем обратиться к врачу";
                }
                return false;
            }
        },
        states: {
            test: {
                title: "Описание методов для раздела «Проблемные зоны»"
            },
            devices: {
                title: "Учётные записи и данные"
            },
            profile: {
                title: "О себе",
                comment: "(основные характеристики, информация профиля)",
                fields: [
                    "avatar",
                    "familyName",
                    "givenName",
                    "middleName",
                    "birthdate",
                    "gender",
                    "height",
                    "weight",
                    "familyStatus",
                    "locations",
                    "numberPhone",
                    "men40",
                    "username"
                ],
                converters: {
                    height: n => Number(n),
                    weight: n => Number(n)
                },
                noSave: ["avatar"]
            },
            illnesses: {
                title: "Болезни и семейный анамнез",
                comment: "",
                converters: {
                    ads_upper_usual: n => Number(n),
                    ads_down_usual: n => Number(n)
                },
                fields: [
                    "gender",
                    "hypertonic",
                    "Isolated_systolic_hypertension",
                    "hypoton",
                    "diabetes",
                    "diabetes_form",
                    "merc_aritm",
                    "headache_frequency",
                    "headache_force",
                    "head_pain",
                    "headache_meds",
                    "migren",
                    "HronPain",
                    "BPG",
                    "dizziness_duration",
                    "loss_sight",
                    "loss_sight_d",
                    "memory_loss",
                    "awkward_mov",
                    "awkward_mov_d",
                    "increase_fatigue",
                    "homocist",
                    "homocist_cause",
                    "vaskul",
                    "antifospho",
                    "GipaC",
                    "System_volk",
                    "sys_sclero",
                    "uzel_peritanit",
                    "f_cerebro_artereet",
                    "fybro_displazia_sosud",
                    "ritino",
                    "insult_hs",
                    "infarkt_hs",
                    "aterscker",
                    "crirt_coronal_sind_anam",
                    "spondelt",
                    "revm_art",
                    "artrit",
                    "apnoe",
                    "importent",
                    "hantington",
                    "parkinson",
                    "icenkushinga",
                    "addisona",
                    "ras_sclerosis",
                    "akne",
                    "coronal_rev",
                    "bad_cancer",
                    "ren_sick",
                    "ren_stad",
                    "schit_jel",
                    "schit_jel_type",
                    "fam_hyp_hol",
                    "fam_hyp_hol_type",
                    "psoriaz",
                    "periodontit",
                    "steno",
                    "noooooo",
                    "pach",
                    "OTJ",
                    "BISPN",
                    "BDSP",
                    "PoSR",
                    "TrevR",
                    "EDIP",
                    "snot_year",
                    "painkiller_yer",
                    "sedat_year",
                    "gipotenz_year",
                    "ster_year",
                    "interferon_year",
                    "Bblock_year",
                    "izoto_year",
                    "UOGY",
                    "UPSPY",
                    "UAPPY",
                    "UGPY",
                    "UHPY",
                    "himka",
                    "LuchTer",
                    "depression_family",
                    "sas1",
                    "sas2",
                    "sas3",
                    "sas4",
                    "ishas1",
                    "ishas2",
                    "ishas3",
                    "ishas4",
                    "infas1",
                    "infas2",
                    "infas3",
                    "infas4",
                    "ads_upper_usual",
                    "ads_down_usual",
                    "antimigren_year",
					"fhag1"
                ]
            },
            lifestyle: {
                title: "Образ жизни",
                comment: "",
                fields: [
                    "bad_sleep",
                    "sleepless",
                    "smoking_status",
                    "smoking",
                    "cigaretts_per_day",
                    "alco_now",
                    "alco",
                    "move_activ",
                    "move_problem",
                    "move_duration",
                    "social_net",
                    "IDLL3H",
                    "HMLN",
                    "hight_job_perdomance",
                    "activity_status",
					"work_night_daily",
					"work_noise_strain"

                ]
            },
            food: {
                title: "Питание",
                comment: "",
                fields: [
                    "fruiit_less",
                    "vegan_less",
                    "food_style",
                    "freq_eat",
                    "food_salt",
                    "D_less",
                    "B6_less",
                    "B12_less",
                    "fa_less",
                    "se_less",
                    "fr_less",
                    "ca_less",
                    "ma_less",
                    "zn_less",
                    "tr_less",
                    "complex_carbs_less",
                    "simple_carbs_high",
                    "fatty_acids_high"
                ]
            },
            physiology: {
                title: "Физиология",
                comment: "(динамические характеристики)",
                fields: [
                    "pulse_person",
                    "ads_upper_now",
                    "ads_down_now",
                    "height",
                    "weight",
                    "OBcm",
                    "OTcm",
                    "ads_upper_usual",
                    "ads_down_usual",
                    "ad_differ"
                ],
                graphs: {
                    pulse_person: () => moment().add(-3, "month"),
                    ads_upper_now: () => moment().add(-3, "month"),
                    ads_down_now: () => moment().add(-3, "month"),
                    weight: () => moment().add(-3, "month"),
                    OBcm: () => moment().add(-3, "month"),
                    OTcm: () => moment().add(-3, "month")
                },
                converters: {
                    pulse_person: n => Number(n),
                    ads_upper_now: n => Number(n),
                    ads_down_now: n => Number(n),
                    weight: n => Number(n),
                    OBcm: n => Number(n),
                    OTcm: n => Number(n),
                    height: n=> Number(n)
                },
                confirms: {
                    OTcm: n => _ns.confirms.lower(n, 40) || _ns.confirms.upper(n, 250),
                    OBcm: n => _ns.confirms.lower(n, 40) || _ns.confirms.upper(n, 250),
                    ads_upper_now: (n, s) => n < s["ads_down_now"] ? "Верхнее давление не может быть ниже нижнего." : _ns.confirms.lower(n, 70) || _ns.confirms.upper(n, 250),
                    ads_down_now: (n, s) => n > s["ads_upper_now"] ? "Нижнее давление не может быть выше верхнего." :  _ns.confirms.lower(n, 40) || _ns.confirms.upper(n, 120),
                    pulse_person: n => _ns.confirms.lower(n, 25) || _ns.confirms.upper(n, 300),
                    weight: n => _ns.confirms.lower(n, 33) || _ns.confirms.upper(n, 599)
                },
                warnings: {
                    ads_upper_now: n => _ns.warnings.upper(n, 170),
                    ads_down_now: n => _ns.warnings.upper(n, 100)
                }
            },
            lab: {
                title: "Лабораторные и функциональные исследования",
                comment: "",
                fields: [
                    "citokeens",
                    "TotalHol",
                    "hol_lpnp",
                    "hol_lpvp",
                    "triglycerides",
                    "blood_clqotting",
                    "blood_drug",
                    "mgas1",
                    "mgas2",
                    "mgas3",
                    "mgas4",
                    "mgas5",
                    "mgas6",
                    "mgas7",
                    "mgas8",
                    "mgas9",
                    "mgas10",
                    "mgas11",
                    "mgas12",
                    "mgas13",
                    "creatinin20",
                    "albumin",
                    "albumin_day",
                    "speed_club_filtrac",
                    "PHTLJ",
                    "PQQSEKG",
                    "bllpg",
                    "lpi",
                    "Head_neck_reserach",
                    "slart70",
                    "hordart70",
                    "anevrheads"
                ],
                converters: {
                    TotalHol: v => Number(v),
                    hol_lpnp: v => Number(v),
                    hol_lpvp: v => Number(v),
                    triglycerides: v => Number(v),
                    creatinin20: v => Number(v),
                    blood_clqotting: v => Number(v),
                    albumin: v => Number(v),
                    albumin_day: v => Number(v),
                    speed_club_filtrac: v => Number(v),
                    lpi: v => Number(v)
                }
            },
            social: {
                title: "Среда и социальный статус",
                comment: "",
                fields: [
                    "health_status",
                    "health_status_equal",
                    "education",
                    "education_date",
                    "moneylvl",
                    "stress",
                    "alone_fils",
                    "relgion"
                ],
                converters: {
                    stress: v => Math.round(v)
                }
            },
            events: {
                title: "События жизни",
                comment: "",
                fields: [
                    "lost_men",
                    "heavy_chronical_disease_family",
                    "heavy_chronical_disease_family_hz",
                    "PEICYO",
                    "EEICYO",
                    "SEICYO",
                    "TPICYO",
                    "m_dead",
                    "RBPIOY",
                    "Ctastroph_old",
                    "PEIOYY",
                    "EEIOYY",
                    "SEIOYY"
                ]
            }
        },
        /**
         * Получает состояние по умолчанию для Layout в разделе /edit/:form
         * @param {string} form - Имя формы, например "profile"
         * @return {object} Состояние Layout
         */
        get: form => {
            var def = _ns.def;
            def.form = form;

            return {
                ...def,
                ...(_ns.states[form] || {})
            };
        }
    };
    return _ns;
})();

export default EditStates;
