export const Stubs = (() => {
    var _ns = {
        risks: {
            infarct: {
                rang: 4,
                id: "infarct",
                name: "Инфаркт миокарда",
                description:
                    "В данном разделе представлены Ваши факторы риска, указывающие на возможные механизмы возникновения инфаркта миокарда, которые были выявлены при анализе Вашей анкеты."
            },
            insult: {
                rang: 3,
                id: "insult",
                name: "Инсульт",
                description:
                    "В данном разделе представлены Ваши факторы риска, указывающие на возможные механизмы возникновения инсульта, которые были выявлены при анализе Вашей анкеты."
            },
            depression: {
                rang: 4,
                id: "depression",
                name: "Депрессия 111",
                description:
                    "В данном разделе представлены Ваши факторы риска, указывающие на возможные механизмы возникновения депрессии, которые были выявлены при анализе Вашей анкеты и психологических тестов."
            }
        },
        risk_dynamic: () => {
            return {
                status: 200,
                result: {
                    id: "insult",
                    actual_risks: [
                        {
                            date: "2018-10-25",
                            values: [
                                {
                                    id: "1",
                                    name: "Очень высокий риск инсульта",
                                    rang: 4
                                },
                                {
                                    id: "2",
                                    name: "Высокий риск инсульта",
                                    rang: 3
                                }
                            ]
                        },
                        {
                            date: "2018-10-26",
                            values: [
                                {
                                    id: "2",
                                    name: "Высокий риск инсульта",
                                    rang: 3
                                }
                            ]
                        }
                    ],
                    trends: [
                        {
                            id: "2",
                            values: [
                                {
                                    date: "2018-10-25",
                                    values: {
                                        up: 3,
                                        avg: 2,
                                        bottom: 1
                                    }
                                },
                                {
                                    date: "2018-10-26",
                                    values: {
                                        up: 3,
                                        avg: 2,
                                        bottom: 2
                                    }
                                }
                            ]
                        },
                        {
                            id: "1",
                            values: [
                                {
                                    date: "2018-10-25",
                                    values: {
                                        up: 4,
                                        avg: 3,
                                        bottom: 3
                                    }
                                },
                                {
                                    date: "2018-10-26",
                                    values: {
                                        up: 3,
                                        avg: 3,
                                        bottom: 1
                                    }
                                }
                            ]
                        }
                    ]
                }
            };
        },
        prob_all: () => {
            return {
                status: 200,
                result: _ns.risks
            };
        },
        prob_history: () => {
            return {
                status: 200,
                result: ["2018-05-01", "2018-08-03", "2018-11-01"]
            };
        },
        prob_risk: pars => {
            var data = { status: 404, message: "Нет такого риска" };
            var risk = _ns.risks[pars.risk];
            if (risk) {
                data = {
                    status: 200,
                    result: {
                        ...risk,
                        risks: [
                            {
                                id: "1",
                                name: "Очень высокий риск инсульта",
                                description: "",
                                rang: 4,
                                isApproved: true,
                                factors: [
                                    {
                                        id: "1",
                                        name: "Синдром CADASIL"
                                    }
                                ]
                            },
                            {
                                id: "2",
                                name: "Высокий риск инсульта",
                                description: "",
                                rang: 3,
                                isApproved: true,
                                factors: [
                                    {
                                        id: "2",
                                        name:
                                            "Повышенный уровень липопротеина-а и протеина Апо-Е"
                                    },
                                    {
                                        id: "3",
                                        name:
                                            "Гомоцистеинемия как следствие генетических нарушений"
                                    },
                                    {
                                        id: "4",
                                        name:
                                            "Снижение уровня активных антитромботических протеинов"
                                    }
                                ]
                            }
                        ],
                        recs: [
                            {
                                id: "1",
                                name: "Придерживаться здорового питания",
                                description:
                                    "<p>Здоровое питание подразумевает потребление следующих продуктов в большом количестве: оливковое масло, орехи, фрукты, овощи, злаки и молочные продукты с низким содержанием жира. </p><p>Здоровое питание подразумевает потребление следующих продуктов в умеренном количестве: рыба, птица, горький шоколад. </p><p>Здоровое питание подразумевает потребление следующих продуктов в минимальном количестве: красное мясо и мясопродукты, сладости, поваренная соль.</p>",
                                explanation: `
<p>
    Последнее время особое внимание стали уделять особенностям питания, которые могут выступать и как ФР,
    и как факторы антириска развития сосудистых заболеваний мозга и сердца. Доказано, что питание оказывает влияние на такие ФР, как:
</p>
<ul>
    <li>уровень АД;</li>
    <li>содержание липидов крови;</li>
    <li>процессы тромбоза и коагуляции;</li>
    <li>содержание глюкозы крови;</li>
    <li>эндотелиальную функцию;</li>
    <li>кишечный микробиом;</li>
    <li>массу тела.</li>
</ul>
<h3>Среднеземноморская диета (СЗД) – диета DASH<br>(Mediterranean Dietary Approaches to Stop Hypertension)</h3>
<p>
    Большое количество исследований (метаанализ 13 обсервационных исследований и 1 рандомизированного контролируемого испытания)
    продемонстрировали, что соблюдение СЗД снижает риск развития инсульта на 30%. СЗД характеризуется употреблением:
</p>
<ul>
    <li><i>большого количества:</i> оливкового масла, орехов, фруктов, овощей, злаков и молочных продуктов с низким содержанием жира;</li>
    <li><i>умеренного количества:</i> рыбы, птицы, сухого красного вина;</li>
    <li><i>низким содержанием:</i> красного мяса и мясопродуктов, сладостей, поваренной соли.</li>
</ul>
<h3>Специальные исследования были посвящены отдельным продуктам питания, потребление которых повышает или, наоборот, снижает риск развития И.</h3>
<h4>1. <u>Продукты, потребление которых снижает риск развития инсульта:</u></h4>
<ul>
    <li>
        Увеличение потребления <i>фруктов</i> и <i>овощей</i> на 200 г/сутки ассоциируется со <i>снижением риска
        развития</i> инсульта на 16%. Особенно полезен высокий уровень потребления яблок, груш,
        цитрусовых, зеленых листовых овощей, богатых клетчаткой, микроэлементами, витаминами.
        Их потребление умеренно снижает АД, уменьшает содержание в крови липопротеинов низкой
        плотности, замедляет развитие атеросклероза.
    </li>
    <li>
        Добавление к СЗД большого количества <i>орехов</i> ассоциируется со <i>снижением риска развития</i>
        инсульта на 46%.
    </li>
    <li>
        Увеличение потребления <i>оливкового масла</i> на 25 г в сутки ассоциируется с <i>достоверным
        снижением инсульта</i> на 24%.
    </li>
    <li>
        5 перспективных исследований показали, что увеличение потребления <i>шоколада положительно
        влияет</i> на уровень АД, агрегацию тромбоцитов, эндотелиальную функцию и инсулиновую
        резистентность. Флавоноиды, содержащиеся в какао обладают антиоксидантным действием.
    </li>
    <li>
        Увеличение потребления <i>рыбы</i> ассоциируется с <i>достоверным снижением</i> риска инсульта на 4%.
    </li>
    <li>
        Обнаружено <i>положительное</i> антиоксидантное действие <i>кофе</i> (от 3 до 4 чашек в день) и <i>чая</i>
        (3 и более чашки в день). Чай одновременно усиливает эндотелиально-зависимую
        вазодилатацию.
    </li>
    <li>
        Обнаружено положительное антиоксидантное действие кофе (от 3 до 4 чашек в день) и чая
        (3 и более чашки в день). Чай одновременно усиливает эндотелиально-зависимую
        вазодилатацию.
    </li>
    <li>
        <i>Умеренное</i> употребление <i>алкоголя здоровым человеком</i>, особенно сухого красного вина
        (1-2 дринка в день), <i>снижает риск развития</i> инсульта – напротив, значительное потребление
        алкоголя (более 4 дринков в день) <i>повышает АД</i> и тем самым <i>повышает риск инсульта</i>
        (прежде всего внутримозгового и субарахноидального кровоизлияния). 1 дринк (14 г..) этанола
        соответствует 45 мл. крепкого 40° напитка (водка, коньяк) и 150 мл. сухого (10°) вина. Красное
        вино содержит фенольные соединения (ресвератрол), обладающие антиоксидантными
        и антитромботическими свойствами, снижающие окисление липопротеинов низкой плотности
        и улучшающие состояние эндотелия.
    </li>
</ul>
<h4>2. <u>Продукты, потребление которых повышает риск развития инсульта:</u></h4>
<ul>
    <li>
        Установлено, что <i>мясные продукты</i> с высоким содержанием натрия и нитратов (бекон, ветчина,
        колбасы) <i>увеличивают риск инсульта</i> на 13%, употребление изделий из <i>необработанного
        красного мяса</i> – на 11%. Снижение потребления мяса – снижает риск инсульта.
    </li>
    <li>
        Высокий уровень потребления <i>поваренной соли</i> (≥2 г в сутки) <i>увеличивает риск</i> инсульта на 24%.
    </li>
    <li>
        В 3 из 4 проспективных исследованиях показано, что ежедневное потребление <i>сладких напитков</i>
        связано с <i>повышением риска</i> инсульта.
    </li>
</ul>
<h4>3. <u>Не установлено значительного влияния на риск развития инсульта:</u></h4>
<ul>
    <li>
        Не установлено значительного влияния на риск развития инсульта употребления в умеренных
        количествах: молочных продуктов (сыр, йогурт, сливочное масло), яиц (≤1 яйцо в день),
        картофеля, круп.
    </li>
</ul>
`,
                                rang: 4,
                                isApproved: true
                            },
                            {
                                id: "7",
                                name: "Нормализация массы тела",
                                description:
                                    "<p>Индекс массы тела (ИМТ) — отношение массы тела к росту, использующееся для диагностики недостаточного, нормального или избыточного веса у взрослых. </p><p>Индекс рассчитывается как отношение массы тела в килограммах к квадрату роста в метрах (кг/м²). По рекомендациям ВОЗ необходимо удерживать ИМТ в пределах от 20 до 24,99. </p><p>Для поддержания нормальной массы тела необходимо следовать принципам здорового питания, соблюдать энергетический баланс (количество потребленных калорий должно соответствовать количеству потраченных калорий), оптимизировать двигательную и физическую активности.</p>",
                                explanation:
                                    "Сюда нужно сверстать объяснение рекомендации из макета",
                                rang: 4,
                                isApproved: true
                            },
                            {
                                id: "3",
                                name: "Тренировка памяти",
                                description:
                                    "<p>Для тренировки памяти лучше всего подходят чтение, запоминание и пересказ. </p><p>Чтение и запоминание стихов ввиду их музыкальности, ритмичности и эмоциональной насыщенности. Также хорошей тренировкой для мозга является припоминание стихов и песен, которые знали наизусть в детстве и юности. Очень полезны разучивание новых стихов, пересказ (желательно близко к тексту) рассказа, статьи, содержание просмотренного спектакля, телепередачи. </p><p>Важный аспект в тренировке памяти – это регулярность и постоянное усложнение заданий.</p>",
                                explanation:
                                    "Сюда нужно сверстать объяснение рекомендации из макета",
                                rang: 4,
                                isApproved: true
                            },
                            {
                                id: "4",
                                name:
                                    "Снизить уровень эмоционального стресса и связанную с ним тревожность",
                                description:
                                    "<p>Снять хроническую стрессовую ситуацию (или уменьшить её выраженность) помогают положительные эмоции: поход в театр, на концерт, посещение выставок, спортивных соревнований, общение с друзьями, чтение интересных книг, прогулки на свежем воздухе, физические упражнения, сон.</p><p>Снизить хронический эмоциональный стресс помогает творчество на любительском уровне: живопись, сочинение стихов и рассказов, игра на музыкальных инструментах.</p><p>При стрессовых ситуациях помогают практики аутогенных тренировок и методы релаксации.</p>Если длительно беспокоит чувство внутреннего напряжения, беспокойство, раздраженность, появилась несдержанность, вспыльчивость, нарушился сон и самим преодолеть их не удаётся, следует обратиться к врачу.</p>",
                                explanation: "",
                                rang: 3,
                                isApproved: true
                            },
                            {
                                id: "5",
                                name: "Отказ от курения",
                                description:
                                    "<p>Самостоятельный отказ от табакокурения заключается в следующем:</p><ul><li>вести дневник выкуренных сигарет, стараясь каждые 1-2 дня выкуривать на одну сигарету меньше;</li><li>стараться курить глубоко не затягиваясь;</li><li>оставлять большие окурки, в которых концентрируются наиболее вредные компоненты табачного дыма;</li><li>избегать ситуаций, провоцирующих курение;</li><li>в качестве дополнительного средства можно использовать медикаментозные средства, вызывающие отвращение к табачному дыму.</li></ul><p>Если самостоятельно бросить курить не получается, то обратитесь за помощью к специалисту. В муниципальных и частных поликлиниках работают наркологические и психотерапевтические кабинеты.</p>",
                                explanation:
                                    "Сюда нужно сверстать объяснение рекомендации из макета",
                                rang: 3,
                                isApproved: true
                            },
                            {
                                id: "6",
                                name:
                                    "Оптимизировать двигательную и физическую активность",
                                description:
                                    "<p>Здоровый образ жизни включает виды физической и двигательной активности, как быстрая ходьба, плавание, велосипедные, лыжные и пешие прогулки подвижные игры на свежем воздухе.</p><p>Начинать следует с умеренного уровня тренировок: с упражнений (типа утренней гимнастики) по 15-20 минут ежедневно 3-4 раза в неделю, постепенно повышая нагрузки и время занятий до 30-40 минут 5 раз в неделю.</p><p>Примерами умеренной активности являются: ходьба в быстром темпе (4,8-6,5км/час), медленная езда на велосипеде (&lt; 15км/ч), нетяжелые садово-огородные работы (например с газонокосилкой), бальные танцы, аквааэробика, теннис в парах.</p><p>При достижении устойчивого умеренного уровня физической и двигательной активности при отсутствии сердечно-сосудистых заболеваний и других ограничений можно постепенно повышать нагрузки.</p><p>Если при выполнении умеренных физических нагрузок возникают слабость, вялость, снижение работоспособности, иногда переходящие в хроническую утомляемость; мышечные боли; боли в области сердца или головокружения, то необходимо снизить уровень активности.</p>",
                                explanation:
                                    "Сюда нужно сверстать объяснение рекомендации из макета",
                                rang: 3,
                                isApproved: true
                            }
                        ]
                    }
                };
            }
            return data;
        }
    };
    return _ns;
})();
