import React from "react";
import Header from "./header";
import LeftAuthForm from "./left-auth-form";
import RightAuthForm from "./right-auth-form";

import "../../../css/pages/auth.css";

export class AuthLayout extends React.Component {
    componentDidMount() {
        document.title = "Sign In - Med.Proto";
    }
    render() {
        return (
            <div className="auth">
                <Header />
                <div className="form form-auth">
                    <LeftAuthForm />
                    <RightAuthForm />
                    <div className="cb" />
                </div>
            </div>
        );
    }
}

export default AuthLayout;
