import React from "react";
import { BaseEditForm } from "./base-edit-form";
import ReactJson from "react-json-view";
import LabeledBlock from "./labeled-block";
import { Api } from "../../libs/api";
import Button from "../shared/button";

export class TestForm extends BaseEditForm {
    constructor(props) {
        super(props);
        this.state = {
            "prob_all-in": {
                date: "2018-11-01",
                system: "all"
            },
            "prob_risk-in": {
                date: "2018-11-01",
                risk: "infarct"
            },
            error: "",
            success: ""
        };
    }
    handleChange = v => {
        // var fields = {};
        // fields[v.name] = v.value;
        // if (this.props.onChange)
        //     this.props.onChange({ name: this.props.form, fields });
    };
    loadingState = (point, val) => {
        var s = {};
        s[point + "-loading"] = true;
        return s;
    };
    handleRunClick = point => {
        var d = this.state[point + "-in"] || {};
        this.setState(this.loadingState(point, true));
        Api.point(point, d)
            .then(x => {
                var s = this.loadingState(point, false);
                s[point + "-out"] = x;
                this.setState(s);
            })
            .catch(x => {});
    };
    handleSrcEdit = (name, x) => {
        var res = {};
        res[name + "-in"] = x.updated_src;
        res[name + "-out"] = false;
        this.setState(res);
    };
    jsonViewerProps = name => {
        return {
            onEdit: x => this.handleSrcEdit(name, x),
            src: this.state[name + "-in"] || {},
            name: false
        };
    };
    render() {
        return (
            <div className="appear-left">
                <h2 className="db cb">/prob_all</h2>
                <i className="comment db mb">
                    Метод выбирает список рисков из последней сгенерированной
                    оценки до заданной даты
                </i>
                <ul>
                    <li>
                        <i className="comment">«date»</i>: Дата, до которой
                        выбирать оценку
                    </li>
                    <li>
                        <i className="comment">«system»</i>: Система. В текущей
                        обсужденной версии — всегда "all"
                    </li>
                </ul>
                <LabeledBlock title="Вход">
                    <ReactJson {...this.jsonViewerProps("prob_all")} />
                </LabeledBlock>
                <Button
                    className="fr mt-1-5"
                    onClick={() => {
                        this.handleRunClick("prob_all");
                    }}
                >
                    Выполнить (получить результат)
                </Button>
                <div className="cb" />
                {this.state["prob_all-out"] && (
                    <LabeledBlock title="Результат">
                        <ReactJson
                            src={this.state["prob_all-out"]}
                            name={false}
                        />
                    </LabeledBlock>
                )}

                <h2 className="db cb mt-1-5">
                    /prob_history <i className="comment">(Новый метод)</i>
                </h2>
                <i className="comment db mb">
                    Метод выбирает список всех дат, в которые происходила оценка
                    рисков. Необходим, чтобы вывести элемент управления,
                    позволяющий перейти к оценке рисков за конкретную дату.
                </i>
                <LabeledBlock title="Вход">
                    <ReactJson {...this.jsonViewerProps("prob_history")} />
                </LabeledBlock>
                <Button
                    className="fr mt-1-5"
                    onClick={() => {
                        this.handleRunClick("prob_history");
                    }}
                >
                    Выполнить (получить результат)
                </Button>
                <div className="cb" />
                {this.state["prob_history-out"] && (
                    <LabeledBlock title="Результат">
                        <ReactJson
                            src={this.state["prob_history-out"]}
                            name={false}
                        />
                    </LabeledBlock>
                )}
                <h2 className="db cb mt-1-5">
                    /prob_risk <i className="comment">(Новый метод)</i>
                </h2>
                <i className="comment db mb">
                    Метод выбирает информацию о риске за заданную дату, такую
                    как:
                </i>
                <ul className="mb">
                    <li>Название риска.</li>
                    <li>Описание риска.</li>
                    <li>
                        Сетепени риска (для графического индикатора, в
                        процентах)
                    </li>
                    <li>Список различных оценок риска</li>
                    <li>
                        Для каждой оценки выбирает описание, название и степень
                        риска (для графического индикатора, в процентах)
                    </li>
                    <li>
                        Выбирает списки факторов риска и рекоммендаций,
                        сгруппированные по различным оценкам риска.
                    </li>
                </ul>
                <i className="comment db mb">Параметры метода:</i>
                <ul>
                    <li>
                        <i className="comment">«date»</i>: Дата, до которой
                        выбирать информацию о риске
                    </li>
                    <li>
                        <i className="comment">«risk»</i>: Идентификатор риска,
                        о котором выбирать информацию
                    </li>
                </ul>
                <LabeledBlock title="Вход">
                    <ReactJson {...this.jsonViewerProps("prob_risk")} />
                </LabeledBlock>
                <Button
                    className="fr mt-1-5"
                    onClick={() => {
                        this.handleRunClick("prob_risk");
                    }}
                >
                    Выполнить (получить результат)
                </Button>
                <div className="cb" />
                {this.state["prob_risk-out"] && (
                    <LabeledBlock title="Результат">
                        <ReactJson
                            src={this.state["prob_risk-out"]}
                            name={false}
                        />
                    </LabeledBlock>
                )}
            </div>
        );
    }
}

export default TestForm;
