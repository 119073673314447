import React from "react";
import { Switch, Route } from "react-router-dom";

import ProblemsOverviewPage from "./ProblemsOverviewPage";
import ProblemPage from "./ProblemPage";

import { Api } from "../../../libs/api";
import RiskErrorPage from "./ErrorPage";

export default class HealthLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            historyDates: null,
            selectedDate: null,
            isLoaded: false,
            error: null
        };
    }

    componentDidMount() {
        this.getRisksHistory();
    }

    getRisksHistory = () => {
        return Api.getRisksHistory()
            .then(res => {
                this.setState({
                    historyDates: res.result,
                    selectedDate: res.result[res.result.length - 1],
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({ error: err, isLoaded: true });
            });
    };

    handleDateSelect = date => {
        this.setState({ selectedDate: date });
    };

    render() {
        const { match } = this.props;
        const { historyDates, selectedDate, isLoaded, error } = this.state;

        if (!isLoaded) {
            return <h2>LOADING</h2>;
        }
        if (error) {
            return <RiskErrorPage error={error} />;
        }
        return (
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={props => (
                        <ProblemsOverviewPage
                            {...props}
                            onRecalc={this.getRisksHistory}
                            historyDates={historyDates}
                            selectedDate={selectedDate}
                            onSelectDate={this.handleDateSelect}
                        />
                    )}
                />
                <Route
                    path={`${match.path}/:problemId`}
                    render={props => (
                        <ProblemPage
                            {...props}
                            historyDates={historyDates}
                            selectedDate={selectedDate}
                            onSelectDate={this.handleDateSelect}
                        />
                    )}
                />
            </Switch>
        );
    }
}
