import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import MenuItem from "../shared/menu-item";
import "../../css/comp/menu.css";

class Menu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var form = this.props.form;
        var title = fill => (fill ? `Раздел заполнен на ${fill}%` : "");
        let defProps = {
            title: title,
            fill: 0,
            // checkChanges: this.props.checkChanges,
            goTo: this.props.goTo,
            form: form
        };
        return (
            <div className="secondary-menu">
                <Scrollbars
                    renderThumbVertical={props => (
                        <div {...props} className="scroll-thumb" />
                    )}
                >
                    <MenuItem
                        {...defProps}
                        selected={form === "illnesses"}
                        to="/edit/illnesses"
                    >
                        Болезни и семейный анамнез
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "lifestyle"}
                        to="/edit/lifestyle"
                    >
                        Образ жизни
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "food"}
                        to="/edit/food"
                    >
                        Питание
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "physiology"}
                        to="/edit/physiology"
                    >
                        Физиология
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "lab"}
                        to="/edit/lab"
                    >
                        Лабораторные и функциональные исследования
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "social"}
                        to="/edit/social"
                    >
                        Среда и социальный статус
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "events"}
                        to="/edit/events"
                    >
                        События жизни
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "polls"}
                        to="/edit/polls"
                    >
                        Опросники
                    </MenuItem>
                    <MenuItem
                        {...defProps}
                        selected={form === "devices"}
                        glyph="devices"
                        to="/edit/devices"
                    >
                        Учётные записи и данные
                    </MenuItem>
                </Scrollbars>
            </div>
        );
    }
}

export default Menu;
