import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import { Route } from "react-router-dom";
import $ from "jquery";

export class SelfRelationsForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=57;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = ["Нет", "Да"];
        return (
            <div className="appear-left">
                <h2 className="db cb">
                    Тест на выявление уровней самоотношения
                </h2>
                <i className="comment db mb">
                    Опросник самоотношения (ОСО) построен в соответствии с
                    разработанной В.В.Столиным иерархической моделью структуры
                    самоотношения. Опросник позволяет выявить три уровня
                    самоотношения, отличающихся по степени обобщенности:
                </i>
                <ul className="comment db mb">
                    <li>глобальное самоотношение;</li>
                    <li>
                        самоотношение, дифференцированное по самоуважению,
                        аутсимпатии, самоинтересу и ожиданиям отношения к себе;
                    </li>
                    <li>
                        уровень конкретных действий (готовностей к ним) в
                        отношении к своему «Я».
                    </li>
                </ul>
                <i className="comment db mb-1-5">
                    Вам предлагается ответить на следующие 57 утверждений. Если
                    Вы согласны с данным утверждением выберите «Да», если не
                    согласны — «Нет».
                </i>
                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset poll-bool">
						<div className="q1">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_0")}
							>
								Думаю, что большинство моих знакомых относится ко
								мне с симпатией.
							</PollRadios>
						</div>
						<div className="q2">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_1")}
							>
								Мои слова не так уж часто расходятся с делом.
							</PollRadios>
						</div>
						<div className="q3">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_2")}
							>
								Думаю, что многие видят во мне что-то сходное с
								собой.
							</PollRadios>
						</div>
						<div className="q4">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_3")}
							>
								Когда я пытаюсь себя оценить, я прежде всего вижу
								свои недостатки.
							</PollRadios>
						</div>
						<div className="q5">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_4")}
							>
								Думаю, что как личность я вполне могу быть
								притягательным для других.
							</PollRadios>
						</div>
						<div className="q6">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_5")}
							>
								Когда я вижу себя глазами любящего меня человека,
								меня неприятно поражает то, насколько мой образ
								далек от действительности.
							</PollRadios>
						</div>
						<div className="q7">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_6")}
							>
								Мое «Я» всегда мне интересно.
							</PollRadios>
						</div>
						<div className="q8">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_7")}
							>
								Я считаю, что иногда не грех пожалеть самого себя.
							</PollRadios>
						</div>
						<div className="q9">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_8")}
							>
								В моей жизни есть или по крайней мере были люди с
								которыми я был чрезвычайно близок.
							</PollRadios>
						</div>
						<div className="q10">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_9")}
							>
								Собственное уважение мне еще надо заслужить.
							</PollRadios>
						</div>
						<div className="q11">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_10")}
							>
								Бывало, и не раз, что я сам себя остро ненавидел.
							</PollRadios>
						</div>
						<div className="q12">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_11")}
							>
								Я вполне доверяю своим внезапно возникшим желаниям.
							</PollRadios>
						</div>
						<div className="q13">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_12")}
							>
								Я сам хотел во многом себя переделать.
							</PollRadios>
						</div>
						<div className="q14">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_13")}
							>
								Мое собственное «Я» не представляется мне чем-то
								достойным глубокого внимания.
							</PollRadios>
						</div>
						<div className="q15">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_14")}
							>
								Я искренне хочу, чтобы у меня было все хорошо в
								жизни.
							</PollRadios>
						</div>
						<div className="q16">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_15")}
							>
								Если я и отношусь к кому-нибудь с укоризной, то
								прежде всего к самому себе.
							</PollRadios>
						</div>
						<div className="q17">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_16")}
							>
								Случайному знакомому я скорее всего покажусь
								человеком приятным.
							</PollRadios>
						</div>
						<div className="q18">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_17")}
							>
								Чаще всего я одобряю свои планы и поступки.
							</PollRadios>
						</div>
						<div className="q19">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_18")}
							>
								Собственные слабости вызывают у меня что-то
								наподобие презрения.
							</PollRadios>
						</div>
						<div className="q20">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_19")}
							>
								Если бы я раздвоился, то мне было бы довольно
								интересно общаться со своим двойником.
							</PollRadios>
						</div>
						<div className="q21">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_20")}
							>
								Некоторые свои качества я ощущаю как посторонние,
								чужие мне.
							</PollRadios>
						</div>
						<div className="q22">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_21")}
							>
								Вряд ли кто-либо сможет почувствовать свое сходство
								со мной.
							</PollRadios>
						</div>
						<div className="q23">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_22")}
							>
								У меня достаточно способностей и энергии воплотить в
								жизнь задуманное.
							</PollRadios>
						</div>
						<div className="q24">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_23")}
							>
								Часто я не без издевки подшучиваю над собой.
							</PollRadios>
						</div>
						<div className="q25">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_24")}
							>
								Самое разумное, что может сделать человек в своей
								жизни – это подчиниться собственной судьбе.
							</PollRadios>
						</div>
						<div className="q26">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_25")}
							>
								Посторонний человек, на первый взгляд, найдет во мне
								много отталкивающего.
							</PollRadios>
						</div>
						<div className="q27">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_26")}
							>
								К сожалению, если я и сказал что-то, это не значит,
								что именно так и буду поступать.
							</PollRadios>
						</div>
						<div className="q28">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_27")}
							>
								Свое отношение к самому себе можно назвать
								дружеским.
							</PollRadios>
						</div>
						<div className="q29">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_28")}
							>
								Быть снисходительным к собственным слабостям вполне
								естественно.
							</PollRadios>
						</div>
						<div className="q30">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_29")}
							>
								У меня не получается быть для любимого человека
								интересным длительное время.
							</PollRadios>
						</div>
						<div className="q31">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_30")}
							>
								В глубине души я бы хотел, чтобы со мной произошло
								что-то катастрофическое.
							</PollRadios>
						</div>
						<div className="q32">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_31")}
							>
								Вряд ли я вызываю симпатию у большинства моих
								знакомых.
							</PollRadios>
						</div>
						<div className="q33">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_32")}
							>
								Мне бывает очень приятно увидеть себя глазами
								любящего меня человека.
							</PollRadios>
						</div>
						<div className="q34">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_33")}
							>
								Когда у меня возникает какое-либо желание, я прежде
								всего спрашиваю о себя, разумно ли это.
							</PollRadios>
						</div>
						<div className="q35">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_34")}
							>
								Иногда мне кажется, что если бы какой-то мудрый
								человек смог увидеть меня насквозь, он бы тут же
								понял, какое я ничтожество.
							</PollRadios>
						</div>
						<div className="q36">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_35")}
							>
								Временами я сам собой восхищаюсь.
							</PollRadios>
						</div>
						<div className="q37">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_36")}
							>
								Можно сказать, что я ценю себя достаточно высоко.
							</PollRadios>
						</div>
						<div className="q38">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_37")}
							>
								В глубине души я никак не могу поверить, что я
								действительно взрослый человек.
							</PollRadios>
						</div>
						<div className="q39">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_38")}
							>
								Без посторонней помощи я мало что могу сделать.
							</PollRadios>
						</div>
						<div className="q40">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_39")}
							>
								Иногда я сам себя плохо понимаю.
							</PollRadios>
						</div>
						<div className="q41">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_40")}
							>
								Мне очень мешает недостаток энергии, воли и
								целеустремленности.
							</PollRadios>
						</div>
						<div className="q42">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_41")}
							>
								Думаю, что другие в целом оценивают меня достаточно
								высоко.
							</PollRadios>
						</div>
						<div className="q43">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_42")}
							>
								В моей личности есть, наверное, что-то такое, что
								способно вызывать у других неприязнь.
							</PollRadios>
						</div>
						<div className="q44">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_43")}
							>
								Большинство моих знакомых не принимают меня уж так
								всерьез.
							</PollRadios>
						</div>
						<div className="q45">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_44")}
							>
								Сам у себя я довольно часто вызываю чувство
								раздражения.
							</PollRadios>
						</div>
						<div className="q46">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_45")}
							>
								Я вполне могу сказать, что уважаю себя сам.
							</PollRadios>
						</div>
						<div className="q47">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_46")}
							>
								Даже мои негативные черты не кажутся мне чужими.
							</PollRadios>
						</div>
						<div className="q48">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_47")}
							>
								В целом, меня устраивает то, какой я есть.
							</PollRadios>
						</div>
						<div className="q49">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_48")}
							>
								Вряд ли меня можно любить по-настоящему.
							</PollRadios>
						</div>
						<div className="q50">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_49")}
							>
								Моим мечтам и планам не хватает реалистичности.
							</PollRadios>
						</div>
						<div className="q51">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_50")}
							>
								Если бы мое второе «Я» существовало, то для меня это
								был бы самый скучный партнер по общению.
							</PollRadios>
						</div>
						<div className="q52">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_51")}
							>
								Думаю, что мог бы найти общий язык с любым разумным
								и знающим человеком.
							</PollRadios>
						</div>
						<div className="q53">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_52")}
							>
								То, что во мне происходит, как правило, мне понятно.
							</PollRadios>
						</div>
						<div className="q54">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_53")}
							>
								Мои достоинства вполне перевешивают мои недостатки.
							</PollRadios>
						</div>
						<div className="q55">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_54")}
							>
								Вряд ли найдется много людей, которые обвинят меня в
								отсутствии совести.
							</PollRadios>
						</div>
						<div className="q56">
							<PollRadios
								opts={[0, 1]}
								titles={vals}
								{...this.baseEditProps("selfrelation_55")}
							>
								Когда со мной случаются неприятности, как правило, я
								говорю: «И поделом тебе».
							</PollRadios>
						</div>
						<div className="q57">
							<PollRadios
								opts={[1, 0]}
								titles={vals}
								{...this.baseEditProps("selfrelation_56")}
							>
								Я могу сказать, что в целом я контролирую свою
								судьбу.
							</PollRadios>
						</div>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SelfRelationsForm;
