import React from "react";
import { Redirect, Route } from "react-router-dom";
import EditHeader from "./header";
import EditStates from "./edit-states";
import EditSide from "./side";
import "../../css/pages/edit.css";
import { ProfileForm } from "./profile";
import { IllnessesForm } from "./illnesses";
import { LifestyleForm } from "./lifestyle";
import { FoodForm } from "./food";
import { PhysiologyForm } from "./physiology";
import { LabForm } from "./lab";
import { SocialForm } from "./social";
import { EventsForm } from "./events";
import { Api } from "../../libs/api";

import * as Scroll from "react-scroll";
import TestForm from "./test";
import DevicesForm from "./devices";
import moment from "moment";
import "moment/locale/ru";

class EditLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _loading: true,
            "TotalHol-units": "ммоль./л.",
            "hol_lpnp-units": "ммоль./л.",
            "hol_lpvp-units": "ммоль./л.",
            "triglycerides-units": "ммоль./л.",
            "blood_clqotting-units": "ед.",
            "lpi-units": "eд.",
            showScroll: false,
            changedFields: {},
            goToPos: { x: 0, y: 0 },
            confirmGoTo: false
        };
    }
    /**
     * @typedef {Object} FormState
     * @property {Boolean} _loading Индикатор состояния загрузки данных на форме
     * @property {Redirect|Boolean} _redirect Компонент — перенаправление или false
     * @property {string?} _error Строка с описанием ошибки на форме или null
     * @property {string?} _success Строка с описание успешного выполнения запроса с формы или null
     */
    /**
     * Формирует объект — пустое начальное состоянии формы
     * @returns {FormState}
     */
    defaultState = () => {
        return {
            _loading: false,
            _redirect: false,
            _error: null,
            _success: null
        };
    };
    /**
     * Устанавливает заголовок страницы в соответствии с подгруженной формой (из EditStates)
     * @returns {EditLayout} this
     */
    setTitle = () => {
        var state = EditStates.get(this.props.match.params.form);
        document.title = "Изменение данных - Med.Proto";
        document.title = state.title + ": " + document.title;
        return this;
    };
    /**
     * Пустое обещание — эмуляция запроса (resolve({ status:200, result:{} }))
     * @returns {Promise} Пустое обещаение
     */
    emptyPromise = () => {
        return new Promise((resolve, rejects) => {
            resolve({ status: 200, result: {} });
        });
    };
    /** Возвращает пустое состояние с незаполненными полями */
    emptyFields = () => {
        var state = EditStates.get(this.props.match.params.form);
        var res = {};
        var fields = state.fields || [];
        var noSave = state.noSave || [];
        for (var i = 0; i < fields.length; i++) {
            if (noSave.indexOf(fields[i]) > -1) continue;
            res[state.fields[i]] = "";
        }
        return res;
    };
    /**
     * Формируем данные графиков для компоненты отображении
     * @param {Object} gdata Объект, куда добавить именовоное поле с данными (field-graph)
     * @param {string} field Название поля, для которого формируются данные
     * @param {Object[]} x Данные для графика поля с сервера в формате [{<date>:<int>}]
     * @returns {Object} gdata
     */
    formGraphData = (gdata, field, x) => {
        var serie = [];
        for (let d in x) {
            serie.push({ x: moment(d).toDate(), y: x[d] });
        }
        serie.sort((a, b) => (a.x.getTime() > b.x.getTime() ? 1 : -1));
        gdata[field + "-graph"] = [serie];
        return gdata;
    };
    /**
     * Получает данные с сервера и заполняет состояние
     * @param {string[]} fields Список идентификаторов полей
     * @param {Boolean} rewriteFields Если задан, очищает поля формы перед заполнением. В противном случае — обновляет только поданные поля
     * @param {Boolean} noConstraints Если true, не загружает ограничения к полям
     * @returns {Promise} Обещание выполнения /get_data, /get_fields и набора запросов для графиков или пустой, если для формы не заданы поля или "fields" не заполнен
     */
    getData = (fields, rewriteFields, noConstraints, graphs) => {
        this.clearErrors();
        let form = this.props.match.params.form;
        var state = EditStates.get(form);

        var _fields = fields || state.fields;

        if (!_fields) return this.emptyPromise();

        var promises = [Api.getData(_fields)];
        if (!noConstraints) promises.push(Api.getFields(_fields));

        var graphsNames = [];
        let _graphs = state.graphs || graphs;
        //Если на форме есть графики, так же запрашиваем данные для них
        if (_graphs) {
            for (let i in _graphs) {
                if (_fields.indexOf(i) === -1) continue; //Если нет в списке свойств на выбор — не выбираем данные для графика

                let de = moment().format("YYYY-MM-DD");
                var func =
                    typeof _graphs[i] === "string"
                        ? () => {
                              return moment().add(-1, _graphs[i]);
                          }
                        : _graphs[i];

                let ds = func().format("YYYY-MM-DD");
                let p = Api.getGraphicData(i, ds, de);
                promises.push(p);
                graphsNames.push(i);
            }
        }

        return Promise.all(promises).then(x => {
            let s = {};
            if (rewriteFields) s = this.emptyFields();
            //Заполняем поля
            let gdata = {};
            let gindex = noConstraints ? 1 : 2;
            if (x.length > gindex) {
                for (let i = gindex; i < x.length; i++) {
                    gdata = this.formGraphData(
                        gdata,
                        graphsNames[i - gindex],
                        x[i].result
                    );
                }
            }
            s = { ...s, ...x[0].result, ...gdata };
            if (!noConstraints) {
                s = { ...s, ...x[1].result };
            }
            this.setState(s);
        });
    };
    /**
     * Получает данные о карточке профиля пользователя и устанавливает состояние страницы
     * @returns {Promise} Обещание выполнения запроса на получение данных
     */
    getUserCard = () => {
        return Api.getProfileCard().then(x => {
            this.setState({ user: x.result });
        });
    };
    /**
     * Обработчик изменения значений полей формы.
     * Устанавливает значения изменяемых полей в соответствующие значение состояния формы,
     * после чего (в процесси изменения состояния) значения спускаются в виде props обратно в поля.
     * Если изменяется поле «avatar», загружает аватар на сервер.
     * @param {Object} v Набор значений вида {<field-name>:<value>}
     * @returns {EditLayout} this
     */
    handleChange = v => {
        let form = this.props.match.params.form;
        let state = EditStates.get(form);
        let converters = state.converters || {};

        var toSet = {};
        for (var i in v.fields) {
            var field = v.fields[i];

            let conv = converters[i];

            if (conv && field) {
                let cv = conv(field);
                if (typeof cv === "number" && cv <= 0) {
                    field = "0";
                }
            }

            this.handleConfirmNo(i);

            if (i === "avatar") {
                var data = new FormData();
                data.append("avatar", field);
                this.setState({ _loading: true });

                Api.setAvatar(data)
                    .then(x => {
                        return Api.getData(["avatar"]);
                    })
                    .then(x => {
                        this.setState({
                            _loading: false,
                            user: { ...this.state.user, ...x.result }
                        });
                        return x;
                    })
                    .then(x => {
                        this.setState(x.result);
                    })
                    .catch(x =>
                        console.error(
                            "Ошибка при загрузке аватара: " + x.message
                        )
                    );
                continue;
            }

            toSet[i] = field;
        }

        this.setState({
            ...toSet,
            changedFields: { ...this.state.changedFields, ...toSet }
        });
        return this;
    };

    /**
     * Получает функцию-валидатор значения поля
     * @param {string} field Идентификатор поля
     * @returns {Function} Функция валидатор
     */
    getValidator = field => {
        var val = this.state.form_valid[field];

        if (!val || typeof val === "string") return () => "";
        if (
            (val.min !== null && val.min !== undefined) ||
            (val.max !== null && val.max !== undefined)
        ) {
            //Функция проверки на попадение в интервал
            return v => {
                let _val = val;
                if (_val.min !== null && v < _val.min)
                    return "Значение поля не может быть меньше " + _val.min;
                if (_val.max !== null && v > _val.max)
                    return "Значение поля не может быть больше " + _val.max;
                return "";
            };
        }
        if (val.min_length !== null || val.max_length !== null) {
            //Функция проверки на длинну строки
            return v => {
                let _val = val;
                if (
                    _val.min_length !== null &&
                    (v || "").toString().length < _val.min_length
                )
                    return `Длина значения поля должна быть больше ${
                        _val.min_length
                    }`;
                if (
                    _val.max_length !== null &&
                    (v || "").toString().length > _val.max_length
                )
                    return `Длинна значения поля должна быть меньше ${
                        _val.max_length
                    }`;
                return "";
            };
        }

        return () => "";
    };
    validateFields = data => {
        let res = true;
        var errs = {};
        for (var f in data) {
            var val = this.getValidator(f);
            var r = (errs[f + "-fieldError"] = val(data[f]));
            if (r) {
                res = false;
            }
        }
        this.setState(errs);
        return res;
    };
    clearErrors = () => {
        let form = this.props.match.params.form;
        let state = EditStates.get(form);
        let fields = state.form.fields || [];

        var errs = {};
        for (var i = 0; i < fields.length; i++) {
            errs[fields[i] + "-fieldError"] = "";
        }
        this.setState(errs);
    };
    fieldLoadingState = (field, state) => {
        let s = {};
        s["_loading-" + field] = state;
        return s;
    };
    fieldUndoState = (field, state) => {
        let s = {};
        s["_undo-" + field] = state;
        return s;
    };
    fieldConfirmState = (field, message) => {
        let s = {};
        s[field + "-fieldConfirm"] = message;
        return s;
    };
    /**
     * Сохраняет значение одного поля. Сделано для полей с кнопкой «+»
     * @param {string} form Идентификатор формы
     * @param {string} field Идентификатор поля
     * @param {any} v Значение поля для сохранения
     * @param {Boolean} confirmed Если true, пропускает проверку на подтверждение выхода из диапазона
     * @return {Promise}
     */
    saveOneField = (form, field, v, confirmed) => {
        if (v === null || v === undefined) return; //Не рассматриваем пустые значения
        let state = EditStates.get(form);
        let converters = state.form.converters || {};
        let fieldConfirm = (state.form.confirms || {})[field];

        let conv = converters[field];
        let data = {};
        data[field] = conv ? conv(v) : v; //Конвертируем значение, если нужно

        //Начальное состояние + индикатор загрузки на конкретное поле
        this.setState({
            ...this.defaultState(),
            ...this.fieldLoadingState(field, true),
            ...this.fieldConfirmState(field, "")
        });

        //Проверяем, а не показать ли предупреждение
        if (!confirmed && fieldConfirm) {
            let cmessage = fieldConfirm(data[field], this.state);
            this.setState({
                ...this.fieldLoadingState(field, false),
                ...this.fieldConfirmState(field, cmessage)
            });
            if (cmessage) return;
        }

        //Если поля не верны, не сохраняем ничего
        if (!this.validateFields(data)) {
            this.setState(this.fieldLoadingState(field, false));
            return;
        }

        console.log("saving: ", data);

        //Подготавливаем список (один) графиков для подгрузки данных
        var g = null;
        if (state.graphs) {
            for (var i in state.graphs) {
                if (i === field) {
                    g = {};
                    g[i] = state.graphs[i];
                    break;
                }
            }
        }

        //Сохраняем изменившиеся свойства через API
        return Api.setData(data)
            .then(x => {
                return this.getData([field], false, true, g);
            })
            .then(x => {
                var cf = this.state.changedFields;
                cf[field] = null;
                this.setState({
                    ...this.defaultState(),
                    ...this.fieldLoadingState(field, false),
                    ...this.fieldUndoState(field, true),
                    changedFields: cf,
                    _success: "Информация успешно сохранена!"
                });
            })
            .catch(x => {
                this.setState({
                    ...this.defaultState(),
                    ...this.fieldLoadingState(field, false),
                    ...this.fieldUndoState(field, true),
                    _error: x.message
                });
            });
    };
    undoOneField = (form, field) => {
        let state = EditStates.get(form);

        //Начальное состояние + индикатор загрузки на конкретное поле
        this.setState({
            ...this.defaultState(),
            ...this.fieldLoadingState(field, true),
            ...this.fieldConfirmState(field, "")
        });

        //Подготавливаем список (один) графиков для подгрузки данных
        var g = null;
        if (state.graphs) {
            for (var i in state.graphs) {
                if (i === field) {
                    g = {};
                    g[i] = state.graphs[i];
                    break;
                }
            }
        }

        //Сохраняем изменившиеся свойства через API
        Api.cancelFieldValue(field)
            .then(x => {
                return this.getData([field], false, true, g);
            })
            .then(x => {
                var cf = this.state.changedFields;
                cf[field] = null;
                this.setState({
                    ...this.defaultState(),
                    ...this.fieldLoadingState(field, false),
                    ...this.fieldUndoState(field, false),
                    changedFields: cf, //Очищаем список изменившихся полей
                    _success: "Информация успешно сохранена!"
                });
            })
            .catch(x => {
                this.setState({
                    ...this.defaultState(),
                    ...this.fieldLoadingState(field, false),
                    ...this.fieldUndoState(field, false),
                    _error: x.message
                });
            });
    };
    saveFields = (name, cFields) => {
        if (this.state._loading) return;
		
		//alert('test 555');

        var state = EditStates.get(name);
        var fields = state.form.fields || [];
        var converters = state.form.converters || {};

        //Вычисляем изменившиеся свойства
        let data = {};
        var exclude = state.form.noSave || [];

        var changed = [];
        let changedFields = cFields || this.state.changedFields;

        for (var i in changedFields) {
            changed.push(i);
        }

        for (let i = 0; i < fields.length; i++) {
            let f = fields[i];
            let v = this.state[f];
            if (v === null || v === undefined || v === "_clear") continue; //Исключаем пустые значения
            if (exclude.indexOf(f) > -1) continue; //Исключаем все значения в массиве noSave
            if (changed.indexOf(f) === -1) continue; //Исключаем все не измененные поля
            let conv = converters[f]; //Если задан конвертер, используем на данных
            v = data[f] = conv ? conv(v) : v;
        }

        this.setState({ ...this.defaultState(), _loading: true });

        //Если поля не верны, не сохраняем ничего
        if (!this.validateFields(data)) {
            this.setState({ _loading: false });
            return;
        }

        console.log("saving: ", data);

        //Сохраняем изменившиеся свойства через API
        return Api.setData(data)
            .then(x => {
                var proms = [this.getData(null, true)];
                //Если изменились поля профиля перезапрашиваем данные для карточки профиля слева
                if (state.form.form === "profile") {
                    proms.push(this.getUserCard());
                }
                return Promise.all(proms);
            })
            .then(x => {
                this.setState({
                    ...this.defaultState(),
                    changedFields: {}, //Очищаем список изменившихся полей
                    _success: "Информация успешно сохранена!"
                });
            })
            .catch(x => {
                this.setState({
                    ...this.defaultState(),
                    _error: x.message
                });
            });
    };
    handleConfirmNo = f => {
        this.setState(this.fieldConfirmState(f, ""));
    };
    scrollTop = () => {
        Scroll.animateScroll.scrollTo(0, { duration: 300 });
    };
    componentDidUpdate(prev) {
        this.setTitle();
        //Загружаем новые данные, только если изменилась форма
        if (this.props.match.params.form !== prev.match.params.form) {
            this.setState({ _loading: true });
            this.getData()
                .then(() => {
                    this.setState(this.defaultState());
                })
                .catch(this.toAuth);
            this.scrollTop();
        }
    }
    toAuth = () => {
        this.setState({ _redirect: <Redirect to="/auth" /> });
    };
    componentDidMount() {
        this.setTitle();
        this.setState({ _loading: true });

        Promise.all([this.getData(null, true), this.getUserCard()])
            .then(() => {
                this.setState(this.defaultState());
            })
            .catch(this.toAuth);

        window.addEventListener("scroll", this.handlePageScroll);

        this.scrollTop();
    }
    handlePageScroll = e => {
        let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        var ns = scrollTop > 10;
        if (ns !== this.state.showScroll) {
            this.setState({ showScroll: ns });
        }
        this.clearGoTo();
    };
    /**
     * Проверяет, есть ли несохраненные данные на форме
     * @param {string} form Идентификатор формы
     * @returns {Boolean}
     */
    checkUnsavedChanges = form => {
        // return false;
        var changed = this.state.changedFields;
        var state = EditStates.get(form);
        var fields = state.fields || [];
        var res = null;
        for (let i = 0; i < fields.length; i++) {
            if (changed[fields[i]] != null && changed[fields[i]] !== "") {
                res = res || {};
                res[fields[i]] = changed[fields[i]];
            }
        }
        return res || false;
    };
    clearGoTo = () => {
        this.setState({ confirmGoTo: false, confirmTo: "" });
    };
    /**
     * Проверяет, есть ли не сохраненные данные и осуществляет переход на др. страницу
     * @param {History} history История браузера
     * @param {string} to Адрес страницы перехода (относительный)
     * @param {Element} target Элемент, который вызвал переход, чтобы показть предупреждение в случае не сохраненных данных
     * @param {Boolean} isTop Если true, показывать предупреждение НАД элементом
     */
    goTo = (history, to, target, isTop) => {
        let rect = target.getBoundingClientRect();

        let form = this.props.match.params.form;
        let changed = this.checkUnsavedChanges(form);

        if (changed) {
            console.log("changed: ", changed);
            this.setState({
                confirmTo: to,
                confirmGoTo: true,
                goToPos: { x: rect.x, y: rect.y + rect.height, isTop: !!isTop }
            });
            return;
        }
        this.clearGoTo();
        history.push(to);
    };
    handleGoToConfirmYes = history => {
        this.clearGoTo();
        history.push(this.state.confirmTo);
    };
    handleGoToConfirmNo = () => {
        this.clearGoTo();
    };
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handlePageScroll);
    }
    getEditFormComponent = (props, form) => {
        if (form === "profile") return <ProfileForm {...props} />;
        if (form === "illnesses") return <IllnessesForm {...props} />;
        if (form === "lifestyle") return <LifestyleForm {...props} />;
        if (form === "food") return <FoodForm {...props} />;
        if (form === "physiology") return <PhysiologyForm {...props} />;
        if (form === "lab") return <LabForm {...props} />;
        if (form === "social") return <SocialForm {...props} />;
        if (form === "events") return <EventsForm {...props} />;
        if (form === "test") return <TestForm {...props} />;
        if (form === "devices") return <DevicesForm {...props} />;

        return null;
    };
    render() {
        if (this.state._redirect)
            return <React.Fragment>{this.state._redirect}</React.Fragment>;

        let form = this.props.match.params.form;
        var state = EditStates.get(form);

        var props = {
            onChange: this.handleChange,
            onConfirmNo: this.handleConfirmNo,
            form: state,
            fields: this.state,
            loading: this.state._loading,
            onSave: this.saveFields,
            onOneSave: this.saveOneField,
            onOneUndo: this.undoOneField,
            goTo: this.goTo
        };

        let content = this.getEditFormComponent(props, form);

        return (
            <div className={"edit" + (this.state._loading ? " loading" : "")}>
                {this.state.showScroll && (
                    <div
                        onClick={this.scrollTop}
                        className="fixed-btn scroll-up appear-up"
                    />
                )}
                <EditHeader form={state} />
                <EditSide
                    form={state}
                    user={this.state.user}
                    // checkChanges={this.checkUnsavedChanges}
                    goTo={this.goTo}
                />
                <div className="container main-container">
                    <div className="form">{content}</div>
                </div>
                {this.state.confirmGoTo && (
                    <Route
                        render={({ history }) => (
                            <div
                                className={
                                    "go-to-confirm" +
                                    (this.state.goToPos.isTop ? " is-top" : "")
                                }
                                style={{
                                    top: this.state.goToPos.y + "px",
                                    left: this.state.goToPos.x + "px"
                                }}
                            >
                                На форме остались не сохраненные данные, вы
                                уверены, что хотите перейти в другой раздел?
                                <div className="btns mt-half">
                                    <button
                                        onClick={() =>
                                            this.handleGoToConfirmYes(history)
                                        }
                                        className="mr-half"
                                    >
                                        Да
                                    </button>{" "}
                                    <button
                                        onClick={() =>
                                            this.handleGoToConfirmNo(history)
                                        }
                                    >
                                        Нет
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                )}
            </div>
        );
    }
}

export default EditLayout;
