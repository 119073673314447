import React from "react";
import "../../../css/comp/fast-graph.css";
import * as d3 from "d3";

import moment from "moment";
import "moment/locale/ru";

export class FastGraph extends React.Component {
    constructor(props) {
        super(props);

        this.svg = React.createRef();
        this.colors = this.props.colors || [
            "#38C559",
            "#3883C5",
            "#C53838",
            "#E6C800",
            "#e67a00"
        ];
        this.tipColors = {
            "#38C559": "cold-green",
            "#3883C5": "blue",
            "#C53838": "red",
            "#E6C800": "yellow",
            "#e67a00": "orange"
        };

        this.state = {};

        this.data = this.props.data;
        if (!this.data) {
            //Пример данных
            var data = [];
            for (var j = 0; j < 2; j++) {
                var a = [];

                for (var i = 0; i < 10; i++) {
                    var d = new Date();
                    d.setDate((30 / 10) * i);
                    a.push({
                        x: d,
                        y: Math.round(Math.random() * 5 + 8) * 1000
                    });
                }
                data.push(a);
            }
            this.data = data;
        }
    }
    formatByVal = (v, format) => {
        if (Date.prototype.isPrototypeOf(v)) return moment(v).format(format);
        else return v;
    };
    draw = () => {
        this.data = this.props.data || this.data;

        if (this.data.length === 1) {
            let sorted = this.data[0].sort((a, b) => a.x - b.x);
            let sel = sorted;
            if (sel.length > 8 && false) {
                sel = sorted.slice(sorted.length - 8);
            }
            if (sel.length > 0 && sel[0].x < moment().add(-1, "month") && false) {
                sel = sorted.slice(sorted.length > 16 ? sorted.length - 16 : 0);
            }
            this.data[0] = sel;
        }

        this.colors = this.props.colors || this.colors;
        var xFormat = this.props.xFormat || (v => this.formatByVal(v, "DD/MM"));
        var yFormat = this.props.yFormat || (v => this.formatByVal(v, "DD/MM"));

        //svg
        var svg = d3.select(this.svg.current);

        //Удаляем лишнее, если есть
        svg.selectAll("*").remove();
        //Габариты (хак для FF, нужно потом разобраться что не так с clientWidth)
        var w = this.svg.current.clientWidth;
        var h = this.svg.current.clientHeight;
        let ff = false;
        if (!w || !h) {
            let box = this.svg.current.getBoundingClientRect();
            w = box.right - box.left;
            h = box.bottom - box.top;
            ff = true;
        }
        //Отступы
        var margins = { top: 20, right: 0, bottom: 20, left: 30 };

        //Полный домен значений
        var fullData = [];
        for (let i = 0; i < this.data.length; i++) {
            fullData = fullData.concat(this.data[i]);
        }

        //Минимальная и максимальная дата (ось X)
        var minX = this.props.minX == undefined ? d3.min(fullData, d => d.x): this.props.minX;
        var maxX = this.props.maxX == undefined ? d3.max(fullData, d => d.x): this.props.maxX;
        //Минимальная и максимальная дата (ось Y)
        var minY = this.props.minY == undefined ? d3.min(fullData, d => d.y): this.props.minY;
        var maxY = this.props.maxY == undefined ? d3.max(fullData, d => d.y): this.props.maxY;

        var xr = d3
            .scaleTime()
            .range([margins.left, w - margins.right - 4])
            .domain([minX, maxX]);

        //Смещение диапозона на 10 пикселей снизу и сверху по Y
        var yOffset = this.props.yOffset == undefined ? ((maxY - minY) / (h - margins.top - margins.bottom)) * 10: this.props.yOffset;

        if(minY > yOffset)
            minY = minY - yOffset;

        
        maxY = maxY + yOffset;

        var yr = d3
            .scaleLinear()
            .range([h - margins.top, margins.bottom])
            .domain([minY, maxY]);
        
        //Рабочая область графика
        svg.append("rect")
            .attr("x", margins.left)
            .attr("y", margins.top)
            .attr("width", w - margins.left - margins.right)
            .attr("height", h - margins.top - margins.bottom)
            .attr("stroke", "none")
            .attr("fill", "#FFF");

        //Оси
        var axis = svg.append("g").attr("class", "axis");
        axis.append("path")
            .attr("d", `M${margins.left},0 L${margins.left},${h}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        axis.append("path")
            .attr("d", `M0,${margins.top} L${w},${margins.top}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        axis.append("path")
            .attr("d", `M0,${h - margins.bottom} L${w},${h - margins.bottom}`)
            .attr("stroke", "rgba(34, 42, 50, .30)");

        //Подписи
        var maxYt = yFormat(maxY || 0);
        var minYt = yFormat(minY || 0);
        var ls = "0";
        if (maxYt.length > 4 || minYt.length > 4) ls = "-.7px";
        else if (maxYt.length > 3 || minYt.length > 3) ls = "-.4px";

        //OY
        axis.append("text")
            .attr("x", margins.left - 3)
            .attr("y", margins.top - 3)
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .text(maxYt);
        axis.append("text")
            .attr("x", margins.left - 3)
            .attr("y", h - margins.bottom - 3)
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .text(minYt);

        //OX
        axis.append("text")
            .attr("x", margins.left + 3)
            .attr("y", h - margins.bottom + 3 + (ff ? 10 : 0))
            .attr("text-anchor", "start")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .attr("alignment-baseline", "hanging")
            .text(xFormat(minX));

        axis.append("text")
            .attr("x", w)
            .attr("y", h - margins.bottom + 3 + (ff ? 10 : 0))
            .attr("text-anchor", "end")
            .attr("font-size", "12px")
            .attr("letter-spacing", ls)
            .attr("alignment-baseline", "hanging")
            .text(xFormat(maxX));

        for (let i = 0; i < this.data.length; i++) {
            //График
            var line = d3
                .line()
                .x(d => xr(d.x))
                .y(d => yr(d.y))
                .curve(d3.curveMonotoneX);

            svg.append("svg:path")
                .attr("d", line(this.data[i]))
                .attr("stroke", this.colors[i])
                .attr("stroke-width", "2")
                .attr("fill", "none")
                .attr(
                    "transform",
                    "translate(0,-" + (margins.bottom - margins.top) + ")"
                )
                .attr("class", "fast-graph-line");
            //Точки
            svg.selectAll("dot")
                .data(this.data[i])
                .enter()
                .append("circle")
                .attr("stroke", this.colors[i])
                .attr("stroke-width", "2")
                .attr("fill", "#FFF")
                .attr("r", 3)
                .attr("cx", d => {
                    return xr(d.x);
                })
                .attr("cy", d => {
                    return yr(d.y);
                })
                .on("mouseover", d => {
                    this.setState({
                        tipX: xr(d.x),
                        tipY: yr(d.y),
                        tipText: xFormat(d.x) + ": " + yFormat(d.y),
                        tipColor: this.tipColors[this.colors[i]] || ""
                    });
                })
                .on("mouseout", d => {
                    this.setState({ tipText: "" });
                });
        }
    };
    componentDidMount() {
        this.draw();
    }
    componentDidUpdate() {
        if (this.props.data && this.props.data !== this.data) {
            this.draw();
        }
    }
    render() {
        var tipPos = {
            left: (this.state.tipX || 0) + "px",
            top: (this.state.tipY || 0) + "px"
        };
        return (
            <div className={"fast-graph " + this.props.className}>
                {this.props.label && <label>{this.props.label}:</label>}
                <div
                    className={`tip ${
                        this.state.tipText ? "visible appear-down" : ""
                    } ${this.state.tipColor || ""}`}
                    style={tipPos}
                >
                    {this.state.tipText || ""}
                </div>
                <svg ref={this.svg} />
            </div>
        );
    }
}

export default FastGraph;
