import React from "react";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { Check } from "../shared/CustomCheck";
import { LabeledInput } from "../shared/LabeledInput";
import { Radio, RadioGroup } from "../shared/CustomRadio";

import classnames from "classnames";

import "moment/locale/ru";
import { Slider } from "../shared/Slider";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class LabForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
        this.containerRef = React.createRef();
    }
    // handleSliderDragStart = name => {
    //     this.containerRef.current.className = classnames(
    //         this.containerRef.current.className,
    //         {
    //             "no-select": true
    //         }
    //     );
    // };
    // handleSliderDragStop = name => {
    //     this.containerRef.current.className = this.containerRef.current.className.replace(
    //         "no-select",
    //         ""
    //     );
    // };
    handleChange(v) {
        console.log('handleChange v', v)
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "Head_neck_reserach" && v.value !== 2) {
            fields["slart70"]       = -1;
            fields["hordart70"]     = -1;
            fields["anevrheads"]    = -1;
        }

        if (this.props.onChange)
            var that = this.props.onChange({ name: this.props.form, fields });
            console.log(that.state)
    }

    handleUnitsChange = option => {
        var s = {};
        s[option.name] = option.value;
        s[option.name + "-units"] = option.units;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields: s });
    };
    getUnitsValue = (name, units) => {
        let fields = this.props.fields || {};
        var cUnits = fields[name + "-units"] || "";
        var cVal = Number(fields[name]) || 0;

        if (cUnits === units) return cVal;
        if (cUnits === "ммоль/л")
            return Math.round(cVal * 38.7 * 10000) / 10000; // -> мг./дл.
        if (cUnits === "мг/дл")
            return Math.round((10000 * cVal) / 38.7) / 10000; // -> ммоль./л.
    };
    getUnits = name => {
        return (this.props.fields || {})[name + "-units"] || "";
    };

    componentDidMount() {}

    render() {
        var F = React.Fragment;
        let fields = this.props.fields || {};
        return (
            <div className="appear-left" ref={this.containerRef}>
                <h2>Иммунологический анализ крови</h2>
                <Check
                    className="col col-three col-last mb-1-5"
                    {...this.baseEditProps("citokeens")}
                >
                    Повышенный уровень цитокинов
                </Check>
                <h2 className="db cb">Биохимический анализ крови</h2>
                <div className="col col-six fl col-last">
                    <i className="comment db mb">

                        Обратите внимание, что данные результатов биохимического анализа
                        крови можно вводить только используя размерность <i>[ммоль/л]</i>.
                        Если результаты анализов были получены в размерности <i>[мг/дл]</i>,
                        Вы можете вручную перевести значения параметров по формуле:{" "}
                        <div className="div-center text-up">
                            <i className="green">значение [ммоль/л]</i> = <i className="green">значение{" "}
                            [мг/дл]</i> <span className="red">/</span>{" "}
                            <span className="green">38,7</span>
                        </div>
                    </i>
                </div>
                <div className="cb" />
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-TotalHol"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("TotalHol")}
                        label="Общий холестерин"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units="ммоль/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("TotalHol")}
                        options={[
                            { value: this.getUnitsValue, units: "ммоль/л" },
                            { value: this.getUnitsValue, units: "мг/дл" }
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-hol_lpnp"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("hol_lpnp")}
                        label="Холестерин ЛПНП"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units="ммоль/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("hol_lpnp")}

                        options={[
                            { value: this.getUnitsValue, units: "ммоль/л" },
                            { value: this.getUnitsValue, units: "мг/дл" }
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-hol_lpvp"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("hol_lpvp")}
                        label="Холестерин ЛПВП"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units="ммоль/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("hol_lpvp")}

                        options={[
                            { value: this.getUnitsValue, units: "ммоль/л" },
                            { value: this.getUnitsValue, units: "мг/дл" }
                        ]}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-triglycerides"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("triglycerides")}
                        label="Триглицериды"
                        className="col col-two col-last"
                        onOptionChange={this.handleUnitsChange}
                        units="ммоль/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("triglycerides")}

                        options={[
                            { value: this.getUnitsValue, units: "ммоль/л" },
                            { value: this.getUnitsValue, units: "мг/дл" }
                        ]}
                    />
                </div>
                <div className="cb" />
                {/* blood_clqotting */}
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-blood_clqotting"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("blood_clqotting")}
                        label="Свёртываемость крови (МНО)"
                        className="col col-two col-last"
                        type="number"
                        min="0"
                        onOptionChange={this.handleUnitsChange}
                        units="ед"
                        btns={this.baseOneSaveBtns("blood_clqotting")}

                    />
                </div>

                <div className="cb" />

                <Check
                    {...this.baseEditProps("blood_drug")}
                    className="col col-four col-last mb-1-5"
                >
                    Принимаю препараты, влияющие на свертываемость крови
                </Check>

                <div className="cb">
                    <div
                        className={
                            "col col-two fl" +
                            (fields["_loading-speed_club_filtrac"]
                                ? " loading"
                                : "")
                        }
                    >
                        <LabeledInput
                                {...this.baseEditProps("speed_club_filtrac")}
                                label="Скорость клубочковой фильтрации"
                                className="col col-two col-last"
                                units={
                                    <F>
                                        мл/мин/
                                        <span className="green">1,73</span>
                                        м²
                                    </F>
                                }
                                type="number"
                                min="0"
                                btns={this.baseOneSaveBtns("speed_club_filtrac")}

                            />
                    </div>
                </div>

                <div className="col col-four col-last mb-1-5">
                    <label>
                        Выберите все выявленные у Вас генетические полиморфизмы:
                    </label>
                    <Check
                        {...this.baseEditProps("mgas1", 0)}
                        className="col col-four col-last"
                    >
                        Повышенный уровень липопротеина-а и протеина Апо-Е
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas2", 0)}
                        className="col col-four col-last"
                    >
                        Снижение уровня активных антитромботических протеинов
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas3", 0)}
                        className="col col-four col-last"
                    >
                        Гомоцистеинемия
                    </Check>
                    <Check
                        {...this.baseEditProps("mgas4", 0)}
                        className="col col-four col-last"
                    >
                        Синдром CADASIL
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas5", 0)}
                        className="col col-four col-last"
                    >
                        SCNN1B (1b субъединица эпителиального Na канала)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas6", 0)}
                        className="col col-four col-last"
                    >
                        SCNN1G (1g субъединица эпителиального Na канала)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas7", 0)}
                        className="col col-four col-last"
                    >
                        АРNН (Na+/H+антипортер)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas8", 0)}
                        className="col col-four col-last"
                    >
                        REN (ренин)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas9", 0)}
                        className="col col-four col-last"
                    >
                        AGT(ангиотензинI)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas10", 0)}
                        className="col col-four col-last"
                    >
                        ACE (ангиотензинI конвертирующий фермент)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas11", 0)}
                        className="col col-four col-last"
                    >
                        PLA2 (панкреатическая фосфолипаза А2)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas12", 0)}
                        className="col col-four col-last"
                    >
                        SAN (гипертензия, обусловленная геном, экспрессирующимся в почке)
                    </Check>
					
                    <Check
                        {...this.baseEditProps("mgas13", 0)}
                        className="col col-four col-last"
                    >
                        NOS3 (эндотелиальная синтаза окиси азота)
                    </Check>
					
					
					
                </div>
                <div className="cb" />
                <h2 className="db cb">Анализ мочи на альбумин и креатинин</h2>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-creatinin20"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("creatinin20")}
                        label="Креатинин в порции мочи, концентрация"
                        className="col col-two col-last"
                        units="ммоль/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("creatinin20")}

                    />
                </div>
                <div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-albumin"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("albumin")}
                        label="Альбумин в порции мочи, концентрация"
                        className="col col-two col-last"
                        units="мг/л"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("albumin")}

                    />
                </div>
                {/*<div
                    className={
                        "col col-two fl col-last" +
                        (fields["_loading-albumin_day"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("albumin_day")}
                        label="Альбумин, суточная экскреция"
                        className="col col-two col-last"
                        units="мг./сутки"
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("albumin_day")}

                    />
                </div>*/}

                <h2 className="db cb">Функциональные исследования</h2>
                <div className="col col-four fl mb-1-5">
                    <label>
                        Данные электрокардиографии{" "}
                        <i className="comment">(ЭКГ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PHTLJ")}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("PQQSEKG")}
                    >
                        Патологический зубец Q или интервал QS
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("bllpg")}
                    >
                        Блокада левой ножки пучка Гиса
                    </Check>
                </div>
                <div className="col col-two fl col-last">
                    <i className="comment">
                        Данные для данного раздела необходимо заполнить из
                        заключения.
                    </i>
                </div>
                <div className="cb" />
                {/* <div className="col col-four col-last cb mb-1-5">
                    <label>
                        Данные Эхо-кардиографии{" "}
                        <i className="comment">(Эхо-КГ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-eho"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-eho")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-four fl mb-1-5">
                    <label>
                        Данные компьютерной томографии{" "}
                        <i className="comment">(КТ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-kt"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-kt")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("calcium-index-kt")}
                        label="Кальциевый индекс по КТ"
                        // className="col col-two col-last"
                    />
                </div> */}
                {/* <div className="col col-four cb col-last mb-1-5">
                    <label>
                        Данные магниторезонансной томографии{" "}
                        <i className="comment">(МРТ)</i>:
                    </label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps(
                            "left-ventricular-hypertrophy-mrt"
                        )}
                    >
                        Гипертрофия левого желудочка
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("heart-attak-signes-mrt")}
                    >
                        Признаки перенесенного инфаркта
                    </Check>
                </div> */}
                {/* <div className="col col-four col-last mb-1-5">
                    <label>
                        Данные коронароангиографии{" "}
                        <i className="comment">
                            (стеноз просвета коронарных сосудов)
                        </i>
                        :
                    </label>
                    <Slider
                        {...this.baseEditProps("coronary-angiography")}
                        ticks={10}
                        from={0}
                        to={100}
                        digits={0}
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                        units="%"
                    />
                </div> */}
                {/* <div className="col col-four col-last mb-1-5">
                    <label>
                        Данные ультразвукового <i className="comment">(УЗДГ)</i>{" "}
                        исследования периферических артерий:
                        <i className="comment db">
                            (стеноз сосудов атеросклеротическими бляшками)
                        </i>
                    </label>
                    <Slider
                        {...this.baseEditProps("uzdg-peripheral-arteries")}
                        ticks={10}
                        from={0}
                        to={100}
                        digits={0}
                        onStartDrag={this.handleSliderDragStart}
                        onStopDrag={this.handleSliderDragStop}
                        units="%"
                    />
                </div> */}
                <div className="cb" />
                {/* <div className="col col-two fl">
                    <LabeledInput
                        {...this.baseEditProps("pulse-vawe-speed")}
                        label="Скорость пульсовой волны"
                        units="м./с."
                        // className="col col-two col-last"
                    />
                </div> */}
                {/* <div className="col col-two col-last fl">
                    <LabeledInput
                        {...this.baseEditProps("lpi")}
                        type="number"
                        min="0"
                        label={(() => (
                            <F>
                                Лодыжечно-плечевой индекс{" "}
                                <i className="comment">(ЛПИ)</i>
                            </F>
                        ))()}
                        // className="col col-two col-last"
                    />
                </div> */}

                <div
                    className={
                        "col col-two col-last fl" +
                        (fields["_loading-lpi"] ? " loading" : "")
                    }
                >
                    <LabeledInput
                        {...this.baseEditProps("lpi")}
                        label="Лодыжечно-плечевой индекс"
                        className="col col-two col-last"
                        min="0"
                        type="number"
                        onOptionChange={this.handleUnitsChange}
                        units="ед"
                        btns={this.baseOneSaveBtns("lpi")}

                    />
                </div>

                <div className="cb" />
                <div className="col col-three fl mb-1-5">
                    <label>
                        Проводилось ли Вам исследование <br />
                        сосудов головы и шеи?
                    </label>
                    <RadioGroup {...this.baseEditProps("Head_neck_reserach")}>
                        <Radio value={0} className="col col-three col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-three col-last">
                            Да, отклонений не выявлено
                        </Radio>
                        <Radio value={2} className="col col-three col-last">
                            Да, выявлены отклонения
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["Head_neck_reserach"], 0) === 2 && (
                    <div className="col col-three col-last fl mb-1-5 mt appear-left">
                        <label>Отклонения, выявленные при исследовании:</label>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("slart70")}
                        >
                            Просвет сонных артерий сужен ≥70%
                        </Check>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("hordart70")}
                        >
                            Просвет позвоночных артерий сужен ≥70%
                        </Check>
                        <Check
                            className="col col-three col-last"
                            {...this.baseEditProps("anevrheads")}
                        >
                            Аневризмы сосудов головного мозга
                        </Check>
                        <div className="cb" />
                    </div>
                )}
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/social",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Среда и социальное состояние»
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default LabForm;
