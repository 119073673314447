import React from "react";

import { Check } from "../../shared/CustomCheck";
import { LabeledInput } from "../../shared/LabeledInput";

import { Radio, RadioGroup } from "../../shared/CustomRadio";

class AppoinmentList extends React.Component{

    render () {
        const eventTypes    = this.props.eventTypes;
        const eventTypesIds = this.props.eventTypesIds;
        const listItems = eventTypesIds.map((eventTypeId) =>
            <div 
                key={eventTypeId.toString()} 
                className="appointment-list-item" 
                onClick={() => {
                    this.props.onClick(eventTypes[eventTypeId])
                }}>
                {eventTypes[eventTypeId].name}
            </div>
        );

        return (
          <div className="appointment-list">
              <div className="appointment-list-items">
                {listItems}
              </div>
              <div className="appointment-list-counter">
                Всего найдено мероприятий: {listItems.length} 
              </div>
          </div>
        );
      }
}

class AppoinmentStageFilter extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            stage: 0
        }
    }

    handleFilterChange = (event) => {
       
        this.setState((oldState) => {
            oldState.stage = event.value;
            return oldState;
        },
        () => {
            this.props.onChange(this.state.stage);
        });

    }

    componentDidMount(){
        this.props.onChange(this.state.stage);
    }

    render(){
        return <div className="appointment-list-filter">
            <label>Этап:</label>
            <RadioGroup
                onChange={this.handleFilterChange}
                name="stage"
                value={this.state.stage}
            >
                <Radio value={0} className="col col-three col-last">
                    Все
                </Radio>
                <Radio value={1} className="col col-three col-last">
                    Первый
                </Radio>
                <Radio value={2} className="col col-three col-last">
                    Второй
                </Radio>
            </RadioGroup>
        </div>
    }
}

class AppoinmentPicker extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            modalIsOpen: false,
            nameFilter: "",
            filteredEventTypesIds: [],
            stageFilter: [],
            selectedEvent: undefined
        }

    }

    componentDidMount = () => {
        this.filterEventTypes()
    }

    componentWillReceiveProps = (nextProps) => {
        this.filterEventTypes()
        return nextProps
    }

    filterEventTypes = () => {
        const nameFilter        = this.state.nameFilter;
        const stageFilter       = this.state.stageFilter;

        const eventTypes        = this.props.eventTypes;
        
        var filteredEventTypesIds  = this.state.filteredEventTypesIds;

        filteredEventTypesIds = Object.keys(eventTypes).filter(
            (eventTypeId) => {

                return (
                    (this.props.eventsData[eventTypeId] === undefined)
                    && 
                    (
                        stageFilter === 0 || stageFilter === eventTypes[eventTypeId].stage
                    )
                    &&
                    (
                        nameFilter == "" || eventTypes[eventTypeId].name.toLowerCase().includes(nameFilter.toLowerCase())
                    )
                )
            }
        )
        
        this.setState({
            filteredEventTypesIds: filteredEventTypesIds
        })

    }

    handleNameFilterChange = (changeEvent) => {
        if(changeEvent.value === this.state.nameFilter) return;

        this.setState((oldState) => {
            oldState.nameFilter = changeEvent.value
                return oldState
            },
            () => {
                this.filterEventTypes()    
            }
        );
    }

    handleStageFilterChange = (stage) => {
        this.setState(            
            (oldState) => {
                oldState.stageFilter = stage
                return oldState
            },
            () => {
                this.filterEventTypes()
            }
        );
    }

    handleEventCreate = (date) => {
        const eventType = this.state.selectedEventType
        this.props.onEventCreate({ eventType, date })
        .then( () => {
            this.setState(            
                (oldState) => {
                    oldState.selectedEventType  = undefined
                    oldState.modalIsOpen        = false
                    return oldState
                },
                () => {
                    this.filterEventTypes()
                }
            )
        })
    }

    handleItemClick = (eventType) => {
        this.props.onItemClick(eventType)
    }

    render(){
        return <div className="appointment-picker">
            <LabeledInput
                {...this.props}
                label="Поиск"
                className="col col-three col-last"
                type="string"
                value={this.state.nameFilter}
                onChange={this.handleNameFilterChange}
            />

            <AppoinmentStageFilter
                onChange={this.handleStageFilterChange}
            />

            <AppoinmentList
                eventTypes={this.props.eventTypes}
                eventTypesIds={this.state.filteredEventTypesIds}
                onClick={this.handleItemClick}
            />
        </div>
    }
}

export default AppoinmentPicker;