import React from "react";
import { Redirect } from "react-router-dom";
import cx from "classnames";

import RiskHeader from "../RiskHeader";
import PopupBlock from "../PopupBlock";
import OrderedList from "../OrderedList";
import IndicatorBlock from "./IndicatorBlock";
import HistoryDatePicker from "./HistoryDatePicker";

import { Api } from "../../../libs/api";
import "../../../css/comp/risk/problem-page.css";
import FastGraphRisks from "../../shared/FastGraphRisks";

import moment from "moment";
import "moment/locale/ru";

export default class ProblemPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoaded: false,
            error: null,
            openedDetailsId: null,
            graphData: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { selectedDate, match } = this.props;
        if (
            selectedDate !== prevProps.selectedDate ||
            match.params.problemId !== prevProps.match.params.problemId
        ) {
            this.setState({ isLoaded: false });
            this.fetchData();
        }
    }

    fetchData = () => {
        const { selectedDate, match } = this.props;
		
		//alert('Параметры для Api.getRiskInfo: selectedDate = '+ selectedDate + '; match.params.problemId = ' + match.params.problemId); //PF
		
        Api.getRiskInfo(selectedDate, match.params.problemId)
            .then(res => {
                this.setState({
                    data: res.result,
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({
                    isLoaded: true,
                    error: err
                });
            });
        let risk = this.props.match.params.problemId;
        let sd = moment()
            .subtract(10, "years")
            .format("YYYY-MM-DD");
        let ed = moment()
            .add(1, "days")
            .format("YYYY-MM-DD");
        Api.getRiskDynamic({ risk: risk, startDate: sd, endDate: ed })
            .then(x => {
                let graphData = this.prepareGraphData(x.result);
                this.setState({ graphData: graphData });
            })
            .catch(x => {});
    };

    prepareGraphData = (data) => {
        console.log("before prepareGraphData", data)

        Object.keys(data.trends).forEach(
            (trendId) => {

                var temp = []

                var dates = Object.keys(data.trends[trendId])
                .sort(
                    (a, b) => moment(b) - moment(a)
                )
                .reduce(
                    (acc, date) => {
                        
                        var d = moment(date).format("DD.MM.YYYY")
                        
                        if(!temp.includes(d)){
                            temp.push(d)
                            acc.push(date)
                        }

                        return acc
                    },
                    []
                )

                var temp_trend = data.trends[trendId]
                data.trends[trendId] = {}
                
                dates.forEach(
                    (date) => {
                        data.trends[trendId][date] = temp_trend[date]
                    }
                )

            }
        )

        var temp = []

        var dates = Object.keys(data.actual_risks)
        .sort(
            (a, b) => moment(b) - moment(a)
        )
        .reduce(
            (acc, date) => {
                var d = moment(date).format("DD.MM.YYYY")
                        
                if(!temp.includes(d)){
                    temp.push(d)
                    acc.push(date)
                }

                return acc
            },
            []
        )

        var temp_risks = data.actual_risks
        data.actual_risks = {}

        dates.forEach(
            (date) => {
                data.actual_risks[date] = temp_risks[date]
            }
        )

        return data
    }

    handleDetailsClick = id => {
        var s = {};
        s[id + "-opened"] = !this.state[id + "-opened"];
        this.setState(s);
    };
    handleDetailsCloseClick = id => {
        var s = {};
        s[id + "-opened"] = false;
        this.setState(s);
    };

    getBarTitle = v => {
        var color =
            v <= 20
                ? "Низкий"
                : v <= 40
                ? "Ниже среднего"
                : v <= 60
                ? "Средний"
                : v <= 80
                ? "Выше среднего"
                : "Высокий";
        return color;
    };

    render() {
        const { historyDates, selectedDate, onSelectDate } = this.props;
        const { data, isLoaded, error } = this.state;

        if (error) {
            return <Redirect to="/auth/login" />;
        }
        if (!isLoaded) {
            return <h2>Подождите, происходит загрузка...</h2>;
        }

        const renderedGroups = data.risks.map(group => (
            <div key={group.rang} className="rang-group">
                <IndicatorBlock className="mt-1-5" fill={group.rang * 20}>
                    <OrderedList
                        heading={"Факторы риска"}
                        items={group.factors.map(factor => factor.name)}
                    />
                </IndicatorBlock>
                
                <OrderedList
                    className="with-border-bottom mt-half recs-list"
                    heading={"Рекомендации"}
                    items={data.recs
                        .filter(rec => rec.rang === group.rang)
                        .map(rec => {
                            const content = (
                                <div className="recommendation-summary">
                                    {rec.name}
                                    <i className="db comment">
                                        <div
                                            className="rawhtml"
                                            dangerouslySetInnerHTML={{
                                                __html: rec.description
                                            }}
                                        />
                                    </i>

                                    <div
                                        className="details-button fr"
                                        onClick={() =>
                                            this.handleDetailsClick(rec.id)
                                        }
                                    >
                                        <a className="text">Подробнее</a>
                                        <span
                                            className={cx(
                                                "icon",
                                                this.state[rec.id + "-opened"]
                                                    ? "down"
                                                    : "right"
                                            )}
                                        />
                                    </div>
                                </div>
                            );
                            const after = this.state[rec.id + "-opened"] ? (
                                <PopupBlock
                                    className="appear-up"
                                    title={"Пояснение"}
                                    onCloseClick={() =>
                                        this.handleDetailsCloseClick(rec.id)
                                    }
                                    arrowStyle={{ left: `${60 - 8}px` }}
                                >
                                    <div
                                        className="rawhtml"
                                        dangerouslySetInnerHTML={{
                                            __html: rec.explanation //PF
                                        }}
                                    />
                                </PopupBlock>
                            ) : null;
                            return { content, after };
                        })}
                />
            </div>
        ));

        return (
            <div className="problem-page">
                <RiskHeader title={data.name}>
                    <HistoryDatePicker
                        selected={selectedDate}
                        items={historyDates}
                        onSelect={onSelectDate}
                    />
                </RiskHeader>

                <div className="container main-container">
                    <div className="form appear-left">
                        <i className="comment db">{data.description}</i>
                        <h2 className="mt-1-5 mb">
                            Динамика изменения рисков:
                        </h2>
                        <div className="col col-six col-last">
                            <FastGraphRisks
                                data={this.state.graphData}
                                xFormat={v => moment(v).format("DD.MM.YY")}
                                yFormat={v => Math.round(v * 10) / 10}
                                tipFormat={(x, y) =>
                                    moment(x).format("MMM YY") +
                                    ": " +
                                    this.getBarTitle(y)
                                }
                                className="mb"
                            />
                        </div>
                        <h2 className="mt-1-5">
                            Факторы риска и рекомендации:
                        </h2>
                        {renderedGroups}
                    </div>
                </div>
            </div>
        );
    }
}
