import React from "react";

import "../../css/comp/check.css";

export class Check extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: this.props.disabled
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if (this.props.disabled) return;

        var nv = Number(this.props.value) !== 1 ? 1 : 0;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.name, value: nv });
    }

    componentWillReceiveProps() {
        this.setState({ selected: this.props.checked });
    }

    render() {
        let classname = `${this.props.className} input check${
            Number(this.props.value) === 1 ? " check-checked" : " check-unchecked"
        } ${this.props.disabled ? "disabled" : ""}`;
        return (
            <div className={classname} onClick={this.toggle.bind(this)}>
                {this.props.children}
            </div>
        );
    }
}
