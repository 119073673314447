import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import { Radio, RadioGroup } from "../../shared/CustomRadio";
import {Route} from "react-router-dom";
import $ from "jquery";

export class HADSForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=14;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
		
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }
    render() {
        return (
            <div className="appear-left">
                <i className="comment db">
                    Ученые уверены в том, что эмоции играют важную роль в
                    возникновении большинства заболеваний. Если Ваш доктор
                    больше узнает о Ваших переживаниях, он сможет лучше помочь
                    Вам. Этот опросник разработан для того, чтобы помочь Вашему
                    доктору понять, как Вы себя чувствуете. Не обращайте
                    внимания на цифры и буквы, помещенные в левой части
                    опросника. Прочитайте внимательно каждое утверждение и в
                    пустой графе слева отметьте крестиком ответ, который в
                    наибольшей степени соответствует тому, как Вы себя
                    чувствовали на прошлой неделе. Не раздумывайте слишком долго
                    над каждым утверждением. Ваша первая реакция всегда будет
                    более верной
                </i>
                <h2 className="db cb mt2">Часть I: Оценка уровня тревоги</h2>
                <div className="col col-four col-last q1">
                    <label>1. Я испытываю напряжение, мне не по себе</label>
                    <RadioGroup {...this.baseEditProps("HADS_anxiety_1", -1)}>
                        <Radio value={3} className="col col-four">
                            Все время
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Часто
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Время от времени, иногда
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q2">
                    <label>
                        2. Я испытываю страх, кажется, что что-то ужасное может
                        вот-вот случиться
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_anxiety_2", -1)}
                    >
                        <Radio value={3} className="col col-four">
                            Определенно это так, и страх очень велик
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Да, это так, но страх не очень велик
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Иногда, но это меня не беспокоит
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q3">
                    <label>3. Беспокойные мысли крутятся у меня в голове</label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_anxiety_3", -1)}
                    >
                        <Radio value={3} className="col col-four">
                            Постоянно
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Большую часть времени
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Время от времени и не так часто
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Только иногда
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q4">
                    <label>4. Я легко могу присесть и расслабиться</label>
                    <RadioGroup {...this.baseEditProps("HADS_anxiety_4", -1)}>
                        <Radio value={0} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Наверно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Лишь изредка, это так
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Совсем не могу
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q5">
                    <label>5. Я испытываю внутреннее напряжение или дрожь</label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_anxiety_5", -1)}
                    >
                        <Radio value={0} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Иногда
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Часто
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Очень часто
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q6">
                    <label>
                        6. Я испытываю неусидчивость, мне постоянно нужно двигаться
                    </label>
                    <RadioGroup {...this.baseEditProps("HADS_anxiety_6", -1)}>
                        <Radio value={3} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Наверно, это так
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Лишь в некоторой степени, это так
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q7">
                    <label>7. У меня бывает внезапное чувство паники</label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_anxiety_7", -1)}
                    >
                        <Radio value={3} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Наверно, это так
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Лишь в некоторой степени, это так
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <h2 className="db cb mt-1-5">
                    Часть II: Оценка уровня депрессии
                </h2>
                <div className="col col-six col-last q8">
                    <label>
                        8. То, что приносило мне большое удовольствие, и сейчас
                        вызывает у меня такое же чувство
                    </label>
                    <RadioGroup
                        {...this.baseEditProps(
                            "HADS_depression_1",
                            -1
                        )}
                    >
                        <Radio value={0} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Наверно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Лишь в некоторой степени, это так
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q9">
                    <label>
                        9. Я способен рассмеяться и увидеть в том или ином событии
                        смешное
                    </label>
                    <RadioGroup {...this.baseEditProps("HADS_depression_2", -1)}>
                        <Radio value={0} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Наверно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Лишь в некоторой степени, это так
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Совсем не испытываю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q10">
                    <label>10. Я испытываю бодрость</label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_depression_3", -1)}
                    >
                        <Radio value={3} className="col col-four">
                            Cовсем не испытываю
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Очень редко
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Иногда
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Практически все время
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q11">
                    <label>
                        11. Мне кажется, что я стал все делать очень медленно
                    </label>
                    <RadioGroup {...this.baseEditProps("HADS_depression_4", -1)}>
                        <Radio value={3} className="col col-four">
                            Практически все время
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Часто
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Иногда
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Совсем нет
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q12">
                    <label>12. Я не слежу за своей внешностью</label>
                    <RadioGroup {...this.baseEditProps("HADS_depression_5", -1)}>
                        <Radio value={3} className="col col-four">
                            Определенно, это так
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Я не уделяю этому столько времени, сколько нужно
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Может быть, я стал меньше уделять этому времени
                        </Radio>
                        <Radio value={0} className="col col-four">
                            Я слежу за собой так же, как и раньше
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 q13">
                    <label>
                        13. Я считаю, что мои дела{" "}
                        <i className="comment">(занятия, увлечения)</i> могут
                        принести мне чувство удовлетворения
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_depression_6", -1)}
                    >
                        <Radio value={0} className="col col-four">
                            Точно так же, как и обычно
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Да, но не в той степени, как раньше
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Значительно меньше, чем обычно
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Совсем так не считаю
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mt-1-5 mb-1-5 q14">
                    <label>
                        14. Я могу получить удовольствие от хорошей книги, радио-
                        или телепрограммы
                    </label>
                    <RadioGroup
                        {...this.baseEditProps("HADS_depression_7", -1)}
                    >
                        <Radio value={0} className="col col-four">
                            Часто
                        </Radio>
                        <Radio value={1} className="col col-four">
                            Иногда
                        </Radio>
                        <Radio value={2} className="col col-four">
                            Редко
                        </Radio>
                        <Radio value={3} className="col col-four">
                            Очень редко
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default HADSForm;
