import React from "react";
import { BaseEditForm } from "./base-edit-form";
import Button from "../shared/button";
import { Api } from "../../libs/api";

export class DevicesForm extends BaseEditForm {
    constructor(props) {
        super(props);
        this.state = { vkAuthUrl: "" };
    }

    handlePsyTest = () => {
        if (this.state.vkAuthUrl) window.open(this.state.vkAuthUrl);
    };

    vkLoginScopes = () => {
        window["handleVkLoginResponse"] = (x, e) => {
            if (e.url) {
                this.setState({ vkAuthUrl: e.url });
            }
        };

        window.open(
            "https://oauth.vk.com/authorize?client_id=" +
                Api.config.vkApiId +
                "&v=5.92&response_type=token&display=popup&scope=" +
                (2 + 4 + 1024 + 8192 + 65536 + 262144) +
                "&revoke=1&redirect_uri=http://" +
                document.location.host +
                "/auth/vklogin",
            "_blank",
            "toolbar=no,scrollbars=no,resizable=no,width=400,height=400"
        );
    };

    // Google Fitness

    update_auth_url = () => {
        return Api
        .point('gfit/auth', {}, 'POST')
        .then(r => {
            return this.setState({ gFitLoginSuccess: r.authorized, auth_url: r.auth_url })
        })
    }

    authorize = code => {
        Api
        .point('gfit/auth', {"code": code}, 'POST')
        .then(r => { 
            this.setState({ gFitLoginSuccess: r.authorized });
            
            if(!r.authorized && r.message)
                alert(r.message);

        });
    }

    revokeAccess = () => {
        if(this.state.gFitLoginSuccess){
            Api
            .point('gfit/revoke-access', {}, 'POST')
            .then(r => {
                this.setState({ gFitLoginSuccess: false })
            })
            .catch(r => {
                alert('Ошибка отключения Google fit')
            })
        }
    }

    gFitLogin = () => {
        window["handleGFitLoginReponse"] = this.authorize;
        
        this.update_auth_url()
        .then(r => {
            window.open(
                this.state.auth_url,
                "_blank",
                "toolbar=no,scrollbars=no,resizable=no,width=880,height=600,top=" +
                    (window.screen.height / 2 - 300) +
                    ",left=" +
                    (window.screen.width / 2 - 440)
            );
        })
        .catch(() => {
            alert('Ошибка авторизации Google Fitness')
        })
    };

    componentDidMount(){
        this.update_auth_url()
        
        console.log('componentDidMount');

        window.addEventListener('message', (messageEvent) => {
            this.authorize(messageEvent.data.code);
        });
    }

    componentWillUnmount = () => {
        window["handleGFitLoginReponse"]    = null;
        window["handleVkLoginResponse"]     = null;
    };

    render() {
        let F = React.Fragment;
        return (
            <div className="appear-left">
                <h2 className="db cb">
                    Учетные записи социальных сетей и сервисов
                </h2>
                <div className="glyph-list mb">
                    <div className="db glyph-item glyph-vk">
                        <div className="col col-four fl">
                            <div className="title">Учетная запись VK</div>
                            <i className="comment db">
                                Подключив вашу учетную запись VK вы позволите
                                системе собирать данные связанные с Вашим
                                социальным окружением, Ваш психологичесий
                                портрет и прочие данные. Нажмите «Подключить»
                                в&nbsp;блоке справа, чтобы подключить Вашу
                                учетную запись.
                            </i>
                        </div>
                        <div className="col col-two fl col-last mb-half appear-left">
                            {!this.state.vkAuthUrl ? (
                                <F>
                                    <div className="info-baloon mt-half mb">
                                        Нажмите на кнопку ниже, чтобы подключить
                                        профиль VK.
                                    </div>
                                    <Button
                                        onClick={this.vkLoginScopes}
                                        className="col col-two col-last"
                                    >
                                        Подключить
                                    </Button>
                                </F>
                            ) : (
                                <F>
                                    <div className="info-baloon mt-half mb">
                                        Вы успешно подключили учетную запись VK!
                                        Нажмите кнопку ниже, чтобы пройти оценку
                                        психологического состояния
                                    </div>
                                    <Button
                                        className="col col-two col-last"
                                        onClick={this.handlePsyTest}
                                    >
                                        Пройти оценку
                                    </Button>
                                </F>
                            )}
                        </div>
                        <div className="cb" />
                    </div>
                    <div className="db glyph-item glyph-fit">
                        <div className="col col-four fl">
                            <div className="title">
                                Учетная запись GoogleFit
                            </div>
                            <i className="comment db">
                                Подключив Вашу учетную запись GoogleFit вы
                                позволите системе автоматически собирать данные
                                о&nbsp;вашей активности и&nbsp;состоянии
                                здоровья с&nbsp;различных источников, таких как:
                                телефоны, сенсоры, умные часы, фитнес-браслеты,
                                умные весы и&nbsp;прочие гаджеты.
                            </i>
                        </div>
                        <div className="col col-two fl col-last mb-half appear-left">
                            {!this.state.gFitLoginSuccess ? (
                                <F>
                                    <div className="info-baloon mt-half mb">
                                        Нажмите на кнопку ниже, чтобы получить
                                        данные из GoogleFit
                                    </div>
                                    <Button
                                        className="col col-two col-last"
                                        onClick={this.gFitLogin}
                                    >
                                        Подключить
                                    </Button>
                                </F>
                            ) : (
                                <F>
                                    <div className="info-baloon mt-half mb">
                                        Вы успешно подключили учетную запись
                                        Google.Fit!
                                    </div>
                                    <Button 
                                        className="col col-two col-last btn-red"
                                        onClick={this.revokeAccess}
                                    >
                                        Отключить
                                    </Button>
                                </F>
                            )}
                        </div>
                        <div className="cb" />
                    </div>
                    <script type="text/javascript">
                        
                    </script>
                </div>
                {/* <h2 className="db">Собираемые данные</h2>
                <div className="col col-four col-last glyph-list mb-1-5">
                    <div className="db glyph-item glyph-step">
                        <div className="title">Данные об активности</div>
                        <i className="comment db">— Длительность активности</i>
                        <i className="comment db">— Количество шагов</i>
                    </div>
                    <div className="db glyph-item glyph-heart">
                        <div className="title">Данные о здоровье</div>
                        <i className="comment db">— Ритм биения сердца</i>
                        <i className="comment db">— Пульс</i>
                    </div>
                    <div className="db glyph-item glyph-scales">
                        <div className="title">Антропометрические данные</div>
                        <i className="comment db">— Вес</i>
                        <i className="comment db">— Процент жировой ткани</i>
                    </div>
                </div> */}
                <div className="cb" />
            </div>
        );
    }
}

export default DevicesForm;
