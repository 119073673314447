import React from "react";
import { Route } from "react-router-dom";
import Button from "../shared/button";
import { Check } from "../shared/CustomCheck";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class LifestyleForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "smoking_status" && (v.value === 0 || v.value === 4)) {
            fields["smoking"] = -1;
            fields["cigaretts_per_day"] = -1;
        }

        if (v.name === "alco_now" && v.value === 0) {
            fields["alco"] = -1;
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidMount() {
        console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <div className="col col-two col-last mb-1-5">
                    <label>Ваша оценка качества сна:</label>
                    <Check
                        className="col col-two col-last"
                        {...this.baseEditProps("bad_sleep")}
                    >
                        Частые пробуждения
                    </Check>
                    <Check
                        className="col col-two col-last"
                        {...this.baseEditProps("sleepless")}
                    >
                        Долгое засыпание
                    </Check>
                </div>
                <h2 className="db cb">Курение</h2>
                <div className="col col-two fl mb-1-5">
                    <label>В настоящее время:</label>
                    <RadioGroup {...this.baseEditProps("smoking_status")}>
                        <Radio value={0} className="col col-two">
                            Никогда не курил
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Продолжаю курить
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Бросил менее года назад
                        </Radio>
                        <Radio value={3} className="col col-two">
                            Бросил более года назад
                        </Radio>
                        <Radio value={4} className="col col-two">
                            Бросил более 10 лет назад
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["smoking_status"], -1) > 0 && this.emptyOrDef(fields["smoking_status"], -1) < 4 && (
                    <React.Fragment>
                        <div className="col col-two fl appear-up">
                            <label>Продолжительность:</label>
                            <RadioGroup {...this.baseEditProps("smoking")}>
                                <Radio value={0} className="col col-two">
                                    5 или менее лет
                                </Radio>
                                <Radio value={1} className="col col-two">
                                    от 6 до 10 лет
                                </Radio>
                                <Radio value={2} className="col col-two">
                                    Более 10 лет
                                </Radio>
                            </RadioGroup>
                        </div>
                        <div className="col col-two col-last fl appear-left">
                            <label>Количество сигарет в день:</label>
                            <RadioGroup
                                {...this.baseEditProps("cigaretts_per_day")}
                            >
                                <Radio value={0} className="col col-two">
                                    Менее 5 штук
                                </Radio>
                                <Radio value={1} className="col col-two">
                                    От 5 до 10 штук
                                </Radio>
                                <Radio value={2} className="col col-two">
                                    От 11 до 20 штук
                                </Radio>
                                <Radio value={3} className="col col-two">
                                    Более 20 штук
                                </Radio>
                            </RadioGroup>
                        </div>
                    </React.Fragment>
                )}
                <div className="cb" />
                <h2 className="db cb">Злоупотребление алкоголем</h2>
                <i className="db comment col col-six mb">
                    Чрезмерным употреблением спиртных напитков считается
                    потребление в день более 45 мл. крепких 40° напитков (водка,
                    коньяк) или 150 мл. сухого (10°) вина или 500 мл. пива
                </i>
                <div className="col col-two fl mb-1-5">
                    <label>В настоящее время:</label>
                    <RadioGroup {...this.baseEditProps("alco_now")}>
                        <Radio value={0} className="col col-two">
                            Никогда не злоупотреблял
                        </Radio>
                        <Radio value={1} className="col col-two">
                            Злоупотребляю в настоящее время
                        </Radio>
                        <Radio value={2} className="col col-two">
                            Ранее злоупотреблял
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["alco_now"], -1) > 0 && (
                    <React.Fragment>
                        <div className="col col-two fl appear-up">
                            <label>Продолжительность:</label>
                            <RadioGroup {...this.baseEditProps("alco")}>
                                <Radio value={0} className="col col-two">
                                    5 или менее лет
                                </Radio>
                                <Radio value={1} className="col col-two">
                                    от 6 до 10 лет
                                </Radio>
                                <Radio value={2} className="col col-two">
                                    Более 10 лет
                                </Radio>
                            </RadioGroup>
                        </div>
                        {/* <div className="col col-two fl col-last mb-1-5 appear-left">
                            <div className="info-baloon mt-label mb">
                                Более точные данные о характере потребления
                                алкоголя вы можете внести, пройдя специальный
                                тест
                            </div>
                            <Button
                                to="/edit/polls/alcohol"
                                className="col col-two col-last"
                            >
                                Приступить к выполнению
                            </Button>
                        </div> */}
                    </React.Fragment>
                )}
                <h2 className="db cb">Активность</h2>
                <div className="col col-four fl mb-1-5">
                    <label>Двигательная активность:</label>
                    <RadioGroup {...this.baseEditProps("move_activ")}>
                        <Radio value={0} className="col col-four col-last">
                            Очень высокая активность
                            <i className="comment db">
                                (Хожу пешком более 1.5 часа в день. Занимаюсь 5
                                и более раз в неделю дополнительной физической
                                активностью (любой)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Высокая активность
                            <i className="comment db">
                                (Хожу пешком более 1 часа в день. Занимаюсь
                                более 3-х раз в неделю дополнительной физической
                                активностью (любой)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Умеренная активность
                            <i className="comment db">
                                (Хожу пешком не менее 1 часа в день. Занимаюсь
                                2-3 раза в неделю дополнительной физической
                                активностью (любой)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Низкая активность
                            <i className="comment db">
                                (Хожу пешком менее 30 минут в день.
                                <br /> Не имею другой физической нагрузки)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                            Очень низкая активность
                            <i className="comment db">
                                (Хожу пешком менее 15 минут в день.
                                <br /> Не имею другой физической нагрузки)
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-two col-last fl">
                    <label>
                        Ограничение возможности передвижения, в том числе из-за
                        травм, инвалидности и других заболеваний:
                    </label>
                    <RadioGroup {...this.baseEditProps("move_problem")}>
                        <Radio value={1} className="col fl">
                            Да
                        </Radio>
                        <Radio value={0} className="col col-last fl">
                            Нет
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                    {/*<div className="col col-two fl col-last mb-1-5 appear-left">
                        <div className="info-baloon with-glyph mt-label mb">
                            <div className="glyph google-fit" />
                            Нажмите сюда, чтобы разрешить системе собирать более точные данные о Вашей активности
                        </div>
                            <Route
                                render={({ history }) => (
                                    <Button
                                        className="col col-two col-last"
                                        onClick={e =>
                                            this.props.goTo(
                                                history,
                                                "/edit/devices",
                                                e.target, 
                                                true
                                            )
                                        }
                                    >
                                        Подключить
                                    </Button>
                                )}
                            />
                    </div>*/}
                </div>
                <div className="cb" />
                <div className="col col-four mb-1-5">
                    <label>
                        Как давно у Вас сохраняется такой уровень двигательной
                        активности?
                    </label>
                    <RadioGroup {...this.baseEditProps("move_duration")}>
                        <Radio value={0} className="col col-two col-last">
                            Менее 5 лет
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Последние 5 лет и более
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />

                <div className="col col-six col-last mb-1-5">
                    <label>
                        Выберите максимальный уровень активности, при выполнении
                        которой у Вас не возникает неприятных ощущений, одышки,
                        болей за грудиной или головокружения:
                    </label>
                    <RadioGroup {...this.baseEditProps("activity_status")}>
                        <Radio value={0} className="col col-four col-last">
                            Обслуживать себя, есть, одеваться, выполнять
                            гигиенические процедуры
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Перемещаться по квартире
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Пройти расстояние 100 м. по ровной местности со
                            скоростью 3-5 км/ч
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Выполнять легкую работу по дому, например, вытирать
                            пыль, мыть посуду
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                            Подниматься на 1-2 лестничных пролета или идти в
                            гору
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                            Пробежать короткую дистанцию
                        </Radio>
                        <Radio value={6} className="col col-four col-last">
                            Выполнять тяжелую работу по дому, такую как мыть
                            полы, поднимать или передвигать мебель
                        </Radio>
                        <Radio value={7} className="col col-four col-last">
                            Заниматься спортом с умеренными энергозатратами,
                            например, играть в боулинг, танцевать, играть в
                            парный теннис
                        </Radio>
                        <Radio value={8} className="col col-four col-last">
                            Заниматься спортом с высокими энергозатратами, таким
                            как плавание, теннис, футбол, баскетбол, лыжи
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                </div>
				
				<h2 className="db cb">Влияние профессиональной вредности</h2>
                <div className="col col-four col-last mb-1-5">
                    <label>Работа в ночное время, суточная работа:</label>
                    <RadioGroup {...this.baseEditProps("work_night_daily")}>
                        <Radio value={0} className="col col-four col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Периодически
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Постоянно
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>Шум, постоянное напряжение зрения, внимания:</label>
                    <RadioGroup {...this.baseEditProps("work_noise_strain")}>
                        <Radio value={0} className="col col-four col-last">
                            Нет
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Периодически
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Постоянно
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>Рабочие условия:</label>
                    <RadioGroup {...this.baseEditProps("hight_job_perdomance")}>
                        <Radio value={2} className="col col-four col-last">
                            Нормальная рабочая неделя
                            <i className="comment db">
                                (40 рабочих часов в неделю)
                            </i>
                        </Radio>
                        <Radio value={0} className="col col-four col-last">
                            Ненормированный рабочий день
                            <i className="comment db">
                                (от 48 до 54 рабочих часов в неделю)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Высокая рабочая нагрузка
                            <i className="comment db">
                                (от 55 и более рабочих часов в неделю)
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
				
                <h2>Прочее</h2>
                <div className="col col-four mb-1-5">
                    <label>
                        Интенсивность использования социальных сетей и мессенджеров:
                    </label>
                    <RadioGroup {...this.baseEditProps("social_net")}>
                        <Radio value={0} className="col col-four col-last">
                            Низкая{" "}
                            <i className="comment db">
                                (захожу реже 1-2 раз в день не больше, чем на 2
                                часа суммарно)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            Средняя{" "}
                            <i className="comment db">
                                (провожу от 2 до 4-ех часов в течение дня)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                            Высокая
                            <i className="comment db">
                                (провожу 5 часов в течение дня)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                            Не использую
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>Освещение:</label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("IDLL3H")}
                    >
                        Пребывание при дневном свете меньше 3 часов в сутки
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("HMLN")}
                    >
                        Искусственное освещение ночью
                    </Check>
                </div>

                <div className="btn-container mt">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/edit/food",
                                        e.target, 
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Перейти к разделу «Питание»
                            </a>
                        )}
                    />
                    <Button
                        className="fr"
                        loading={this.props.loading}
                        onClick={this.handleSaveClick}
                    >
                        Сохранить
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default LifestyleForm;
