import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CalendarYearPage from "./CalendarYearPage";
import CalendarMonthPage from "./CalendarMonthPage";
// import EventsListPage from "./EventsListPage";


export default class RiskLayout extends React.Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route exact path={`${match.path}/calendar`} component={CalendarYearPage} />
                <Route path={`${match.path}/calendar/:date(\\d{4}-\\d{2})`} component={CalendarMonthPage} />

                {/* <Route path={`${match.path}/list`} component={EventsListPage} /> */}

                <Redirect to={`${match.path}/calendar`} />
			</Switch>
        );
    }
}
