import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";

import { Route } from "react-router-dom";

export class SelfRelationsForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
        if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = ["Нет", "Да"];
        return (
            <div className="appear-left">
                <h2 className="db cb">
                    Тест на выявление уровней самоотношения
                </h2>
                <i className="comment db mb">
                    Опросник самоотношения (ОСО) построен в соответствии с
                    разработанной В.В.Столиным иерархической моделью структуры
                    самоотношения. Опросник позволяет выявить три уровня
                    самоотношения, отличающихся по степени обобщенности:
                </i>
                <ul className="comment db mb">
                    <li>глобальное самоотношение;</li>
                    <li>
                        самоотношение, дифференцированное по самоуважению,
                        аутсимпатии, самоинтересу и ожиданиям отношения к себе;
                    </li>
                    <li>
                        уровень конкретных действий (готовностей к ним) в
                        отношении к своему «Я».
                    </li>
                </ul>
                <i className="comment db mb-1-5">
                    Вам предлагается ответить на следующие 57 утверждений. Если
                    Вы согласны с данным утверждением выберите «Да», если не
                    согласны — «Нет».
                </i>
                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset poll-bool">
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_0")}
                        >
                            Думаю, что большинство моих знакомых относится ко
                            мне с симпатией.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_1")}
                        >
                            Мои слова не так уж часто расходятся с делом.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_2")}
                        >
                            Думаю, что многие видят во мне что-то сходное с
                            собой.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_3")}
                        >
                            Когда я пытаюсь себя оценить, я прежде всего вижу
                            свои недостатки.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_4")}
                        >
                            Думаю, что как личность я вполне могу быть
                            притягательным для других.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_5")}
                        >
                            Когда я вижу себя глазами любящего меня человека,
                            меня неприятно поражает то, насколько мой образ
                            далек от действительности.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_6")}
                        >
                            Мое «Я» всегда мне интересно.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_7")}
                        >
                            Я считаю, что иногда не грех пожалеть самого себя.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_8")}
                        >
                            В моей жизни есть или по крайней мере были люди с
                            которыми я был чрезвычайно близок.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_9")}
                        >
                            Собственное уважение мне еще надо заслужить.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_10")}
                        >
                            Бывало, и не раз, что я сам себя остро ненавидел.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_11")}
                        >
                            Я вполне доверяю своим внезапно возникшим желаниям.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_12")}
                        >
                            Я сам хотел во многом себя переделать.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_13")}
                        >
                            Мое собственное «Я» не представляется мне чем-то
                            достойным глубокого внимания.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_14")}
                        >
                            Я искренне хочу, чтобы у меня было все хорошо в
                            жизни.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_15")}
                        >
                            Если я и отношусь к кому-нибудь с укоризной, то
                            прежде всего к самому себе.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_16")}
                        >
                            Случайному знакомому я скорее всего покажусь
                            человеком приятным.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_17")}
                        >
                            Чаще всего я одобряю свои планы и поступки.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_18")}
                        >
                            Собственные слабости вызывают у меня что-то
                            наподобие презрения.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_19")}
                        >
                            Если бы я раздвоился, то мне было бы довольно
                            интересно общаться со своим двойником.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_20")}
                        >
                            Некоторые свои качества я ощущаю как посторонние,
                            чужие мне.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_21")}
                        >
                            Вряд ли кто-либо сможет почувствовать свое сходство
                            со мной.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_22")}
                        >
                            У меня достаточно способностей и энергии воплотить в
                            жизнь задуманное.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_23")}
                        >
                            Часто я не без издевки подшучиваю над собой.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_24")}
                        >
                            Самое разумное, что может сделать человек в своей
                            жизни – это подчиниться собственной судьбе.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_25")}
                        >
                            Посторонний человек, на первый взгляд, найдет во мне
                            много отталкивающего.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_26")}
                        >
                            К сожалению, если я и сказал что-то, это не значит,
                            что именно так и буду поступать.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_27")}
                        >
                            Свое отношение к самому себе можно назвать
                            дружеским.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_28")}
                        >
                            Быть снисходительным к собственным слабостям вполне
                            естественно.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_29")}
                        >
                            У меня не получается быть для любимого человека
                            интересным длительное время.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_30")}
                        >
                            В глубине души я бы хотел, чтобы со мной произошло
                            что-то катастрофическое.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_31")}
                        >
                            Вряд ли я вызываю симпатию у большинства моих
                            знакомых.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_32")}
                        >
                            Мне бывает очень приятно увидеть себя глазами
                            любящего меня человека.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_33")}
                        >
                            Когда у меня возникает какое-либо желание, я прежде
                            всего спрашиваю о себя, разумно ли это.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_34")}
                        >
                            Иногда мне кажется, что если бы какой-то мудрый
                            человек смог увидеть меня насквозь, он бы тут же
                            понял, какое я ничтожество.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_35")}
                        >
                            Временами я сам собой восхищаюсь.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_36")}
                        >
                            Можно сказать, что я ценю себя достаточно высоко.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_37")}
                        >
                            В глубине души я никак не могу поверить, что я
                            действительно взрослый человек.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_38")}
                        >
                            Без посторонней помощи я мало что могу сделать.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_39")}
                        >
                            Иногда я сам себя плохо понимаю.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_40")}
                        >
                            Мне очень мешает недостаток энергии, воли и
                            целеустремленности.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_41")}
                        >
                            Думаю, что другие в целом оценивают меня достаточно
                            высоко.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_42")}
                        >
                            В моей личности есть, наверное, что-то такое, что
                            способно вызывать у других неприязнь.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_43")}
                        >
                            Большинство моих знакомых не принимают меня уж так
                            всерьез.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_44")}
                        >
                            Сам у себя я довольно часто вызываю чувство
                            раздражения.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_45")}
                        >
                            Я вполне могу сказать, что уважаю себя сам.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_46")}
                        >
                            Даже мои негативные черты не кажутся мне чужими.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_47")}
                        >
                            В целом, меня устраивает то, какой я есть.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_48")}
                        >
                            Вряд ли меня можно любить по-настоящему.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_49")}
                        >
                            Моим мечтам и планам не хватает реалистичности.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_50")}
                        >
                            Если бы мое второе «Я» существовало, то для меня это
                            был бы самый скучный партнер по общению.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_51")}
                        >
                            Думаю, что мог бы найти общий язык с любым разумным
                            и знающим человеком.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_52")}
                        >
                            То, что во мне происходит, как правило, мне понятно.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_53")}
                        >
                            Мои достоинства вполне перевешивают мои недостатки.
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_54")}
                        >
                            Вряд ли найдется много людей, которые обвинят меня в
                            отсутствии совести.
                        </PollRadios>
                        <PollRadios
                            opts={[0, 1]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_55")}
                        >
                            Когда со мной случаются неприятности, как правило, я
                            говорю: «И поделом тебе».
                        </PollRadios>
                        <PollRadios
                            opts={[1, 0]}
                            titles={vals}
                            {...this.baseEditProps("selfrelation_56")}
                        >
                            Я могу сказать, что в целом я контролирую свою
                            судьбу.
                        </PollRadios>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Save answers
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SelfRelationsForm;
