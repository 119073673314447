import React from "react";

export class PollLegend extends React.PureComponent {
    render() {
        var opts = this.props.opts || [];
        return (
            <div className={"poll-legend " + (this.props.className || "")}>
                {opts.map((x, i) => (
                    <div
                        key={i}
                        className="poll-radio input radio radio-unchecked"
                    >
                        {x.key}.{" "}
                        <div
                            className="info-baloon abs two-line"
                        >
                            {x.title}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
