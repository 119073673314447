import React from 'react'

class FeedbackButton extends React.Component{
    constructor(props){
        super(props);
        this.action = props.action;
    }

    render(){
        return <a href='' {...this.props}>
            Feedback
            <i className="material-icons md-light">feedback</i>
        </a>
    };
}

export default FeedbackButton;