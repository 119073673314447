import React from "react";
import Button from "../shared/button";

class RegSecondForm extends React.Component {
    constructor(props) {
        super(props);

        this.toRegister = this.toRegister.bind(this);
    }
    toRegister() {
        //Передаем выше
        if (this.props.toRegister) this.props.toRegister();
    }
    render() {
        return (
            <div className="form-right pl-1-5 appear-left form-fixed-height">
                <h2>Успешно!</h2>
                <i className="comment mb-1-5 db">
                    Вы успешно зарегистрировались в системе. Чтобы начать
                    работу, авторизуйтесь, воспользовавшись формой слева
                </i>
                <div className="btn-container">
                    <Button className="fr" onClick={this.toRegister}>
                        Вернуться
                    </Button>
                </div>
            </div>
        );
    }
}

export default RegSecondForm;
