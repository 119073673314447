import React from "react";
import cx from 'classnames';

import OrderedList from '../OrderedList';


const MONTHS_NAMES = [
    // январь февраль март апрель май июнь июль август сентябрь октябрь ноябрь декабрь
    ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
    ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'],
]

export default function EventsListMonth({ eventsData, month, onEventDelete, onEventReschedule }) {

    const groupedByDay = Object.keys(eventsData)
        .reduce(
            (obj, eventTypeId) => {
                if(!eventsData[eventTypeId].event) return obj;
                
                const date = new Date(eventsData[eventTypeId].event.date);
                
                if(date.getMonth() !== month) return obj;
                
                const day = date.getDate();
                
                
                if(!obj.hasOwnProperty(day)){
                    obj[day] = {
                        heading: `${date.getDate()} ${MONTHS_NAMES[0][date.getMonth()]} ${date.getFullYear()}`,
                        eventTypes: [],
                        key: eventTypeId
                    } 
                }

                obj[day].eventTypes.push(
                    eventsData[eventTypeId]
                    )
                
                return obj;
            },
            {}
        )

    return (
        <div>
            {Object.keys(groupedByDay).map((day) => (
                <OrderedList
                    key={groupedByDay[day].key}
                    className="mb-1-5"
                    heading={groupedByDay[day].heading}
                    items={groupedByDay[day].eventTypes.map((eventType) => (
                        <React.Fragment>
                            {eventType.name}
                            
                            <a
                            
                            style={{
                                cursor:     "pointer",
                                display:    "inline-block",
                                margin:     "0 10px"
                            }}

                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    onEventDelete(eventType)
                                }
                            }
                            >Удалить</a>
                            
                            {/* <a
                            
                            style={{
                                cursor:     "pointer",
                                display:    "inline-block",
                                margin:     "0 10px"
                            }}

                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    onEventReschedule(eventType.event.id)}
                            }
                            >Переместить</a> */}

                            {eventType.description && (
                                <i className="db comment">{eventType.description}</i>
                            )}
                        </React.Fragment>
                    ))}
                />
            ))}
        </div>
    )
}
