import React from "react";
import { Link } from "react-router-dom";


export default function RiskSide(props) {
    return (
        <div className="side">
            <div className="side-head nav-panel appear-right">
                <div className="nav-item red results current">
                    Health condition, risk assessment and recommendations
                </div>
            </div>
            <div className="nav-panel">
                {/* <Link className="nav-item dashboard cold-green" to="/dashboard" /> */}
                <Link className="nav-item profile yellow" to="/eng/edit" />
            </div>
            <div className="secondary-panel appear-right" 
            // style={{ display: "none" }}
            >
                {props.children}
                <div className="bottom-panel">
                    <Link className="glyph glyph-exit fr" to="/eng/auth">Logout</Link>
                </div>
            </div>
        </div>
    );
}
