import React from "react";
import cx from 'classnames';

import "../../css/comp/risk/dropdown.css";


export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.default,
            isOpen: false,
        };
    }

    select = (item) => {
        if (this.props.onChange) this.props.onChange(item)
        this.setState({ selected: item })
    }
    show = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hide);
    }
    hide = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hide);
    }

    render() {
        return (
            <div className={cx("dropdown", { open: this.state.isOpen }, this.props.className)}>
                <div
                    className="dropdown-display"
                    title={this.state.selected}
                    onClick={this.show}
                >
                    {this.state.selected}
                    <span className="icon down"></span>
                </div>
                {this.state.isOpen ? (
                    <div className="dropdown-list appear-up">
                        {this.props.items.map((item) => (
                            <div
                                key={item}
                                className={cx("dropdown-item", { selected: item === this.state.selected })}
                                onClick={() => this.select(item)}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        )
    }
}
