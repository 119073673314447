import React from "react";
import PropTypes from "prop-types";

import "../../css/comp/radio.css";

export class Radio extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selected: false };
    }

    toggle() {
        const { onChange } = this.context.radioGroup;
        const selected = !this.state.selected;
        this.setState({ selected });
        onChange(selected, this);
    }

    setSelected(selected) {
        this.setState({ selected });
    }

    render() {
        let classname = `${this.props.className} input radio${
            this.state.selected ? " radio-checked" : " radio-unchecked"
        }`;
        return (
            <div className={classname} onClick={this.toggle.bind(this)}>
                {this.props.children}
            </div>
        );
    }
}

Radio.contextTypes = {
    radioGroup: PropTypes.object
};

export class RadioGroup extends React.Component {
    constructor(props) {
        super(props);
        this.options = [];
    }

    getChildContext() {
        const { name } = this.props;
        return {
            radioGroup: {
                name,
                onChange: this.onChange.bind(this)
            }
        };
    }

    onChange(selected, child) {

        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.name,
                value: selected ? child.props.value : "_clear"
            });
        }
    }
    
    componentDidMount() {
        if (this.props.value || this.props.value === 0) {
            this.options.forEach(option => {
                if (option) {
                    option.setSelected(option.props.value === this.props.value);
                }
            });
        }
    }
    componentDidUpdate(prev) {
        if (this.props.value !== prev.value) {
            this.options.forEach(option => {
                if (option) {
                    let sel = option.props.value === this.props.value;
                    if (!sel && typeof option.props.value === "number") {
                        sel = option.props.value === Number(this.props.value);
                    }
                    option.setSelected(sel);
                }
            });
        }
    }
    render() {
        let children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                ref: component => {
                    if (component instanceof Radio) {
                        //Если не проверить, в список попадут все HTML ноды
                        this.options.push(component);
                    }
                }
            });
        });
        return <React.Fragment>{children}</React.Fragment>;
    }
}

RadioGroup.childContextTypes = {
    radioGroup: PropTypes.object
};
