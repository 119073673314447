import React from "react";
import RegSecondForm from "./reg-second";
import RegForm from "./reg-form";

class RightAuthForm extends React.Component {
    constructor(props) {
        super(props);

        this.toSecond = this.toSecond.bind(this);
        this.toRegister = this.toRegister.bind(this);

        this.state = { form: "reg-form" };
    }
    toSecond() {
        this.setState({ form: "reg-second" });
    }
    toRegister() {
        this.setState({ form: "reg-form" });
    }
    emailChanged(val) {
        this.setState({ email: val });
    }
    render() {
        switch (this.state.form) {
            case "reg-second":
                return <RegSecondForm toRegister={this.toRegister} />
            default:
                return <RegForm toSecond={this.toSecond} />
        }
    }
}

export default RightAuthForm;