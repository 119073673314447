import React, { Component } from "react";
import { Route } from "react-router-dom";
import cx from "classnames";

import FillArc from "./fill-arc";
import "../../css/comp/menu-item.css";

export class MenuItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirm: false
        };
    }
    handleClick = (history, e) => {
        // if (this.props.checkChanges) {
        //     let changed = this.props.checkChanges(this.props.form);

        //     if (changed) {
        //         this.setState({ confirm: true });
        //         return;
        //     }
        // }
        // history.push(this.props.to);
        if(this.props.goTo)
        {
            return this.props.goTo(history, this.props.to, e.target);
        }
        history.push(this.props.to)
    };
    render() {
        let props = this.props;
        let title = "";
        if (typeof props.title === "string") {
            title = props.title;
        } else if (typeof props.title === "function") {
            title = props.title(props.fill);
        }
        return (
            <Route
                render={({ history }) => (
                    <div
                        onClick={(e) => this.handleClick(history, e)}
                        className={cx(
                            "menu-item",
                            {
                                selected: props.selected,
                                "with-glyph": !!props.glyph
                            },
                            props.glyph
                        )}
                        title={title}
                    >
                        {props.children}
                        {!!props.fill && <FillArc fill={props.fill} />}
                        {/* {this.state.confirm && (
                            <div className="confirm">
                                На форме остались не сохраненные данные, вы
                                уверены, что хотите перейти в другой раздел?
                                <div className="btns mt-half">
                                    <button
                                        onClick={() =>
                                            this.handleConfirmYes(history)
                                        }
                                        className="mr-half"
                                    >
                                        Да
                                    </button>{" "}
                                    <button
                                        onClick={() =>
                                            this.handleConfirmNo(history)
                                        }
                                    >
                                        Нет
                                    </button>
                                </div>
                            </div>
                        )} */}
                    </div>
                )}
            />
        );
    }
}

export default MenuItem;
