import React from "react";
import { Link } from "react-router-dom";
import FeedbackSide from '../edit/feedback-side'


export default function RiskSide(props) {
    return (
        <div className="side">
            <div className="side-head nav-panel appear-right">
                <div className="nav-item red results current">
                    Состояние здоровья, оценка рисков и рекомендации
                </div>
            </div>
            <div className="nav-panel">
                {/* <Link className="nav-item dashboard cold-green" to="/dashboard" /> */}
                <Link className="nav-item profile yellow" to="/edit" />
            </div>
            <div className="secondary-panel appear-right">
                {props.children}
                <div className="bottom-panel">
					<FeedbackSide/>
					<br />
                    <Link className="glyph glyph-exit fr" to="/auth">Выйти</Link>
                </div>
            </div>
        </div>
    );
}
