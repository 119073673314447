import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import {PollLegend} from  "./poll-legend";
import {Route} from "react-router-dom";
import $ from "jquery";

export class SocialProblemsForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = (v) => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = (history) => {
		//alert('Тестовое сообщение');
		var v = '';
		for(let q=1;q<=38;q++){
			var html = $(".q"+q).html();
			if(html.indexOf('radio-checked') < 0){
				v += '<li>Вопрос #'+q+': нет ответа!</li>';
			}

		} // for
		
		if(v != ''){
			//alert(v);
			$('#fon').css({'display': 'block'});
			$('#popup-content').html(v);
			$('#popup').css({'display': 'block'});
		}else{
			if (this.props.onSave) this.props.onSave(this.props.form, history);
		}
        //if (this.props.onSave) this.props.onSave(this.props.form, history);
    };

    componentDidMount() {
        // console.log(this.fieldsOnForm.join("','"));
    }

    render() {
        var vals = [0, 1, 2, 3, 4];
        let F = React.Fragment;
        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    Тест состоит из утверждений, которые описывают то, как вы
                    решаете свои повседневные проблемы. Прочитайте каждое
                    утверждение внимательно и укажите номер ответа, который
                    наилучшим образом описывает то, насколько вы с ним согласны.
                </i>
                <div className="poll-header">
                    <h2 className="db cb">
                        Тест на выявление способности к решению социальных проблем{" "}
                    </h2>
                    <PollLegend
                        className="pt3"
                        opts={[
                            { key: "1", title: (<F>Совершенно<br/>не верно</F>) },
                            { key: "2", title: (<F>В некоторой<br/>степени верно</F>) },
                            { key: "3", title: (<F>В целом<br/>верно</F>) },
                            { key: "4", title: (<F>Верно</F>), twoline:true },
                            { key: "5", title: (<F>Совершенно<br/>верно</F>) },
                        ]}
                    />
                </div>
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    <ol className="poll-reset">
						<div className="q1">
                        <PollRadios
                            opts={vals}
                            {...this.baseEditProps("social_problem_1")}
                        >
                            Я провожу слишком много времени, беспокоясь
                            по&nbsp;поводу своих проблем вместо того, чтобы
                            пытаться их&nbsp;решить.
                        </PollRadios>
						</div>
						<div className="q2">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_2")}
							>
								Я чувствую угрозу и испытываю страх, когда передо
								мной стоит задача решить важную проблему.
							</PollRadios>
						</div>
						<div className="q3">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_3")}
							>
								Когда я принимаю решения, я не&nbsp;достаточно
								тщательно взвешиваю все свои возможности.
							</PollRadios>
						</div>
						<div className="q4">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_4")}
							>
								Когда мне нужно принять решение, я упускаю
								из&nbsp;виду те&nbsp;последствия, которые каждое
								из&nbsp;них может нести для благополучия других
								людей.
							</PollRadios>
						</div>
						<div className="q5">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_5")}
							>
								Когда я пытаюсь решить проблему, я обычно взвешиваю
								различные варианты решения и&nbsp;затем пытаюсь
								совместить некоторые из&nbsp;них для&nbsp;достижения
								наилучшего результата.
							</PollRadios>
						</div>
						<div className="q6">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_6")}
							>
								Я нервничаю и&nbsp;чувствую себя неуверенно, когда
								мне нужно принимать важное решение.
							</PollRadios>
						</div>
						<div className="q7">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_7")}
							>
								Когда я пытаюсь решить проблему я действую согласно
								первой возникшей у&nbsp;меня идее.
							</PollRadios>
						</div>
						<div className="q8">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_8")}
							>
								Когда бы&nbsp;передо мной не&nbsp;возникала
								проблема, я верю, что&nbsp;смогу ее&nbsp;решить.
							</PollRadios>
						</div>
						<div className="q9">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_9")}
							>
								Я жду, не&nbsp;решится ли&nbsp;проблема сама, прежде
								чем&nbsp;приступать к&nbsp;ее&nbsp;решению.
							</PollRadios>
						</div>
						<div className="q10">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_10")}
							>
								Когда мне нужно решить проблему, первое что&nbsp;я
								делаю — анализирую ситуацию и&nbsp;пытаюсь
								обнаружить препятствия, которые мешают мне получить
								желаемое.
							</PollRadios>
						</div>
						<div className="q11">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_11")}
							>
								Когда мои первые попытки решить проблему
								проваливаются, я сильно расстраиваюсь.
							</PollRadios>
						</div>
						<div className="q12">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_12")}
							>
								Когда передо мной возникает сложная проблема, я
								сомневаюсь в&nbsp;своей способности решить
								самостоятельно, как&nbsp;бы&nbsp;сильно я
								ни&nbsp;старался.
							</PollRadios>
						</div>
						<div className="q13">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_13")}
							>
								Когда проблема появляется в&nbsp;моей жизни, я
								откладываю ее&nbsp;решение на&nbsp;столько, сколько
								это возможно.
							</PollRadios>
						</div>
						<div className="q14">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_14")}
							>
								После выработки решения проблемы я не&nbsp;трачу
								время на&nbsp;тщательную оценку всех результатов.
							</PollRadios>
						</div>
						<div className="q15">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_15")}
							>
								Трудные проблемы очень расстраивают меня.
							</PollRadios>
						</div>
						<div className="q16">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_16")}
							>
								Когда мне необходимо принять решение, я стараюсь
								спрогнозировать позитивные и&nbsp;негативные
								последствия каждого варианта решения.
							</PollRadios>
						</div>
						<div className="q17">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_17")}
							>
								Когда проблемы возникают в&nbsp;моей жизни, я
								стараюсь решать их&nbsp;как можно скорее.
							</PollRadios>
						</div>
						<div className="q18">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_18")}
							>
								Когда я пытаюсь решить проблему, я стараюсь
								проявлять изобретательность и&nbsp;придумываю новые
								или&nbsp;оригинальные решения.
							</PollRadios>
						</div>
						<div className="q19">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_19")}
							>
								При возникновении проблемы я решаю ее&nbsp;первым
								же&nbsp;способом, пришедшим мне в&nbsp;голову.
							</PollRadios>
						</div>
						<div className="q20">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_20")}
							>
								Когда я размышляю о&nbsp;различных способах решения
								проблемы, я не&nbsp;могу придумать много вариантов.
							</PollRadios>
						</div>
						<div className="q21">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_21")}
							>
								Я предпочитаю не&nbsp;думать о&nbsp;проблемах
								в&nbsp;моей жизни вместо того, чтобы решать их.
							</PollRadios>
						</div>
						<div className="q22">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_22")}
							>
								Когда я принимаю решения, я рассматриваю
								как&nbsp;незамедлительно возникающие последствия,
								так&nbsp;и&nbsp;отсроченные результаты каждого
								варианта.
							</PollRadios>
						</div>
						<div className="q23">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_23")}
							>
								После того, как я решил проблему, я анализирую, что
								в&nbsp;процессе пошло хорошо, а&nbsp;что плохо.
							</PollRadios>
						</div>
						<div className="q24">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_24")}
							>
								Прежде чем реализовать какое-то решение своей
								проблемы, я тренируюсь в&nbsp;его воплощении,
								чтобы&nbsp;повысить свои шансы на&nbsp;успех.
							</PollRadios>
						</div>
						<div className="q25">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_25")}
							>
								Когда я сталкиваюсь с&nbsp;трудной проблемой, я
								верю, что смогу ее&nbsp;решить самостоятельно, если
								буду достаточно стараться.
							</PollRadios>
						</div>
						<div className="q26">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_26")}
							>
								Когда я сталкиваюсь с&nbsp;проблемой, я начинаю
								собирать столько фактов о&nbsp;ней, сколько
								возможно.
							</PollRadios>
						</div>
						<div className="q27">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_27")}
							>
								Я откладываю решение проблем до&nbsp;того момента,
								когда уже слишком поздно что-либо предпринимать.
							</PollRadios>
						</div>
						<div className="q28">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_28")}
							>
								Я провожу больше времени, избегая проблем, нежели
								пытаясь их&nbsp;решить.
							</PollRadios>
						</div>
						<div className="q29">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_29")}
							>
								Когда я пытаюсь решить проблему, я
								так&nbsp;расстраиваюсь, что&nbsp;не&nbsp;могу
								мыслить ясно.
							</PollRadios>
						</div>
						<div className="q30">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_30")}
							>
								Перед тем, как&nbsp;решать проблему, я ставлю перед
								собой конкретную цель, чтобы знать наверняка, чего я
								хочу достичь.
							</PollRadios>
						</div>
						<div className="q31">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_31")}
							>
								Когда мне необходимо принять решение,
								я&nbsp;не&nbsp;уделяю времени рассмотрению всех
								«за»&nbsp;и&nbsp;«против» каждого варианта.
							</PollRadios>
						</div>
						<div className="q32">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_32")}
							>
								Я ненавижу решать проблемы, возникающие в&nbsp;моей
								жизни.
							</PollRadios>
						</div>
						<div className="q33">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_33")}
							>
								Когда я сталкиваюсь с&nbsp;проблемой, я стараюсь
								рассматривать ее как&nbsp;испытание или возможность
								в&nbsp;некоторой степени выиграть
								от&nbsp;ее&nbsp;наличия.
							</PollRadios>
						</div>
						<div className="q34">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_34")}
							>
								Я впадаю в&nbsp;депрессию и&nbsp;чувствую себя
								парализованным, когда мне необходимо решать трудную
								проблему.
							</PollRadios>
						</div>
						<div className="q35">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_35")}
							>
								Когда я сталкиваюсь со&nbsp;сложной проблемой, я
								обращаюсь к&nbsp;другим людям с&nbsp;просьбой помочь
								мне решить ее.
							</PollRadios>
						</div>
						<div className="q36">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_36")}
							>
								В момент принятия решений я&nbsp;следую своему
								«шестому чувству», а&nbsp;не&nbsp;обдумываю слишком
								долго последствия тех&nbsp;или&nbsp;иных ходов.
							</PollRadios>
						</div>
						<div className="q37">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_37")}
							>
								Когда мои первые усилия по&nbsp;решению проблемы
								проваливаются, это меня
								обескураживает&nbsp;и&nbsp;вводит в&nbsp;депрессию.
							</PollRadios>
						</div>
						<div className="q38">
							<PollRadios
								opts={vals}
								{...this.baseEditProps("social_problem_38")}
							>
								Когда решение проблемы, которое я избрал,
								не&nbsp;приносит желаемого результата, я
								не&nbsp;уделяю достаточного времени,
								чтобы&nbsp;тщательно рассмотреть причины своего
								неуспеха.
							</PollRadios>
						</div>
                    </ol>
                </div>
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Сохранить ответы
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default SocialProblemsForm;
