import React from "react";
import moment from "moment";
import "moment/locale/ru";

import MonthPickerInput from 'react-month-picker-input'
import '../../../style/LabeledMonthPicker.scss'

export default class LabeledMonthPicker extends React.Component {
    handleChange = (maskedValue, year, month) => {
        const value = moment({ year, month }).format("YYYY-MM");

        console.log({
            name: this.props.name,
            value: value,
        });
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.name,
                value: value,
            });
        }
    }

    render() {
        const value = moment(this.props.value);
        const year = value.isValid() ? value.get('year') : undefined;
        const month = value.isValid() ? value.get('month') : undefined;

        return (
            <React.Fragment>
                <label>{this.props.label}:</label>
                <MonthPickerInput
                    year={year} month={month}
                    inputProps={{
                        className:'input',
                    }}
                    lang='ru'
                    closeOnSelect={true}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        );
    }
}
