import React from "react";
import Button from "../shared/button";
import { Route } from "react-router-dom";
import { FastGraph } from "../shared/FastGraph";
import { LabeledInput } from "../shared/LabeledInput";

import moment from "moment";
import "moment/locale/ru";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class PhysiologyForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    render() {
        const F = React.Fragment;
        let fields = this.props.fields;

        return (
            <div className="appear-left">
                <div
                    className={
                        "col col-two col-last mb-1-5" +
                        (fields["_loading-pulse_person"] ? " loading" : "")
                    }
                >
                <h2 className="cb db">Pulse</h2>
                
                <div className="col col-four fl">
                    <div className="col col-four col-last fl mb cb">
                    <FastGraph
                        className="mb"
                        {...this.baseGraphProps("pulse_person")}
                        xFormat={v => moment(v).format("DD/MM")}
                        yFormat={v => Math.round(v)}
                    />
                    </div>
                </div>

                    <LabeledInput
                        {...this.baseEditProps("pulse_person")}
                        label="Pulse"
                        className="col col-two col-last"
                        units="уд./м."
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("pulse_person")}
                    />
                </div>
                
                <h2 className="cb db">Рабочее артериальное давление</h2>

                <div className="col col-four fl appear-up">
                    <div className="col col-two fl">
                        <LabeledInput
                            {...this.baseEditProps("ads_upper_usual")}
                            label={
                                <F>
                                    Верхнее рабочее давление{" "}
                                    <i className="comment red">
                                        (систолическое)
                                    </i>
                                </F>
                            }
                            className="col col-two"
                            units="мм. рт. с."
                            type="number"
                            btns={this.baseOneSaveBtns("ads_upper_usual")}
                        />
                    </div>
                    <div className="col col-two fl col-last">
                        <LabeledInput
                            {...this.baseEditProps("ads_down_usual")}
                            label={
                                <F>
                                    Нижнее рабочее давление{" "}
                                    <i className="comment">
                                        (диастолическое)
                                    </i>
                                </F>
                            }
                            className="col col-two col-last"
                            units="мм. рт. с."
                            type="number"
                            btns={this.baseOneSaveBtns("ads_down_usual")}
                        />
                    </div>
                </div>
                <div className="col col-two fl col-last appear-left">
                    <i className="comment">
                        Рабочее давление — давление, при&nbsp;котором вы
                        чувствуете себя нормально.
                    </i>
                </div>

                <h2 className="cb db">Текущее артериальное давление</h2>
                <div className="col col-four fl">
                    <div className="col col-four col-last fl mb cb">
                        <FastGraph
                            {...this.baseGraphProps([
                                "ads_down_now",
                                "ads_upper_now"
                            ])}
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v)}
                            colors={["#3883C5", "#C53838"]}
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl" +
                            (fields["_loading-ads_upper_now"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("ads_upper_now")}
                            label={
                                <F>
                                    Upper {" "}
                                    <i className="comment red">
                                        (systolic)
                                    </i>
                                </F>
                            }
                            type="number"
                            min="0"
                            className="col col-two col-last"
                            units="мм. рт. с."
                            btns={this.baseOneSaveBtns("ads_upper_now")}
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl col-last" +
                            (fields["_loading-ads_down_now"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("ads_down_now")}
                            label={
                                <F>
                                    Lower {" "}
                                    <i className="comment">(diastolic)</i>
                                </F>
                            }
                            type="number"
                            min="0"
                            className="col col-two col-last"
                            units="мм. рт. с."
                            btns={this.baseOneSaveBtns("ads_down_now")}
                        />
                    </div>
                </div>
                <div className="col col-two col-last fl">
                    <i className="comment db" style={{ paddingTop: "280px" }}>
                    Measurement of pressure with an automatic meter (tonometer) should be performed three times and the average should be displayed or the last value should be used.
                    </i>
                </div>
                <h2 className="cb">Anthropometric parameters of the body</h2>
                <div className="col col-two col-last cb">
                    <LabeledInput
                        {...this.baseEditProps("height")}
                        label="Your height"
                        className="col col-two"
                        units="cm."
                        type="number"
                        min="0"
                        btns={this.baseOneSaveBtns("height")}
                    />
                </div>
                <div
                    className={
                        "col col-two fl" +
                        (fields["_loading-weight"] ? " loading" : "")
                    }
                >
                    <FastGraph
                        {...this.baseGraphProps("weight")}
                        xFormat={v => moment(v).format("DD/MM")}
                        yFormat={v => Math.round(v)}
                        className="mb"
                    />
                    <LabeledInput
                        {...this.baseEditProps("weight")}
                        label="Current body weight"
                        className="col col-two col-last"
                        units="kg."
                        btns={this.baseOneSaveBtns("weight")}
                        type="number"
                        min="0"
                    />
                </div>
                <div className="col col-four col-last fl mb cb">
                        <FastGraph
                            {...this.ratioGraphProps([
                                "OTcm",
                                "OBcm"
                            ])}
                            label="Изменение отношения окружности талии к окружности бёдер"
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v*100)/100}
                            colors={["#3883C5", "#C53838"]}
                            minY={0.0}
                            maxY={3.0}
                            yOffset={0}
                        />
                </div>

                <div className="cb" />

                <div className="col col-two fl">

                {/* <div className="cb" /> */}
                    <div
                        className={
                            "col col-two fl col-last" +
                            (fields["_loading-OTcm"] ? " loading" : "")
                        }
                    >
                        <LabeledInput
                            {...this.baseEditProps("OTcm")}
                            label="Current waist circumference"
                            className="col col-two col-last"
                            units="cm."
                            btns={this.baseOneSaveBtns("OTcm")}
                            type="number"
                            min="0"
                        />
                    </div>
                    <div
                        className={
                            "col col-two fl" +
                            (fields["_loading-OBcm"] ? " loading" : "")
                        }
                    >
                        {/* <FastGraph
                            {...this.baseGraphProps("OBcm")}
                            xFormat={v => moment(v).format("DD/MM")}
                            yFormat={v => Math.round(v)}
                            className="mb"
                        /> */}
                        <LabeledInput
                            {...this.baseEditProps("OBcm")}
                            label="Current hips circumference"
                            className="col col-two col-last"
                            units="cm."
                            btns={this.baseOneSaveBtns("OBcm")}
                            type="number"
                            min="0"
                        />
                    </div>
                </div>
                        <div className="col col-four fl col-last">
                            <i className="comment db mb">
                            Measure the circumference of the hips with a tape measure at the most protruding point of the buttocks. Stand in front of the mirror, wrap the tape around the hips, notice the most prominent point of the buttocks, move the tape so that it intersects with this point, measure it.
                            </i>
                            <i className="comment db mb">
                            It is important that the tape is wrapped tightly, without sagging, but it is not very tight.
                            </i>
                            <i className="comment db mb">
                            For proper measurement of thigh circumference, all the above conditions must be met.
                            </i>
                        </div>
                <div className="cb" />
                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/lab",
                                        e.target,
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to section "Laboratory and functional studies"
                            </a>
                        )}
                    />
                    <Button
                        loading={this.props.loading}
                        className="fr"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default PhysiologyForm;
