import React from "react";
import Button from "../../shared/button";
import { BaseEditForm, BtnMessage } from "../base-edit-form";
import PollRadios from "./poll-radios";
import { PollLegend } from "./poll-legend";

import { Route } from "react-router-dom";

const POOL_ITEMS = [
    {
        id: "neo_ffi_1",
        description:  "Я не тревожный человек",
    },
    {
        id: "neo_ffi_2",
        description:  "Мне нравится, когда вокруг меня много людей",
    },
    {
        id: "neo_ffi_3",
        description:  "Я не люблю проводить время, мечтая о чем-то",
    },
    {
        id: "neo_ffi_4",
        description:  "Я стараюсь быть дружелюбным с каждым, кого я встречаю",
    },
    {
        id: "neo_ffi_5",
        description:  "Я содержу свои вещи в чистоте и порядке",
    },
    {
        id: "neo_ffi_6",
        description:  "Я часто чувствую, что я хуже других",
    },
    {
        id: "neo_ffi_7",
        description:  "Мне легко рассмеяться",
    },
    {
        id: "neo_ffi_8",
        description:  "Я стараюсь всегда придерживаться одного и того же способа делать что-то.",
    },
    {
        id: "neo_ffi_9",
        description:  "Я часто ссорюсь с членами моей семьи и знакомыми",
    },
    {
        id: "neo_ffi_10",
        description:  "Я всегда могу задать себе хороший темп в моей работе",
    },
    {
        id: "neo_ffi_11",
        description:  "Я иногда чувствую, что «разваливаюсь на части», когда бываю в сильном напряжении",
    },
    {
        id: "neo_ffi_12",
        description:  "Я не считаю себя очень радостным человеком",
    },
    {
        id: "neo_ffi_13",
        description:  "Меня увлекают образы, которые я нахожу в искусстве или природе",
    },
    {
        id: "neo_ffi_14",
        description:  "Некоторые люди считают меня эгоистичным и себялюбивым",
    },
    {
        id: "neo_ffi_15",
        description:  "Я не являюсь дисциплинированным человеком",
    },
    {
        id: "neo_ffi_16",
        description:  "Я редко чувствую себя несчастным",
    },
    {
        id: "neo_ffi_17",
        description:  "Я действительно очень люблю разговаривать с людьми",
    },
    {
        id: "neo_ffi_18",
        description:  "Я думаю, что спорные высказывания лекторов могут только запутать и ввести в заблуждение учащихся",
    },
    {
        id: "neo_ffi_19",
        description:  "Я скорее буду сотрудничать с другими людьми, чем соперничать с ними",
    },
    {
        id: "neo_ffi_20",
        description:  "Я стараюсь выполнять все задания, которые мне дают, добросовестно",
    },
    {
        id: "neo_ffi_21",
        description:  "Я часто чувствую себя напряженным и очень нервозным",
    },
    {
        id: "neo_ffi_22",
        description:  "Мне нравится быть там, где что-то происходит",
    },
    {
        id: "neo_ffi_23",
        description:  "Поэзия на меня почти совсем или совсем не производит впечатления",
    },
    {
        id: "neo_ffi_24",
        description:  "В отношении намерений других людей я склонен быть циничным и скептичным",
    },
    {
        id: "neo_ffi_25",
        description:  "Я имею четкий набор целей и систематично работаю для их достижения",
    },
    {
        id: "neo_ffi_26",
        description:  "Иногда я ощущаю себя абсолютно ничего не стоящим человеком",
    },
    {
        id: "neo_ffi_27",
        description:  "Я обычно предпочитаю работать один",
    },
    {
        id: "neo_ffi_28",
        description:  "Я очень люблю пробовать необычные блюда",
    },
    {
        id: "neo_ffi_29",
        description:  "Я думаю, что большинство людей будет использовать человека, если это им выгодно",
    },
    {
        id: "neo_ffi_30",
        description:  "Я теряю много времени перед тем, как приняться за работу",
    },
    {
        id: "neo_ffi_31",
        description:  "Я редко испытываю страх или тревогу",
    },
    {
        id: "neo_ffi_32",
        description:  "Я часто чувствую, будто меня переполняет энергия",
    },
    {
        id: "neo_ffi_33",
        description:  "Я редко замечаю настроения или чувства, которые вызывает разная окружающая обстановка",
    },
    {
        id: "neo_ffi_34",
        description:  "Большинство моих знакомых любит меня",
    },
    {
        id: "neo_ffi_35",
        description:  "Я много работаю, чтобы достичь своих целей",
    },
    {
        id: "neo_ffi_36",
        description:  "Меня часто злит то, как люди обращаются со мной",
    },
    {
        id: "neo_ffi_37",
        description:  "Я веселый, живой человек",
    },
    {
        id: "neo_ffi_38",
        description:  "Я думаю, что для решения личных проблем иногда следует обращаться к авторитетам",
    },
    {
        id: "neo_ffi_39",
        description:  "Некоторые люди считают меня холодным и расчетливым",
    },
    {
        id: "neo_ffi_40",
        description:  "Когда я беру на себя обязательство, то на меня определенно можно положиться",
    },
    {
        id: "neo_ffi_41",
        description:  "Слишком часто, когда дела идут плохо, я падаю духом и бросаю начатое дело",
    },
    {
        id: "neo_ffi_42",
        description:  "Я не являюсь жизнерадостным оптимистом",
    },
    {
        id: "neo_ffi_43",
        description:  "Когда я читаю стихи или смотрю на произведение искусства, то иногда я чувствую дрожь или сильное волнение",
    },
    {
        id: "neo_ffi_44",
        description:  "В своих взглядах я практичен и холоден",
    },
    {
        id: "neo_ffi_45",
        description:  "Иногда я бываю не таким исполнительным и надежным, каким следовало бы быть",
    },
    {
        id: "neo_ffi_46",
        description:  "Я редко бываю грустным или подавленным",
    },
    {
        id: "neo_ffi_47",
        description:  "У моей жизни быстрый темп",
    },
    {
        id: "neo_ffi_48",
        description:  "У меня слабый интерес к рассуждениям о природе мира или состоянии человечества",
    },
    {
        id: "neo_ffi_49",
        description:  "Я обычно стараюсь быть заботливым и внимательным",
    },
    {
        id: "neo_ffi_50",
        description:  "Я работоспособный человек, который всегда справляется со своей работой",
    },
    {
        id: "neo_ffi_51",
        description:  "Я часто чувствую себя беспомощным и хочу, чтобы мои проблемы решил кто-то другой",
    },
    {
        id: "neo_ffi_52",
        description:  "Я очень активный человек",
    },
    {
        id: "neo_ffi_53",
        description:  "Большинство моих знакомых считают меня очень любознательным человеком",
    },
    {
        id: "neo_ffi_54",
        description:  "Если мне не нравятся люди, я даю им это понять",
    },
    {
        id: "neo_ffi_55",
        description:  "Мне кажется, я никогда не смогу стать организованным человеком",
    },
    {
        id: "neo_ffi_56",
        description:  "Мне часто бывало стыдно",
    },
    {
        id: "neo_ffi_57",
        description:  "Я скорее один пойду своим путем, чем буду вести других людей",
    },
    {
        id: "neo_ffi_58",
        description:  "Я часто наслаждаюсь игрой с теориями и абстрактными идеями",
    },
    {
        id: "neo_ffi_59",
        description:  "Если мне необходимо, я готов манипулировать людьми, чтобы получить то, что хочу",
    },
    {
        id: "neo_ffi_60",
        description:  "Я стремлюсь к совершенству во всем, что я делаю",
    },
]
    .map((obj) => ({
        ...obj,
        opts: [
            'neo_ffi_1', 'neo_ffi_3', 'neo_ffi_8', 'neo_ffi_9', 'neo_ffi_12',
            'neo_ffi_14', 'neo_ffi_15', 'neo_ffi_16', 'neo_ffi_18', 'neo_ffi_23',

            'neo_ffi_24', 'neo_ffi_27', 'neo_ffi_29', 'neo_ffi_30', 'neo_ffi_31',
            'neo_ffi_33', 'neo_ffi_38', 'neo_ffi_39', 'neo_ffi_42', 'neo_ffi_44',

            'neo_ffi_45', 'neo_ffi_46', 'neo_ffi_48', 'neo_ffi_54', 'neo_ffi_55',
            'neo_ffi_57', 'neo_ffi_59',

        ].includes(obj.id)
            ? [4, 3, 2, 1, 0] : [0, 1, 2, 3, 4],
        titles: ['5.', '4.', '3.', '2.', '1.'],

    }))



export default class NeoFfiForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.state = { error: "", success: "" };
    }
    handleChange = v => {
        var fields = {};
        fields[v.name] = v.value;

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    };

    handleSaveClick = history => {
        if (this.props.onSave) this.props.onSave(this.props.form, history);
    };


    render () {

        const renderedPoolItems = (
            <ol className="poll-reset">
                {POOL_ITEMS.map(({ id, description, opts, titles }) => (
                    <PollRadios
                        key={id}
                        opts={opts}
                        titles={titles}
                        {...this.baseEditProps(id)}
                    >
                        {description}
                    </PollRadios>
                ))}
            </ol>
        )


        return (
            <div className="appear-left">
                <i className="comment db mb-1-5">
                    {/* Скопированно из stability.jsx */}
                    Ответьте, пожалуйста, на несколько вопросов
                    о себе. Выбирайте тот ответ, который наилучшим образом
                    отражает Ваше мнение. Здесь нет правильных или неправильных
                    ответов, так как важно только Ваше мнение. Просьба работать
                    в темпе, подолгу не задумываясь над ответами.
                    Работайте последовательно, не пропуская вопросов.
                </i>
                
                <div className="poll-header">
                    <h2 className="db cb">
                        Опросник личностных черт (методика NEO-FFI)
                    </h2>

                    <PollLegend
                        className="pt2 pb2"
                        opts={[
                            { key: "1", title: "Абсолютно не согласен" },
                            { key: "2", title: "Не согласен" },
                            { key: "3", title: "Нейтрально отношусь" },
                            { key: "4", title: "Согласен" },
                            { key: "5", title: "Абсолютно согласен" },
                        ]}
                    />
                </div>

                <div className="cb" />
                <div className="col col-six col-last mb-1-5 mt-1-5">
                    {renderedPoolItems}
                </div>

                <div className="btn-container mt">
                    <Route
                        render={({ history }) => (
                            <Button
                                className="fr"
                                onClick={() => this.handleSaveClick(history)}
                            >
                                Save answers
                            </Button>
                        )}
                    />
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}
