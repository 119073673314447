import React from "react";
import Header from "./header";
import { Redirect } from "react-router-dom";

import "../../css/pages/auth.css";
import { Api } from "../../libs/api";

export class AuthStartForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { redirect: "" };
    }
    componentDidMount() {
        Api.getProfileCard()
            .then(x => {
                this.setState({ redirect: <Redirect to="/edit/profile" /> });
            })
            .catch(x => {
                this.setState({ redirect: <Redirect to="/auth" /> });
            });
    }
    render() {
        return (
            <div className="auth">
                <Header />
                <div className="form form-auth form-full">
                    <h2>Подождите пару секунд...</h2>{" "}
                    <i className="comment">
                        (Производится загрузка компонентов системы)
                    </i>
                    <div className="cb" />
                    {this.state.redirect}
                </div>
            </div>
        );
    }
}
