import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { DatePickerInput } from "rc-datepicker";
import "../../../css/comp/rc-datepicker.css";

export class LabeledDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(...a) {
        if (this.props.onChange)
            this.props.onChange({
                name: this.props.name,
                value: moment(a[0]).format("YYYY-MM-DD")
            });
    }
    render() {
        return (
            <React.Fragment>
                <label>{this.props.label}:</label>
                <DatePickerInput
                    onChange={this.handleChange}
                    value={this.props.value}
                    className="input"
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    startMode={this.props.startMode}
                    showOnInputClick
                />
            </React.Fragment>
        );
    }
}

export default LabeledDatePicker;
