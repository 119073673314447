import React from "react";
import { Route } from "react-router-dom";
import Button from "../shared/button";
import { Check } from "../shared/CustomCheck";
import { Radio, RadioGroup } from "../shared/CustomRadio";
import { BaseEditForm, BtnMessage } from "./base-edit-form";

export class LifestyleForm extends BaseEditForm {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.state = { error: "", success: "" };
    }
    handleChange(v) {
        var fields = {};
        fields[v.name] = v.value;

        if (v.name === "smoking_status" && (v.value === 0 || v.value === 4)) {
            fields["smoking"] = -1;
            fields["cigaretts_per_day"] = -1;
        }

        if (v.name === "alco_now" && v.value === 0) {
            fields["alco"] = -1;
        }

        if (this.props.onChange)
            this.props.onChange({ name: this.props.form, fields });
    }

    componentDidMount() {
        console.log(this.fieldsOnForm.join("','"));
    }

    onRequestClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        let fields = this.props.fields || {};
        return (
            <div className="appear-left">
                <div className="col col-two col-last mb-1-5">
                    <label>Your assessment of sleep quality:</label>
                    <Check
                        className="col col-two col-last"
                        {...this.baseEditProps("bad_sleep")}
                    >
                        Frequent awakenings
                    </Check>
                    <Check
                        className="col col-two col-last"
                        {...this.baseEditProps("sleepless")}
                    >
                        Long sleep
                    </Check>
                </div>
                <h2 className="db cb">Smoking</h2>
                <div className="col col-two fl mb-1-5">
                    <label>Currently:</label>
                    <RadioGroup {...this.baseEditProps("smoking_status")}>
                        <Radio value={0} className="col col-two">
                        Never smoked
                        </Radio>
                        <Radio value={1} className="col col-two">
                        Continue to smoke
                        </Radio>
                        <Radio value={2} className="col col-two">
                        Quit less than a year ago
                        </Radio>
                        <Radio value={3} className="col col-two">
                        Quit over a year ago
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["smoking_status"], -1) > 0 && this.emptyOrDef(fields["smoking_status"], -1) < 4 && (
                    <React.Fragment>
                        <div className="col col-two fl appear-up">
                            <label>Duration:</label>
                            <RadioGroup {...this.baseEditProps("smoking")}>
                                <Radio value={0} className="col col-two">
                                Less than 5 years
                                </Radio>
                                <Radio value={1} className="col col-two">
                                From 6 to 10 years
                                </Radio>
                                <Radio value={2} className="col col-two">
                                More than 10 years
                                </Radio>
                            </RadioGroup>
                        </div>
                        <div className="col col-two col-last fl appear-left">
                            <label>Number of cigarettes per day:</label>
                            <RadioGroup
                                {...this.baseEditProps("cigaretts_per_day")}
                            >
                                <Radio value={0} className="col col-two">
                                    Less than 5
                                </Radio>
                                <Radio value={1} className="col col-two">
                                    From 5 to 10
                                </Radio>
                                <Radio value={2} className="col col-two">
                                    From 11 to 20
                                </Radio>
                                <Radio value={3} className="col col-two">
                                    More than 20
                                </Radio>
                            </RadioGroup>
                        </div>
                    </React.Fragment>
                )}
                <div className="cb" />
                <h2 className="db cb">Alcohol abuse</h2>
                <i className="db comment col col-six mb">
                Excessive consumption of alcoholic beverages is considered the consumption per day of more than 45 ml. strong 40 ° drinks (vodka, brandy) or 150 ml. dry (10 °) wine or 500 ml. of beer
                </i>
                <div className="col col-two fl mb-1-5">
                    <label>Currently:</label>
                    <RadioGroup {...this.baseEditProps("alco_now")}>
                        <Radio value={0} className="col col-two">
                        Never used to drink
                        </Radio>
                        <Radio value={1} className="col col-two">
                        Keep going
                        </Radio>
                        <Radio value={2} className="col col-two">
                        Stopped
                        </Radio>
                    </RadioGroup>
                </div>
                {this.emptyOrDef(fields["alco_now"], -1) > 0 && (
                    <React.Fragment>
                        <div className="col col-two fl appear-up">
                            <label>Duration:</label>
                            <RadioGroup {...this.baseEditProps("alco")}>
                                <Radio value={0} className="col col-two">
                                Less than 5 years
                                </Radio>
                                <Radio value={1} className="col col-two">
                                From 6 to 10 years
                                </Radio>
                                <Radio value={2} className="col col-two">
                                More than 10 years
                                </Radio>
                            </RadioGroup>
                        </div>
                        {/* <div className="col col-two fl col-last mb-1-5 appear-left">
                            <div className="info-baloon mt-label mb">
                                Более точные данные о характере потребления
                                алкоголя вы можете внести, пройдя специальный
                                тест
                            </div>
                            <Button
                                to="/edit/polls/alcohol"
                                className="col col-two col-last"
                            >
                                Приступить к выполнению
                            </Button>
                        </div> */}
                    </React.Fragment>
                )}
                <h2 className="db cb">Activity</h2>
                <div className="col col-four fl mb-1-5">
                    <label>Physical activity:</label>
                    <RadioGroup {...this.baseEditProps("move_activ")}>
                        <Radio value={0} className="col col-four col-last">
                        Very high activity
                            <i className="comment db">
                            (I walk more than 1.5 hours a day. I do extra physical activity 5 or more times a week (any kind of)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                        High activity
                            <i className="comment db">
                            (I walk more than 1 hour a day. I do more than 3 times a week with additional physical activity (any kind of)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                        Moderate activity
                            <i className="comment db">
                            (I walk on foot at least 1 hour a day. I do 2-3 times a week with additional physical activity (any kind of)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                        Low activity
                            <i className="comment db">
                            (I walk less than 30 minutes a day. I have no other physical activity)
                            </i>
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                        Very low activity
                            <i className="comment db">
                            (I walk less than 15 minutes a day. I have no other physical activity))
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-two col-last fl">
                    <label>
                        Restrictions on mobility, including due to injuries, disabilities and other diseases:
                    </label>
                    <RadioGroup {...this.baseEditProps("move_problem")}>
                        <Radio value={1} className="col fl">
                            Yes
                        </Radio>
                        <Radio value={0} className="col col-last fl">
                            No
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                    <div className="col col-two fl col-last mb-1-5 appear-left">
                        <div className="info-baloon with-glyph mt-label mb">
                            <div className="glyph google-fit" />
                                Click the button below to get data from Google Fit
                            </div>
                            <Route
                                render={({ history }) => (
                                    <Button
                                        className="col col-two col-last"
                                        onClick={e =>
                                            this.props.goTo(
                                                history,
                                                "/eng/edit/devices",
                                                e.target, 
                                                true
                                            )
                                        }
                                    >
                                        Plug
                                    </Button>
                                )}
                            />
                    </div>
                </div>
                <div className="cb" />
                <div className="col col-four mb-1-5">
                    <label>
                    How long have you maintained this level of motor activity?
                    </label>
                    <RadioGroup {...this.baseEditProps("move_duration")}>
                        <Radio value={0} className="col col-two col-last">
                            Less than 5 years
                        </Radio>
                        <Radio value={1} className="col col-two col-last">
                            Last 5 years or more
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="cb" />

                <div className="col col-six col-last mb-1-5">
                    <label>
                    Choose the maximum level of activity, during which you do not experience discomfort, shortness of breath, chest pain or dizziness:
                    </label>
                    <RadioGroup {...this.baseEditProps("activity_status")}>
                        <Radio value={0} className="col col-four col-last">
                        Serve yourself, eat, dress, perform hygienic procedures
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                        Move around the apartment
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                        Walk a distance of 100 meters on flat ground at a speed of 3-5 km / h
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                        Do light housework, such as dusting, washing dishes
                        </Radio>
                        <Radio value={4} className="col col-four col-last">
                        Climb 1-2 flights of stairs or go uphill
                        </Radio>
                        <Radio value={5} className="col col-four col-last">
                        Run a short distance
                        </Radio>
                        <Radio value={6} className="col col-four col-last">
                        Do heavy chores such as scrub floors, lift or move furniture
                        </Radio>
                        <Radio value={7} className="col col-four col-last">
                        Doing sports with moderate energy costs, such as bowling, dancing, playing doubles
                        </Radio>
                        <Radio value={8} className="col col-four col-last">
                        Play sports with high energy costs, such as swimming, tennis, football, basketball, skiing
                        </Radio>
                        <div className="cb" />
                    </RadioGroup>
                </div>
                <h2>Other</h2>
                <div className="col col-four mb-1-5">
                    <label>
                    Intensity of using social networks and messengers:
                    </label>
                    <RadioGroup {...this.baseEditProps("social_net")}>
                        <Radio value={0} className="col col-four col-last">
                        Low{" "}
                            <i className="comment db">
                            (I go less than 1-2 times a day for no more than 2 hours in total)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                        Average{" "}
                            <i className="comment db">
                            (I spend from 2 to 4 hours during the day)
                            </i>
                        </Radio>
                        <Radio value={2} className="col col-four col-last">
                        High
                            <i className="comment db">
                            (I spend 5 hours during the day)
                            </i>
                        </Radio>
                        <Radio value={3} className="col col-four col-last">
                        Not using
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>Lighting:</label>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("IDLL3H")}
                    >
                        Daylight stay less than 3 hours per day
                    </Check>
                    <Check
                        className="col col-four col-last"
                        {...this.baseEditProps("HMLN")}
                    >
                        Artificial lighting at night
                    </Check>
                </div>
                <div className="col col-four col-last mb-1-5">
                    <label>Working conditions:</label>
                    <RadioGroup {...this.baseEditProps("hight_job_perdomance")}>
                        <Radio value={2} className="col col-four col-last">
                            Normal working hours
                            <i className="comment db">
                            (40 working hours per week)
                            </i>
                        </Radio>
                        <Radio value={0} className="col col-four col-last">
                            Irregular working hours
                            <i className="comment db">
                            (48 to 54 working hours per week)
                            </i>
                        </Radio>
                        <Radio value={1} className="col col-four col-last">
                            High workload
                            <i className="comment db">
                            (from 55 and more working hours per week)
                            </i>
                        </Radio>
                    </RadioGroup>
                </div>
                <div className="btn-container mt">
                <Route
                        render={({ history }) => (
                            <a
                                href="javascript:void(0)"
                                onClick={e =>
                                    this.props.goTo(
                                        history,
                                        "/eng/edit/food",
                                        e.target, 
                                        true
                                    )
                                }
                                className="fr glyph glyph-right-round cnt-link ml"
                            >
                                Go to section "Nutrition"
                            </a>
                        )}
                    />
                    <Button
                        className="fr"
                        loading={this.props.loading}
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </Button>
                    <BtnMessage
                        error={this.props.fields._error}
                        success={this.props.fields._success}
                    />
                </div>
                <div className="cb" />
            </div>
        );
    }
}

export default LifestyleForm;
